import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  label: {
    width: 'fit-content',
    borderRadius: (props) => props.borderRadius || 'none',
    textTransform: 'capitalize',
    color: (props) => props.color,
    backgroundColor:(props) => props.backgroundColor,
    border: (props) => `1px solid ${props.color}`,
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    padding: '0 8px;',
    gap: '4px',
  },
});

const CqChip = ({ color, borderRadius, children, className, backgroundColor }) => {
  const classes = useStyles({ color, borderRadius, backgroundColor });
  return <div className={clsx(classes.label, className)}>{children}</div>;
};

export default CqChip;
