import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import {
  getTenantConfig,
  updateTenantCrawlConfig,
  updateTenantDiscoveryAlgorithm,
  updateTenantFindingsAlgorithm,
  getApiGatewayDiscoveryConfigs,
  getApiGatewayDiscoveryConfig,
  updateGatewayConfig,
  updateGatewayConfigStatus,
  addGatewayConfigQuery,
  getApiGatewayDiscoveryDefaultConfig,
  getEndpointClassificationDiscoveryDefaultConfig,
  updateEndpointClassificationDiscoveryConfig,
  getEndpointClassificationDiscoveryConfigs,
  updateTenantGlobalConfig,
  testCloudProvider,
  getCrawlRegions,
} from './discoveryQueries';

export const getTenantInfo = async () => {
  return await graphqlQuery(getTenantConfig);
};

export const updateTenantUserAgent = async (useragent) => {
  return await graphqlMutation(updateTenantCrawlConfig, { userAgent: useragent });
};

export const updateDiscoveryAlgorithm = async (data) => {
  return await graphqlMutation(updateTenantDiscoveryAlgorithm, { data });
};

export const updateFindingsAlgorithm = async (data) => {
  return await graphqlMutation(updateTenantFindingsAlgorithm, { data });
};

export const updateGlobalConfig = async (data) => {
  return await graphqlMutation(updateTenantGlobalConfig, {data})
}

export const testGlobalConfig = async (data) => {
  return await graphqlMutation(testCloudProvider, data)
}

export const submitGatewayConfig = async (config, name) => {
  return await graphqlMutation(updateGatewayConfig, { config: config, name: name })
}

export const submitGatewayConfigStatus = async (config) => {
  return await graphqlMutation(updateGatewayConfigStatus, { config: [config]})
}

export const addGatewayConfig = async (config) => {
  return await graphqlMutation(addGatewayConfigQuery, { config: config})
}

export const apiDefaultGatewayDiscoveryConfig = async (name) => {
  return await graphqlQuery(getApiGatewayDiscoveryDefaultConfig, {configName: name})
}

export const getEndpointDefaultConfig = async () => {
  return await graphqlQuery(getEndpointClassificationDiscoveryDefaultConfig)
}

export const updateEndpointConfig = async (config) => {
  return await graphqlMutation(updateEndpointClassificationDiscoveryConfig, {config: config})
}

export const getEndpointConfig = async () => {
  return await graphqlQuery(getEndpointClassificationDiscoveryConfigs)
}
export const getRegions = async () => {
  return await graphqlQuery(getCrawlRegions);
};
export const apiGatewayDiscoveryConfigs = async () => {
  return await graphqlQuery(getApiGatewayDiscoveryConfigs)
}

export const apiGatewayDiscoveryConfig = async (name) => {
  return await graphqlQuery(getApiGatewayDiscoveryConfig, {configName: name})
}
