import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import { styled } from '@mui/material/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: '48px',
    padding: '6px',
  },
  menuItemLabel: {
    fontSize: '14px',
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: '4px',
  color: colors.gray300,
}));

const StyledSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    paddingTop: '6px',
    color: colors.white,
    background: colors.gray600,
    fontSize: 14,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: colors.blueButtonBg,
  },
  '&.MuiInputBase-root': {
    lineHeight: '1.1876em',
  },
}));

const CqMultiSelect = ({
  label,
  value = {},
  options = [],
  onChange,
  width,
  placeholder,
  onClose,
}) => {
  const classes = useStyles();
  const selected = Object.keys(value).filter((key) => value[key]);
  const renderValue = (value) => {
    const showingPlaceholder = options.length === value.length || !value.length;
    return showingPlaceholder ? placeholder : value.join(', ');
  };

  return (
    <Grid>
      <StyledInputLabel className={classes.selectLabel}>
        {label}
      </StyledInputLabel>
      <FormControl style={{ width: width }} size="small">
        <StyledSelect
          className={classes.selectField}
          multiple
          displayEmpty
          value={selected}
          style={{ height: '35px' }}
          onChange={(e) => onChange(e.target.value)}
          onClose={onClose}
          renderValue={renderValue}
          MenuProps={{
            variant: 'menu',
            PaperProps: {
              sx: {
                maxHeight: '350px',
                border: `1px solid ${colors.fontGray}`,
              },
            },
          }}
        >
          {options.map((item, idx) => (
            <MenuItem key={idx} value={item} className={classes.menuItem}>
              <Checkbox checked={value[item] ?? false} />
              <Typography className={classes.menuItemLabel}>{item}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Grid>
  );
};

CqMultiSelect.prototype = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

export default CqMultiSelect;
