const routes = {
  home: {
    path: '/',
    name: 'Home',
  },
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    parent: 'discovery',
    icon: 'dashboard',
  },
  discovery: {
    path: '/discovery',
    name: 'API Discovery',
    parent: 'discovery',
    icon: 'endpoints',
  },
  crawls: {
    path: '/crawls',
    name: 'Crawl History',
    parent: 'discovery',
    icon: 'crawls',
  },
  domains: {
    path: '/domains',
    name: 'Domains',
    parent: 'discovery',
    icon: 'domains',
  },
  crawlCatalog: {
    path: '/domains/crawlCatalog/:domainId',
    name: 'CrawlCatalog',
    parent: 'domains',
    icon: 'domains',
  },
  notifications: {
    path: '/notifications',
    name: 'Notifications',
    parent: 'discovery',
    icon: 'notifications',
  },
  configurations: {
    path: '/configurations',
    name: 'Configurations',
    parent: 'testing',
    icon: 'configurations',
  },
  reports: {
    path: '/reports',
    name: 'Reports',
    parent: 'testing',
    icon: 'reports',
  },
  discoveryConfig: {
    path: '/spyder-discovery-config',
    name: 'Discovery',
    parent: 'crawlConfig',
    icon: 'discovery',
  },
  findingsConfig: {
    path: '/findings-config',
    name: 'Findings',
    parent: 'crawlConfig',
    icon: 'findings',
  },
  users: {
    path: '/users',
    name: 'Authentication',
    parent: 'settings',
    icon: 'users',
  },
  licenses: {
    path: '/licenses',
    name: 'Licenses',
    parent: 'settings',
    icon: 'licenses',
  },
  help: {
    path: '/help',
    name: 'Help',
    parent: 'settings',
    icon: 'help',
  },
  cspm: {
    path: '/cspm-integrations',
    name: 'CSPM Integrations',
    parent: 'integrations',
    icon: 'help',
  },
  cspmItem: {
    path: '/cspm-integrations/:integrationId',
    name: 'Cspm-Integration',
    parent: 'cspm',
  },
  apiDocs: {
    path: '/apiDocs',
    name: 'API Documentation',
    parent: 'settings',
    icon: 'doc',
  },
};

const updateRoutes = (prefix) => {
  if(prefix != null && prefix !== '' && prefix !== '/') {
    for(const route of Object.values(routes)) {
      route.path = `${prefix}${route.path}`;
    }
  }
}
updateRoutes(window.__APP_RUNTIME_CONFIG__.SPYDER_ROUTE_PREFIX || '')

export const getRoutes = () => {
  return routes;
}

export const vulnerabiltiesTypes = {
  high: [
    {
      name: 'API Endpoints with Log4j/LoNg4j Vulnerabilities',
      description: `
				Log4Shell is a high severity vulnerability (CVE-2021-44228, CVSSv3 10.0) impacting multiple versions of the
				Apache Log4j 2 utility. It was disclosed publicly via the project's GitHub on December 9, 2021. This
				vulnerability impacts Apache Log4j 2 versions 2.0 to 2.14.1.

				This vulnerability allows for unauthenticated remote code execution. Log4j is an open-source Java logging
				library and is widely used in many enterprise applications and numerous cloud services.

				LoNg4j is a variation of the Log4Shell vulnerability discovered by Cequence Security that extends
				the vulnerability finding across the broader digital supply chain of the organization. LoNg4j discovers the
				vulnerability in open-source software, 3rd party software and API-driven cloud software services that are
				linked to the organization's servers.
			`,
      risk: `
				This vulnerability is exploitable via a multitude of application specific methods. Any scenario that allows a
				remote connection to supply arbitrary data that is written to log files by an application utilizing the Log4j
				library is susceptible to exploitation.

				This vulnerability poses a significant and active real-world risk to affected systems - PLEASE
				TAKE IMMEDIATE ACTION.`,
      recommendation: `
				Upgrading to the patched versions of Log4j 2 or impacted applications will eliminate this vulnerability.

				The corrected version of this code is now available as Log4j 2.17.1 (Java 8), 2.12.4 (Java 7), and 2.3.2 (Java 6).
				Additional mitigation and risk information is available from Apache.org https://logging.apache.org/log4j/2.x/security.html',
				https://logging.apache.org/log4j/2.x/security.html

				Cequence recommends that you follow official CISA guidance to urgently remediate this.
			`,
      reference: `
				Apache: JNDI Lookup plugin support
				LunaSec blog: Guide: How To Detect nd Mitigate the Log4Shell Vulnerability (CVE-2021-44228 & CVE-2021-45046)
				NIST: CVE-2021-4104
				NIST: CVE-2021-45046
				NIST: CVE-2021-45105
				NIST: CVE-2021-45105
			`,
    },
    {
      name: 'Insecure SSL/TLS Implementation',
      description: `
				Transport Layer Security (TLS), the successor to Secure Socket Layer (SSL), is a network protocol that encrypt communications between TLS servers (e.g., websites) and TLS clients (e.g., web browsers). SSL/TLS is established with a handshake that determines what cipher suite and master secret can be used, and then uses digital certificates to make a connection between a client and server. Using the agreed-upon cipher suite, SSL/TLS uses cryptography to encode data and hashing algorithms to maintain message integrity. An insecure SSL/TLS implementation finding indicates that the SSL certificate of these servers have expired or do not match the site subdomain name.
			`,
      risk: `
				Having weaknesses in the SSL/TLS implementation can affect your organization in several ways such as the following:
					Misuse or exfiltration of data via insecure protocols
					MITM (Man-in-the-middle) attacks
					Loss of customer trust or brand reputation
					Display of warnings by browsers connecting to insecure servers`,
      recommendation: `
				To harden your SSL/TLS implementation, Cequence recommends the following steps:
				Turn off support for old or vulnerable versions of SSL/TLS protocols.
				Turn off insecure cipher suites and establish a priority of cipher suites based on their security.
				Ensure that the certificates have not expired and match the subdomains of the servers.
			`,
      reference: `
				SSL/TLS Best Practices:https://www.ssl.com/guide/ssl-best-practises/
			`,
    },
    {
      name: 'Potentially Unhandled or Internal Errors',
      description: `
				Unhandled or HTTP 500 Server response indicate that the server encountered an unexpected condition that
				prevented it from fulfilling the request. This error response is a generic "catch-all" response used when an
				unhandled exception is triggered while processing a request.

				Internal Server Errors are usually benign. However, an unhandled exception may be indicative of a security issue,
				such as a SQL injection or other targeted attacks that were unexpected by the application.
			`,
      risk: `
				The unhandled server error response may include information about the application that can help the attacker
				fine-tune their attacks to accurately target the application. For example, software developers often add stack
				traces to error messages as a debugging aid. Stack traces can tell the developer more about the sequence of
				events that led to the failure but expose sensitive aspects of the application that are valuable to a willful attacker.`,
      recommendation: `
				Consider adding consistent error handling mechanisms which can handle any user input to your API. Ideally, the
				error would return a 4xx status like '400 Bad Request' and provide meaningful details to end-users so they
				understand how to fix their request.

				Also, ensure that error messages are not providing information useful to an attacker, like a stacktrace.
			`,
      reference: `
				OWASP Guidance:  https://owasp.org/www-community/Improper_Error_Handling
			`,
    },
  ],
  medium: [
    {
      name: `Unhandled or Internal Errors`,
      description: `Unhandled or HTTP 500 Server response indicate that the server encountered an unexpected condition that prevented it from fulfilling the request. This error response is a generic "catch-all" response used when an unhandled exception is triggered while processing a request. Internal Server Errors are usually benign. However, an unhandled exception may be indicative of a security issue, such as a SQL injection or other targeted attacks that were unexpected by the application.`,
      risk: `The unhandled server error response may include information about the application that can help the attacker fine-tune their attacks to accurately target the application. For example, software developers often add stack traces to error messages as a debugging aid. Stack traces can tell the developer more about the sequence of events that led to the failure but expose sensitive aspects of the application that are valuable to a willful attacker.`,
      recommendation: `Consider adding consistent error handling mechanisms which can handle any user input to your API. Ideally, the error would return a 4xx status like '400 Bad Request' and provide meaningful details to end-users so they understand how to fix their request. Also, ensure that error messages are not providing information useful to an attacker, like a stacktrace.`,
      reference: `OWASP Guidance:https://owasp.org/www-community/Improper_Error_Handling`,
    },
    {
      name: `Non-production or Internal Application Server`,
      description: `Applications in production typically have additional security measures in place to prevent unauthorized access of sensitive data. This includes encrypting the network between the web and application servers and the database servers where the sensitive data resides. Protecting data in transit, or data in motion, is a common practice for production environments. Non-production servers are development, test, staging or other forms of non-production grade servers that are publicly accessible. These are often easily identified by looking for terms such as "dev", "staging" or "uat" in the DNS name of the servers.`,
      risk: `
			Non-production servers, when exposed publicly, may present the following challenges to an organization:
				Lower degree of security implementation: Development or test environments often have lower scales of security layers protecting them, thereby potentially exposing them to active attacks from external actors.
				Use of production test data: Some internal servers have access to production data, thereby jeopardizing the security of that data due to the lower security posture.
				Rapid changes: Development or test servers often iterate through rapid changes, thereby potentially exposing insufficiently tested code to external actors and presenting an opportunity to attackers to target them. It also provides an attacker to try and guess the production versions of the servers and laterally target those for subsequent attacks.
		`,
      recommendation: `Determine if such servers need to be exposed publicly at all. If they don't, work with network security teams to implement appropriate security policies to disable public access. If they do need to be exposed publicly, ensure that the application security posture (including Web App Firewalls, etc) is comparable to that of production servers.
		Cequence Unified API Protectionis the only solution that safeguards your organization from losses across your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales quickly. Reach out to us to explore how Cequence can be deployed to protect such non-production servers exposed publicly.`,
      reference: `Cequence:https://www.cequence.ai`,
    },
    {
      name: 'App. Health Monitoring Endpoints Exposed',
      description: `This server was found to expose one or more Application Health Monitoring endpoints, that allow users to query the health of an application using an API call. Such endpoints are usually designed as diagnostics tools and return the operational status of the application to help developers detect problems with their applications before they get escalated or cause outages.`,
      risk: `Application health monitoring endpoints often expose information about the application being monitored, thereby potentially exposing sensitive information to unauthorized users of these endpoints. Therefore such endpoints should be gated behind appropriate network and/or user access controls.`,
      recommendation: `It is recommended to protect such endpoints using an application security solution to prevent unauthorized usage or targeted DoS/DDoS attacks.`,
      reference: `Cequence Unified API Protectionis the only solution that safeguards your organization from losses across your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales quickly. Using Cequence, you can protect your applications from the full range of automated attacks and eliminate bot induced business impacts such as infrastructure cost overruns, site outages and broad damage from bot, frustrated customers. Reach out to us to explore how Cequence can be deployed to protect such servers exposed publicly.`,
    },
  ],
  low: [
    {
      name: 'User Login Or Auth. Endpoints Exposed',
      description: `This server was found to expose one or more Login or Authentication endpoints, that will allow users to login to the application server with their credentials. Such endpoints are specifically targeted by attackers using sophisticated bots to conduct password spraying or credential stuffing in Targeted Account Takeover (ATO) attacks.`,
      risk: `Attackers can target such endpoints with credential stuffing, replaying credentials obtained from another service. Or they could use password spraying by trying to brute-force access to a service by guessing the credentials repeatedly, in a short period of time. Such attacks often cause the application to have an outage due to the targeted denial of service attacks and can also result in compromises of legitimate user accounts.`,
      recommendation: `Cequence Unified API Protectionis the only solution that safeguards your organization from losses across your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales quickly. Using Cequence, you can protect your applications from the full range of automated attacks and eliminate bot induced business impacts such as infrastructure cost overruns, site outages and broad damage from lost, frustrated customers. Reach out to us to explore how Cequence can be deployed to protect such servers exposed publicly.`,
      reference: `
			Cequence API Spartan:https://www.cequence.ai/products/api-spartan/
			62% Growth in Account Takeover attacks targeting Login APIs:https://www.cequence.ai/blog/new-api-research-shows-62-growth-in-atos-targeting-login-apis/
		`,
    },
    {
      name: 'OpenAPI/Swagger Endpoints Exposed',
      description: `Swagger specification (also known as OpenAPI) is an API description format for REST APIs. A Swagger file describes the entire API, including available endpoints, operations on each endpoint, operation parameters input, and output for each operation. The server was found to expose one or more OpenAPI endpoints publicly, allowing external users to access the API description format and gaining vital information about the exposed APIs.`,
      risk: `
			OpenAPI/Swagger files can often contain misconfigurations that provide vital information to attackers to try and gan access to sensitive APIs. These can include:
				Hardcoded keys.
				Internal data regarding users, data or internal server information
				Information about insecure API endpoints that do not expect any authentication or security, or weak forms of security, in incoming requests
		`,
      recommendation: `Review all exposed OpenAPI/Swagger files to ensure that no information is exposed in them that may potentially jeopardize the security of the APIs. Use security schemes within the OpenAPI/Swagger files to ensure that the APIs are secured.`,
      reference: `Redhat OpenAPI guidance:https://appdev.consulting.redhat.com/tracks/contract-first/security-with-openapi.html#security-schemes`,
    },
    {
      name: 'GraphQL Endpoints Detected',
      description: `This server was found to expose one or more Login or Authentication endpoints, that will allow users to login to the application server with their credentials. Such endpoints are specifically targeted by attackers using sophisticated bots to conduct password spraying or credential stuffing in Targeted Account Takeover (ATO) attacks.`,
      risk: `Attackers can target such endpoints with credential stuffing, replaying credentials obtained from another service. Or they could use password spraying by trying to brute-force access to a service by guessing the credentials repeatedly, in a short period of time. Such attacks often cause the application to have an outage due to the targeted denial of service attacks and can also result in compromises of legitimate user accounts.`,
      recommendation: `Cequence Unified API Protectionis the only solution that safeguards your organization from losses across your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales quickly. Using Cequence, you can protect your applications from the full range of automated attacks and eliminate bot induced business impacts such as infrastructure cost overruns, site outages and broad damage from bot, frustrated customers. Reach out to us to explore how Cequence can be deployed to protect such servers exposed publicly.`,
      reference: `
			Cequence API Spartan:https://www.cequence.ai/products/api-spartan/
			62% Growth in Account Takeover attacks targeting Login APIs:https://www.cequence.ai/blog/new-api-research-shows-62-growth-in-atos-targeting-login-apis/
		`,
    },
  ],
};

const ISSUE_SEVERITY = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const ISSUE_TYPES = {}

export const IssueDescription = {
  "NON_PROD_OR_ORIGIN": {
      description: 'Development, test, staging or other forms of non-production servers that are publicly accessible, but may use weak security or offer access to sensitive data'
  },
  "HEALTH_MONITORING": {
      description: 'Monitoring endpoints that may expose information about applications, thereby potentially exposing sensitive information to unauthorized users'
  },
  "LOGIN": {
      description: 'Endpoints targeted by attackers using sophisticated bots to conduct password spraying or credential stuffing in targeted Account takeover (ATO) attacks'
  },
  "GRAPHQL": {
      description: 'Publicly accessible GraphQL Endpoints can be exploited by malicious actors for penetration testing, DoS attacks or to identify the structure and scheme of the backend'
  },
  "OPENAPI_SWAGGER": {
      description: 'OpenAPI endpoints that allow external users to access API specifications and gain vital information about publicly exposed APIs'
  },
  "UNHANDLED": {
      description: 'Error responses that may include information that can enable attackers to target and exploit specific vulnerabilities in an application'
  },
  "EXPOSED_FILE": {
      description: 'Publicly accessible, unencrypted cryptographic keystores (PFX, PKCS12) that include private keys that can be used to impersonate human or machine identities'
  },
  "TLS": {
      description: 'Invalid SSL/TLS certificates, incorrectly configured certificate chains and weak protocols, that weaken encrypted communications, and facilitate Man-in-the-Middle (MITM) attacks.'
  }
};