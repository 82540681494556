import React, { useEffect, useState, useContext } from 'react';
import { colors } from '../../../assets/theme';
import { styled } from '@mui/material/styles';
import spyderEnabledImg from '../../../assets/img/spyder-enabled.svg';
import cspmDisabledImg from '../../../assets/img/cspm-disabled.svg';
import {
  Link,
  Grid,
  SvgIcon,
  Typography,
  Popover,
  List,
  ListItem,
  Tooltip,
  Box,
  TextField,
  InputAdornment
} from '@mui/material';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import { SwapHoriz } from '@mui/icons-material';
import FindingsCell from '../../../components/FindingsCell';
import { abbrFormat, findingsSort } from '../../../lib/utils';
import { containsFilter } from '../gridHelpers/CustomFilters';
import getMethodImg from '../../../assets/img/get-method.svg';
import SearchIcon from '@mui/icons-material/Search';
import postMethodImg from '../../../assets/img/post-method.svg';
import { useStyles } from '../styles';
import MediationIcon from '@mui/icons-material/Mediation';
import CqDialog from '../../../components/common/CqDialog';
import DiscoveryConfig from '../../../components/DiscoveryConfig';
import FindingsConfig from './FindingsConfig';
import { WrapperContext } from '../../../components/wrapper/wrapperContext';
import GatewayCell from '../../../components/GatewayCell';
import { SearchContext } from '../serverHelpers';
import { fetchCrawlInfo } from '../provider';

const StyledSvg = styled(SvgIcon)({
  width: '20px',
  height: '20px',
  margin: 5,
});

function CustomToolbar(props) {
  const apiRef = useGridApiContext();
  const filteredRowCount = apiRef.current.getRowsCount();
  const { totalEndpointsCount } = props;
  const searchcontext = useContext(SearchContext)
  const searchStr = searchcontext.searchHostStr
  const [hostVal, setHostVal] = useState(searchStr)

  const handleHostVal = (e) => {
    setHostVal(e.target.value)
  }

  const handleHostSearch = () => {
    searchcontext.searchHost(hostVal)
  }

  const submitSearch = (e) => {
    if(e.key === 'Enter') {
      searchcontext.searchHost(hostVal)
    }
  }

  return (
    <Grid
      container
      sx={{
        height: '36px',
        border: '1px solid #333',
        paddingBottom: '8px',
        boxSizing: 'unset',
      }}
    >
      <Grid
        item
        xs={8}
        sx={{ alignItems: 'center', display: 'flex', padding: '4px' }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <TextField
            placeholder="Search Host"
            value={hostVal}
            onChange={handleHostVal}
            onKeyDown={submitSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{cursor: 'pointer'}} onClick={handleHostSearch} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{width: '300px'}}
          />
          <Typography variant='body2'>
            {Intl.NumberFormat('en-us').format(totalEndpointsCount)} Endpoints
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ justifyContent: 'end', display: 'flex' }}>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Grid>
    </Grid>
  );
}

const filterPath = (val, filter) => {
  return val.path.includes(filter);
};

const filterHost = (val, filter) => {
  return val.data.hostname.includes(filter);
};

const columns = [
  {
    headerName: 'Method',
    field: 'method',
    minWidth: '100',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => (
      <SvgIcon
        sx={{ width: 75, height: 20, margin: 0 }}
        component={params.value === 'get' ? getMethodImg : postMethodImg}
      />
    ),
  },
  {
    headerName: 'API Host',
    field: 'hostname',
    minWidth: '300',
    renderCell: (params) => (
      <Link
        onClick={() => params.value.callback(params.value.data)}
        sx={{ color: colors.blue300 }}
      >
        {params.value.data.hostname}
      </Link>
    ),
    sortComparator: (v1, v2) => (v1.data.hostname > v2.data.hostname ? -1 : 1),
    filterOperators: [containsFilter(filterHost)],
  },
  {
    headerName: 'Path',
    field: 'path',
    minWidth: '300',
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`//${params.value.host}${params.value.path}`}
        sx={{ color: colors.blue300 }}
      >
        {params.value.path}
      </Link>
    ),
    sortComparator: (v1, v2) => (v1.path > v2.path ? -1 : 1),
    filterOperators: [containsFilter(filterPath)],
  },
  {
    headerName: 'API Gateway',
    field: 'apiGateways',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => <GatewayCell list={params.value} />,
    width: '100',
    filterable: false,
  },
  {
    headerName: 'Endpoint Type',
    field: 'isApi',
    sortingOrder: ['desc', 'asc'],
    width: '100',
    filterable: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: '100',
    sortingOrder: ['desc', 'asc'],
  },
  {
    headerName: 'Content Type',
    field: 'contentType',
    sortingOrder: ['desc', 'asc'],
    minWidth: '100',
  },
  // {
  //   headerName: 'App Hosting Provider',
  //   field: 'providers',
  //   sortingOrder: ['desc', 'asc'],
  //   sortComparator: (v1, v2) => v1.name > v2.name,
  //   renderCell: (params) => <span>{params.value}</span>,
  //   minWidth: '200',
  //   filterable: false,
  // },
  {
    headerName: 'Findings',
    field: 'findings',
    renderCell: FindingsCell,
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) => findingsSort(v1?.findings, v2?.findings),
    filterable: false,
    minWidth: '180',
  },
  {
    headerName: '',
    field: 'requestResponse',
    renderCell: (params) => (
      <Tooltip title="View Request Response">
        <SwapHoriz
          onClick={() => {
            params.value.callback(params.row, 'info');
          }}
        />
      </Tooltip>
    ),
    filterable: false,
    minWidth: '25',
    sortable: false,
  },
];

const EndpointTable = ({
  endpoints,
  routeToServerDetails,
  getRequestResponse,
  totalEndpointsCount,
  crawlInfo,
  setFilters,
  filtersState
}) => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const wrapperContext = useContext(WrapperContext);
  const [endpointsData, setEndpointsData] = useState([]);
  const [findingsIssues, setFindingsIssues] = useState({ type: '', list: [] });
  const [issuesPopover, setIssuesPopover] = useState(null);
  const [findingsAlgoDialog, setFindingsAlgoDialog] = useState(false);
  const [selectedFindingAlgo, setSelectedFindingAlgo] = useState({});
  const [tenantConfig, setTenantConfig] = useState({})
  const classes = useStyles();
  const issTypes = wrapperContext.issueTypes;
  const gatewayConfigData = wrapperContext.gatewayConfig;
  const gatewayConfigObj = {};
  for (const obj of gatewayConfigData) {
    gatewayConfigObj[obj.name] = obj;
  }

  const [paginationModel, setPaginationModel] = useState({
    page: filtersState.from,
    pageSize: filtersState.size,
  });
  
  const handlePaginationModel = (pagData) => {
    setPaginationModel(prev => ({ ...prev, page: pagData.page, pageSize: pagData.pageSize }))
    setFilters({...filtersState, from: pagData.page, size: pagData.pageSize })
  }

  const showIssuePopover = async(e, type, data, list) => {
    let issuesPopover = false;
    for (const iss of list) {
      if (type === iss.severity) {
        issuesPopover = true; // show popover if atleast one item belong to severity
        break;
      }
    }
    if (issuesPopover) {
      setFindingsIssues({ type: type, list: list });
      setIssuesPopover(e.target);
      const crawlInfo = await fetchCrawlInfo(data.crawlId)
      if (crawlInfo?.config?.finding) {
        const tenantCrawlConfig = {};
        console.log(Buffer.from(crawlInfo.config.finding, 'base64').toString('utf-8'))
        tenantCrawlConfig.finding = JSON.parse(
          Buffer.from(crawlInfo.config.finding, 'base64').toString('utf-8')
        );
        setTenantConfig(tenantCrawlConfig)
      }
    }
  };

  useEffect(() => {
    let i = 0;
    const data = [];
    for (const endpoint of endpoints) {
      const gateways = endpoint.apiGateways || [];
      data.push({
        method: endpoint.method,
        path: { path: endpoint.path, host: endpoint.hostname },
        hostname: { data: endpoint, callback: routeToServerDetails },
        contentType: endpoint.contentType,
        // providers: endpoint?.providers?.name,
        findings: {
          findings: endpoint.findings,
          issues: endpoint?.findings?.issues,
          callback: showIssuePopover,
        },
        status: endpoint.status,
        crawlId: endpoint.crawlId,
        isApi: endpoint.isApi ? 'API' : 'Non API',
        hostId: endpoint.hostId,
        objectKey: endpoint.objectKey,
        // apiGateways: endpoint.apiGateways,
        id: i,
        requestResponse: { callback: (data) => getRequestResponse(data) },
        apiGateways: gateways
          .map((val) => gatewayConfigObj?.[val]?.displayName || '')
          .join(', '),
      });
      i++;
    }
    setEndpointsData(data);
  }, [endpoints]);
  const data = {
    rows: endpointsData,
    columns: columns,
    pagination: {
      paginationModel: paginationModel,
    }
  };
  const getSeverityClass = (severity) => {
    if (severity == 'HIGH') {
      return classes.severityHighLabel;
    }
    if (severity == 'MEDIUM') {
      return classes.severityMediumLabel;
    }
    if (severity == 'INFO') {
      return classes.severityLowLabel;
    }
  };

  const setQueryParam = (finding) => {
    const algo = tenantConfig?.finding?.find((i) => finding.name === i.name);
    setSelectedFindingAlgo(algo);
  };

  return (
    <div style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
      <DataGridPremium
        {...data}
        initialState={data}
        headerHeight={36}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            count: data.rows.length,
            totalEndpointsCount: totalEndpointsCount,
          },
          pagination: {
            labelDisplayedRows: function defaultLabelDisplayedRows(obj) {
              let label = ''
              if(obj.count > 0 && paginationModel.pageSize > 0){
                const pageCount = Math.round(obj.count/paginationModel.pageSize)
                label = `Page ${obj.page+1} of ${Intl.NumberFormat('en-us').format(pageCount+1)}`
              }
              return label
            },
          },
        }}
        pageSizeOptions={[10, 50, 100]}
        sx={dataGridStyles}
        disableAggregation
        disableRowGrouping
        pagination
        rowCount={totalEndpointsCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModel}
      />
      <Popover
        open={Boolean(issuesPopover)}
        anchorEl={issuesPopover}
        elevation={0}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setFindingsIssues({ type: '', list: [] });
          setIssuesPopover(null);
          setTenantConfig({});
        }}
      >
        <List className={classes.popMenuList}>
          {findingsIssues.list.map((iss, idx) => {
            return (
              <>
                {findingsIssues.type === iss.severity && (
                  <ListItem key={iss.name} className={classes.popMenuListItem}>
                    <Grid item container className={classes.popListItem}>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className={getSeverityClass(iss.severity)}
                        >
                          {issTypes?.[iss.name]?.description}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <MediationIcon
                          style={{
                            fontSize: 14,
                            cursor: 'pointer',
                            marginLeft: 10,
                          }}
                          onClick={() => {
                            setFindingsAlgoDialog(!findingsAlgoDialog);
                            setQueryParam(iss);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
              </>
            );
          })}
        </List>
      </Popover>
      <CqDialog
        title={`Findings Result for ${
          issTypes[selectedFindingAlgo?.name]?.description
        } `}
        open={findingsAlgoDialog}
        onClose={() => {
          setFindingsAlgoDialog(false);
        }}
        maxWidth="md"
        closeIcon={true}
        showSubmitBtn={false}
        showCloseBtn={false}
      >
        <FindingsConfig selectedFindingAlgo={selectedFindingAlgo} />
      </CqDialog>
    </div>
  );
};

export default EndpointTable;
