import React, {
  useState,
  useEffect,
  memo,
  useLayoutEffect,
  useRef,
} from 'react';

import { isEqual } from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

const SankeyChart = ({endpointData}) => {
  const chartRef = useRef(null);
  
  const createChart = (endpointData) => {
    var chart = am4core.create("snecky-chart", am4charts.SankeyDiagram);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    const endpoints = {}
    for(const key of Object.keys(endpointData)){
      endpoints[key] = endpointData[key] || 'NA'
    }
    let other = 0
    if(endpoints.text !=='NA'){
      other = other + endpoints.text
    }
    if(endpoints.yaml !=='NA'){
      other = other + endpoints.yaml
    }
    chart.data = [
      { from: `Total: ${endpoints.attempted}`, to: `JSON: ${endpoints.json}`, value: endpoints.json, id:"A0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `XML: ${endpoints.xml}`, value: endpoints.xml, id:"B0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `HTML: ${endpoints.html}`, value: endpoints.html, id:"C0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `Other: ${other === 0 ? 'NA' : other}`, value: other, id:"D0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `Status 4xx: ${endpoints.status4xx}`, value: endpoints.status4xx, id:"E0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `Status 5xx: ${endpoints.status5xx}`, value: endpoints.status5xx, id:"F0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `Status 404: ${endpoints.status404}`, value: endpoints.status404, id:"G0-0" },
      { from: `Total: ${endpoints.attempted}`, to: `Status 200: ${endpoints.status200}`, value: endpoints.status200, id:"H0-0" },

      // { from: `JSON: ${endpointData.json}`, to: "JSON 2xx", value: 1, id:"A1-0" },
      // { from: `JSON: ${endpointData.json}`, to: "JSON 3xx", value: 1, id:"B1-0" },
      // { from: `JSON: ${endpointData.json}`, to: "JSON 5xx", value: 1, id:"C1-0" },
      // { from: `JSON: ${endpointData.json}`, to: "JSON redirects", value: 1, id:"D1-0" },

      // { from: `XML: ${endpointData.xml}`, to: "XML 2xx", value: 1, id:"A2-0" },
      // { from: `XML: ${endpointData.xml}`, to: "XML 3xx", value: 1, id:"B2-0" },
      // { from: `XML: ${endpointData.xml}`, to: "XML 5xx", value: 1, id:"C2-0" },
      // { from: `XML: ${endpointData.xml}`, to: "XML redirects", value: 1, id:"D2-0" },

      // { from: `HTML: ${endpointData.html}`, to: "HTML 2xx", value: 1, id:"A3-0" },
      // { from: `HTML: ${endpointData.html}`, to: "HTML 3xx", value: 1, id:"B3-0" },
      // { from: `HTML: ${endpointData.html}`, to: "HTML 5xx", value: 1, id:"C3-0" },
      // { from: `HTML: ${endpointData.html}`, to: "HTML redirects", value: 1, id:"D3-0" },
      
      // { from: `Other: ${other}`, to: "Other 2xx", value: 1, id:"A3-0" },
      // { from: `Other: ${other}`, to: "Other 3xx", value: 1, id:"B3-0" },
      // { from: `Other: ${other}`, to: "Other 5xx", value: 1, id:"C3-0" },
      // { from: `Other: ${other}`, to: "Other redirects", value: 1, id:"D3-0" },
    ];

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";
    chart.dataFields.label = "label";
    chart.tooltip.disabled = true
    var nodeTemplate = chart.nodes.template;
    nodeTemplate.clickable = false;
    nodeTemplate.draggable = false;
    nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
      switch (target.parent.level) {
        case 0:
          return 1;
        case 1:
          return -15;
        default:
          return 1;
      }
    });
    return chart
  };

  useLayoutEffect(() => {
    const chart = createChart(endpointData);
    chartRef.current = chart;
    return () => {
      if (chart) chart.dispose();
    };
  }, [endpointData]);
  return (
    <>
      <div>
        <div
          id="snecky-chart"
          style={{
            marginLeft: '20px',
            width: '90%',
            height: '600px',
            overflow: 'hidden',
            marginTop: 20,
          }}
        ></div>
      </div>
    </>
  );
};

function arePropsEqual(prevProps, nextProps) {
  if (isEqual(prevProps, nextProps)) {
    return true;
  } else {
    return false;
  }
}

export default memo(SankeyChart, arePropsEqual);
