import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { colors } from '../../assets/theme';

const IFRAME_ID = 'spy-docs-iframe';

const useStyles = makeStyles((theme) => ({
  pageMain: {
    marginBottom: theme.spacing(3),
    height: '100%',
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  pageContent: {
    height: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const HelpPage = () => {
  const classes = useStyles();

  const [frameHeight, setFrameHeight] = useState();

  useEffect(() => {
    const frame = document.getElementById(IFRAME_ID);
    setTimeout(() => {
      setFrameHeight(
        frame.contentWindow.document.body.scrollHeight - 50 + 'px'
      );
    }, 1000);
  }, []);

  return (
    <div className={classes.pageMain}>
      <Grid item container className={classes.pageHeader}>
        <Typography variant="h4">Help</Typography>
      </Grid>
      <Grid container className={classes.pageContent}>
        <iframe
          id={IFRAME_ID}
          src="/docs/SpyD.WI/"
          // scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          width="100%"
          height={frameHeight}
          className={classes.docsIframe}
        ></iframe>
      </Grid>
    </div>
  );
};

export default HelpPage;
