import React, { useState, useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { wildCardRule } from '../../lib/utils';
import { Typography, Switch, Popover, Link } from '@mui/material';
import CqButton from '../../components/common/CqButton';
import RuleIcon from '@mui/icons-material/Rule';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { CrawlContext } from './crawlContext';
import { getRulesArray, getRulecatagory } from './helpers';

import { colors } from '../../assets/theme';
import { color } from '@mui/system';

const useStyles = makeStyles({
  menu: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
    width: '500px',
  },
  menuButton: {
    '& svg': {
      fontSize: '14px',
    },
  },
  flexContainer: {
    display: 'flex',
    gap: '4px',
    fontSize: '14px',
    alignItems: 'center',
  },
  ruleMenu: {
    justifyContent: 'end',
    display: 'flex',
    paddingRight: '12px',
  },
  ruleItem: {
    padding: '6px 16px',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.2)',
  },
  ruleBorder: {
    borderLeft: `1px solid ${colors.gray600}`,
    paddingLeft: '16px',
  },
  ruleName: {
    paddingRight: '16px',
    '& p': {
      width: '100px',
    },
  },
  switchContainer: {
    '& .Mui-checked': {
      color: colors.blue300,
    },
  },
  disableItem: {
    backgroundColor: color.gray700,
    color: '#6B6B6B',
  },
  createRuleLink: {
    justifyContent: 'center',
    display: 'flex',
    textDecoration: 'none',
    '& svg': {
      color: colors.blue300,
      fontSize: '16px',
    },
  },
  styledIcon: {
    cursor: 'pointer',
  },
  countBox: {
    width: '18px',
    height: '18px',
    backgroundColor: '#006169',
  },
  icons: {
    width: '75px',
  },
  rulebox: {
    width: '240px',
  },
});

const RulesItem = ({ rule, subDomainsList }) => {
  const classes = useStyles();

  const {
    dispatch,
    setDialogOpen,
    setNotify,
    domain,
    updateRuleStatus,
    rules,
    subDomains,
  } = useContext(CrawlContext);
  const setStatus = () => {
    const newStatus = !rule.active;
    const updatedRule = { ...rule, active: newStatus };
    setNotify((state) => ({ ...state, loading: true }));
    updateRuleStatus(domain, rules, updatedRule)
      .then((res) => {
        dispatch({ type: 'loadData', value: res });
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          success: true,
          message: `${rule.name} ${
            newStatus ? 'Enabled' : 'Disabled'
          } successfully!`,
        }));
      })
      .catch((e) => {
        console.log(e);
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          error: true,
          message: 'Something went wrong while updating Crawl rule!',
        }));
      });
  };
  const openDeleteRuleDialog = () => {
    setDialogOpen((state) => ({ ...state, deleteDialog: true }));
    dispatch({ type: 'setRule', value: rule });
  };
  const openRuleDialog = () => {
    setDialogOpen((state) => ({ ...state, ruleDialog: true }));
    dispatch({ type: 'setRule', value: rule });
  };
  const { category } = getRulecatagory(rules, rule);
  const ruleType = rule.type.toLowerCase();
  let ruleValue;

  ruleValue = rule[ruleType];
  if (ruleType === 'simple') {
    ruleValue = `${rule[ruleType].length} Sub-Domains ${
      category === 'Exclude' ? 'excluded' : 'included'
    }`;
  }
  if (ruleType === 'wildcard') {
    const wc = wildCardRule(rule.wildcard);
    let subdomainsImpacted = 0;
    for (const sd of subDomainsList) {
      if (wc(sd)) {
        subdomainsImpacted++;
      }
    }
    const exceptList = rule.except.length;
    const subdomainsApplied = subdomainsImpacted - exceptList;
    ruleValue = `${subdomainsApplied} Sub-Domains ${
      category === 'Exclude' ? 'excluded' : 'included'
    }`;
  }
  if (ruleType === 'regex') {
    try {
      const regexRule = new RegExp(rule.regex);
      const subdomainsImpacted = [];
      for (let sd of subDomainsList) {
        if (regexRule.test(sd)) {
          subdomainsImpacted.push(sd);
        }
      }
      const exceptList = rule.except.length;
      const subdomainsApplied = subdomainsImpacted.length - exceptList;
      ruleValue = `${subdomainsApplied} sub domains ${
        category === 'Exclude' ? 'excluded' : 'included'
      }`;
    } catch (e) {}
  }
  return (
    <div
      className={clsx(
        classes.flexContainer,
        classes.ruleItem,
        rule.active ? null : classes.disableItem
      )}
      key={rule.name}
    >
      <div className={classes.ruleName}>
        <p>{rule.name}</p>
      </div>
      <div
        className={clsx(classes.ruleName, classes.ruleBorder, classes.rulebox)}
      >
        {ruleValue}
      </div>
      <div className={clsx(classes.flexContainer, classes.icons)}>
        <Switch
          checked={rule.active}
          onChange={setStatus}
          className={classes.switchContainer}
        />
        <EditIcon className={classes.styledIcon} onClick={openRuleDialog} />
        <DeleteIcon
          className={classes.styledIcon}
          onClick={openDeleteRuleDialog}
        />
      </div>
    </div>
  );
};

const RulesMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { rules, setDialogOpen, subDomains } = useContext(CrawlContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRuleDialog = () => {
    setDialogOpen((state) => ({ ...state, ruleDialog: true }));
  };
  const rulesArray = getRulesArray(rules);
  let appliedRules = 0;
  if (rulesArray.length > 0) {
    appliedRules = rulesArray.reduce((acc, rule) => {
      return rule.active ? acc + 1 : acc;
    }, 0);
  }
  const subDomainsList = subDomains.map((sd) => sd.subdomain);
  return (
    <span className={classes.ruleMenu}>
      <CqButton
        onClick={handleClick}
        variant="gray"
        className={classes.menuButton}
      >
        <div className={classes.flexContainer}>
          <RuleIcon />
          <Typography variant="body2">Rules Applied</Typography>
          {appliedRules > 0 && (
            <div className={classes.countBox}>{appliedRules}</div>
          )}
        </div>
      </CqButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: classes.menu }}
      >
        {rulesArray.map((rule, index) => (
          <RulesItem
            rule={rule}
            key={rule.name}
            subDomainsList={subDomainsList}
          />
        ))}
        <div className={classes.createRuleLink}>
          <CqButton onClick={handleRuleDialog} className={classes.menuButton}>
            <div className={classes.flexContainer}>
              <AddCircleOutlineIcon />
              <span>Create New Rule</span>
            </div>
          </CqButton>
        </div>
      </Popover>
    </span>
  );
};

export default RulesMenu;
