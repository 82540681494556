import React, { useEffect, useState, useCallback, useReducer } from 'react';
import moment from 'moment';
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import HeaderFilters from '../../components/common/headerFilters/HeaderFilters';
import CqLoading from '../../components/common/CqLoading';
import { colors } from '../../assets/theme';
import Overview from './Overview/Overview';
import NotificationsTable from './Notifications/NotificationsTable';
import {
  getNotificationsQuery,
  getCrawlHistoryQuery, submitCrawlAnalyzeRequestQuery,
} from './DashboardQueries';
import CqButton from '../../components/common/CqButton';
import CqDialog from '../../components/common/CqDialog';
import { localStore } from '../../store/localStore';
import { graphqlQuery } from '../../services/graphql';
import Search from '../../components/Search';
import { useFiltersState, useGlobalState } from '../../store/globalState';
import DomainIcon from '@mui/icons-material/Domain';
import {formatDateTime, nowDate, twoWeekAgoTimestamp} from '../../lib/utils';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import Charts from './Charts';
import InProgress from './InProgress';
import {
  fetchDomainReport,
  retrieveDomains,
} from './provider';
import { getApiFilter } from './constants';
import TextWithIcon from '../../components/common/TextWithIcon';

import { useStyles } from './styles';
import { generatePdf } from './generatePdf';
import DomainTable from './DomainsTable';
import * as red from '../crawlCatalog/rulesReducer';
import { getRoutes } from '../../lib/constants';
import { hideNavMenu } from '../../services/auth';

const initialState = {
    overview: {
      apiServers: {total: 0},
      hostingProviders: { total: 0},
      apiEndpoints: {total: 0}
    },
    hostingProviders: [],
    issues: {high: {total: 0, issues: []}, medium: {total: 0, issues: []}, low: {}},
    domainsSummary: []
  }

const routes = getRoutes();

const DashboardPage = ({ activeDomains, showNewCrawl }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const global = useGlobalState();
  const [state, dispatch] = useReducer(red.reducer, red.initialState);
  const { config } = state;
  const { licenseInfo } = global.get();
  const filters = useFiltersState();
  const store = localStore();
  const localStorageFilters = store.getFilters('dashboardFilters');
  const { timeRange, crawlId, uapAwareness, apiHosts } = localStorageFilters
  const selectedDomain = localStorageFilters.selectedDomain || ''
  const [loading, setLoading] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [latestCrawlReport, setLatestCrawlReport] = useState(initialState);
  const [selectedServers, setSelectedServers] = useState([]);
  const [selectedOtherServers, setSelectedOtherServers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [crawlHistory, setCrawlHistory] = useState([]);
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [dialogInProgress, setDialogInProgress] = useState(false);
  const [selectedPdfDomains, setSelectedPdfDomains] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [crawlInProgress, setCrawlInProgress] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [hostTypeFilter ,setHostTypeFilter] = useState(apiHosts ? {
    isApiFilter: apiHosts === 'api' ? true : false,
    nonApiFilter: apiHosts === 'non-api' ? true : false,
    allFilter: apiHosts === 'all' ? true : false
  } : {
    isApiFilter: true,
    nonApiFilter: false,
    allFilter: false
  })
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRange || 'All'
  );
  const [selectedCspmIntegrationType, setSelectedCspmIntegrationType] = useState(uapAwareness);
  const [selectedCrawlId, setSelectedCrawlId] = useState(crawlId);
  const [showCrawlStat, setShowCrawlStat] = useState(false);
  const UAP_Awareness = {
    'Spyder Crawls': 'Spyder Crawls',
    CSPM: 'CSPM',
    Sentinel: 'Sentinel',
  };
  const [domain, setDomain] = useState(selectedDomain)
  const hostFilters = [
    {name: 'APIs only', selected: hostTypeFilter.isApiFilter || hostTypeFilter.allFilter},
    {name: 'Non APIs only', selected: hostTypeFilter.nonApiFilter || hostTypeFilter.allFilter}
  ]

  const columnData = ['Date and Time', 'Notifications', 'Risk Level'];
  const serversColumnData = ['Hosting Providers', 'API Hosts'];

  const checkCrawlInProgress = (history, crawlId) => {
    const crawlInfo = history.find(val => val.crawlId === crawlId)
    let crawlProgress = false
    if (crawlInfo &&  crawlInfo.status !== 'COMPLETED') {
      crawlProgress = true;
    }
    return crawlProgress
  }

  const fetchCrawlHistory = useCallback(async (selectedDomain) => {
    if(selectedDomain !== ''){
      let crawlHistoryData = await retrieveCrawlHistory(selectedDomain)
      crawlHistoryData = crawlHistoryData.map(crawlItem => {
        crawlItem.inprogress = crawlItem.status !== 'COMPLETED'
        return crawlItem
      })
      const latestCrawl = crawlHistoryData[0] || {}
      const latestCrawlId = latestCrawl.crawlId
      return {crawlHistoryData, latestCrawlId}
    }
    return {crawlHistoryData: [], crawlIdSelected: '', crawlProgress: false}
  }, [selectedDomain, selectedCrawlId])

  const getDashboardSummary = useCallback(async (selectedDomain, activeDomains, selectedCrawlId, crawlProgress) => {
    let domainReport = initialState
    if(selectedDomain !== '' && selectedCrawlId !== ''){
      if(!crawlProgress){
        domainReport = await fetchDomainReport([selectedDomain], selectedCrawlId);
      }
    } else if(selectedDomain === '') {
      const domainsList = activeDomains.map((val) => val.domain);
      domainReport = await fetchDomainReport(domainsList);
    }
    return domainReport
    }, [selectedDomain, activeDomains, selectedCrawlId]
  )
  const applyRefresh = () => {
    setRefreshData(!refreshData);
  }

  useEffect(async () => {
    try {
      setLoading(true);
      retrieveNotifications(domain ? [domain] : []);
      const {crawlHistoryData, latestCrawlId} = await fetchCrawlHistory(domain)
      setCrawlHistory(crawlHistoryData)
      const crawlIdSelected = domain === '' ? '' : latestCrawlId
      const crawlProgress = checkCrawlInProgress(crawlHistoryData, crawlIdSelected);
      const domainReport = await getDashboardSummary(domain, activeDomains, crawlIdSelected, crawlProgress)
      setLatestCrawlReport(domainReport)
      setCrawlInProgress(crawlProgress)
      setSelectedCrawlId(crawlIdSelected)
      setLoading(false);
    } catch (e) {
      console.error(e)
      setLoading(false);
    }
  }, [domain]);

  const fetchCrawlDatabyCrawlId = async (crawlId) => {
    try {
      if(activeDomains && activeDomains.length > 0){
        setLoading(true);
        const crawlProgress = checkCrawlInProgress(crawlHistory, crawlId);
        setCrawlInProgress(crawlProgress)
        setSelectedCrawlId(crawlId);
        filters.crawlId.set(crawlId);
        const domainReport = await getDashboardSummary(domain, activeDomains, crawlId, crawlProgress)
        const fil = store.getFilters('dashboardFilters')
        fil.crawlId = crawlId
        store.setFilters('dashboardFilters', fil);
        setLatestCrawlReport(domainReport)
        setLoading(false);
      }
    }
    catch (e) {
      console.error(e)
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(
        latestCrawlReport,
        'hostingProviders'
      )
    ) {
      pieChartServers();
    }
  }, [latestCrawlReport, apiHosts]);

  useEffect(() => {
    if (selectedOtherServers.length > 0) {
      setSelectedServers([
        ...selectedServers.slice(0, 10),
        { ...selectedServers[10], count: otherServersCount.count, countAttempted: otherServersCount.countAttempted },
      ]);
    }
  }, [selectedOtherServers]);


  const handleApiHosts = (list) => {
    filters.apiHosts.set([...list])
    const hostFilterStatus = getApiFilter(list)
    setHostTypeFilter(hostFilterStatus)
    let filterStr = 'api'
    if(hostFilterStatus.allFilter){
      filterStr = 'all'
    } else if(hostFilterStatus.nonApiFilter){
      filterStr = 'non-api'
    }
    const fil = store.getFilters('dashboardFilters')
    fil.apiHosts = filterStr
    store.setFilters('dashboardFilters', fil);
  }

  const routeToPage = (path, params) => {
    const data = {
      selectedDomain: selectedDomain,
      crawlId: selectedCrawlId,
      timeRange: selectedTimeRange,
      uapAwareness: selectedCspmIntegrationType,
      apiHosts: [{name: 'API Hosts', selected: hostTypeFilter.isApiFilter}, {name: 'Non API Hosts', selected: hostTypeFilter.nonApiFilter}],
      selectedIssueType: params.selectedIssueType  || {}
    };

    store.setFilters('serverFilters', {...data, ...params})
    navigate(path);
  };

  const retrieveNotifications = async (domainsList) => {
    setNotificationsLoading(true);
    const params = {
      selectedDomains: domainsList,
      startTime: nowDate(),
      endTime: twoWeekAgoTimestamp(),
    };
    try {
      let response = await graphqlQuery(getNotificationsQuery, params, global);
      const data = response.data
        ? response.data.notification.list.slice(0, 5)
        : [];
      setNotifications(data);
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setNotificationsLoading(false);
    }
  };

  const retrieveCrawlHistory = async (domain) => {
    const params = {
      crawlHistoryDomains: [domain],
    };
    try {
      let response = await graphqlQuery(getCrawlHistoryQuery, params, global);
      const data = response.data ? response.data.crawl.getCrawlHistory : [];
      return data
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  const pieChartServers = () => {
    let hostingProviders = [];
    let otherHostingProviders = [];
    let hpData = []
    if(apiHosts === 'api'){
      hpData = latestCrawlReport?.hostingProviders.filter(i => i.apiServersDiscovered !== 0).sort((a, b) => b.apiServersDiscovered - a.apiServersDiscovered)
    } else if(apiHosts === 'non-api'){
      hpData = latestCrawlReport?.hostingProviders.filter(i => i.apiServersDiscovered === 0).sort((a, b) => b.nonApiServer - a.nonApiServer)
    } else {
      hpData = latestCrawlReport?.hostingProviders.filter(i => i.apiServersAttempted !== 0).sort((a, b) => b.apiServersAttempted - a.apiServersAttempted)
    }
    hpData.slice(0, 10).forEach((i, index) => {
      const c = colors.charts[index];
      hostingProviders.push({
        name: i.name,
        count: i.apiServersDiscovered,
        nonApiCount: i.apiServersAttempted - i.apiServersDiscovered,
        countAttempted: i.apiServersAttempted,
        color: c,
        selected: true,
      });
    });
    if (hpData.length > 10) {
      const count = hpData
        .slice(10)
        .map((i, index) => {
          const c = colors.charts[index];
          otherHostingProviders.push({
            name: i.name,
            count: i.apiServersDiscovered,
            nonApiCount: i.apiServersAttempted - i.apiServersDiscovered,
            countAttempted: i.apiServersAttempted,
            selected: true,
            color: c
          });
          return {apiServersDiscovered: i.apiServersDiscovered, apiServersAttempted: i.apiServersAttempted};
        })
        .reduce((sum, val) => {
          sum.apiServersAttempted = sum.apiServersAttempted || 0 + val.apiServersAttempted || 0;
          sum.nonApiCount = sum.apiServersAttempted - sum.apiServersDiscovered,
          sum.apiServersDiscovered = sum.apiServersDiscovered || 0 + val.apiServersDiscovered || 0;
          return sum;
        }, {});
      hostingProviders.push({
        name: 'Others',
        count: count.apiServersDiscovered,
        nonApiCount: count.apiServersAttempted - count.apiServersDiscovered,
        countAttempted: count.apiServersAttempted,
        color: colors.charts[10],
        selected: true,
      });
    }
    setSelectedOtherServers(otherHostingProviders);
    setSelectedServers(hostingProviders);
  };

  const handleOtherServersSelection = (event, item) => {
    const data = selectedOtherServers.map((i) =>
      i.name === item.name ? { ...i, selected: !i['selected'] } : i
    );
    setSelectedOtherServers(data);
  };

  const handleServerSelection = (event, item) => {
    const data = selectedServers.map((i) =>
      i.name === item.name ? { ...i, selected: !i['selected'] } : i
    );
    setSelectedServers(data);
    if (item.name === 'Others') {
      const othersData = selectedOtherServers.map((i) => ({
        ...i,
        selected: event.target.checked,
      }));
      setSelectedOtherServers(othersData);
    }
  };

  const handleTableAllSelectClick = (event) => {
    const data = selectedServers.map((i) =>
      event.target.checked
        ? { ...i, selected: true }
        : { ...i, selected: false }
    );
    const othersData = selectedOtherServers.map((i) =>
      event.target.checked
        ? { ...i, selected: true }
        : { ...i, selected: false }
    );
    setSelectedOtherServers(othersData);
    setSelectedServers(data);
  };

  const handleDomainsAllSelectClick = (event) => {
    const data = selectedPdfDomains.map((i) =>
      event.target.checked
        ? { ...i, selected: true }
        : { ...i, selected: false }
    );
    setSelectedPdfDomains(data);
  };

  const handleDomainsSelection = (event, item) => {
    const data = selectedPdfDomains.map((i) =>
      i.domain === item.domain ? { ...i, selected: !i['selected'] } : i
    );
    setSelectedPdfDomains(data);
  };

  const handleExportClose = () => {
    const data = selectedPdfDomains.map((i) => {
      return { ...i, selected: false };
    });
    setSelectedPdfDomains(data);
    setOpenPdfDialog(false);
  };

  const handleDomainChange = (domain) => {
    setSelectedTimeRange('All');
    setSelectedCrawlId('');
    setSelectedCspmIntegrationType({
      'Spyder Crawls': true,
      CSPM: true,
      Sentinel: true,
    });
    setDomain(domain === 'All' ? '' : domain)
    // filters.merge({
    //   selectedDomain: domain === 'All' ? '' : domain,
    //   timeRange: 'All',
    //   crawlId: '',
    //   uapAwareness: { 'Spyder Crawls': true, CSPM: true, Sentinel: true },
    // });
    store.setFilters('dashboardFilters', {
      selectedDomain: domain === 'All' ? '' : domain,
      timeRange: 'All',
      crawlId: '',
      uapAwareness: { 'Spyder Crawls': true, CSPM: true, Sentinel: true },
    })
  };

  const exportPdfModal = () => {
    const selectedPdfDomains = activeDomains.map((i) => {
      return { ...i, selected: false };
    });
    setSelectedPdfDomains(selectedPdfDomains);
    setOpenPdfDialog(true);
  };

  const filteredServerData = selectedServers.filter((i) => i.selected === true);
  const selectedServerCount = filteredServerData.reduce((a, b) => {
    return a + b.count;
  }, 0);
  const filteredPdfDomainData = selectedPdfDomains?.filter((d) =>
    d.domain.includes(searchValue)
  );
  const selectedDomainsCount = selectedPdfDomains.filter(
    (i) => i.selected === true
  ).length;

  const otherServersCount = selectedOtherServers
    .filter(i => i.selected === true)
    .map((i) => {
        return {count: i.count, countAttempted: i.countAttempted}
    })
    .reduce((sum, val) => {
      sum.count = (sum.count || 0) + val.count;
      sum.countAttempted = (sum.countAttempted || 0) + val.countAttempted;
      return sum
    }, {});

  const updateCspmIntegrationType = (selectedTypes) => {
    const cspmFilters = selectedTypes.reduce((obj, type) => {
      if (type.selected) {
        obj[type.name] = true;
      }
      return obj;
    }, {})
    setSelectedCspmIntegrationType(cspmFilters);
    filters.uapAwareness.set(cspmFilters)
    updateDashboardSummary(activeDomains.map((val) => val.domain));
  };

  const handleTimeRangeChange = (time) => {
    setSelectedTimeRange(time);
    filters.timeRange.set(time);
  };

  const routeToServersPage = () => {
    if (hideNavMenu){
      window.parent.postMessage(routes.discovery.path, window.location.origin);
    }
    routeToPage(routes.discovery.path, {
      ...{ description: 'servers' }
    });
  };

  const handleChangeCrawl = (val) => {
    fetchCrawlDatabyCrawlId(val)
  };

  const createPdf = () => {
    generatePdf(
      setDialogInProgress,
      selectedPdfDomains,
      setOpenPdfDialog,
      licenseInfo,
      handleExportClose
    );
  };

  const handleAnalyzeCrawl = () => {
    const params = {
      domain: selectedDomain,
      crawlId: selectedCrawlId
    };
    try {
      let response = graphqlQuery(submitCrawlAnalyzeRequestQuery, params);
      const data = response.data ? response.data.discovery.submitCrawlAnalyzeRequest : [];
      return data
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };
  let filterDomains = []
  if(activeDomains && activeDomains.length > 0){
    filterDomains = [{ domain: 'All' }, ...activeDomains];
  } else {
    filterDomains = [{ domain: 'All' }]
  }

  const allDomainsReport = selectedDomain === '';
  let latestCrawlReportData = {};
  if (
    latestCrawlReport &&
    latestCrawlReport.domains &&
    latestCrawlReport.domains.length > 0
  ) {
    latestCrawlReportData = latestCrawlReport?.domains[0];
  }
  const log4jScanData = latestCrawlReportData.log4jScan || false;
  const hostingProvidersCount =
    latestCrawlReport?.overview?.hostingProviders?.total || 0;
  // Revisit chart props may be put data in React context
  const chartProps = {
    allDomainsReport,
    filteredServerData,
    hostingProvidersCount,
    selectedServers,
    serversColumnData,
    handleServerSelection,
    handleTableAllSelectClick,
    selectedServerCount,
    selectedOtherServers,
    otherServersCount,
    handleOtherServersSelection,
    setSelectedServers,
    setSelectedOtherServers,
    routeToPage,
    hostTypeFilter
  };
  let selectedCrawlDate = '';
  if(crawlInProgress){
    const crawl = crawlHistory.find(val => val.crawlId === selectedCrawlId)
    if(crawl) {
      selectedCrawlDate = moment(crawl.submittedAt || 0).format('yyyy-MM-DD');
    }
  }
  return (
    <div className={classes.pageContent}>

      <div className={classes.dashboardHeader}>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ marginBottom: 10, position: 'relative' }}
        >
          <Grid item>
            <Typography
              auto-id={'dashboard-page-title'}
              variant="h4"
              className={classes.subTitleContainer}
            >
              Dashboard
            </Typography>
          </Grid>
          {!loading &&
            !notificationsLoading &&
            activeDomains && activeDomains.length > 0 && !crawlInProgress && (
              <Grid item sx={{display: 'flex', gap: '4px'}}>
                {!allDomainsReport && <Grid item>
                  <CqButton variant="primary" height={28} onClick={() => {
                    showNewCrawl(selectedDomain, config);
                  }}>
                    <PublishedWithChangesIcon />
                    <span>Crawl Now</span>
                  </CqButton>
                </Grid>
                }
                <CqButton
                  text={'Export As PDF'}
                  height={26}
                  variant="borderBlue"
                  onClick={exportPdfModal}
                  auto-id={'export-pdf-btn'}
                ></CqButton>
              </Grid>
            )}
        </Grid>
        <HeaderFilters
          data={filterDomains}
          handleChange={handleDomainChange}
          selectedDomain={selectedDomain}
          crawlInProgress={crawlInProgress}
          crawlHistory={crawlHistory}
          onSelectCrawlId={handleChangeCrawl}
          selectedTimeRange={selectedTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
          updateCspmIntegrationType={updateCspmIntegrationType}
          selectedCspmIntegrationType={selectedCspmIntegrationType}
          uapAwareness={UAP_Awareness}
          handleTimeRangeChange={handleTimeRangeChange}
          selectedCrawlId={selectedCrawlId}
          allDomainsReport={allDomainsReport}
          loading={loading}
          applyRefresh={applyRefresh}
          crawlStats={crawlInProgress !== true && selectedDomain !== '' ? true : false}
          showCrawlStat={showCrawlStat}
          setShowCrawlStat={setShowCrawlStat}
          apiHosts={hostFilters}
          handleApiHosts={handleApiHosts}
        />
      </div>
      {(loading ||
        notificationsLoading) &&
        <CqLoading />}
      {!loading &&
        !notificationsLoading && crawlInProgress && <InProgress domain={selectedDomain} crawlId={selectedCrawlId} refreshData={refreshData} handleAnalyzeCrawl={() => handleAnalyzeCrawl()}/>}
      {!loading &&
        !notificationsLoading &&
        activeDomains && activeDomains.length > 0 && !crawlInProgress && (
          <div>
            <div className={classes.childContainer}/>
            <Overview
              data={latestCrawlReport.overview}
              loadServersPage={routeToServersPage}
              latestCrawlReport={latestCrawlReport}
              routeToPage={routeToPage}
              log4jScanData={log4jScanData}
              hostTypeFilter={hostTypeFilter}
            />
            {/*  */}
            <div className={classes.childContainer}/>
            <Grid container columnSpacing='12px'>
              <Grid item xs={12}>
                <div className={classes.hostingContainer}>
                  {selectedServers.length > 0 ? (
                    <Charts {...chartProps} />
                  ) : (
                    <Grid container justifyContent={'center'}>
                      <Grid item>
                        <InfoIcon />
                      </Grid>
                      <Grid item style={{ marginLeft: 8 }}>
                        <Typography variant={'subtitle2'}>
                          Application hosting providers are not found.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
            {allDomainsReport && (
              <>
                <Grid item container className={clsx(classes.childContainer, classes.domainContainer)}>
                  <TextWithIcon>
                    <DomainIcon
                      auto-id={'crawl-overview-icon'}
                      sx={{fill: colors.gray600}}
                    />
                    <Typography sx={{color: colors.gray200}} auto-id={'api_servers_txt'} variant={'subtitle1'}>Domains</Typography>
                    <span className={classes.numberCountStyles}>{activeDomains.length}</span>
                  </TextWithIcon>
                  <DomainTable
                    domains={latestCrawlReport.domainsSummary}
                    loading={loading}
                    handleDomainChange={handleDomainChange}
                    activeDomains={activeDomains}
                    hostTypeFilter={hostTypeFilter}
                  />
                </Grid>
              </>
            )}
            <Grid>
              <Grid container className={classes.childContainer}>
                <Typography
                  variant="subtitle1"
                  className={classes.subTitleContainer}
                  sx={{
                    lineHeight: '24px', /* 150% */
                    letterSpacing: '0.15px',
                    color: colors.gray200,
                  }}
                >
                  <NotificationsIcon
                    auto-id={`notification-icon`}
                    sx={{
                      fill: colors.gray600,
                      fontSize: '24px',
                      height: '100%',
                    }}
                  />
                  <span auto-id={`notification-txt`} style={{ marginLeft: 8 }}>
                    Notifications
                  </span>
                </Typography>
              </Grid>
              <NotificationsTable
                notifications={notifications}
                columns={columnData}
              />
              <Grid
                item
                container
                xs={12}
                className={classes.notificationsLink}
              >
                <CqButton
                  text="View All Notifications"
                  height={26}
                  withBg={false}
                  onClick={() => navigate(routes.notifications.path, {state: {selectedDomain: selectedDomain !== '' ? selectedDomain: null}})}
                  autoId={`notification-link`}
                />
              </Grid>
            </Grid>
          </div>
        )}
      <CqDialog
        title={'Export as PDF'}
        open={openPdfDialog}
        onClose={handleExportClose}
        onSubmit={createPdf}
        submitBtnDisabled={selectedDomainsCount <= 0}
        maxWidth="sm"
        backdrop={dialogInProgress}
        closeIcon={true}
      >
        <Grid container>
          <Grid item xs={12}>
            <Search
              auto-id={'domain-search-bar'}
              localSearchOp={(x) => setSearchValue(x)}
              searchLabel="Domains"
              searchColor={false}
              width={537}
              height={40}
            />
            <TableContainer className={classes.root}>
              <Table>
                <caption>
                  <Grid item container justifyContent="space-between">
                    <Grid item style={{ color: colors.white }}>
                      <Checkbox
                        auto-id={'select-all'}
                        className={classes.domainSelection}
                        checked={
                          selectedPdfDomains.filter((i) => i.selected === false)
                            .length <= 0
                        }
                        onChange={(event) => handleDomainsAllSelectClick(event)}
                      />
                      Select All
                    </Grid>
                    <Grid
                      auto-id={'total-domain-selected'}
                      item
                      style={{ color: colors.white }}
                    >
                      {`${selectedDomainsCount} / ${selectedPdfDomains.length} selected`}
                    </Grid>
                  </Grid>
                </caption>
                <TableBody>
                  {filteredPdfDomainData.map((c, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Checkbox
                          auto-id={`select-${c.domain}`}
                          className={classes.domainSelection}
                          checked={c.selected ? true : false}
                          onClick={(event) => handleDomainsSelection(event, c)}
                        />
                        {c.domain}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CqDialog>
      <CqDialog
        title={`Additional Crawl Info`}
        open={showCrawlStat}
        onClose={() => setShowCrawlStat(false)}
        maxWidth="lg"
        backdrop={dialogInProgress}
        closeIcon={true}
        showSubmitBtn={false}
        onSubmit={() => handleAnalyzeCrawl()}
        showCloseBtn={false}
        submitBtnText={'Analyze Now'}
      >
        <Grid container>
          <InProgress domain={selectedDomain} crawlId={selectedCrawlId} refreshData={false} showImage={false} handleAnalyzeCrawl={() => handleAnalyzeCrawl()} />
        </Grid>
      </CqDialog>
    </div>
  );
};

export default DashboardPage;
