import {
  getAggregateData,
  getDomainsQuery,
  getDashboardSummaryQuery,
  getCrawlProgressQuery
} from './DashboardQueries';
import {getCrawlInfo} from '../domains/domainGraphQL'
import { graphqlQuery } from '../../services/graphql';
import { getHighIssues, getLowIssues, getMediumIssues } from '../../store/constants';

const mergeFindings = (apiIssues, nonApiIssues) => {
  const issues = {}
  for(const iss of [...apiIssues, ...nonApiIssues]){
    if(!issues[iss.name]){
      issues[iss.name] = iss
    } else {
      issues[iss.name].value = issues[iss.name].value + iss.value
    }
  }
  return Object.values(issues)
}


export const retrieveDomains = async () => {
  try {
    let response = await graphqlQuery(getDomainsQuery, {}, global);
    return response?.data?.domain?.getActiveDomains || [];
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};

const getAggregateDomainData = async (domains, crawlId) => {
  const params = { domains: domains }
  if(crawlId){
    params.crawlId = crawlId
  }
  try {
    return await graphqlQuery(getAggregateData, params, global);
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};

export const getCrawlProgressData = async (domain, crawlId, setChartData) => {
  const params = { domain: domain, crawlId: crawlId }
  try {
    const response = await graphqlQuery(getCrawlProgressQuery, { domain: domain, crawlId: crawlId }, global);
    const responseCodes = response?.data?.discovery?.getCrawlProgress?.responseCodes || [];
    const res = await graphqlQuery(getCrawlInfo, { domain: domain, crawlId: crawlId });
    const crawlInfo = res?.data?.crawl?.getCrawlInfo || {};
    const totalAttempted = response?.data?.discovery?.getCrawlProgress?.total || 0
    const { activeSubdomains, totalSubdomains, submittedAt, standardScan, log4jScan } = crawlInfo;
    const crawlData = {};
    crawlData.submittedAt = submittedAt;
    crawlData.activeSubdomains = activeSubdomains;
    crawlData.standardScan = standardScan
    crawlData.log4jScan = log4jScan
    crawlData.totalSubdomains = totalAttempted > 0 ? totalSubdomains : 0;
    if (activeSubdomains > 0 && totalSubdomains > 0 && totalAttempted > 0) {
      crawlData.crawlPercent = Math.round(
        (activeSubdomains / totalSubdomains) * 100
      );
    }
    setChartData({
      hosts: {total: totalSubdomains,
        discovered: response?.data?.discovery?.getCrawlProgress?.hosts?.status},
        responseCodes: responseCodes,
        totalAttempted: totalAttempted,
        crawlInfo: crawlData,
        loading: false,
      })
  } catch (error) {
    setChartData({hosts: {total: 0, discovered: 0}, responseCodes: [], loading: false})
    console.log(`graphql query error`, error);
  }
};


export const fetchDomainReport = async(domainsList, crawlId) => {
  const report = await getAggregateDomainData(domainsList, crawlId);
  const data = report?.data?.discovery?.getDomainSummary || {};
  const apiservers = { total:  data?.hosts?.discovered || 0, attempted: data?.hosts?.attempted || 0}
  const apiEndpoints = {total: data?.endpoints?.discovered || 0, attempted: data?.endpoints?.attempted || 0}
  const hostingProvidersCount = data?.providers?.total || 0;
  const overview = {
    apiServers: apiservers,
    hostingProviders: { total: hostingProvidersCount},
    apiEndpoints: apiEndpoints
  };
  const allIssues = data?.findings?.issues || [];
  const highIssues = getHighIssues(allIssues)
  const medIssues = getMediumIssues(allIssues)
  const lowissues = getLowIssues(allIssues)
  const nonAPIissues = data?.nonApiHostsFindings?.issues || [];
  const highIssuesNonapi = getHighIssues(nonAPIissues)
  const medIssuesNonapi = getMediumIssues(nonAPIissues)
  const lowissuesNonapi = getLowIssues(nonAPIissues)
  const issues = {
    high: { total: data?.findings?.high || 0, issues: Object.values(highIssues) },
    medium: { total: data?.findings?.medium || 0, issues: Object.values(medIssues) },
    low: { total: data?.findings?.info || 0, issues: Object.values(lowissues) },
  };

  const nonApiIssues = {
    high: { total: data?.nonApiHostsFindings?.high || 0, issues: Object.values(highIssuesNonapi) },
    medium: { total: data?.nonApiHostsFindings?.medium || 0, issues: Object.values(medIssuesNonapi) },
    low: { total: data?.nonApiHostsFindings?.info || 0, issues: Object.values(lowissuesNonapi) },
  };

  const apiData = JSON.parse(JSON.stringify(issues))
  const nonApiData = JSON.parse(JSON.stringify(nonApiIssues))
  const totalIssues = {
    high: { total: apiData.high.total + nonApiData.high.total, issues: mergeFindings(apiData.high.issues, nonApiData.high.issues) },
    medium: { total: apiData.medium.total + nonApiData.medium.total, issues: mergeFindings(apiData.medium.issues, nonApiData.medium.issues) },
    low: { total: apiData.low.total + nonApiData.low.total, issues: mergeFindings(apiData.low.issues, nonApiData.low.issues) },
  };

  const hostingProviders = []
  const providersList = data?.providers?.values || []
  providersList.map((val) => {
    // if (val.discovered > 0){
      hostingProviders.push(val)
    // }
  });

  return {
    overview: overview,
    hostingProviders: hostingProvidersCount > 0 ? hostingProviders.map(val => ({ name: val.name, apiServersDiscovered: val.discovered, apiServersAttempted: val.total, nonApiServer: val.total - val.discovered })) : [],
    issues: issues,
    nonApiIssues: nonApiIssues,
    totalIssues: totalIssues,
    domainsSummary: data.domains
  };
};

export const retrieveDashboardSummary = async (
  domains,
  setIssuesLoading,
  setCrawlInProgress,
  setAttackSurfaceByIntegration,
  selectedCspmIntegrationType,
  selectedCrawlId,
  setLatestCrawlReport
) => {
  setIssuesLoading(true);
  let includeSpyderCrawls =
    Object.keys(selectedCspmIntegrationType).indexOf('Spyder Crawls') !== -1;
  let includeCSPM =
    Object.keys(selectedCspmIntegrationType).indexOf('CSPM') !== -1;
  let includeSentinel =
    Object.keys(selectedCspmIntegrationType).indexOf('Sentinel') !== -1;
  const noneSelected =
    includeSpyderCrawls === false &&
    includeCSPM === false &&
    includeSentinel === false;
  if (noneSelected === true) {
    includeSpyderCrawls = true;
    includeCSPM = true;
    includeSentinel = true;
  }

  const params = {
    query: {
      domains: domains,
      // timeRange: time || timeRange,
      crawlId: selectedCrawlId,
      includeSpyderCrawls: includeSpyderCrawls,
      includeCSPM: includeCSPM,
      includeSentinel: includeSentinel,
    },
  };
  try {
    let response = await graphqlQuery(getDashboardSummaryQuery, params);
    const data = response.data ? response.data.crawl.getCrawlSummary : {};
    // look for 3h submission time for crawl to display data
    if (Date.now() - data.latestCrawlTs <= 10800000) {
      setCrawlInProgress(true);
    } else {
      setCrawlInProgress(false);
    }
    if (domains.length === 1) {
      setLatestCrawlReport(data);
    }
    const integrations = [];
    integrations.push({
      name: 'Spyder Crawls',
      value: data.integrations?.spyderCrawls || 0,
    });
    integrations.push({ name: 'CSPM', value: data.integrations?.cspm || 0 });
    integrations.push({
      name: 'Sentinel',
      value: data.integrations?.sentinel || 0,
    });
    integrations.push({
      name: 'Spyder Crawls & CSPM',
      value: data.integrations?.spyderCspm || 0,
      sets: ['Spyder Crawls', 'CSPM'],
    });
    integrations.push({
      name: 'Spyder Crawls & Sentinel',
      value: data.integrations?.spyderSentinel || 0,
      sets: ['Spyder Crawls', 'Sentinel'],
    });
    integrations.push({
      name: 'CSPM & Sentinel',
      value: data.integrations?.cspmSentinel || 0,
      sets: ['CSPM', 'Sentinel'],
    });
    integrations.push({
      name: 'Spyder Crawls, CSPM & Sentinel',
      value: data.integrations?.spyderCspmSen || 0,
      sets: ['Spyder Crawls', 'CSPM', 'Sentinel'],
    });
    setAttackSurfaceByIntegration(integrations);
  } catch (error) {
    console.log(`graphql query error`, error);
  } finally {
    setIssuesLoading(false);
  }
};
