import React, { useContext, useEffect, useState } from 'react';
import { Typography, Grid, RadioGroup, FormControlLabel } from '@mui/material';
import {
  StyledContainer,
  Input,
  StyledButtonContainer,
  SpSwitch,
  useStyles,
  StyledDiscoveryGrid,
  StyledGroup,
  StyledLeftOperator,
  StyledVerticalOperator,
  StyledlastOperator,
  StyledContainerElm
} from './../styles';
import SpSlider from '../../../components/SpSlider';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { colors } from '../../../assets/theme';
import Box from '@mui/material/Box';
import CqButton from '../../../components/common/CqButton';
import TextWithIcon from '../../../components/common/TextWithIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { updateDiscoveryAlgorithm } from '../provider';
import { DiscoveryContext } from '../DiscoveryContext';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { StyledCircle, StyledLine, StyledOvel, StyledRectangle} from '../../../components/FlowChartItems';
import { WrapperContext } from '../../../components/wrapper/wrapperContext';
import InputWithChips from '../../../components/InputWithChips';

const DEFAULT_DISCOVERY_CONFIG = {
  json: {
    join: 'AND',
    rules: [
      {
        operator: 'gt',
        type: 'NUMBER',
        value: 1,
      },
    ],
  },
  xml: {
    join: 'AND',
    rules: [
      {
        operator: 'gt',
        type: 'NUMBER',
        value: 1,
      },
    ],
  },
  html: {
    join: 'AND',
    rules: [
      {
        operator: 'lt',
        type: 'PERCENT',
        value: 50,
      },
    ],
  },
  hostname: {
    excludes: [],
    includes: []
  },
  ignoreWhenAllRedirects: true,
  restoreToDefaults: false,
};

const DiscoveryAlgorithm = ({
  tenantConfigurations,
  setTenantConfigurations,
}) => {
  const classes = useStyles();
  const { setTenantConfig } = useContext(WrapperContext)
  const [configData, setConfigData] = useState(
    tenantConfigurations?.discoveryConfig || {}
  );

  const [loading, setLoading] = useState(false);

  const jsonConfig = Object.assign(
    DEFAULT_DISCOVERY_CONFIG.json,
    configData?.json
  );
  const [jsonCondition, setJsonCondition] = useState(
    jsonConfig.rules[0]?.type || 'PERCENT'
  );
  const [jsonNumberValue, setJsonNumberValue] = useState(
    jsonConfig.rules[0]?.type === 'NUMBER' ? jsonConfig.rules[0]?.value : ''
  );
  const [jsonPercentageValue, setJsonPercentageValue] = useState(
    jsonConfig.rules[0]?.type === 'PERCENT' ? jsonConfig.rules[0]?.value : 0
  );
  const xmlConfig = Object.assign(
    DEFAULT_DISCOVERY_CONFIG.xml,
    configData?.xml
  );
  const [xmlCondition, setXmlCondition] = useState(
    xmlConfig.rules[0]?.type || 'PERCENT'
  );
  const [xmlNumberValue, setXmlNumberValue] = useState(
    xmlConfig.rules[0]?.type === 'NUMBER' ? xmlConfig.rules[0]?.value : ''
  );
  const [xmlPercentageValue, setXmlPercentageValue] = useState(
    xmlConfig.rules[0]?.type === 'PERCENT' ? xmlConfig.rules[0]?.value : 0
  );
  
  const hostConfig = Object.assign(
    DEFAULT_DISCOVERY_CONFIG.hostname,
    configData?.hostname
  )

  const [hostnameConfig, setHostnameConfig] = useState(
    hostConfig
  );

  const htmlConfig = Object.assign(
    DEFAULT_DISCOVERY_CONFIG.html,
    configData?.html
  );
  const [htmlCondition, setHtmlCondition] = useState(
    htmlConfig.rules[0]?.type || 'PERCENT'
  );
  const [htmlNumberValue, setHtmlNumberValue] = useState(
    htmlConfig.rules[0]?.type === 'NUMBER' ? htmlConfig.rules[0]?.value : ''
  );
  const [htmlPercentageValue, setHtmlPercentageValue] = useState(
    htmlConfig.rules[0]?.type === 'PERCENT' ? htmlConfig.rules[0]?.value : 0
  );

  const [ignoreWhenAllRedirects, setIgnoreWhenAllRedirects] = useState(
    configData?.ignoreWhenAllRedirects !== false
  );
  const discoverContext = useContext(DiscoveryContext);

 //TODO change to pass the flag like finding algorithm so service will set the defaults.
  const saveDiscoveryAlgorithm = () => {
    setLoading(true);
    const data = {
      json: {
        join: 'AND',
        rules: [
          {
            operator: 'gt',
            type: jsonCondition,
            value:
              jsonCondition === 'PERCENT'
                ? jsonPercentageValue
                : jsonNumberValue,
          },
        ],
      },
      xml: {
        join: 'AND',
        rules: [
          {
            operator: 'gt',
            type: xmlCondition,
            value:
              xmlCondition === 'PERCENT' ? xmlPercentageValue : xmlNumberValue,
          },
        ],
      },
      html: {
        join: 'AND',
        rules: [
          {
            operator: 'lt',
            type: htmlCondition,
            value:
              htmlCondition === 'PERCENT'
                ? htmlPercentageValue
                : htmlNumberValue,
          },
        ],
      },
      hostname: hostnameConfig,
      ignoreWhenAllRedirects: ignoreWhenAllRedirects,
      restoreToDefaults: false,
    };
    updateDiscoveryAlgorithm(data)
      .then((res) => {
        const configData = res?.data?.updateTenantDiscoveryConfig?.config;
        setTenantConfigurations(configData);
        setTenantConfig(configData);
        discoverContext.setNotify({
          open: true,
          message: 'Saved Tenant Discovery Configuration',
          success: true,
        });
      })
      .catch(() => {
        discoverContext.setNotify({
          open: true,
          message: 'Something went wrong please try again later',
          error: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveDefaults = () => {
    const data = {
      json: {
        join: 'AND',
        rules: [
          {
            operator: 'gte',
            type: jsonCondition,
            value:
              jsonCondition === 'PERCENT'
                ? jsonPercentageValue
                : jsonNumberValue,
          },
        ],
      },
      xml: {
        join: 'AND',
        rules: [
          {
            operator: 'gte',
            type: xmlCondition,
            value:
              xmlCondition === 'PERCENT' ? xmlPercentageValue : xmlNumberValue,
          },
        ],
      },
      html: {
        join: 'AND',
        rules: [
          {
            operator: 'lte',
            type: htmlCondition,
            value:
              htmlCondition === 'PERCENT'
                ? htmlPercentageValue
                : htmlNumberValue,
          },
        ],
      },
      hostname: hostnameConfig,
      ignoreWhenAllRedirects: ignoreWhenAllRedirects,
      restoreToDefaults: true,
    };

    updateDiscoveryAlgorithm(data)
      .then((res) => {
        const data = res?.data?.updateTenantDiscoveryConfig?.config;
        setTenantConfigurations(data);
        setConfigData(data?.discoveryConfig);
        const jsonData = data?.discoveryConfig?.json;
        const xmlData = data?.discoveryConfig?.xml;
        const htmlData = data?.discoveryConfig?.html;
        const redirectsData = data?.discoveryConfig?.ignoreWhenAllRedirects;
        setJsonCondition(jsonData?.rules[0]?.type);
        setJsonNumberValue(jsonData?.rules[0]?.value);
        setJsonPercentageValue(jsonData?.rules[0]?.value);
        setXmlCondition(xmlData?.rules[0]?.type);
        setXmlNumberValue(xmlData?.rules[0]?.value);
        setXmlPercentageValue(xmlData?.rules[0]?.value);
        setHtmlCondition(htmlData?.rules[0]?.type);
        setHtmlNumberValue(htmlData?.rules[0]?.value);
        setHtmlPercentageValue(htmlData?.rules[0]?.value);
        setIgnoreWhenAllRedirects(redirectsData);
        discoverContext.setNotify({
          open: true,
          message: 'Saved Tenant Discovery Configuration to Defaults',
          success: true,
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const updateExcludes = (e) => {
    if(e.target.value !== ''){
      setHostnameConfig(val => ({...val, excludes: [...val.excludes, e.target.value]}))
    }
  }

  const deleteExcludes = (val) => () => {
    let excludes = [...hostnameConfig.excludes]
    excludes = excludes.filter(str => str !== val)
    setHostnameConfig(val => ({...val, excludes: excludes}))
  }

  const updateIncludes = (e) => {
    if(e.target.value !== ''){
      setHostnameConfig(val => ({...val, includes: [...val.includes, e.target.value]}))
    }
  }

  const deleteIncludes = (val) => () => {
    let includes = [...hostnameConfig.includes]
    includes = includes.filter(str => str !== val)
    setHostnameConfig(val => ({...val, includes: includes}))
  }

  return (
    <>
      <StyledDiscoveryGrid container>
        <Grid item xs={5}>
          <Typography variant="h4" sx={{ paddingBottom: '8px' }}>
            Customize Discovery Algorithm.
          </Typography>
          <Typography
            variant="body2"
            sx={{ width: '80%', textAlign: 'justify' }}
          >
            This rule is a way to categorize a host as an API host based on the
            types of responses its endpoints provide. It requires that HTML
            responses are less prevalent while JSON and XML responses are more
            prevalent. If all these conditions hold true, the server is
            considered an API host.
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{position: 'relative'}}>
          <StyledLeftOperator>
            <StyledCircle color="#00929D">If</StyledCircle>
            <StyledLine width="20px" color="#00929D"></StyledLine>
          </StyledLeftOperator>
          <StyledContainer>
            <Box style={{ display: 'flex', gap: '8px' }}>
              <StyledContainerElm>
                <Typography variant="body2" style={{ color: colors.gray100 }}>
                  Hostname includes
                </Typography>
              </StyledContainerElm>
              <StyledContainerElm style={{flex: '1 1 auto'}}>
                <InputWithChips
                  list={hostnameConfig.includes}
                  onChange={updateIncludes}
                  onDelete={deleteIncludes}
                  onBlur={updateIncludes}
                />
              </StyledContainerElm>
            </Box>
            <Box style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
            <StyledContainerElm>
                <Typography variant="body2" style={{ color: colors.gray100 }}>
                  Hostname excludes
                </Typography>
              </StyledContainerElm>
              <StyledContainerElm style={{flex: '1 1 auto'}}>
                <InputWithChips
                  list={hostnameConfig.excludes}
                  onChange={updateExcludes}
                  onDelete={deleteExcludes}
                />
              </StyledContainerElm>
            </Box>
          </StyledContainer>
          <StyledVerticalOperator>
            <StyledLine height="20px" color="#F4806A"></StyledLine>
            <StyledOvel color="#F4806A">Or</StyledOvel>
            <StyledLine height="20px" color="#F4806A"></StyledLine>
          </StyledVerticalOperator>
          <StyledGroup>
            <StyledContainer container style={{ marginTop: 10 }}>
              <Grid>
                <Box style={{ display: 'flex' }}>
                  <Box
                    className={classes.conditionColumn}
                    style={{ justifyContent: 'center' }}
                  >
                    <Typography variant="body2" style={{ color: colors.gray100 }}>
                      <strong>Greater</strong> than
                    </Typography>
                  </Box>
                  <Box className={classes.conditionColumn}>
                    <Box>
                      <FormControl>
                        <RadioGroup
                          onChange={(event) =>
                            setJsonCondition(event.target.value)
                          }
                          value={jsonCondition}
                        >
                          <FormControlLabel
                            value="PERCENT"
                            control={
                              <Radio
                                sx={{
                                  color: colors.white,
                                  '&.Mui-checked': { color: colors.blue300 },
                                }}
                              />
                            }
                            label={
                              <Grid
                                item
                                container
                                style={{ alignItems: 'center' }}
                              >
                                <SpSlider
                                  text={'JSON'}
                                  value={jsonCondition === 'PERCENT' ? jsonPercentageValue : 0}
                                  disabled={jsonCondition === 'NUMBER'}
                                  handleSliderChange={(event) =>
                                    setJsonPercentageValue(
                                      Number(event.target.value)
                                    )
                                  }
                                />
                              </Grid>
                            }
                          />
                          <FormControlLabel
                            value="NUMBER"
                            control={
                              <Radio
                                sx={{
                                  color: colors.white,
                                  '&.Mui-checked': { color: colors.blue300 },
                                }}
                              />
                            }
                            label={
                              <Grid
                                item
                                container
                                style={{ alignItems: 'center' }}
                              >
                                <Input
                                  value={jsonCondition === 'NUMBER' ? jsonNumberValue : ''}
                                  disabled={jsonCondition === 'PERCENT'}
                                  onChange={(event) =>
                                    setJsonNumberValue(Number(event.target.value))
                                  }
                                  inputProps={{
                                    step: 1,
                                    min: 0,
                                    type: 'NUMBER',
                                  }}
                                />
                              </Grid>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box
                    className={classes.conditionColumn}
                    style={{ justifyContent: 'center' }}
                  >
                    <Typography variant="body2" style={{ color: colors.gray100 }}>
                      of <strong>Endpoints</strong> on a host have <strong>JSON</strong> Responses
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </StyledContainer>
            <StyledVerticalOperator>
              <StyledLine height="20px" color="#F4806A"></StyledLine>
              <StyledOvel color="#F4806A">Or</StyledOvel>
              <StyledLine height="20px" color="#F4806A"></StyledLine>
            </StyledVerticalOperator>
            <StyledContainer container style={{ marginTop: 10 }}>
              <Box style={{ display: 'flex' }}>
                <Box
                  className={classes.conditionColumn}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography variant="body2" style={{ color: colors.gray100 }}>
                    <strong>Greater</strong> than
                  </Typography>
                </Box>
                <Box className={classes.conditionColumn}>
                  <Box>
                    <FormControl>
                      <RadioGroup
                        onChange={(event) => setXmlCondition(event.target.value)}
                        value={xmlCondition}
                      >
                        <FormControlLabel
                          value="PERCENT"
                          control={
                            <Radio
                              sx={{
                                color: colors.white,
                                '&.Mui-checked': { color: colors.blue300 },
                              }}
                            />
                          }
                          label={
                            <Grid item container style={{ alignItems: 'center' }}>
                              <SpSlider
                                text={'XML'}
                                value={xmlCondition === 'PERCENT' ? xmlPercentageValue : 0}
                                disabled={xmlCondition === 'NUMBER'}
                                handleSliderChange={(event) =>
                                  setXmlPercentageValue(
                                    Number(event.target.value)
                                  )
                                }
                              />
                            </Grid>
                          }
                        />
                        <FormControlLabel
                          value="NUMBER"
                          control={
                            <Radio
                              sx={{
                                color: colors.white,
                                '&.Mui-checked': { color: colors.blue300 },
                              }}
                            />
                          }
                          label={
                            <Grid item container style={{ alignItems: 'center' }}>
                              <Input
                                value={xmlCondition === 'NUMBER' ? xmlNumberValue : ''}
                                disabled={xmlCondition === 'PERCENT'}
                                onChange={(event) =>
                                  setXmlNumberValue(Number(event.target.value))
                                }
                                inputProps={{
                                  step: 1,
                                  min: 0,
                                  type: 'NUMBER',
                                }}
                              />
                            </Grid>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  className={classes.conditionColumn}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography variant="body2" style={{ color: colors.gray100 }}>
                    of <strong>Endpoints</strong> on a host have <strong>XML</strong> Responses
                  </Typography>
                </Box>
              </Box>
            </StyledContainer>
            <StyledVerticalOperator>
              <StyledLine height="20px" color="#A45C84"></StyledLine>
              <StyledOvel color="#A45C84">And</StyledOvel>
              <StyledLine height="20px" color="#A45C84"></StyledLine>
            </StyledVerticalOperator>
          <StyledContainer container style={{ marginTop: 10 }}>
            <Box style={{ display: 'flex' }}>
              <Box
                className={classes.conditionColumn}
                style={{ justifyContent: 'center' }}
              >
                <Typography variant="body2" style={{ color: colors.gray100 }}>
                  <strong>Less</strong> than
                </Typography>
              </Box>
              <Box className={classes.conditionColumn}>
                <Box>
                  <FormControl>
                    <RadioGroup
                      onChange={(event) => setHtmlCondition(event.target.value)}
                      value={htmlCondition}
                    >
                      <FormControlLabel
                        value="PERCENT"
                        control={
                          <Radio
                            sx={{
                              color: colors.white,
                              '&.Mui-checked': { color: colors.blue300 },
                            }}
                          />
                        }
                        label={
                          <Grid item container style={{ alignItems: 'center' }}>
                            <SpSlider
                              text={'HTML'}
                              value={htmlCondition === 'PERCENT' ? htmlPercentageValue : 0}
                              disabled={htmlCondition === 'NUMBER'}
                              handleSliderChange={(event) =>
                                setHtmlPercentageValue(
                                  Number(event.target.value)
                                )
                              }
                            />
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        value="NUMBER"
                        control={
                          <Radio
                            sx={{
                              color: colors.white,
                              '&.Mui-checked': { color: colors.blue300 },
                            }}
                          />
                        }
                        label={
                          <Grid item container style={{ alignItems: 'center' }}>
                            <Input
                              value={htmlCondition === 'NUMBER' ? htmlNumberValue : ''}
                              disabled={htmlCondition === 'PERCENT'}
                              onChange={(event) =>
                                setHtmlNumberValue(Number(event.target.value))
                              }
                              inputProps={{
                                step: 1,
                                min: 0,
                                type: 'NUMBER',
                              }}
                            />
                          </Grid>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box
                className={classes.conditionColumn}
                style={{ justifyContent: 'center' }}
              >
                <Typography variant="body2" style={{ color: colors.gray100 }}>
                  of <strong>Endpoints</strong> on a host have <strong>HTML</strong> Responses
                </Typography>
              </Box>
            </Box>
          </StyledContainer>
          </StyledGroup>
          <StyledVerticalOperator>
            <StyledLine height="50px" color="#339A6D"></StyledLine>
            <StyledlastOperator>
              <StyledOvel color="#00341D" space="4px" borderColor="#339A6D">Then</StyledOvel>
              <StyledLine width="80px" color="#339A6D"></StyledLine>
              <StyledRectangle color="#00341D" borderColor="#339A6D">classify as an <strong>API HOST</strong></StyledRectangle>
            </StyledlastOperator>
          </StyledVerticalOperator>
        </Grid>
      </StyledDiscoveryGrid>
      <StyledDiscoveryGrid container style={{ marginTop: 10 }}>
        <Grid item container xs={5}>
          <Grid container item>
            <Typography variant="subtitle1">Redirects</Typography>
          </Grid>
          <Grid item container>
            <Typography variant="body2" className={classes.headerText}>
              Suppress API Host if 100% of endpoints are being redirected
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={7} style={{ justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={<SpSwitch sx={{ m: 1 }} />}
            onChange={(event) =>
              setIgnoreWhenAllRedirects(event.target.checked)
            }
            checked={ignoreWhenAllRedirects}
          />
        </Grid>
      </StyledDiscoveryGrid>
      <StyledButtonContainer>
        <Grid item container style={{ justifyContent: 'flex-end' }}>
          <Grid item style={{ marginRight: 10 }}>
            <CqButton
              variant="borderBlue"
              text={'Restore To Defaults'}
              onClick={() => saveDefaults()}
            ></CqButton>
          </Grid>
          <Grid item>
            <CqButton
              variant="primary"
              onClick={() => saveDiscoveryAlgorithm()}
            >
              <TextWithIcon>
                {loading ? '...Saving' : <CheckOutlinedIcon />}
                {!loading && <span>Save</span>}
              </TextWithIcon>
            </CqButton>
          </Grid>
        </Grid>
      </StyledButtonContainer>
    </>
  );
};

export default DiscoveryAlgorithm;
