import React from 'react';
import ReactDOM from 'react-dom';

// import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import AppDescope from './AppDescope';
import AppNoAuth from './AppNoAuth';
import { useDescopeAuth } from './services/auth';

ReactDOM.render(
  <HelmetProvider>
    {useDescopeAuth() ? <AppDescope /> : <AppNoAuth />}
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
