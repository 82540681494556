import React, { useContext } from 'react';
import Crawls from './Crawls';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import EmptyState from '../../components/EmptyState';

const CrawlsIndex = () => {
  const { activeDomains, tenantConfig, issueTypes, userInfo, showEmptyState } = useContext(WrapperContext)
  return (
    activeDomains.length > 0 ? <Crawls
    domains={activeDomains}
    tenantConfig={tenantConfig}
    issueTypes={issueTypes}
    userInfo={userInfo}
  /> : <EmptyState showEmptyState={showEmptyState} />
  )
}

export default CrawlsIndex