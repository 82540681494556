import { ISSUE_TYPES } from "../../lib/utils";
const issueTypes = {...ISSUE_TYPES}
delete issueTypes['TLS'] // To be deleted
export const discoveryFilters = Object.values(issueTypes).map(itype => {
  return {
    type: itype.name,
    selected: true,
    name: itype.description
  }
})

export const timeRangeFilters = [
  {
    name: 'Last Day',
    selected: false,
  },
  {
    name: 'Last Week',
    selected: true,
  },
  {
    name: 'Last 30 days',
    selected: false,
  },
];

export const DISCOVERIES = (data) => {
  const obj = {}
  obj.highSeverityIssues = {
    name: 'High',
  }
  obj.mediumSeverityIssues = {
    name: 'Medium',
  }
  obj.lowSeverityIssues = {
    name: 'Info',
  }
  
  obj.apiServers = {
    name: 'API Hosts',
    color: 'outline',
  }

  obj.appHostingProviders = {
    name: 'App Hosting Providers',
    color: 'outline',
  }

  for(const item of data){
    for(const k in item){
      if(ISSUE_TYPES[k] && !obj[k]){
        obj[k] = {name: ISSUE_TYPES[k].description, type: 'sub',}
      }
    }
  }
  return obj
};

export const getDiscoveryIssues = (data) => {
  const output= {}
  output.highSeverityIssues = {
    name: 'High',
    color: 'pink',
  }
  output.mediumSeverityIssues = {
    name: 'Medium',
    color: 'yellow',
  }
  output.lowSeverityIssues = {
    name: 'Info',
    color: 'gray',
  }
  for(const iss of data){
    if(iss.severity === 'HIGH'){
      output[iss.name] = {name: iss.description}
    }
    if(iss.severity === 'MEDIUM'){
      output[iss.name] = {name: iss.description}
    }
    if(iss.severity === 'INFO'){
      output[iss.name] = {name: iss.description}
    }
  }
  return output
}
