import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { colors } from '../../../assets/theme';
import CqButton from "../../../components/common/CqButton";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StorageIcon from '@mui/icons-material/Storage';
import FilterItem from "./FilterItem";
import {useFiltersState} from "../../../store/globalState";

const useStyles = makeStyles(theme => ({
    filterContainer: {
		backgroundColor: colors.gray700,
		borderRadius: 5,
		height: 72,
		display: 'flex',
		width: '100%',
		gap: '20px'
	},
	filterItem: {
		width: '200px'
	},
	filterButtonContainer: {
		marginTop: '35px',
		marginLeft: '20px',
		display: 'flex',
		gap: '20px'
	},
    inputRoot: {
        margin: '10px 0px 10px 10px',
        fontSize: 32,
        fontWeight: 600,
        color: colors.white,
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 30,
        '&.MuiSelect-select': {
            paddingRight: 40,
        }
    },
    domainSelection: {
        '& .MuiSelect-icon': {
            fontSize: 36,
            top: 'calc(50% - 17px)',
        },
    },
    menuItem: {
        '&.Mui-selected': {
            backgroundColor: colors.gray600,
            color: 'white',
        },
    },
    dateContent: {
        backgroundColor: colors.gray650,
        borderRadius: 5,
        margin: '20px 0px 20px 0px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    triggerPopoverPaper: {
        minWidth: 278,
        maxWidth: 278,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        backgroundColor: colors.almostBlack,
        border: `1px solid ${colors.white}`,
    },
    triggerPopoverRoot: {
        maxHeight: '50vh',
        marginTop: theme.spacing(1),
        pointerEvents: 'none',
    },
    menuPaper: {
        maxHeight: 200,
        border: `1px solid ${colors.white}`,
    },
    subTitleContainer: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
}));

const Filters = ({ tableView, setView, setFilters }) => {
    const classes = useStyles();
    const globalFilters = useFiltersState();
    const filters = globalFilters.get();
    const [tempSelectedFilters, setTempSelectedFilters] = useState(JSON.parse(JSON.stringify(filters)));
    const [disableApply, setDisableApply] = useState(false);

    const selectMultiple = (options, pageKey, stateKey) => {
        tempSelectedFilters[pageKey][stateKey] = options;
        setTempSelectedFilters(tempSelectedFilters);
        if (stateKey === 'domains') {
            tempSelectedFilters.crawlHistoryFilters.domains.filter((i) => i.selected).length === 0 ? setDisableApply(true) : setDisableApply(false)
        }
    };

    const selectSingle = (option, pageKey, stateKey) => {
        tempSelectedFilters[pageKey][stateKey] = option;
        setTempSelectedFilters(tempSelectedFilters);
    };

    return (
        <div className={classes.filterContainer}>
            <div className={classes.filterItem}>
                <FilterItem
                    label='Domains'
                    options={tempSelectedFilters.crawlHistoryFilters.domains}
                    showAll={true}
                    multiChoice={true}
                    showSearch={true}
                    pageKey={'crawlHistoryFilters'}
                    stateKey={'domains'}
                    selectOptions={selectMultiple}
                    placeholder='All'
                    searchLabel='domain'
                />
            </div>
            {tableView &&
                <div className={classes.filterItem}>
                    <FilterItem
                        label='Findings'
                        options={filters.crawlHistoryFilters.discovery}
                        multiChoice={true}
                        showSearch={false}
                        pageKey={'crawlHistoryFilters'}
                        stateKey={'discovery'}
                        selectOptions={selectMultiple}
                        headers={false}
                        placeholder='All'
                        showAll={true}
                    />
                </div>
            }
            <div className={classes.filterItem}>
                <FilterItem
                    label='Detection Timeframe'
                    options={filters.crawlHistoryFilters.timeRange}
                    multiChoice={false}
                    showSearch={false}
                    pageKey={'crawlHistoryFilters'}
                    stateKey={'timeRange'}
                    selectOptions={selectSingle}
                    placeholder='Last Week'
                />
            </div>
            <div className={classes.filterButtonContainer}>
                <CqButton
                    text={'Apply'}
                    height={26}
                    onClick={() => setFilters({...tempSelectedFilters})}
                    variant={'primary'}
                    disabled={disableApply}
                >
                </CqButton>

                <CqButton
                    text={tableView ? 'View As Chart' : 'View As Table'}
                    height={26}
                    withBg={false}
                    onClick={() => setView(!tableView)}
                >
                    {!tableView &&
                    <StorageIcon
                        sx={{
                            color: colors.blueButtonBg,
                            fontSize: 16,
                        }}
                    />
                    }
                    {tableView &&
                    <ShowChartIcon
                        sx={{
                            color: colors.blueButtonBg,
                            fontSize: 16,
                        }}
                    />
                    }
                </CqButton>
            </div>
        </div>
    );
};



export default Filters;
