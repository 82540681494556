export const getDomainInfo = `
query GetDomainInfo($domain: String!) {
    domain {
      get(domain:$domain, includeCatalog:true, includeNonActive:true) {
        domain
        rules{
          include{
            name
            id
            type
            simple
            wildcard
            regex
            except
            active
          }
          exclude{
            name
            id
            type
            simple
            wildcard
            regex
            except
            active
          }
        }
        subdomains {
          subdomain
          active
          rule{
            id
            name
            type
          }
        }
        config {
          log4jScan
        }
      }
    }
}
`;

export const updateDomainRules = `
mutation AddUpdateDomainRules($domain: String!, $include: [CatalogRuleInput!], $exclude: [CatalogRuleInput!]) {
  domain {
    updateDomainCatalogRules(domain:$domain, rules:{
      include:$include,
      exclude: $exclude
    }, override:true) {
      success
      message
    }
  }
}
`;

export const AddSubdomain = `
mutation addSubdomains($domain: String!, $subdomains:[String!]){
  domain {
    addSubdomains(domain: $domain, subdomains: $subdomains){
      message
      success
    }
  }
}
`;
