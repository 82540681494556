import React, {useContext, useState, useEffect} from 'react';
import {Typography, Grid, Box,} from '@mui/material';

import {
  CustomTabs as Tabs,
  CustomTab as Tab,
  CustomTabPanel as TabPanel,
} from '../../components/Tabs';
import SpSnackBar from '../../components/SpSnackBar';
import {getTenantInfo} from "../DiscoveryConfig/provider";
import FindingsTable from "./FindingsTable/FindingsTable";
import {updateFindingsAlgorithm} from "../DiscoveryConfig/provider";
import CqButton from '../../components/common/CqButton';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import CqLoading from "../../components/common/CqLoading";
import { StyledPageContent, StyledPanelBox, StyledTabs } from './styles';

const removeCustom = (finding) => {
  return finding.map((item) => {
    if(item.custom === null){
      item.custom = false
    }
    return item
  })
}

const isLog4j = (findings) => {
  const log4jFinding = findings.find(item => item.name === 'LOG4J_LONG4J')
  return log4jFinding?.enabled ? true : false
}

const FindingAlgorithm = () => {
    const [tenantConfigurations, setTenantConfigurations] = useState({});
    const [defaultTenantConfiguration, setDefaultTenantConfiguration] = useState({});
    const [configData, setConfigData] = useState(tenantConfigurations?.findingsConfig || []);
    const [openFindingDialog, setOpenFindingDialog] = useState(false);
    const [openTLSFindingDialog, setOpenTLSFindingDialog] = useState(false);
    const [notify, setNotify] = useState({});
    const [openCustomFindingDialog , setOpenCustomFindingDialog] = useState(false)
    const [loading, setLoading] = useState(false);
    const [tabId, setTabId] = useState(0);
    const [deleteFinding, setDeleteFinding] = useState({})
    const wrapperContext = useContext(WrapperContext)

    useEffect(() => {
      retrieveTenantConfiguration();
    }, []);
  
    useEffect(() => {
      //coming from wrapper.js stored as the global state
      // setTenantConfigurations(wrapperContext.tenantConfig);
      setDefaultTenantConfiguration(wrapperContext.defaultTenantConfig)
    }, [wrapperContext]);

    const handleTabChange = (e, val) => {
      setTabId(val);
    };

    const retrieveTenantConfiguration = async() => {
      setLoading(true);
      try {
        const res = await getTenantInfo();
        const configData = res?.data?.getTenantConfig?.config;
        setTenantConfigurations(configData)
        setConfigData(configData?.findingsConfig || []);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    const addFinding = async (data) => {
      const findings = [...tenantConfigurations?.findingsConfig]
      findings.push(data)
      const editFindingsObj = removeCustom(findings)
      return updateFindingsAlgorithm(editFindingsObj)
      .then((res) => {
        const updatedData = res?.data?.updateTenantFindingsConfig?.config;
        setTenantConfigurations(updatedData);
        wrapperContext.setTenantConfig(updatedData)
        setConfigData(updatedData.findingsConfig)
        setNotify({
            open: true,
            message: 'Saved Configurations!',
            success: true,
        });
        setOpenCustomFindingDialog(false)
      })
      .catch(() => {
        setNotify({
          open: true,
          message: 'Something went wrong try again later!',
          success: false,
      })})
    }

    const deleteUpdateFindings = async (item) => {
      const findings = [...tenantConfigurations?.findingsConfig]
      const index = findings.findIndex(i => i.name === item.name)
      if(index !== -1){
        const archiveFinding = {...findings[index]}
        archiveFinding.status = 'DEACTIVE'
        archiveFinding.enabled = false
        findings[index] = archiveFinding
        const editFindingsObj = removeCustom(findings)
        return updateFindingsAlgorithm(editFindingsObj)
        .then((res) => {
          const updatedData = res?.data?.updateTenantFindingsConfig?.config;
          setTenantConfigurations(updatedData);
          setConfigData(updatedData.findingsConfig)
          wrapperContext.setTenantConfig(updatedData)
          setNotify({
              open: true,
              message: 'Saved Configurations!',
              success: true,
          });
        })
        .catch(() => {
          setNotify({
            open: true,
            message: 'Something went wrong try again later!',
            success: false,
        })})
        .finally(()=> setDeleteFinding({}))
      } else {
        return 
      }
    }

    const handleSubmit = async (data, type) => {
      let postData = [];
      if (type === 'RULE') {
        postData = tenantConfigurations?.findingsConfig.map(
          item => {
          if (item.name == data.name) {
            return data
          }
          return item;
        });
      }

      if (type === 'TLS') {
        postData = [...tenantConfigurations?.findingsConfig.filter(item => item?.type !== 'TLS'), ...data]
      }

      const editFindingsObj = removeCustom(postData)
      return updateFindingsAlgorithm(editFindingsObj)
      .then((res) => {
          const updatedData = res?.data?.updateTenantFindingsConfig?.config;
          setTenantConfigurations(updatedData);
          setConfigData(updatedData.findingsConfig)
          wrapperContext.setTenantConfig(configData)
          wrapperContext.setLog4jEnabled(isLog4j(updatedData.findingsConfig))
          setNotify({
              open: true,
              message: 'Saved Configurations!',
              success: true,
          });
      })
      .catch(() => {
        setNotify({
          open: true,
          message: 'Something went wrong try again later!',
          success: false,
        });
      })
      .finally(() => {
        setOpenTLSFindingDialog(false);
        setOpenCustomFindingDialog(false)
      })
    };

    const convertCombinatorToJoin = (jsonObj) => {
    let updatedJson = {...jsonObj};
    if (updatedJson?.hasOwnProperty("combinator")) {
       updatedJson = {
        ...updatedJson, // Copy all properties from the original object
        join: updatedJson.combinator, // Change the "combinator" field
      };
      delete updatedJson.id;
      delete updatedJson.combinator;
    } else if (updatedJson?.hasOwnProperty("id")) {
      delete updatedJson.id;
    }

    if (updatedJson?.hasOwnProperty("rules")) {
      updatedJson?.rules.forEach(rule => convertCombinatorToJoin(rule));
    }

    return updatedJson;
  };

  return (
    <StyledPageContent>
      {loading ? <CqLoading /> : <>
        <Box>
          <Typography variant="h4">Findings</Typography>
        </Box>
        <Box>
          <StyledTabs value={tabId} onChange={handleTabChange}>
            <Tab id="find_tab_0" label="Host"/>
            <Tab id="find_tab_1" label="Endpoint"/>
          </StyledTabs>
        </Box>
        <StyledPanelBox>
          <TabPanel value={0} tabId={tabId}>
            <>
              <FindingsTable
                type='HOST'
                data={configData}
                handleSubmit={handleSubmit}
                setOpenFindingDialog={setOpenFindingDialog}
                openFindingDialog={openFindingDialog}
                setOpenTLSFindingDialog={setOpenTLSFindingDialog}
                openTLSFindingDialog={openTLSFindingDialog}
                openCustomFindingDialog={openCustomFindingDialog}
                defaultTenantConfiguration={defaultTenantConfiguration}
                addFinding={addFinding}
                setOpenCustomFindingDialog={setOpenCustomFindingDialog}
                deleteUpdateFindings={deleteUpdateFindings}
                deleteFinding={deleteFinding}
                setDeleteFinding={setDeleteFinding}
              />
            </>
          </TabPanel>
          <TabPanel value={1} tabId={tabId}>
            <>
              <FindingsTable
                type='ENDPOINT'
                data={configData}
                handleSubmit={handleSubmit}
                setOpenFindingDialog={setOpenFindingDialog}
                openFindingDialog={openFindingDialog}
                setOpenTLSFindingDialog={setOpenTLSFindingDialog}
                openTLSFindingDialog={openTLSFindingDialog}
                openCustomFindingDialog={openCustomFindingDialog}
                defaultTenantConfiguration={defaultTenantConfiguration}
                addFinding={addFinding}
                setOpenCustomFindingDialog={setOpenCustomFindingDialog}
                deleteUpdateFindings={deleteUpdateFindings}
                deleteFinding={deleteFinding}
                setDeleteFinding={setDeleteFinding}
              />
            </>
          </TabPanel>
        </StyledPanelBox>
      </>}
      <SpSnackBar
        isOpen={notify.open}
        autoHideDuration={2000}
        notifications={[
          {
            id: '1',
            message: notify.message,
            category: notify.success ? 'success' : 'error',
          },
        ]}
        onNotificationDelete={() =>
          setNotify({ open: false, error: false, success: false })
        }
      />
    </StyledPageContent>
  );
};

export default FindingAlgorithm;
