import { ISSUE_TYPES } from "../../lib/utils";

export const responseCode = [
  {
    name: '200',
    selected: false,
    value: 'status200',
  },
  {
    name: '404',
    selected: false,
    value: 'status404',
  },
  // {
  //   name: '500',
  //   selected: false,
  //   value: 'status500',
  // },
  // {
  //   name: '501',
  //   selected: false,
  //   value: 'status501',
  // },
  // {
  //   name: '502',
  //   selected: false,
  //   value: 'status502',
  // },
  // {
  //   name: '503',
  //   selected: false,
  //   value: 'status503',
  // },
  // {
  //   name: '2xx',
  //   selected: false,
  //   value: 'status2xx',
  // },
  {
    name: '3xx',
    selected: false,
    value: 'status3xx',
  },
  {
    name: '4xx',
    selected: false,
    value: 'status4xx',
  },
  {
    name: '5xx',
    selected: false,
    value: 'status5xx',
  },
  {
    name: '0',
    selected: false,
    value: '0',
  },
];

export const uapAwareness = [
  {
    name: 'Spyder Crawls',
    selected: false,
  },
  {
    name: 'CSPM',
    selected: false,
  },
  {
    name: 'Sentinel',
    selected: false,
  },
];

export const apiHosts = [
  {
    name: 'API Hosts',
    selected: true,
  },
  {
    name: 'Non API Hosts',
    selected: false,
  },
];



export const severity = (iTypes) => {
  const list = []
  for(const key in iTypes){
    list.push({
      type: key,
      selected: false,
      name: iTypes?.[key]?.description
    })
  }
  list.push({
    type: 'ZERO_FINDINGS',
    selected: false,
    name: 'Zero Findings'
  })
  return list
}

export const highSeverityIssue = {
  High: [
    {
      name: 'Log4j Vulnerabilities',
      selected: true,
      type: 'LOG4J',
    },
    {
      name: 'LoNg4j Vulnerabilities',
      selected: true,
      type: 'LONG4J',
    },
    {
      name: 'Exposed Keystores',
      selected: true,
      type: 'EXPOSED_FILE',
    },
    {
      name: 'Insecure SSL/TLS Implementation',
      selected: true,
      type: 'TLS',
    },
  ],
  Medium: [
    {
      name: 'Unhandled Or Internal Errors',
      selected: false,
      type: 'UNHANDLED',
    },
    {
      name: 'Non-production Or Internal Application Server',
      selected: false,
      type: 'NON_PROD_OR_ORIGIN',
    },
  ],
  Info: [
    {
      name: 'Application Health Monitoring Endpoints Exposed',
      selected: false,
      type: 'HEALTH_MONITORING',
    },
    {
      name: 'User Login Or Authentication Endpoints Exposed',
      selected: false,
      type: 'LOGIN',
    },
    {
      name: 'OpenAPI/Swagger Endpoints Exposed',
      selected: false,
      type: 'OPENAPI_SWAGGER',
    },
    {
      name: 'GraphQL Endpoints Detected',
      selected: false,
      type: 'GRAPHQL',
    },
  ],
};

export const mediumSeverityIssue = {
  High: [
    {
      name: 'Log4j Vulnerabilities',
      selected: false,
      type: 'LOG4J',
    },
    {
      name: 'LoNg4j Vulnerabilities',
      selected: false,
      type: 'LONG4J',
    },
    {
      name: 'Exposed Keystores',
      selected: false,
      type: 'EXPOSED_FILE',
    },
    {
      name: 'Insecure SSL/TLS Implementation',
      selected: false,
      type: 'TLS',
    },
  ],
  Medium: [
    {
      name: 'Unhandled Or Internal Errors',
      selected: true,
      type: 'UNHANDLED',
    },
    {
      name: 'Non-production Or Internal Application Server',
      selected: true,
      type: 'NON_PROD_OR_ORIGIN',
    },
  ],
  Info: [
    {
      name: 'Application Health Monitoring Endpoints Exposed',
      selected: false,
      type: 'HEALTH_MONITORING',
    },
    {
      name: 'User Login Or Authentication Endpoints Exposed',
      selected: false,
      type: 'LOGIN',
    },
    {
      name: 'OpenAPI/Swagger Endpoints Exposed',
      selected: false,
      type: 'OPENAPI_SWAGGER',
    },
    {
      name: 'GraphQL Endpoints Detected',
      selected: false,
      type: 'GRAPHQL',
    },
  ],
};

export const lowSeverityIssue = {
  High: [
    {
      name: 'Log4j Vulnerabilities',
      selected: false,
      type: 'LOG4J',
    },
    {
      name: 'LoNg4j Vulnerabilities',
      selected: false,
      type: 'LONG4J',
    },
    {
      name: 'Exposed Keystores',
      selected: false,
      type: 'EXPOSED_FILE',
    },
    {
      name: 'Insecure SSL/TLS Implementation',
      selected: false,
      type: 'TLS',
    },
  ],
  Medium: [
    {
      name: 'Unhandled Or Internal Errors',
      selected: false,
      type: 'UNHANDLED',
    },
    {
      name: 'Non-production Or Internal Application Server',
      selected: false,
      type: 'NON_PROD_OR_ORIGIN',
    },
  ],
  Info: [
    {
      name: 'Application Health Monitoring Endpoints Exposed',
      selected: true,
      type: 'HEALTH_MONITORING',
    },
    {
      name: 'User Login Or Authentication Endpoints Exposed',
      selected: true,
      type: 'LOGIN',
    },
    {
      name: 'OpenAPI/Swagger Endpoints Exposed',
      selected: true,
      type: 'OPENAPI_SWAGGER',
    },
    {
      name: 'GraphQL Endpoints Detected',
      selected: true,
      type: 'GRAPHQL',
    },
  ],
};

export const selectedSeverityIssue = {
  High: [
    {
      name: 'Log4j Vulnerabilities',
      selected: false,
      type: 'LOG4J',
    },
    {
      name: 'LoNg4j Vulnerabilities',
      selected: false,
      type: 'LONG4J',
    },
    {
      name: 'Exposed Keystores',
      selected: false,
      type: 'EXPOSED_FILE',
    },
    {
      name: 'Insecure SSL/TLS Implementation',
      selected: false,
      type: 'TLS',
    },
  ],
  Medium: [
    {
      name: 'Unhandled Or Internal Errors',
      selected: false,
      type: 'UNHANDLED',
    },
    {
      name: 'Non-production Or Internal Application Server',
      selected: false,
      type: 'NON_PROD_OR_ORIGIN',
    },
  ],
  Info: [
    {
      name: 'Application Health Monitoring Endpoints Exposed',
      selected: false,
      type: 'HEALTH_MONITORING',
    },
    {
      name: 'User Login Or Authentication Endpoints Exposed',
      selected: false,
      type: 'LOGIN',
    },
    {
      name: 'OpenAPI/Swagger Endpoints Exposed',
      selected: false,
      type: 'OPENAPI_SWAGGER',
    },
    {
      name: 'GraphQL Endpoints Detected',
      selected: false,
      type: 'GRAPHQL',
    },
  ],
};

export const apiServersFound = [
  {
    name: 'All',
    selected: true,
  },
  {
    name: 'New API Hosts',
    selected: false,
  },
  {
    name: 'Missing API Hosts',
    selected: false,
  },
  {
    name: 'New App Hosting Providers',
    selected: false,
  },
  {
    name: 'Missing App Hosting Providers',
    selected: false,
  },
];

export const SORT_ORDER = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
};

export const serverTableHeaderColumns = [
  {
    name: '',
  },
  {
    name: 'API Endpoint',
    id: 'hostname',
    sortable: true,
    sortType: 'string',
  },
  {
    name: 'Hosting Provider',
    id: 'service',
    sortable: true,
    sortType: 'string',
  },
  {
    name: 'Server',
    id: 'service',
    sortable: true,
    sortType: 'string',
  },
  {
    name: 'Total Findings',
    id: 'issues',
    sortable: true,
    sortType: 'numeric',
  },
];

