import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
export const useStyles = makeStyles((theme) => ({
  rowDisabled: {
    opacity: 0.5,
  },
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerRowCellIcon: {
    color: colors.blueButtonBg,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    fontSize: '16px !important',
  },
  rowSelection: {
    cursor: 'default',
    '&.Mui-selected > td': {
      backgroundColor: colors.gray650,
    },
    '&.Mui-selected > td:last-of-type > svg': {
      color: colors.blueButtonBg,
    },
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div > svg': {
      color: colors.blueButtonBg,
    },
    '&:hover > td:nth-last-of-type(2) > button': {
      backgroundColor: colors.gray650,
    },
  },
  selectMenuItem: {
    backgroundColor: colors.gray600,
    color: colors.gray100,
    border: 'none',

    '&:hover': {
      backgroundColor: colors.gray500,
    },
  },
  warningBadge: {
    backgroundColor: colors.orangeMediumLevel,
    color: colors.gray800,
  },
  alertBadge: {
    backgroundColor: colors.red100,
    color: colors.gray800,
  },
  infoBadge: {
    backgroundColor: colors.gray100,
    color: colors.gray800,
  },
  popMenuList: {
    border: `1px solid ${colors.gray400}`,
    backgroundColor: colors.gray700,
    padding: '4px 0',
  },
  popMenuListItem: {
    padding: '10px 12px',
    minWidth: 214,
  },
  popListItem: {
    justifyContent: 'space-between',
  },
  popMenuListItemText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: colors.gray50,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  severityLabel: {
    width: 'fit-content',
    padding: '2px 10px',
    borderRadius: 16,
    textTransform: 'capitalize',
    color: colors.almostBlack,
    marginRight: 10,
  },
  serverFieldName: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  blueCell: {
    color: colors.blue300,
    cursor: 'pointer',
    width: '300px',
    overflowWrap: 'break-word',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  hoverItem: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 36,
    width: 36,
    '&:hover': {
      backgroundColor: colors.gray500,
    },
  },
  serverExpandedRow: {
    background: colors.gray850,
  },
  serverExpandedCell: {
    background: 'none !important',
  },
  ServerChipRed: {
    color: colors.red100,
  },
  ServerChipOrange: {
    color: colors.orange100,
  },
  ServerChipGrey: {
    color: colors.gray100,
  },
  chipContainer: {
    display: 'flex',
    gap: '12px',
    padding: '12px',
  },
  findContainer: {
    lineHeight: '20px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '6px 12px',
  },
  findIcon: {
    width: '20px',
    height: '20px',
  },
  arrowCell: {
    width: '50px',
  },
  endpointRoute: {
    color: colors.blue300,
    wordBreak: 'break-all',
    '& div': {
      width: '200px',
      display: 'flex',
      alignItems: 'baseline',
      gap: '4px',
    },
    '& svg': {
      fill: colors.blue300,
      fontSize: '13px',
    },
  },
  chipCursor: {
    cursor: 'pointer',
  },
  methodImg: {
    width: '20px',
    height: '20px',
    margin: 5,
  },
}));

export const pageStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  childContainer: {
    marginTop: 20,
  },
  subTitleContainer: {
    marginBottom: 12,
    verticalAlign: 'middle',
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'center',
  },
  backButton: {
    fontSize: '16px',
    lineHeight: '20px',
    color: colors.blueButtonBg,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    display: 'inline-flex',
  },
  switchLink: {
    color: colors.blue300,
    cursor: 'pointer',
  },
  switchLinkContainer: {
    paddingRight: '12px',
    '& svg': {
      fill: colors.blue300,
      fontSize: '13px',
    },
  },
  root: {
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& thead > tr th': {
        backgroundColor: colors.gray800,
        padding: '6px 24px 6px 0px',
        '&:nth-of-type(2)': {
          padding: '6px 24px 6px 0px',
        },
      },

      '& tbody tr': {
        borderBottom: `1px solid ${colors.gray650}`,
        '& td': {
          backgroundColor: colors.darkLabelText,
          padding: '6px 24px 6px 16px',
          '&:nth-of-type(2)': {
            padding: '6px 24px 6px 0px',
          },
          '& > *': {
            fontSize: '13px',
          },
        },
      },
    },
  },
  domainSelection: {
    height: 20,
    color: colors.white,
    '&.Mui-checked': {
      color: colors.blueButtonBg,
    },
  },
  domainSelectionText: {
    paddingTop: '2px',
    display: 'flex',
    gap: '4px',
    '& span': {
      marginLeft: '10px',
    },
  },
  domainSelectionContainer: {
    padding: '10px 0',
    height: '70px',
    '& a': {
      color: colors.blue300,
      cursor: 'pointer',
      fontSize: '13px',
    },
  },
  dialogProgressContainer: {
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px 0',
  },
  copyIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: colors.blueButtonBg,
    },
  },
  methodImg: {
    width: '90px',
    marginLeft: '-20px',
    marginTop: '13px',
  },
  blueCell: {
    color: colors.blue300,
    cursor: 'pointer',
    padding: '10px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
