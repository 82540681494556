export const serverView = {
  'Log4j Vulnerabilities': 'servers',
  'LoNg4j Vulnerabilities': 'servers',
  'Exposed Keystores': 'servers',
  'App. Health Monitoring Endpoints Exposed': 'servers',
  'Unhandled Or Internal Errors': 'servers',
  'Insecure SSL/TLS Implementation': 'servers',
  'Non-production Or Internal Application Server': 'servers',
  'User Login Or Auth. Endpoints Exposed': 'servers',
  'GraphQL Endpoints Detected': 'servers',
  'OpenAPI/Swagger Endpoints Exposed': 'servers',
};

export const timeMapping = {
  '24h': 1,
  '3d': 3,
  '1w': 7,
  All: 0,
};

export const getApiFilter = (list=[]) => {
  let isApi = list.find(item => item.name === 'APIs only')
  const nonApi = list.find(item => item.name === 'Non APIs only')
  let all = false
  if(isApi?.selected && nonApi?.selected){
    all = true
  }
  if(!isApi?.selected && !nonApi?.selected){
    isApi = {selected: true}
  }
  return {
    isApiFilter: isApi?.selected || false,
    nonApiFilter: nonApi?.selected || false,
    allFilter: all
  }
}
