import React, { useState } from "react";

import { styled, Grid, Typography } from "@mui/material";
import CrawlProgress from "../pages/dashboard/CrawlProgress";
import CqButton from "./common/CqButton";
import { NewDomainDialog } from "./NewDomainDialog";
import { useGlobalState } from '../store/globalState';
import { useNavigate } from "react-router-dom";
import { getRoutes } from "../lib/constants";

const StyledContainer = styled(Grid)({
  width: '870px',
  margin: '40px auto',
  verticalAlign: 'center'
})

const StyledTextContainer = styled(Grid)({
  width: '494px',
  paddingBottom: '20px'
})

const EmptyState = ({showEmptyState}) => {
  const global = useGlobalState();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false)
  const routes = getRoutes()
  return (
    <StyledContainer>
      <Grid container>
        {showEmptyState ? <StyledTextContainer item>
          <Typography variant="h4" sx={{mb: '20px'}}>Discover your external facing attack surface with API Spyder</Typography>
          <Typography variant="body-1">Add your first domain to start your first API Spyder crawl. If you already have an existing API Spyder account, connect it now. </Typography>
        </StyledTextContainer>
        :
        <StyledTextContainer item>
          <Typography variant="h4" sx={{mb: '20px'}}>Domain is under approval.</Typography>
        </StyledTextContainer>
        }
        <Grid item>
          <CrawlProgress />
        </Grid>
      </Grid>
      <NewDomainDialog newDomainRequestDialogOpened={openDialog} setNewDomainRequestDialogOpened={setOpenDialog} global={global} retrieveData={() => navigate(routes.domains.path)}/>
      { showEmptyState ? <CqButton variant='primary' text='Add Domain' onClick={() => setOpenDialog(true)} /> : null }
    </StyledContainer>
  )
}

export default EmptyState