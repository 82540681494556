import React, { useEffect, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Grid,
  Pagination,
  SvgIcon,
  Typography,
  Link,
} from '@mui/material';
import {
  CustomTabs as Tabs,
  CustomTab as Tab,
  CustomTabPanel as TabPanel,
} from '../../components/Tabs';
import { colors } from '../../assets/theme';
import ServerDetailsHeader from './ServerDetailsHeader';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Tooltip from '@mui/material/Tooltip';
import ServerDetailsVulnerabilitiesNav from './ServerDetailsVulnerabilitiesNav';
import ServerVulnerabilityDetails from './ServerVulnerabilityDetails';
import CqLoading from '../../components/common/CqLoading';
import { graphqlQuery } from '../../services/graphql';
import {
  getAPIRequestResponseQuery,
  getEndpointsSummaryQuery,
  getApiServerLog4jData,
} from '../servers/serversGraphQL';
import CqDialog from '../../components/common/CqDialog';
import getMethodImg from '../../assets/img/get-method.svg';
import postMethodImg from '../../assets/img/post-method.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDateTime } from '../../lib/utils';
import EndpointTable from './EndpointsTable';
import { CqChip } from '@cequence/ui';
import SankeyChart from '../../components/SankeyChart';
import DiscoveryConfig from '../../components/DiscoveryConfig';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { issueDescription } from '../../store/constants';
import { ResponseData } from '../../components/requestResponse/ResponseData';
import { ResponseHeader } from '../../components/requestResponse/ResponseHeader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  childContainer: {
    marginTop: 20,
  },
  subTitleContainer: {
    marginBottom: 12,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  backButton: {
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.blue300,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    display: 'inline-flex',
  },
  flexBox: {
    display: 'flex',
    gap: '45px',
  },
  flexItem: {
    width: 'auto',
  },
  copyIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: colors.blueButtonBg,
    },
  },
  methodImg: {
    width: '90px',
    marginLeft: '-20px',
    marginTop: '13px',
  },
  blueCell: {
    color: colors.blue300,
    cursor: 'pointer',
    padding: '10px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navigationContainer: {
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: '8px',
    },
  },
  navigationLink: {
    fontSize: '20px',
  },
}));

const StyledPanelBox = styled(Box)({
  paddingTop: '17px',
});

const StyledTabs = styled(Tabs)({
  borderBottom: `1px solid ${colors.gray700}`,
});

const EndpointLabel = ({ count }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      <span>API Endpoints</span>
      <CqChip
        color={colors.nearlyWhite}
        overrides={{ backgroundColor: '#00BBD380', border: 'none' }}
      >
        {count}
      </CqChip>
    </Box>
  );
};

const ServerDetails = ({
  selectedServerData,
  resetSelectedAPIServer,
  selectedDomain,
  serversList,
  updateSelectedServer,
  crawlInfo,
}) => {
  const classes = useStyles();
  const [selectedVulnerability, setSelectedVulnerability] = useState({});
  const [loadingVulnerabilityDetails, setLoadingVulnerabilityDetails] =
    useState(false);
  const [vulnerabilityServers, setVulnerabilityServers] = useState([]);
  const [openServerReqResDialog, setServerOpenReqResDialog] = useState(false);
  const [reqResponseEntries, setReqResponseEntries] = useState([]);
  const [reqResponsePage, setReqResponsePage] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [selectedResponse, setSelectedResponse] = useState({});
  const [currentServerIndex, setServerIndex] = useState(0);
  const [endpointsCount, setEndpointsCount] = useState(0);
  const [tabId, setTabId] = useState(0);
  const wrapperContext = useContext(WrapperContext);
  const issueTypes = wrapperContext.issueTypes;

  const selectedServer = selectedServerData?.servers?.[0] || {};
  let tenantDiscoveryConfig = {};
  if (crawlInfo?.config?.discovery) {
    tenantDiscoveryConfig = JSON.parse(
      Buffer.from(crawlInfo.config.discovery, 'base64').toString('utf-8')
    );
  }

  const findIndex = (servers, server) => {
    const ind = servers.findIndex((val) => val.hostname === server.hostname);
    return ind;
  };

  const severityComparator = (a, b) => {
    const order = {
      HIGH: 1,
      MEDIUM: 2,
      INFO: 3,
    };
    return order[a.severity] - order[b.severity];
  };

  const handleTabChange = (e, val) => {
    setTabId(val);
    if(val === 1){
      setSelectedVulnerability({
        name: 'ALL',
        value: selectedServer?.endpoints?.attempted,
      })
    }
    if(val === 2){
      updateSelectedSeverity()
    }
  };

  const updateSelectedSeverity = () => {
    const sortedTLSIssues = selectedServer?.findings?.issues
      ?.filter((i) => i.type === 'TLS')
      ?.sort(severityComparator);
    const sortedIssues = selectedServer?.findings?.issues
      ?.filter((i) => i.type !== 'TLS')
      ?.sort(severityComparator);
    const tlsIssue = {
      name: 'TLS',
      severity: sortedTLSIssues?.[0]?.severity,
      value: 1,
      type: 'TLS',
    };
    const allEndpoints = {
      name: 'ALL',
      value: selectedServer?.endpoints?.attempted,
    };
    
    if(selectedServer?.findings?.issues?.length > 0){
      let selectedSeverity = {}
      if(sortedIssues?.length > 0){
        selectedSeverity = sortedIssues[0]
      } else {
        selectedSeverity = selectedServer?.findings?.issues[0]
      }
      selectedSeverity.custom = issueTypes?.[selectedSeverity.name]?.custom
      selectedSeverity.longDes = issueTypes?.[selectedSeverity.name]?.longDescription
      sortedTLSIssues?.length > 0
        ? setSelectedVulnerability(tlsIssue)
        : sortedIssues?.length > 0
          ? setSelectedVulnerability(selectedSeverity)
          : setSelectedVulnerability(allEndpoints);
    }
  }
  useEffect(() => {
    updateSelectedSeverity()
    const serverIndex = findIndex(serversList, selectedServer);
    setServerIndex(serverIndex);
  }, []);

  useEffect(() => {
    if (selectedVulnerability?.hasOwnProperty('name') && selectedVulnerability?.name !== 'TLS') {
      retrieveVulnerabilityDetails();
    }
  }, [selectedVulnerability]);

  const retrieveVulnerabilityDetails = async () => {
    setLoadingVulnerabilityDetails(true);
    const issueTypes = selectedVulnerability?.name === 'ALL' ? [] : [selectedVulnerability?.name]
    const params = {
      hostname: new URLSearchParams(window.location.search).get('hostname'),
      domains: [new URLSearchParams(window.location.search).get('domain')],
      crawlId: new URLSearchParams(window.location.search).get('crawlId'),
      issueTypes: issueTypes,
    };

    if (selectedVulnerability?.name === 'INSECURE') {
      try {
        let response = await graphqlQuery(
          getSelectedSSLServerVulnerabilityPathsQuery,
          params
        );
        const data = response.data
          ? response.data.crawl.getApiServerTLSData.servers[0]
          : {};
        setVulnerabilityServers(data);
      } catch (error) {
        console.log(`graphql query error`, error);
      } finally {
        setLoadingVulnerabilityDetails(false);
      }
    } else if (
      selectedVulnerability?.name === 'LOG4J_LONG4J'
    ) {
      try {
        let response = await graphqlQuery(getApiServerLog4jData, params);
        const data = response.data
          ? response.data.crawl.getApiServerLog4jData?.servers?.[0]
          : {};
        setVulnerabilityServers(data.requests || []);
      } catch (error) {
        console.log(`graphql query error`, error);
      } finally {
        setLoadingVulnerabilityDetails(false);
      }
    } else {
      try {
        let response = await graphqlQuery(getEndpointsSummaryQuery, params);
  
        const data = response.data
          ? response?.data?.discovery?.getEndpointsSummary
          : {};
        setVulnerabilityServers(data?.endpoints || []);
        setEndpointsCount(data?.total || 0);
      } catch (error) {
        console.log(`graphql query error`, error);
      } finally {
        setLoadingVulnerabilityDetails(false);
      }
    }
  };

  const getServerLevelRequestResponse = (detail, server) => {
    retrieveAPIRequestResponse(detail, server);
  };

  const setReqResponseValues = (value) => {
    setReqResponsePage(value);
    setSelectedRequest(reqResponseEntries[value - 1].request);
    setSelectedResponse(reqResponseEntries[value - 1].response);
  };

  const retrieveAPIRequestResponse = async (detail, server) => {
    const params = {
      domain: selectedDomain,
      crawlId: detail.crawlId,
      requestId: detail.requestId,
      objectKey: detail.objectKey,
    };

    try {
      let response = await graphqlQuery(getAPIRequestResponseQuery, params);
      const entries =
        response?.data?.crawl?.getAPIRequestResponse?.data?.log?.entries;

      setReqResponseEntries(entries);
      setSelectedRequest(entries[0]?.request || {});
      setSelectedResponse(entries[0]?.response || {});
      setServerOpenReqResDialog(true);
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  const navigateToPrevious = () => {
    const ind = currentServerIndex - 1;
    if (currentServerIndex > 0) {
      const selectedServer = serversList[ind];
      updateSelectedServer(selectedServer, ind);
    }
  };

  const navigateToNext = () => {
    const ind = currentServerIndex + 1;
    if (currentServerIndex + 1 < serversList.length) {
      const selectedServer = serversList[ind];
      updateSelectedServer(selectedServer, ind);
    }
  };

  const handleCopyRequest = async (val) => {
    let json =
      val === 'request'
        ? JSON.stringify(selectedRequest)
        : JSON.stringify(selectedResponse);
    await navigator.clipboard.writeText(json);
  };

  return (
    <div className={classes.pageContent}>
      <Grid container>
        <Grid item xs={9}>
          <Typography
            className={classes.backButton}
            onClick={() => resetSelectedAPIServer()}
          >
            <ArrowBackIcon sx={{ fontSize: 20 }} />
            <span style={{ marginLeft: 4 }}>Back To API Hosts </span>
          </Typography>
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  xs={3}*/}
        {/*  style={{ display: 'flex', justifyContent: 'flex-end' }}*/}
        {/*>*/}
        {/*  <div className={classes.navigationContainer}>*/}
        {/*    <Typography>*/}
        {/*      {currentServerIndex + 1} of {serversList.length}*/}
        {/*    </Typography>*/}
        {/*    <Tooltip title="Previous API Host">*/}
        {/*      <ArrowBackIos*/}
        {/*        sx={{*/}
        {/*          cursor: currentServerIndex === 0 ? 'inherit' : 'pointer',*/}
        {/*          color: currentServerIndex === 0 ? colors.muted : 'inherit',*/}
        {/*        }}*/}
        {/*        onClick={navigateToPrevious}*/}
        {/*        className={classes.navigationLink}*/}
        {/*      />*/}
        {/*    </Tooltip>*/}
        {/*    <Tooltip title="Next API Host">*/}
        {/*      <ArrowForwardIosIcon*/}
        {/*        sx={{*/}
        {/*          cursor:*/}
        {/*            currentServerIndex === serversList.length - 1*/}
        {/*              ? 'inherit'*/}
        {/*              : 'pointer',*/}
        {/*          color:*/}
        {/*            currentServerIndex === serversList.length - 1*/}
        {/*              ? colors.muted*/}
        {/*              : 'inherit',*/}
        {/*        }}*/}
        {/*        onClick={navigateToNext}*/}
        {/*        className={classes.navigationLink}*/}
        {/*      />*/}
        {/*    </Tooltip>*/}
        {/*  </div>*/}
        {/*</Grid>*/}
      </Grid>
      <Link
        target={'_blank'}
        href={`//${selectedServer?.hostname}/`}
        sx={{ display: 'flex' }}
      >
        <Typography variant="h4" sx={{ marginBottom: '8px' }}>
          {selectedServer?.hostname}
        </Typography>
        <LaunchIcon
          sx={{
            fontSize: 16,
            marginLeft: 0.5,
            marginTop: 0.5,
            color: colors.gray450,
          }}
        />
      </Link>

      {loadingVulnerabilityDetails && <CqLoading />}
      {!loadingVulnerabilityDetails && (
        <>
          <Box>
            <StyledTabs value={tabId} onChange={handleTabChange}>
              <Tab id="server-details_tab_0" label="Summary" />
              <Tab id="server-details_tab_1" label="All Endpoints" />
              <Tab id="server-details_tab_2" label="Findings" />
            </StyledTabs>
          </Box>
          <StyledPanelBox>
            <TabPanel value={0} tabId={tabId}>
              <ServerDetailsHeader selectedServerData={selectedServer} />
              {selectedServer.endpoints && (
                <DiscoveryConfig
                  discoveryConfig={tenantDiscoveryConfig}
                  endpointData={selectedServer.endpoints}
                  selectedServerHost={selectedServer.hostname}
                  discoveryConfigResult={selectedServer.isApi}
                />
              )}
            </TabPanel>
            <TabPanel value={1} tabId={tabId}>
              <EndpointTable
                endpoints={vulnerabilityServers}
                getRequestResponse={getServerLevelRequestResponse}
              />
            </TabPanel>
            <TabPanel value={2} tabId={tabId}>
              <div className={classes.flexBox}>
                <Grid item container className={classes.flexItem}>
                  <ServerDetailsVulnerabilitiesNav
                    selectedServerData={selectedServer}
                    setSelectedVulnerability={setSelectedVulnerability}
                    selectedVulnerability={selectedVulnerability}
                  />
                </Grid>
                <Grid item container className={classes.flexItem}>
                  <ServerVulnerabilityDetails
                    selectedVulnerability={selectedVulnerability}
                    vulnerabilityServers={vulnerabilityServers}
                    getRequestResponse={getServerLevelRequestResponse}
                    selectedServerData={selectedServer}
                    endpointsCount={endpointsCount}
                  />
                </Grid>
              </div>
            </TabPanel>
          </StyledPanelBox>
        </>
      )}
      <CqDialog
        title={
          selectedVulnerability?.name !== 'ALL'
            ? issueTypes[selectedVulnerability?.name]?.description
            : selectedVulnerability?.name !== undefined
              ? 'Request / Response'
              : ''
        }
        open={openServerReqResDialog}
        onClose={() => setServerOpenReqResDialog(false)}
        maxWidth="lg"
        closeIcon={true}
        showSubmitBtn={false}
        showCloseBtn={false}
        mediumRiskType={selectedVulnerability?.severity === 'MEDIUM'}
        highRiskType={selectedVulnerability?.severity === 'HIGH'}
        infoRiskType={selectedVulnerability?.severity === 'LOW'}
      >
        <ResponseHeader
          method={selectedRequest?.method}
          url={selectedRequest?.url}
          status={selectedResponse?.status}
          time={reqResponseEntries[reqResponsePage - 1]?.time > 0
            ? `${reqResponseEntries[reqResponsePage - 1]?.time} ms`
            : '--'} 
        />
        <Grid container columnGap={2} style={{ marginTop: 10 }}>
          <Grid
            item
            xs
            style={{
              height: 'auto',
              maxHeight: 600,
              backgroundColor: colors.gray900,
              overflow: 'scroll',
            }}
          >
            <Grid item container xs={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant={'subtitle1'}
                  style={{
                    color: colors.gray100,
                    marginLeft: 10,
                    height: 30,
                    marginTop: 10,
                  }}
                >
                  Request
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={'body2'}
                  style={{
                    color: colors.gray100,
                    height: 30,
                    paddingTop: 16,
                  }}
                >
                  {reqResponseEntries[reqResponsePage - 1]?.startedDateTime
                    ? formatDateTime(
                        reqResponseEntries[reqResponsePage - 1]?.startedDateTime
                      )
                    : '-'}
                </Typography>
              </Box>
            </Grid>
            <Divider />
            <Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}
              >
                <ContentCopyIcon
                  onClick={() => handleCopyRequest('request')}
                  className={classes.copyIcon}
                />
              </div>
              <Grid item container style={{ marginTop: '-20px' }}>
                {selectedRequest?.method === 'get' ? (
                  <SvgIcon
                    sx={{ width: '90px', marginLeft: '-20px' }}
                    component={getMethodImg}
                  />
                ) : (
                  <SvgIcon
                    sx={{ width: '90px', marginLeft: '-20px' }}
                    component={postMethodImg}
                  />
                )}
                {selectedRequest?.url && (
                  <Typography
                    style={{
                      color: colors.gray100,
                      marginLeft: 10,
                      fontSize: 12,
                    }}
                  >
                    {new URL(selectedRequest?.url).pathname}
                  </Typography>
                )}
              </Grid>
              {selectedRequest?.headers?.map((i) => {
                return (
                  <Typography
                    key={`request-${i}`}
                    style={{ marginLeft: 10, fontSize: 12 }}
                  >
                    <span style={{ color: colors.lightBlue100 }}>
                      {i.name}:{' '}
                    </span>
                    <span style={{ color: colors.orange100 }}>{i.value}</span>
                  </Typography>
                );
              })}
              {selectedRequest?.queryString?.map((i) => {
                return (
                  <Grid style={{ whiteSpace: 'pre-line' }}>
                    <br />
                    <Typography
                      key={`queryString-${i}`}
                      style={{
                        color: colors.orange100,
                        marginLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {i.value}
                    </Typography>
                  </Grid>
                );
              })}
              {selectedRequest?.postData && (
                <Grid style={{ whiteSpace: 'pre-line' }}>
                  <br />
                  <Typography
                    style={{
                      color: colors.orange100,
                      fontSize: 12,
                      marginLeft: 10,
                    }}
                  >
                    {window.atob(decodeURIComponent(selectedRequest?.postData))}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <ResponseData selectedResponse={selectedResponse} ip={reqResponseEntries[0]?.server?.ipAddr || '-'} />
        </Grid>
        {reqResponseEntries?.length > 1 && (
          <Grid style={{ marginTop: 10, justifyContent: 'end' }}>
            <Pagination
              count={reqResponseEntries.length}
              page={reqResponsePage}
              onChange={(e, value) => setReqResponseValues(value)}
              variant="outlined"
              color="primary"
              size="small"
            />
          </Grid>
        )}
      </CqDialog>
    </div>
  );
};

export default ServerDetails;
