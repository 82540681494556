import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getSessionJwt } from './auth';

const defaultOptions = {
  watchQuery: { fetchPolicy: 'no-cache' },
  query: { fetchPolicy: 'no-cache' },
};

const API_URL = window.__APP_RUNTIME_CONFIG__.SPYDER_API_URL || '/api';

const apiLink = createHttpLink({
  uri: `${API_URL}`,
});

const authLink = setContext((_, { headers }) => {
  const activeTenantId = window.localStorage.getItem('activeTenantId') || '';
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${getSessionJwt()}`,
      'cq-tenant-id': activeTenantId,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(apiLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});

export const graphqlQuery = (query, variables = {}, state, options) => {
  return new Promise(async (resolve, reject) => {
    client
      .query({
        query: gql`
          ${query}
        `,
        variables: variables,
        ...options,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        const reqRes = JSON.parse(JSON.stringify(e));
        if (reqRes?.networkError?.statusCode === 401) {
          try {
            state?.merge({ sessionTimeout: true });  
          } catch (error) {
            
          }
          
        } else {
          reject(e);
        }
      });
  });
};

export const graphqlMutation = (query, variables = {}, options) => {
  return new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          ${query}
        `,
        variables: variables,
        ...options,
      })
      .then((result) => resolve(result))
      .catch((e) => {
        const reqRes = JSON.parse(JSON.stringify(e));
        if (reqRes?.networkError?.statusCode === 401) {
          state.merge({ sessionTimeout: true });
        } else {
          reject(e);
        }
      });
  });
};

export const getUserQuery = `
  query GetUser {
    user {
      email
      firstName
      lastName
      tenantId
      tenantName
      tenants {
        id
        name
        isDefault
      }
      roles
      tenantStatus
    }
  }
`;

export const tenetMutation = `
mutation ReqSwitchTenant($tenantId: String!){
  switchTenant(tenantId: $tenantId){
    status
    message
  }
}
`;
