import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import { Grid, Link, MenuItem, Popover } from '@mui/material';
import CqSearch from '../../components/common/CqSearch';
import AddTaskIcon from '@mui/icons-material/AddTask';

const useStyles = makeStyles((theme) => ({
  linkStyles: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: colors.blue300,
    textDecoration: 'none',
  },
  menu: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
    width: '250px',
    padding: '0 10px',
  },
  menuItem: {
    padding: '6px',
  },
  searchBox: {
    margin: '10px 0',
  },
}));

const RulesSelect = ({
  options = [],
  onSelect,
  searchRuleVal = '',
  onSearchRule,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnChange = (val) => {
    onSearchRule(val);
  };
  return (
    <Grid>
      <Link onClick={handleClick} className={classes.linkStyles}>
        <AddTaskIcon />
        <span>Add to existing rule</span>
      </Link>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: classes.menu }}
      >
        <div className={classes.searchBox}>
          <CqSearch
            placeholder="Search Rule"
            value={searchRuleVal}
            onChange={handleOnChange}
            width={230}
          />
        </div>
        {options.map((item, idx) => (
          <MenuItem
            className={classes.menuItem}
            key={idx}
            value={item.id}
            onClick={onSelect}
          >
            {item.name}
          </MenuItem>
        ))}
      </Popover>
    </Grid>
  );
};

export default RulesSelect;
