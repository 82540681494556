import React from 'react';
import clsx from 'clsx';
import { customButtonStyles } from '../../assets/theme';

const CqButton = ({
  children,
  text,
  onClick,
  variant,
  width = 36,
  height = 36,
  px = 12,
  disabled = false,
  className,
  autoId,
  marginRight = 0,
  marginTop = 0,
}) => {
  const btnClasses = customButtonStyles();

  return (
    <button
      className={clsx(
        btnClasses.root,
        { [btnClasses.primaryBtn]: variant === 'primary' },
        { [btnClasses.grayBtn]: variant === 'gray' },
        { [btnClasses.borderBlueBtn]: variant === 'borderBlue' },
        className
      )}
      style={{
        height: height,
        minWidth: width,
        paddingLeft: `${px}px`,
        paddingRight: `${px}px`,
        marginRight: marginRight,
        marginTop: marginTop
      }}
      onClick={onClick}
      disabled={disabled}
      auto-id={autoId}
    >
      {children && children}
      {text}
    </button>
  );
};

export default CqButton;
