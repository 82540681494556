import React from 'react';
import { styled } from '@mui/material/styles';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Typography } from '@mui/material';
import TextWithIcon from './common/TextWithIcon';
import moment from 'moment';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { colors } from '../assets/theme';

const StyledProgressContainer = styled('div')(() => ({
  position: 'relative',
  textAlign: 'center',
  width: '100%',
  margin: '4px 0',
}));

const StyledProgressLabel = styled('span')(() => ({
  position: 'absolute',
  marginTop: '-22px',
  color: colors.gray900,
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '27px',
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.gray600,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: colors.blueButtonBg,
  },
}));

const CrawlPercent = ({ crawlData, crawlInProgress }) => {
  let crawlDate = '';
  if (crawlData?.submittedAt) {
    crawlDate = moment(crawlData.submittedAt).format('MMMM Do YYYY, h:mm:ss a');
  }
  return crawlData?.crawlPercent ? (
    <>
      <TextWithIcon>
        <PlaylistAddCheckIcon />
        <Typography variant="body2">Crawl Catalog</Typography>
      </TextWithIcon>
      <StyledProgressContainer>
        <BorderLinearProgress
          variant="determinate"
          value={crawlData.crawlPercent}
        />
        <StyledProgressLabel>{crawlData.crawlPercent}%</StyledProgressLabel>
      </StyledProgressContainer>
      <Typography variant="caption">
        {crawlData.activeSubdomains}/{crawlData.totalSubdomains}{' '}
        {crawlInProgress
          ? 'subdomains are enabled for this crawl'
          : `subdomains were crawled at ${crawlDate}`}
      </Typography>
    </>
  ) : null;
};

export default CrawlPercent;
