import React, { useEffect, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import {
  Grid,
  Typography,
} from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CqLoading from '../../components/common/CqLoading';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { colors } from '../../assets/theme';
import Filters from './Filters/Filters';
import { useGlobalState } from '../../store/globalState';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { serverTableHeaderColumns } from './constants';
import { localStore } from '../../store/localStore';
import DnsIcon from '@mui/icons-material/Dns';
import ServersTable from './ServerTable/ServersTable';
import EndpointTable from './EndpointTable/EndpointTable';
import CqButton from '../../components/common/CqButton';
import {
  retrieveEndpoints,
  retrieveServers,
  retrieveCrawlHistory,
  retrieveHostingProviders,
  fetchCrawlInfo,
  retrieveSelectedServer
} from './provider';

import ServerDetailsPage from '../serverDetails/ServerDetails';
import { getRoutes } from '../../lib/constants';
import { pageStyles } from './styles';
import { getFilters, getQuery } from './serverHelpers';
import XLSDialog from './XLSDialog';
import RequestResponseDialog from './RequestResponse';
import { SearchContext, hostType } from './serverHelpers';

const routes = getRoutes();
const Servers = ({ activeDomains, context }) => {
  const classes = pageStyles();
  const store = localStore();
  const serversFilters = store.getFilters('serverFilters');
  const navigation = useNavigate();
  const issueTypes = context.issueTypes;
  const gatewayConfigData = context.gatewayConfig || [];
  const global = useGlobalState();
  const { userPreferences, userInfo } = global.get();

  // state for server details
  const [serverDetailsLoading, setServerDetailsLoading] = useState(false);
  const [selectedServer, setSelectedServer] = useState({});
  const [selectedServerData, setSelectedServerData] = useState([]);
  const [serversList, setServersList] = useState([]);
  const [activeCrawlInfo, setActiveCrawlInfo] = useState({});
  const [selectedHostname, setSelectedHostname] = useState(
    new URLSearchParams(window.location.search).get('hostname')
  );
  // state for host and endpoints
  const [openReqResDialog, setOpenReqResDialog] = useState(false);
  const [reqResData, setReqResData] = useState({})
  const [loading, setLoading] = useState(false);
  const [filtersState, setFiltersState] = useState(serversFilters);
  const [serverView, setServerView] = useState(userPreferences.serverView);
  const [crawlHistory, setCrawlHistory] = useState([]);
  const [serversData, setServersData] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [totalServersCount, setTotalServersCount] = useState(0);
  const [totalEndpointsCount, setTotalEndpointsCount] = useState(0);
  const [openXLSDialog, setOpenXLSDialog] = useState(false);
  const [searchHostStr, setSearchHostStr] = useState('')

  const handleExportClose = () => {
    setOpenXLSDialog(false);
  };

  const resetSelectedAPIServer = () => {
    setSelectedHostname(null);
    setSelectedServer({});
    navigation(routes.discovery.path);
  };

  const updateSelectedServer = (server, ind) => {
    setSelectedServer(server);
    navigation(
      `${routes.discovery.path}?hostname=${server.hostname}&crawlId=${server.crawlId}&domain=${server.domain}`
    );
    setSelectedHostname(server.hostname);
  };

  const fetchServersData = async (query, filters) => {
    try {
      if(serverView){
        const res = await retrieveServers(query, filters)
        setServersData(res.servers);
        setTotalServersCount(res.total);
      } else {
        const res = await retrieveEndpoints(query, filters)
        setEndpointData(res.endpoints);
        setTotalEndpointsCount(res.total);
      }
    } catch (e) {
      
    }
  }

  const updateServerView = () => {
    setServerView((serverView) => {
      const view = !serverView;
      global.userPreferences['serverView'].set(view);
      store.update('userPreferences', 'serverView', view);
      return view;
    });
    setSearchHostStr('')
  };

  const routeToServerDetails = (
    data,
  ) => {
    setSelectedHostname(data.hostname);
    //we can also set this as a parent component state

    navigation(
      `${routes.discovery.path}?hostname=${data.hostname}&crawlId=${data.crawlId}&domain=${data.domain}`
    );
    setOpenReqResDialog(false);
  };

  const updateServersList = (list) => {
    setServersList(list);
  };

  const updateFiltersState = async (data) => {
    setFiltersState(data);
    const query = getQuery(data, activeDomains)
    setLoading(true)
    await fetchServersData(query, data)
    setLoading(false)
    store.setFilters('serverFilters', data);
  };

  const getRequestResponse = (detail, risk) => {
    setReqResData({detail, risk})
    setOpenReqResDialog(true)
  };

  const handleExportSelectionClick = () => {
    setOpenXLSDialog(true);
  };

  useEffect(async () => {
    setLoading(true)
    const filters = await getFilters(serversFilters, activeDomains, issueTypes, setCrawlHistory, gatewayConfigData)
    const query = getQuery(filters, activeDomains)
    await fetchServersData(query, filters)
    setFiltersState(filters)
    setLoading(false)
  }, [serverView, activeDomains, issueTypes, gatewayConfigData])

  useEffect(async () => {
    if (!isEmpty(selectedHostname)) {
      setServerDetailsLoading(true)
      const res = await retrieveSelectedServer();
      if(res && res.servers && res.servers[0] && res.servers[0].crawlId){
        const crawlInfo = await fetchCrawlInfo(res.servers[0].crawlId)
        setActiveCrawlInfo(crawlInfo);
      }
      await retrieveCrawlHistory(
        new URLSearchParams(window.location.search).get('domain'),
        setCrawlHistory
      );
      setSelectedServerData(res)
      setServerDetailsLoading(false)
    }
  }, [selectedHostname]);

  const searchHost = async (val) => {
    setSearchHostStr(val)
    const query = getQuery(filtersState, activeDomains)
    query.hostname = val
    setLoading(true)
    await fetchServersData(query, filtersState)
    setLoading(false)
  }

  return isNull(selectedHostname) ?
    <SearchContext.Provider
      value={{
        searchHost: searchHost,
        searchHostStr: searchHostStr,
        hostType: hostType(filtersState.apiHosts)
      }}
    >
      <div className={classes.pageContent}>
        <Grid
          item
          container
          className={classes.pageHeader}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h4">API Discovery</Typography>
          </Grid>
          <Grid item>
            <CqButton
              text={'Export As XLS'}
              height={26}
              variant="primary"
              onClick={() => handleExportSelectionClick()}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Filters
            retrieveHostingProviders={retrieveHostingProviders}
            setFilters={updateFiltersState}
            crawlHistory={crawlHistory}
            setCrawlHistory={setCrawlHistory}
            retrieveCrawlHistory={retrieveCrawlHistory}
            filtersState={filtersState}
            serverView={serverView}
            setSearchHostStr={setSearchHostStr}
            loading={loading}
          />
        </Grid>
        {loading && <CqLoading />}
        {!loading && (
          <Grid item container>
            <Grid
              container
              className={classes.childContainer}
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitleContainer}
                >
                  {serverView ? (
                    <>
                      <DnsIcon
                        sx={{ color: colors.white, fontSize: '1.125rem' }}
                      />
                      <span>API Hosts</span>
                    </>
                  ) : (
                    <>
                      <MyLocationIcon
                        sx={{ color: colors.white, fontSize: '1.125rem' }}
                      />
                      <span>API Endpoints</span>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div align="right" className={classes.switchLinkContainer}>
                  <CqButton
                    text={
                      serverView
                        ? 'Switch To API Endpoint View'
                        : 'Switch To API Host View'
                    }
                    height={26}
                    withBg={false}
                    onClick={updateServerView}
                    disabled={loading}
                  >
                    <SyncAltIcon />
                  </CqButton>
                </div>
              </Grid>
            </Grid>
            {!serverView && (
              <EndpointTable
                endpoints={endpointData}
                columns={serverTableHeaderColumns}
                setSelectedServer={setSelectedServer}
                routeToServerDetails={routeToServerDetails}
                getRequestResponse={getRequestResponse}
                totalEndpointsCount={totalEndpointsCount}
                crawlInfo={activeCrawlInfo}
                setFilters={updateFiltersState}
                filtersState={filtersState}
              />
            )}
            {serverView && (
              <ServersTable
                servers={serversData}
                setSelectedServer={setSelectedServer}
                routeToServerDetails={routeToServerDetails}
                updateServersList={updateServersList}
                crawlInfo={activeCrawlInfo}
                filters={serversFilters}
                totalServersCount={totalServersCount}
                setFilters={updateFiltersState}
                filtersState={filtersState}
              />
            )}
          </Grid>
        )}
          <XLSDialog
            openXLSDialog={openXLSDialog}
            handleExportClose={handleExportClose}
            domains={activeDomains}
            userInfo={userInfo}
            issueTypes={issueTypes}
          />
          { openReqResDialog && <RequestResponseDialog
            data={reqResData}
            openReqResDialog={openReqResDialog}
            setOpenReqResDialog={setOpenReqResDialog}
            selectedDomain={filtersState.selectedDomain}
            routeToServerDetails={routeToServerDetails}
          /> }
      </div>
    </SearchContext.Provider>
    :
    <>
      {serverDetailsLoading && <CqLoading />}
      {!serverDetailsLoading && (
        <ServerDetailsPage
          selectedServerData={selectedServerData}
          resetSelectedAPIServer={resetSelectedAPIServer}
          selectedDomain={filtersState.selectedDomain}
          serversList={serversList}
          updateSelectedServer={updateSelectedServer}
          crawlInfo={activeCrawlInfo}
        />
      )}
    </>
}

export default Servers