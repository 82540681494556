import React, {useState} from 'react';
import {Grid, Popover, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {colors} from '../../../assets/theme';
import clsx from 'clsx';
import AdjustIcon from '@mui/icons-material/Adjust';
import DnsIcon from '@mui/icons-material/Dns';
import TextWithIcon from '../../../components/common/TextWithIcon';
import ReportIcon from '@mui/icons-material/Report';
import Issues from '../Issues/Issues';
import { useGlobalState } from '../../../store/globalState';
import { abbrFormat } from '../../../lib/utils';

const useStyles = makeStyles(theme => ({
    overviewContent: {
      padding: theme.spacing(1),
      backgroundColor: colors.gray750,
      border: `1px solid ${colors.gray700}`,
      borderRadius: 4,
      color: colors.white,
      width: '100%',
      alignItems: 'center',
      height: '130px',
      '&:hover': {
        backgroundColor: 'rgba(42,42,42,0.3)'
      }
    },
    overviewCount: {
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: 34,
      fontWeight: 600
    },
    overviewContentDisabled: {
      pointerEvents: 'none'
    },
    trendOverview: {
        backgroundColor: colors.gray650,
        borderRadius: 5,
        padding: 5,
        alignItems: 'center',
        color: colors.white,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.gray400,
        }
    },
    divider: {
        backgroundColor: colors.white,
        marginTop: 10,
        width:'100%'
    },
    triggerPopoverPaper: {
        minWidth: 100,
        maxWidth: 200,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        backgroundColor: colors.almostBlack,
    },
    triggerPopoverRoot: {
        maxHeight: '50vh',
        marginTop: theme.spacing(1),
        pointerEvents: 'none',
    },
    overviewServers: {
      cursor: 'pointer'
    },
    overviewText: {
      width: '100%',
      '& h6': {
        lineHeight: '24px', /* 150% */
        letterSpacing: '0.15px',
        color: colors.gray200,
      },
      '& svg': {
        fill: colors.gray600,
        fontSize: '24px'
      },
      marginBottom: '4px'
    }
}));

const Overview = ({ data, loadServersPage, latestCrawlReport, routeToPage, log4jScanData, hostTypeFilter }) => {
    const classes = useStyles();
    const { apiServers, apiEndpoints } = data;
    const hostCount = hostTypeFilter.allFilter ? apiServers.attempted : hostTypeFilter.nonApiFilter ? apiServers.attempted-apiServers.total : apiServers.total
    const endpointsCount = hostTypeFilter.allFilter ? apiEndpoints.attempted : hostTypeFilter.nonApiFilter ? apiEndpoints.attempted-apiEndpoints.total : apiEndpoints.total
    const apiHostsCount = abbrFormat(hostCount || 0)
    const apiEndpointsCount = abbrFormat(endpointsCount || 0)
    const global = useGlobalState();
    const loadEndpointView = () => {
      global.userPreferences['serverView'].set(false);
      loadServersPage()
    }
    const loadServersView = () => {
      global.userPreferences['serverView'].set(true);
      loadServersPage()
    }
    const loadServersFindings = (url, params) => {
      global.userPreferences['serverView'].set(params.description === 'ENDPOINT' ? false : true);
      routeToPage(url, params)
    }
    const hostLabel = hostTypeFilter.allFilter ? 'Hosts' : hostTypeFilter.nonApiFilter ? 'Non-API Hosts' : 'API Hosts'
    const endpointLabel = hostTypeFilter.allFilter ? 'Endpoints' : hostTypeFilter.nonApiFilter ? 'Non-API Endpoints' : 'API Endpoints' 

    const issues = hostTypeFilter.allFilter ? latestCrawlReport?.totalIssues : hostTypeFilter.nonApiFilter ? latestCrawlReport?.nonApiIssues : latestCrawlReport?.issues

    return (
        <>
            <Grid container columnSpacing='12px'>
                <Grid item container xs={4} onClick={loadEndpointView} className={clsx(classes.overviewServers)}>
                    <div className={clsx(classes.overviewContent)}>
                      <div className={classes.overviewText}>
                        <TextWithIcon>
                          <AdjustIcon />
                          <Typography auto-id={'api_servers_txt'} variant={'subtitle1'}>{endpointLabel}</Typography>
                        </TextWithIcon>
                      </div>
                      <div className={classes.overviewCount}>
                        {apiEndpointsCount}
                      </div>
                    </div>
                </Grid>
                <Grid item xs={4} onClick={loadServersView} className={clsx(classes.overviewServers)}>
                    <div className={classes.overviewContent}>
                        <div className={classes.overviewText}>
                          <TextWithIcon>
                            <DnsIcon />
                            <Typography auto-id={'hosting_providers_txt'} variant={'subtitle1'}>{hostLabel}</Typography>
                          </TextWithIcon>
                        </div>
                        <div className={classes.overviewCount}>
                            {apiHostsCount}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={clsx(classes.overviewContent)}>
                    <div className={classes.overviewText}>
                      <TextWithIcon>
                        <ReportIcon auto-id={'issues-icon'} />
                        <Typography auto-id={'hosting_providers_txt'} variant={'subtitle1'}>Findings</Typography>
                      </TextWithIcon>
                    </div>
                    <Issues
                      data={issues}
                      isLog4jScan={log4jScanData}
                      routeToServerEndpoints={loadServersFindings}
                    />
                  </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Overview;
