import React, { useState } from 'react';
import { useSession, useUser } from '@descope/react-sdk';

import { styled } from '@mui/material';
import LoadingPage from '../Loading';
import LoginSignupContent from './LoginSignup';
import { colors } from '../../assets/theme';

const StyledContainer = styled('div')({
  padding: '12px 24px',
  background: colors.gray800,
  height: 'calc(100vh)',
});

const StyledFlex = styled('div')({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  justifyContent: 'center',
});

const HomePage = () => {
  const { isUserLoading } = useUser();
  const { isAuthenticated, isSessionLoading } = useSession();

  const Content = isSessionLoading || isUserLoading ? <LoadingPage /> : <LoginSignupContent />;

  return (
    <StyledContainer>
      <StyledFlex>{Content}</StyledFlex>
    </StyledContainer>
  );
};

export default HomePage;
