import React, { useContext } from 'react';
import { Grid, Typography, Table, TableRow, TableCell, TableBody } from '@mui/material';
import { colors } from '../../assets/theme';
import { styled } from '@mui/material/styles';
import { SeverityBadge } from '../../components/SeverityBadge';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import GatewayCell from '../../components/GatewayCell';

const StyledCell = styled(TableCell)(({background}) => ({
  backgroundColor: background === 'true' ? colors.gray750 : 'none',
  borderRadius: '4px',
  padding: '10px'
}))

const StyledTableRow = styled(TableRow)({
  border: `0.5px solid ${colors.gray500}`
})

const ServerDetailsHeader = ({ selectedServerData }) => {
  const wrapperContext = useContext(WrapperContext);
  const gatewayConfigData = wrapperContext.gatewayConfig;
  const gatewayConfigObj = {};
  for (const obj of gatewayConfigData) {
    gatewayConfigObj[obj.name] = obj;
  }
  let ips = selectedServerData?.ips?.join(', ')
  if(selectedServerData.cname && selectedServerData.cname !== ''){
    ips = selectedServerData.cname + ', ' + ips
  }

  const gateways = selectedServerData.apiGateways || [];
  const gatewayList = gateways.map((val) => gatewayConfigObj?.[val]?.displayName || '')
    .join(',')

  return (
    <Table sx={{border: `1px solid ${colors.gray650}`, marginBottom: '16px'}}>
      <TableBody>
        <StyledTableRow>
          <StyledCell background='true'><Typography variant='overline'>HOSTING PROVIDER</Typography></StyledCell>
          <StyledCell><Typography variant='body2'>{selectedServerData?.providers}</Typography></StyledCell>
          <StyledCell background='true'><Typography variant='overline'>PROTOCOL</Typography></StyledCell>
          <StyledCell><Typography variant='body2'>{selectedServerData?.tlsInfo?.protocol}</Typography></StyledCell></StyledTableRow>
        <StyledTableRow>
          <StyledCell background='true'><Typography variant='overline'>HOST RISK LEVEL</Typography></StyledCell>
          <StyledCell><SeverityBadge high={selectedServerData?.findings?.high > 0} medium={selectedServerData?.findings?.medium > 0} info={selectedServerData?.findings?.info > 0} /></StyledCell>
          <StyledCell background='true'><Typography variant='overline'>CNAME / IP Address</Typography></StyledCell>
          <StyledCell><Typography variant='body2'>{ips}</Typography></StyledCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledCell background='true'><Typography variant='overline'>TOTAL FINDINGS</Typography></StyledCell>
          <StyledCell><Typography variant='body2'>{selectedServerData?.findings?.total}</Typography></StyledCell>
          <StyledCell background='true'><Typography variant='overline'>API Gateways</Typography></StyledCell>
          <StyledCell><Typography variant='body2'><GatewayCell list={gatewayList} /></Typography></StyledCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  );
};

export default ServerDetailsHeader;
