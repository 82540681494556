import React, { useContext } from 'react';
import Servers from './Servers';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import EmptyState from '../../components/EmptyState';

const ServersIndex = () => {
  const contextData = useContext(WrapperContext)
  const { activeDomains, showEmptyState }  = contextData;
  const domains =  activeDomains.map(val => ({name: val.domain}))
  return (
    activeDomains.length > 0 ? <Servers activeDomains={domains} context={contextData} /> : <EmptyState showEmptyState={showEmptyState} />
  )
}

export default ServersIndex