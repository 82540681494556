import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  selectField: {
    '& .MuiSelect-root': {
      padding: '6px 8px',
      borderRadius: 4,
      color: colors.white,
      background: colors.gray600,
      fontSize: 14,
    },
    '& .MuiSelect-icon': {
      color: colors.blueButtonBg,
    },
    '&:before, &:after': {
      border: 'none !important',
    },
  },
  selectLabel: {
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: '4px',
    color: colors.gray300,
  },
}));

const CqSelect = ({
  label,
  value = '',
  options = [],
  onChange,
  width,
  height,
  placeholder,
}) => {
  const classes = useStyles();
  const props = {};
  if (height) {
    props.style = { height: height };
  }
  return (
    <Grid>
      <InputLabel className={classes.selectLabel}>{label}</InputLabel>
      <FormControl style={{ width: width }}>
        <Select
          className={classes.selectField}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          displayEmpty
          renderValue={(value) => (value ? value : placeholder)}
          {...props}
        >
          {options.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

CqSelect.prototype = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

export default CqSelect;
