
export const updateServersSheet = (serversData, serversSheet, issueTypes) => {
  const serverDataSorted = serversData.sort((a, b) =>
    a.hostname > b.hostname ? 1 : a.hostname < b.hostname ? -1 : 0
  );
  const noVulnerableServers = [];
  for (const server of serverDataSorted) {
    const row = [server.hostname, server.providers];
    let hasIssue = false;
    const highVulnerabilties = [];
    const mediumVulnerabilties = [];
    const lowVulnerabilties = [];
    server?.issues?.forEach((issue) => {
      if (issue.severity === 'HIGH') {
        highVulnerabilties.push(issueTypes?.[issue.name]?.description || '');
      }
      if (issue.severity === 'MEDIUM') {
        mediumVulnerabilties.push(issueTypes?.[issue.name]?.description || '');
      }
      if (issue.severity === 'INFO') {
        lowVulnerabilties.push(issueTypes?.[issue.name]?.description || '');
      }
      hasIssue = true;
    });

    row.push(server?.cname || '')
    row.push(server?.ips?.join(', ') || '')
    row.push(server.protocol)
    row.push(server.isApi ? 'API' : 'Non Api')
    const htmlResponsePercent = server?.endpoints?.attempted ? server.endpoints.html/server.endpoints.attempted : 0
    const xmlResponsePercent = server?.endpoints?.attempted ? server.endpoints.xml/server.endpoints.attempted : 0
    const jsonResponsePercent = server?.endpoints?.attempted ? server.endpoints.json/server.endpoints.attempted : 0
    const otherRes = server.endpoints.text + server.endpoints.yaml
    const otherResponsePercent = server?.endpoints?.attempted ? otherRes/server.endpoints.attempted : 0
    row.push(`${(htmlResponsePercent*100).toFixed(1)}%`)
    row.push(`${(xmlResponsePercent*100).toFixed(1)}%`)
    row.push(`${(jsonResponsePercent*100).toFixed(1)}%`)
    row.push(`${(otherResponsePercent*100).toFixed(1)}%`)
    if (hasIssue) {
      row.push(highVulnerabilties.join('\n'));
      row.push(mediumVulnerabilties.join('\n'));
      row.push(lowVulnerabilties.join('\n'));
      serversSheet.addRow(row);
    } else {
      noVulnerableServers.push(row);
    }

    const lastRow = serversSheet.lastRow;
    lastRow.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
  }
  for (const row of noVulnerableServers) {
    serversSheet.addRow(row);
    const lastRow = serversSheet.lastRow;
    lastRow.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
  }
};
