import React, { memo, useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { isEqual } from 'lodash';
import { DISCOVERIES } from '../pages/crawls/constants';
import { colors } from '../assets/theme';

const LineChart = ({ chartId, data, chartHeight, findingsGroups }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const chartData = setChartData(data);
    const chart = createChart(chartHeight, chartData);
    chartRef.current = chart;
    return () => {
      if (chart) chart.dispose();
    };
  }, [data]);

  const createChart = (chartHeight, chartData) => {
    if (!chartId || !data) return;

    let chart = am4core.create(chartId, am4charts.XYChart);
    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    Object.entries(DISCOVERIES(chartData)).map((item, index) => {
      createSeries(chart, item[0], item[1].name, colors.issuesChart[index]);
    });
    chart.data = chartData
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = `${chartHeight}px`;
    chart.legend.scrollable = true;
    chart.legend.position = 'right';
    chart.legend.valign = 'center';
    chart.legend.paddingLeft = 15;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    return chart;
  };

  const createSeries = (chart, field, name, color) => {
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.stroke = am4core.color(color);
    series.name = name;
  };

  const getCount = (data, field) => {
    const d = data?.filter((i) => i.type === field);
    return d[0].count;
  };

  const setChartData = (data) => {
    if (!data) return;
    const modifyChartData = data.histogram.map((item, idx) => {
      const obj = {}
      obj.date = item?.date ?? '--'
      obj.highSeverityIssues = item?.data?.highSeverityIssues?.total ?? '--'
      for(const highIssue of findingsGroups.high){
        if(item['data']?.['highSeverityIssues']?.['issues']?.[highIssue.name]?.value){
          obj[highIssue.name] = item['data']?.['highSeverityIssues']?.['issues']?.[highIssue.name]?.value
        }
      }
      obj.mediumSeverityIssues = item?.data?.mediumSeverityIssues?.total ?? '--'
      for(const medIssue of findingsGroups.medium){
        if(item['data']?.['mediumSeverityIssues']?.['issues']?.[medIssue.name]?.value){
          obj[medIssue.name] = item['data']?.['mediumSeverityIssues']?.['issues']?.[medIssue.name]?.value
        }
      }
      obj.lowSeverityIssues = item?.data?.lowSeverityIssues?.total ?? '--'
      for(const lowIssue of findingsGroups.low){
        if(item['data']?.['lowSeverityIssues']?.['issues']?.[lowIssue.name]?.value){
          obj[lowIssue.name] = item['data']?.['lowSeverityIssues']?.['issues']?.[lowIssue.name]?.value
        }
      }
      obj.apiServers = item?.data?.apiServers ?? '--'
      obj.appHostingProviders = item?.data?.hostingProviders ?? '--'
      return obj
    });
    return modifyChartData;
  };

  return (
    <div
      id={chartId}
      style={{
        width: '1332px',
        height: `${chartHeight}px`,
        backgroundColor: colors.gray900,
      }}
    />
  );
};

function arePropsEqual(prevProps, nextProps) {
  if (isEqual(prevProps.data, nextProps.data)) {
    return true;
  } else {
    return false;
  }
}

export default memo(LineChart, arePropsEqual);
