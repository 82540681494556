import React from 'react';
import { Chip, Tooltip } from '@mui/material'

const GatewayCell = ({list}) => {
  if(!list || list.length === 0){
    return null
  }
  const listArr = list.split(',')
  return listArr.map(val => val !== '' ? <Chip key={val} size='small' sx={{mr: '4px'}} label={val} /> : null)
}

export default GatewayCell;