import React from 'react';
import { styled } from '@mui/material';
import { Descope } from "@descope/react-sdk";

const StyledLogin = styled('div')({
  marginTop: '100px',
  maxWidth: '500px'
});

const LoginSignupContent = () => {
  return (
    <StyledLogin>
      <Descope
        flowId="sign-up-or-in-cq"
        onError={(e) => console.log('Could not log in!', e)}
        theme="dark"
      />
    </StyledLogin>
  );
};

export default LoginSignupContent;