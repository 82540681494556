import React from 'react';
import icons from '../../assets/icons/Icons';

const CqIcon = ({ icon, size = 18, color = '#FFF', rotate = 0, onClick }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={icons[icon].viewBox || '0 0 1024 1024'}
    transform={`rotate(${rotate})`}
    onClick={onClick}
    style={onClick ? { cursor: 'pointer' } : null}
  >
    {icons[icon].paths.map((path, pi) => (
      <path d={path.data} fill={path.fill || color} key={pi} />
    ))}
  </svg>
);

export default CqIcon;
