export const CRAWL_SCHEDULE = {
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatic',
  NOT_AVAILBLE: 'N / A',
};

export const DOMAIN_STATUS = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  DENIED: 'DENIED',
};

export const SORT_ORDER = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
};

export const getHighIssues = (data) => {
  let highSeverityIssues = data.filter(issue => issue.severity === 'HIGH');
  const issues = {}
  for(const val of highSeverityIssues){
    val.type = val.name
    issues[val.name] = val
  }
  return issues
}

export const getMediumIssues = (data) => {
  const mediumSeverityIssues = data.filter(issue => issue.severity === 'MEDIUM');
  const issues = {}
  for(const val of mediumSeverityIssues){
    val.type = val.name
    issues[val.name] = val
  }
  return issues
}

export const getLowIssues = (data) => {
  const lowSeverityIssues = data.filter(issue => issue.severity === 'INFO');
  const issues = {}
  for(const val of lowSeverityIssues){
    val.type = val.name
    issues[val.name] = val
  }
  return issues
}

export const issueDescription = {
  LOG4J: 'Log4j Vulnerabilities',
  LONG4J: 'LoNg4j Vulnerabilities',
  EXPOSED_FILES: 'Exposed Keystores',
  TLS: 'Insecure SSL/TLS Implementation',
  UNHANDLED: 'Unhandled Or Internal Errors',
  NON_PROD_OR_ORIGIN: 'Non-production Or Internal Application Server',
  HEALTH_MONITORING: 'Application Health Monitoring Endpoints Exposed',
  LOGIN: 'User Login Or Authentication Endpoints Exposed',
  GRAPHQL: 'GraphQL Endpoints Detected',
  OPENAPI_SWAGGER: 'OpenAPI/Swagger Endpoints Exposed'
}
