import React from "react"
import { Autocomplete, Chip, TextField } from "@mui/material"

export default function InputWithChips({label, list, onChange, onDelete, onBlur}) {
  return (
    <Autocomplete
      clearIcon={false}
      options={[]}
      value={list}
      onChange={onChange}
      freeSolo
      multiple
      sx={{width: '100%'}}
      renderTags={(value, props) => {
        // console.log(value)
        return value.map((option, index) => {
          const chipProps = props(index)
          chipProps.onDelete = onDelete(option)
          return (
            <span key={`${option}-${index}`}><Chip label={option} {...chipProps} /></span>
          )})
      }}
      renderInput={(params) => <TextField onBlur={onBlur} placeholder="Press enter after adding value" variant="standard" {...params} />}
    />
  )
}
