import React from 'react';
import { GlobalStyles } from '@mui/material';

const CqGlobalStyles = () => {
  return (
    <GlobalStyles
      styles={{
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundColor: '#808080',
        },
        '*::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
        '*::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: '7px',
          height: '7px',
        },
      }}
    />
  );
};

export default CqGlobalStyles;
