export const GetCSPMIntegrations = `
  query GetCSPMIntegrations($integrationIds: [String!]) {
    cspm {
      getIntegrations(integrationIds: $integrationIds) {
        status
        message
        data {
          id
          name
          type
          status
          regions
          connectionStatus
          connectionCheckedDate
          apiHosts
          apiEndpoints
        }
      }
    }
  }
`;

export const AddIntegration = `
  mutation AddIntegration($name: String!, $type: CSPMIntegrationType!, $config: JSON){
    cspm {
      addIntegration (name: $name, type: $type, config: $config){
        status
        message
      }
    }
  }
`;

export const TestConnectivity = `
  mutation testConnectivity($type: CSPMIntegrationType!, $config: JSON){
    cspm {
      testConnectivity (type: $type, config: $config){
        status
        message
      }
    }
  }
`;

export const TestIntegrationConnectivity = `
  mutation testIntegrationConnectivity($id: String!){
    cspm {
      testIntegrationConnectivity (id: $id){
        status
        message
      }
    }
  }
`;

export const GetCSPMIntegrationsAssets = `
  query GetCSPMIntegrationsAssets($integrationIds: [String!]) {
    cspm {
      getIntegrationAssets (integrationIds: $integrationIds) {
        status
        message
        data {
          id
          name
          type
          apiEndpoints
          state
          region
          hostname
          createdOn
          logsEnabled
          integration {
            id
            type
            name
          }
        }
      }
    }
  }
`;
export const SyncIntegrationAssets = `
  mutation refreshIntegrationAssets($id: String!){
    cspm {
      refreshIntegrationAssets(id: $id){
        status
        message
      }
    }
  }
`;
