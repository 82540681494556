import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Grid, ButtonGroup, InputLabel, FormControlLabel, Switch, Typography} from '@mui/material';
import { colors } from '../../../assets/theme';
import FilterItem from '../../FilterItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectCrawl from '../../SelectCrawl';
import Button from "@mui/material/Button";
import CqMultiSelect from "../CqMultiSelect";
import moment from "moment";
import {timeMapping} from "../../../pages/dashboard/constants";
import CqButton from "../CqButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "../../Tooltip";

const useStyles = makeStyles(theme => ({
    filterContainer: {
        backgroundColor: colors.gray750,
        borderRadius: 4,
        border: `1px solid ${colors.gray700}`,
        alignItems: 'center',
        padding: '6px 0',
        gap: '20px'
    },
    crawlProgressContent: {
        backgroundColor: colors.orange200,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: '150px',
        display: 'flex'
    },
    triggerPopoverPaper: {
        minWidth: 300,
        maxWidth: 300,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        backgroundColor: colors.almostBlack,
        border: `1px solid ${colors.white}`,
    },
    triggerPopoverRoot: {
        maxHeight: '50vh',
        marginTop: theme.spacing(1),
        pointerEvents: 'none',
    },
    subTitleContainer: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    selectedButtonColor: {
        backgroundColor: colors.blueButtonBg
    },
    unselectedButtonColor: {
        backgroundColor: colors.gray650
    },
    inpLabel: {
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: '4px',
      color: colors.gray300,
    }
}));

const HeaderFilters = (props) => {
    const classes = useStyles();
    const {
      data,
      selectedDomain,
      handleChange,
      crawlHistory,
      onSelectCrawlId,
      selectedTimeRange,
      selectedCrawlId,
      updateCspmIntegrationType,
      uapAwareness,
      handleTimeRangeChange,
      selectedCspmIntegrationType,
      allDomainsReport,
      loading,
      crawlInProgress,
      applyRefresh,
      crawlStats,
      setShowCrawlStat,
      apiHosts,
      handleApiHosts
    } = props;
    let filteredCrawlHistory = crawlHistory;
    if (selectedTimeRange !== 'All') {
        const dateFrom = moment(Date.now() - timeMapping[selectedTimeRange] * 24 * 3600 * 1000).valueOf();
        filteredCrawlHistory = crawlHistory.filter(i => {if(i.submittedAt >= dateFrom) return i});
    }
    const [searchFocus, setSearchFocus] = useState(false);
    const selectSingle = (option) => {
      if(option){
        handleChange(option);
      }
    };
    const domainsList = data.map(val => ({name: val.domain}));
    // const uapList = Object.keys(uapAwareness).map(type => {
    //   return {name: type, selected: selectedCspmIntegrationType[type] ? true : false}
    // })
    return (
        <>
          <Grid  container className={classes.filterContainer} gap={2}>
            <Grid item>
              <FilterItem
                label='Domains'
                options={domainsList}
                selectedValue={selectedDomain !== '' ? selectedDomain : 'All'}
                multiChoice={searchFocus}
                showSearch={true}
                showAll={false}
                selectOptions={selectSingle}
                searchLabel='Domain'
                singleSelect={true}
                domainFocus={(val) => setSearchFocus(val)}
                disabled={loading}
              />
            </Grid>
            {!allDomainsReport && <Grid item>
              <InputLabel className={classes.inpLabel}>Timeframe</InputLabel>
              <ButtonGroup size="small" variant="contained">
                <Button className={selectedTimeRange === '24h' ? classes.selectedButtonColor : classes.unselectedButtonColor} onClick={()=>handleTimeRangeChange('24h')}>24h</Button>
                <Button className={selectedTimeRange === '3d' ? classes.selectedButtonColor : classes.unselectedButtonColor} onClick={()=>handleTimeRangeChange('3d')}>3d</Button>
                <Button className={selectedTimeRange === '1w' ? classes.selectedButtonColor : classes.unselectedButtonColor} onClick={()=>handleTimeRangeChange('1w')}>1w</Button>
                <Button className={selectedTimeRange === 'All' ? classes.selectedButtonColor : classes.unselectedButtonColor} onClick={()=>handleTimeRangeChange('All')}>All</Button>
              </ButtonGroup>
            </Grid>}
            {!allDomainsReport && <Grid item>
              <SelectCrawl
                crawlHistory={filteredCrawlHistory}
                selectedCrawlId={selectedCrawlId}
                disabled={loading}
                crawlInProgress={crawlInProgress}
                setCrawlId={onSelectCrawlId} />
            </Grid>}
            <Grid item>
              <FilterItem
                label='Discovery Classification'
                options={apiHosts}
                multiChoice={true}
                showSearch={false}
                selectOptions={handleApiHosts}
                placeholder='All'
                showAll={true}
                width={150}
              />
            </Grid>
            {crawlInProgress && <Grid item sx={{paddingTop: '15px'}}>
              <RefreshIcon
                style={{
                    color: colors.blueButtonBg,
                    fontSize: '30px',
                    cursor: 'pointer'
                }}
                onClick={applyRefresh}
              /></Grid>
            }
            {crawlStats && <Grid item>
              <Tooltip title="Crawl Info">
                <InfoIcon
                  style={{
                    color: colors.gray100,
                    fontSize: '20px',
                    marginTop: '25px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowCrawlStat(true)}
                />
              </Tooltip></Grid>
            }
          </Grid>
        </>
    );
};



export default HeaderFilters;
