import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import VulnerabilitiesDetailsTable from "./VulnerabilitiesDetailsTable";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LaunchIcon from "@mui/icons-material/Launch";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
    {
        name: 'API Endpoint',
    },
    {
        name: 'Method',
    },
    {
        name: 'Response Code', sort: true
    }
];



const UnhandledServerDetails = ({ vulnerabilityServers, getRequestResponse, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false
  }
  const [expand, setExpand] = useState(expandSection)
  useEffect(() => {
      setExpand({
        des: expanded,
        risk: expanded,
        ref: expanded,
        rec: expanded
      })
  }, [expanded])
  const handleChange = (panel) => () => {
    setExpand({...expand, [panel]: !expand[panel]})
  }
    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                            Unhandled or HTTP 500 Server response indicate that the server encountered an unexpected condition that
                            prevented it from fulfilling the request. This error response is a generic "catch-all" response used when an
                            unhandled exception is triggered while processing a request.

                            Internal Server Errors are usually benign. However, an unhandled exception may be indicative of a security issue,
                            such as a SQL injection or other targeted attacks that were unexpected by the application.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            The unhandled server error response may include information about the application that can help the attacker
                            fine-tune their attacks to accurately target the application. For example, software developers often add stack
                            traces to error messages as a debugging aid. Stack traces can tell the developer more about the sequence of
                            events that led to the failure but expose sensitive aspects of the application that are valuable to a willful attacker.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Consider adding consistent error handling mechanisms which can handle any user input to your API. Ideally, the
                            error would return a 4xx status like '400 Bad Request' and provide meaningful details to end-users so they
                            understand how to fix their request.

                            Also, ensure that error messages are not providing information useful to an attacker, like a stacktrace.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            OWASP Guidance:
                            <Link target={'_blank'} href={'https://owasp.org/www-community/Improper_Error_Handling'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                https://owasp.org/www-community/Improper_Error_Handling
                                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                            </Link>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
                <Typography>
                    Potentially Unhandled or Internal Errors
                </Typography>
                <VulnerabilitiesDetailsTable
                    columns={exposedFilesHeaderColumns}
                    vulnerabilityServers={vulnerabilityServers}
                    getRequestResponse={getRequestResponse}
                />
            </Grid>
        </>
    );
};

export default UnhandledServerDetails;
