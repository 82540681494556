import React, { useState } from 'react';
import {Grid, Typography} from '@mui/material';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import makeStyles from '@mui/styles/makeStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SortIcon from '@mui/icons-material/Sort';
import Tooltip from '@mui/material/Tooltip';
import { useGlobalState } from '../../store/globalState';
import { localStore } from '../../store/localStore';
import TextWithIcon from '../../components/common/TextWithIcon';
import BarChartIcon from '@mui/icons-material/BarChart';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import CqButton from '../../components/common/CqButton';
import { colors } from '../../assets/theme';
import SpyBarChart from '../../components/SpyBarChart';
import SpyPieChart from '../../components/SpyPieChart';
import ServersTable from './Servers/ServersTable';
import { numberCountStyles, subtitleContent } from './styles';

const chartHeight = 350;
const chartConfig = {
  type: '3d',
  categoryField: 'service',
  valueField: 'servers',
  disableLabels: false,
  disableLegend: false,
};

const useStyles = makeStyles({
  sortIcon: {
    transform: 'scaleY(-1)',
  },
  container: {
    paddingLeft: '16px',
    paddingTop: '7px'
  },
  filterContainer: {
    display: 'flex',
  },
  filterBox: {
    borderLeft: '1px solid #7F7F7F',
    height: '28px',
    padding: '0 8px',
  },
  sortButton: {
    display: 'flex',
    gap: '5px',
  },
  donutIcon: {
    color: colors.blue300,
    cursor: 'pointer',
  },
  chartIcon: {
    color: colors.blue,
    cursor: 'pointer',
    transform: 'rotate(0.25turn)',
  },
  chartButton: {
    paddingLeft: '18px',
  },
  chatButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  subtitleContent: subtitleContent,
  numberCount: numberCountStyles,
  switchContainer: {
    '& .Mui-checked': {
      color: colors.blue300,
    },
  },
});
const toggleButtonStyles = {
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: colors.blue300,
    color: colors.gray700,
  },
  backgroundColor: colors.gray600,
  color: colors.gray200,
  textTransform: 'capitalize',
  height: '28px',
};

const Charts = ({
  allDomainsReport,
  filteredServerData,
  selectedServers,
  serversColumnData,
  handleServerSelection,
  handleTableAllSelectClick,
  selectedServerCount,
  selectedOtherServers,
  otherServersCount,
  handleOtherServersSelection,
  routeToPage,
  hostTypeFilter
}) => {
  const global = useGlobalState();
  const classes = useStyles();
  const { userPreferences } = global.get();
  const store = localStore();
  const [showPieChart, setPieChartVisible] = useState(
    userPreferences.pieChartView
  );
  const [sortServers, setSortServers] = useState(
    userPreferences.barchartAcendingSort
  );
  const [logScale, updateLogScale] = useState(userPreferences.logscale);
  const handleChart = () => {
    setPieChartVisible((showChart) => {
      global.userPreferences['pieChartView'].set(!showChart);
      store.update('userPreferences', 'pieChartView', !showChart);
      return !showChart;
    });
  };
  const updateSortData = () => {
    setSortServers((state) => {
      global.userPreferences['barchartAcendingSort'].set(!state);
      store.update('userPreferences', 'barchartAcendingSort', !state);
      return !state;
    });
  };

  const setLogScale = (e, alignment) => {
    if (alignment && alignment !== logScale) {
      global.userPreferences['logscale'].set(alignment);
      store.update('userPreferences', 'logscale', alignment);
      updateLogScale(alignment);
    }
  };

  const sortLabel = sortServers
    ? 'Sort By Highest Server Count'
    : 'Sort By Lowest Server Count';

  // need to subtract the others index
  // const hpCount = (filteredServerData.length > 10 ?  filteredServerData.length - 1 : filteredServerData.length) + (selectedOtherServers.length);
  let hpCount = 0
  let chartData = []
  let otherHpServers = []
  let hostLabel = ''
  if(hostTypeFilter.allFilter){
    chartData = filteredServerData.filter(val => val.countAttempted > 0)
    otherHpServers = selectedOtherServers.filter(val => val.countAttempted > 0)
    hpCount = chartData.length + otherHpServers.length
    hostLabel = 'Hosting Providers'
  } else if(hostTypeFilter.nonApiFilter){
    chartData = filteredServerData.filter(val => val.nonApiCount > 0)
    otherHpServers = selectedOtherServers.filter(val => val.nonApiCount > 0)
    hpCount = chartData.length + otherHpServers.length
    hostLabel = 'Non-API Hosting Providers'
  } else {
    hostLabel = 'API Hosting Providers'
    chartData = filteredServerData.filter(val => val.count > 0)
    otherHpServers = selectedOtherServers.filter(val => val.count > 0)
    hpCount = chartData.length + otherHpServers.length
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={4}>
          <TextWithIcon className={classes.subtitleContent}>
            <FilterDramaIcon/>
            <Typography variant={'subtitle1'}>
              {hostLabel}
            </Typography>
            <span className={classes.numberCount}>{hpCount}</span>
          </TextWithIcon>
        </Grid>
        <Grid item xs={8} className={classes.chatButtonsContainer}>
          {showPieChart ? (
            <>
              <Tooltip title="View Bar Chart" placement="left">
                <div>
                  <CqButton
                    height={26}
                    onClick={handleChart}
                    px={0}
                    className={classes.chartButton}
                  >
                    <BarChartIcon className={classes.chartIcon} />
                  </CqButton>
                </div>
              </Tooltip>
            </>
          ) : (
            <div className={classes.filterContainer}>
              <Tooltip title="View Pie Chart" placement="left">
                <div>
                  <CqButton height={26} onClick={handleChart} px={0}>
                    <DonutSmallIcon className={classes.donutIcon} />
                  </CqButton>
                </div>
              </Tooltip>
              <div className={classes.filterBox}>
                <ToggleButtonGroup
                  value={logScale}
                  exclusive
                  onChange={setLogScale}
                >
                  <ToggleButton
                    sx={toggleButtonStyles}
                    value="linear"
                    className={classes.toggleButton}
                  >
                    Linear Scale
                  </ToggleButton>
                  <ToggleButton
                    sx={toggleButtonStyles}
                    value="logarithmic"
                    className={classes.toggleButton}
                  >
                    Log Scale
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.filterBox}>
                <CqButton
                  height={26}
                  variant={'borderBlue'}
                  onClick={updateSortData}
                  className={classes.sortButton}
                >
                  <SortIcon className={!sortServers ? classes.sortIcon : ''} />
                  <span>{sortLabel}</span>
                </CqButton>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <>
        {showPieChart ? (
          <>
            <Grid item container xs={5} style={{ display: 'inline-block' }}>
              <SpyPieChart
                handleChart={handleChart}
                data={chartData}
                hostTypeFilter={hostTypeFilter}
                chartId="sources-chart"
                chartHeight={chartHeight}
                chartConfig={chartConfig}
              />
            </Grid>
            <Grid
              auto-id={'servers-table'}
              item
              container
              xs={7}
              style={{ display: 'inline-block', marginTop: '26px' }}
            >
              <ServersTable
                allDomainsReport={allDomainsReport}
                serversData={selectedServers}
                columns={serversColumnData}
                hostTypeFilter={hostTypeFilter}
                handleUpdateSelection={handleServerSelection}
                handleTableAllSelectClick={handleTableAllSelectClick}
                selectedServerCount={selectedServerCount}
                selectedOtherServers={selectedOtherServers}
                otherServersCount={otherServersCount}
                handleOtherServersSelection={handleOtherServersSelection}
                routeToServerEndpoints={(url, params) =>
                  routeToPage(url, {
                    ...params,
                    selectedServers: selectedServers,
                    selectedOtherServers: selectedOtherServers,
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={12} item>
              <SpyBarChart
                sortServers={sortServers}
                logScale={logScale}
                data={chartData}
                chartId="sources-chart"
                handleChart={handleChart}
                hostTypeFilter={hostTypeFilter}
                otherServerData={otherHpServers}
                routeToServerEndpoints={(url, params, servers, otherServers) =>
                  routeToPage(url, {
                    ...params,
                    selectedServers: servers,
                    selectedOtherServers: otherServers,
                  })
                }
              />
            </Grid>
          </>
        )}
      </>
    </>
  );
};

export default Charts;
