import React, { useState, useEffect } from 'react';

import { Radio, FormControl, FormLabel, RadioGroup, FormControlLabel, Grid, Box, TextField, Switch, Typography  } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IssueDescription } from "../../../lib/constants";
import CqDialog from '../../../components/common/CqDialog';

import QueryBuilderAlgo from '../../../components/QueryBuilderAlgo';
import { endpointLevelFields, hostLevelFields } from '../../../lib/queryBuilderUtils';
import { colors } from '../../../assets/theme';

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  gap: '4px',
  flexDirection: 'row'
})

const StyledBox = styled(Box)({
  margin: '16px 0'
})

const StyledTextBox = styled(Box)({
  margin: '24px 0'
})

const CustomFindingDialog = ({
  openCustomFindingDialog,
  type,
  onSubmit,
  setOpenCustomFindingDialog,
  tempFindingsData,
  setTempFindingsData,
  onExtraBtnClick,
  findingAlgoConfiguration
}) => {
  const queryFields = type === 'HOST' ? hostLevelFields : endpointLevelFields
  const defaultFindingFields = {
    description: '',
    displayName: '',
    severity: 'INFO',
    enabled: true
  }
  const defaultQuery = {
    'rules': [{ field: queryFields[0].name, operator: 'contains', value: ''}],
    combinator: 'and'
  }
  const [findingFields, setFindingFields] = useState(defaultFindingFields)
  const [existingFindingError, setExistingFindingError] = useState(false)
  const [inProgress, setInprogress] = useState(false)
  const [query, setQuery] = useState(defaultQuery);

  const isLog4j = tempFindingsData && tempFindingsData.name === 'LOG4J_LONG4J'

  useEffect(() => {
    if(!openCustomFindingDialog){
      setInprogress(false)
    }
  }, [openCustomFindingDialog]);

  useEffect(() => {
    let des = IssueDescription?.[tempFindingsData.name]?.description
    if(tempFindingsData.type !== 'TLS' && !des){
      des = tempFindingsData.description
    }
    if(tempFindingsData.name){
      if(!isLog4j) {
        setQuery(tempFindingsData.algo)
      } else {
        setQuery(null)
      }
      setFindingFields({
        displayName: tempFindingsData.displayName,
        description: tempFindingsData.description,
        severity: tempFindingsData.severity,
        enabled: tempFindingsData.enabled
      })
    }
  }, [tempFindingsData])

  const updateFindingFields = (key) => (e) => {
    if(key === 'enabled'){
      setFindingFields(obj => ({...obj, enabled: !obj.enabled}))
    } else {
      if(key === 'displayName' && existingFindingError){
        setExistingFindingError(false)
      }
      setFindingFields(obj => ({...obj, [key]: e?.target?.value}))
    }
  }

  let validateData = findingFields.severity === '' || findingFields.description === '' || findingFields.displayName === ''
  if(!isLog4j){
    if(query.rules.length > 0){
      for(const rule of query.rules){
        if(rule.value === ''){
          validateData = true;
          break;
        }
      }
    } else {
      if(query.rules.length === 0){
        validateData = true;
      }
    }
  }

  const handleSubmit = async () => {
    setInprogress(true)
    try {
      if(tempFindingsData.name){
        const data = {...tempFindingsData,
          algo:query,
          description: findingFields.description,
          severity: findingFields.severity,
          enabled: findingFields.enabled,
          displayName: findingFields.displayName
        }
        await onSubmit(data, true)
        setFindingFields(defaultFindingFields)
      } else {
        const existingFinding = findingAlgoConfiguration.filter(val => val.name === findingFields.displayName)
        if(existingFinding.length > 0) {
          setExistingFindingError(true)
          setInprogress(false)
        } else {
          const data = {...findingFields, algo:query, type: type, custom: true, name: findingFields.displayName, status: 'ACTIVE'}
          await onSubmit(data, false)
          setFindingFields(defaultFindingFields)
          setQuery(defaultQuery)
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  let dialogTitle = isLog4j ? 'Log4J/Long4J Vulnerability' : type === 'HOST' ? 'Host Finding' :  'Endpoint Finding'
  if(tempFindingsData.name){
    dialogTitle = `Update ${tempFindingsData.displayName}`
  } else {
    dialogTitle = `New ${dialogTitle}`
  }

  let customFinding = true
  if(tempFindingsData && tempFindingsData.custom === false){
    customFinding = false
  }

  return (
    <CqDialog
      open={openCustomFindingDialog}
      maxWidth={isLog4j ? 'sm' : 'lg'}
      title={dialogTitle}
      submitBtnText='Save Finding'
      onSubmit={handleSubmit}
      submitBtnDisabled={validateData}
      backdrop={inProgress}
      showExtraBtn={customFinding === false}
      onExtraBtnClick={() => onExtraBtnClick()}
      extraBtnText='Show Defaults'
      disableBackdropClick
      disableEscapeKeyDown
      onClose={() => {
        setOpenCustomFindingDialog(false)
        setFindingFields(defaultFindingFields)
        setTempFindingsData({})
        setExistingFindingError(false)
        setQuery(defaultQuery)
      }}
    >
      <Grid container>
        <Grid item xs={isLog4j ? 12 : 4} sx={{pl: '20px'}}>
          <StyledBox>
            <FormControl>
              <FormLabel>Enabled/Disabled</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={findingFields.enabled}
                    onChange={updateFindingFields('enabled')}
                    sx={{
                      '& .Mui-checked': {
                        color: colors.blue300,
                      }
                    }}
                  />
                }
                label={findingFields.enabled ? 'Enabled' : 'Disabled'} />
            </FormControl>
          </StyledBox>
          <StyledBox>
            <FormControl>
              <FormLabel>Finding Severity</FormLabel>
              <StyledRadioGroup
                value={findingFields.severity}
                onChange={updateFindingFields('severity')}
              >
                <FormControlLabel value='HIGH' control={<Radio />} label='High' />
                <FormControlLabel value='MEDIUM' control={<Radio />} label='Medium' />
                <FormControlLabel value='INFO' control={<Radio />} label='Info' />
              </StyledRadioGroup>
            </FormControl>
          </StyledBox>
          <StyledTextBox>
            <TextField
              label='Finding Name *'
              placeholder='Give your finding a name'
              variant='outlined'
              value={findingFields.displayName}
              onChange={updateFindingFields('displayName')}
              error={existingFindingError}
              fullWidth
              helperText={existingFindingError && 'Finding name is taken already.'}
            />
          </StyledTextBox>
          <StyledTextBox>
            <TextField
              label='Description *'
              multiline rows={4}
              placeholder='Describe the nature of this finding'
              variant='outlined'
              value={findingFields.description}
              onChange={updateFindingFields('description')}
              fullWidth
            />
          </StyledTextBox>
        </Grid>
        {!isLog4j && <Grid item xs={8}>
          <Typography variant='caption' sx={{marginLeft: '25px'}}>Add finding algorithm rules*</Typography>
          <QueryBuilderAlgo  fields={type === 'HOST' ? hostLevelFields : endpointLevelFields} query={query} setQuery={setQuery} />
        </Grid>}
      </Grid>
    </CqDialog>
  )
}

export default CustomFindingDialog