import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';

const LoadingPage = () => {
  return (
    <Box sx={{ display: 'flex', margin: 'auto' }}>
      <CircularProgress />
      <Typography sx={{ ml: 2 }} variant='h4'>Please wait...</Typography>
    </Box>
  );
};

export default LoadingPage;
