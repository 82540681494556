import React, { useState, useEffect, useContext, useRef } from "react";
import { removeIdFieldsAndConvertCombinator, convertJoinToComparator } from "../../lib/queryBuilderUtils";
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, MenuItem } from "@mui/material";
import {
  StyledButtonContainer,
} from './styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CqButton from "../../components/common/CqButton";
import { getEndpointDefaultConfig, updateEndpointConfig, getEndpointConfig } from "./provider";
import CqLoading from "../../components/common/CqLoading";
import { DiscoveryContext } from './DiscoveryContext';
import { colors } from "../../assets/theme";
import Rules from "./EndpointAlgorithm/Rules";
import { endpointLevelFields } from "../../lib/queryBuilderUtils";
import CqDialog from "../../components/common/CqDialog";

const strToRules = (list) => {
  const algoList = list.map((val) => ({...val, algo: convertJoinToComparator(JSON.parse(atob(val.algo)))}));
  return algoList
}

const getErrorStr = (obj, type) => {
  let errorMsg = ''
  if(!obj.name ){
    errorMsg += 'Rulename cannot be empty.'
  }
  if(!obj.status){
    errorMsg += ` Aleast one of the rules should be enabled.`
  }
  if(!obj.rules){
    errorMsg += ' Values cannot be empty'
  }
  if(!obj.duplicateName){
    errorMsg += ' Duplicate Rule Name'
  }

  if(errorMsg !== ''){
    return `${type} errors: ${errorMsg}`
  }
  return ''
}

const EndpointAlgorithm = () => {
  const [includesQuery, setIncQuery] = useState([{name: '', status: 'ENABLED', algo: {rules: []}}])
  const [excludesQuery, setExcludesQuery] = useState([{name: '', status: 'ENABLED', algo: {rules: []}}])
  const [expanded, setExpanded] = useState({panel1 : true, panel2: true});
  const [loading, setLoading] = useState(false);
  const [enableSave, setEnableSave] = useState(false)
  const [deleteRuleName, setDeleteRuleName] = useState('')
 
  const discoverContext = useContext(DiscoveryContext);

  const handleChange = (panel) => () => {
    setExpanded(val => ({...val, [panel]: !val[panel] }));
  };

  const loadEndpointConfig = async() => {
    setLoading(true)
    try {
      const res = await getEndpointConfig()
      const rulesInclude = res?.data?.getEndpointClassificationDiscoveryConfigs?.data?.rules_include;
      const rulesExclude = res?.data?.getEndpointClassificationDiscoveryConfigs?.data?.rules_exclude;
      if(rulesInclude !== null){
        const rulesIncludeAlgo = strToRules(rulesInclude);
        setIncQuery(rulesIncludeAlgo)
      }
      if(rulesExclude !== null){
        const rulesExcludeAlgo = strToRules(rulesExclude);
        setExcludesQuery(rulesExcludeAlgo)
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }

  useEffect(() => {
    loadEndpointConfig()
  },[])

  const updateChanges = async () => {
    const validateDataInc = validate(includesQuery, 'includes')
    const validateDataExl = validate(excludesQuery, 'excludes')
    const isValidateIncludes = validateDataInc.name &&
      validateDataInc.status &&
      validateDataInc.rules &&
      validateDataInc.duplicateName

    const isValidateExcludes = validateDataExl.name &&
      validateDataExl.status &&
      validateDataExl.rules &&
      validateDataInc.duplicateName

    if(isValidateIncludes && isValidateExcludes){
      setLoading(true)
      let error = false
      try {
        const incQuery = includesQuery.map(val => ({...val, algo: btoa(JSON.stringify(removeIdFieldsAndConvertCombinator(val.algo)))}))
        const excQuery = excludesQuery.map(val => ({...val, algo: btoa(JSON.stringify(removeIdFieldsAndConvertCombinator(val.algo)))}))
        const res = await updateEndpointConfig({rules_include: incQuery, rules_exclude: excQuery})
        const status =  res?.data?.updateEndpointClassificationDiscoveryConfig?.status
        const rulesInclude = res?.data?.updateEndpointClassificationDiscoveryConfig?.data?.rules_include;
        const rulesExclude = res?.data?.updateEndpointClassificationDiscoveryConfig?.data?.rules_exclude;
        if(rulesInclude !== null){
          const rulesIncludeAlgo = strToRules(rulesInclude);
          setIncQuery(rulesIncludeAlgo)
        }
        if(rulesExclude !== null){
          const rulesExcludeAlgo = strToRules(rulesExclude);
          setExcludesQuery(rulesExcludeAlgo)
        }
        if(!status || status === 'ERROR'){
          error = true
        } else {
          setEnableSave(false)
        }

      } catch (e) {
        console.error(e)
      }
      discoverContext.setNotify({
        open: true,
        message: error ? 'Something went wrong please try again later' : 'Saved Endpoint classification!',
        success: !error,
      });
      setLoading(false)
    } else {
      let errorMsg = <div>
          <p>{getErrorStr(validateDataInc, 'Classify as API Endpoint')}</p>
          <p>{getErrorStr(validateDataExl, 'Do not Classify as API Endpoint')}</p>
        </div>
      loadEndpointConfig()
      discoverContext.setNotify({
        open: true,
        message: errorMsg,
        success: false,
      });
    }
  }

  const handleDeleteClose = () => {
    setDeleteRuleName('')
    loadEndpointConfig()
  }

  const getDefaultConfig = async () => {
    setLoading(true)
    let error = false
    try {
      const res = await getEndpointDefaultConfig()
      const rulesInclude = res?.data?.getEndpointClassificationDiscoveryDefaultConfig?.data?.rules_include || [];
      const rulesExclude = res?.data?.getEndpointClassificationDiscoveryDefaultConfig?.data?.rules_exclude || [];
      const rulesIncludeAlgo = strToRules(rulesInclude);
      const rulesExcludeAlgo = strToRules(rulesExclude);
      const customIncRules = includesQuery.filter(rl => rl.type === 'CUSTOM').map(val => ({...val, status: 'DISABLED'}))
      const customExRules = excludesQuery.filter(rl => rl.type === 'CUSTOM').map(val => ({...val, status: 'DISABLED'}))
      setIncQuery([ ...customIncRules, ...rulesIncludeAlgo ])
      setExcludesQuery([  ...customExRules, ...rulesExcludeAlgo ])
    } catch (e) {
      console.error(e)
      error = true
    }
    discoverContext.setNotify({
      open: true,
      message: error ? 'Something went wrong please try again later' : 'Restored to defaults, Please save changes!',
      success: !error,
    });
    setLoading(false)
  }

  const updateIncludes = (val, ind, key) => {
    let field = val
    if(key === 'status'){
      field = val === true ? 'ENABLED' : 'DISABLED';
    }
    setIncQuery(arr => {
      const list = [...arr];
      list[ind] = {...list[ind], [key]: field}
      return list
    })
    setEnableSave(true)
  }

  const updateExcludes = (val, ind, key)  => {
    let field = val
    if(key === 'status'){
      field = val === true ? 'ENABLED' : 'DISABLED';
    }
    setExcludesQuery(arr => {
      const list = [...arr];
      list[ind] = {...list[ind], [key]: field}
      return list
    })
    setEnableSave(true)
  }

  const deleteAlgo = () => {
    updateChanges()
    setDeleteRuleName('')
  }

  const deleteInc = (i) => () =>{
    const deleteRuleName = includesQuery?.[i]?.name || 'n/a' ;
    setIncQuery(arr => {
      const list = [...arr];
      list.splice(i, 1)
      return list
    });
    setDeleteRuleName(deleteRuleName)
  }

  const deleteExl = (i) => () =>{
    const deleteRuleName = includesQuery?.[i]?.name || 'n/a' ;
    setExcludesQuery(arr => {
      const list = [...arr];
      list.splice(i, 1)
      return list
    });
    setDeleteRuleName(deleteRuleName)
  }

  const addRule = (type) => () => {
    if(type === 'include'){
      setIncQuery(arr => {
        const list = [...arr];
        list.unshift({name: '', status: 'ENABLED', type: 'CUSTOM', algo: {combinator: 'and',rules: []}})
        return list
      })
    }
    if(type === 'exclude'){
      setExcludesQuery(arr => {
        const list = [...arr];
        list.unshift({name: '', status: 'ENABLED',type: 'CUSTOM', algo: {combinator: 'and', rules: []}})
        return list
      })
    }
    setEnableSave(true)
  }

  const validate = (groups, type) => {
    let statusCheck = false
    let errorData = {name: true, status: false, rules: true, duplicateName: true, minimumOneRule: true}
    if(groups.length === 0){
      errorData.status = true;
    }
    for(const group of groups){
      if(group.name === ''){
        errorData.name = false;
        break
      }
      const dupRuleName = groups.filter(i => i?.name?.toLowerCase() === group?.name?.toLowerCase())
      if(dupRuleName.length > 1){
        errorData.duplicateName = false
      }
      if(group.status === 'ENABLED'){
        statusCheck = true
      }
      const rules = group?.algo?.rules || []
      for(const rl of rules){
        if(rl.value === ''){
          errorData.rules = false
          break
        }
        if(rl.param && rl.param  === ''){
          errorData.rules = false
          break
        }
      }
    }
    errorData.status = statusCheck;
    return errorData;
  }

  return loading ? <CqLoading /> : (
    <Box> 
      <Grid container>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <StyledButtonContainer>
            <CqButton variant='borderBlue' onClick={getDefaultConfig} text='Reset to Defaults' />
            <CqButton variant='primary' disabled={!enableSave} onClick={updateChanges} text='Save Changes' />
          </StyledButtonContainer>
        <Grid/>
      </Grid>
      </Grid>
      <Accordion
        sx={{background: colors.gray900}}
        expanded={expanded['panel1']}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1"
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Typography variant='body2'>Classify as API Endpoint if</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Rules
            addRule={addRule('include')}
            deleteRule={deleteInc}
            updateRules={(val, ind, key) => updateIncludes(val, ind, key)}
            rulesList={includesQuery}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded['panel2']}
        onChange={handleChange('panel2')}
        sx={{background: colors.gray900}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel2"
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Typography variant='body2'>Do not classify as API Endpoint if</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Rules
            addRule={addRule('exclude')}
            deleteRule={deleteExl}
            updateRules={(val, ind, key) => updateExcludes(val, ind, key)}
            rulesList={excludesQuery}
          />
        </AccordionDetails>
      </Accordion>
      <CqDialog
        open={deleteRuleName !== ''}
        onClose={handleDeleteClose}
        title={`Delete Algorithm ${deleteRuleName}`}
        onSubmit={deleteAlgo}
      >
        Deleting this algorithm will impact your discovery findings. Are you sure you want to continue?
      </CqDialog>
    </Box>
  )
}

export default EndpointAlgorithm;