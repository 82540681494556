import React, { useState, useContext } from 'react';
import { Divider, Grid, MenuItem, Typography, Popover } from '@mui/material';
import clsx from 'clsx';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { colors } from '../../../assets/theme';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { serverView } from '../constants';
import TextWithIcon from '../../../components/common/TextWithIcon';
import { WrapperContext } from '../../../components/wrapper/wrapperContext';
import { issueDescription } from '../../../store/constants';
import { abbrFormat, groupFindings } from '../../../lib/utils';
import { getRoutes } from '../../../lib/constants';

const routes = getRoutes();
const useStyles = makeStyles((theme) => ({
  issuesContainer: {
    padding: '8px',
    marginBottom: '8px',
    borderRadius: 4,
    color: colors.white,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  issuesContainerDisabled: {
    pointerEvents: 'none',
  },

  centerAlign: {
    justifyContent: 'center',
    alignContent: 'center',
  },
  endAlign: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  issuesCount: {
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: 34,
    fontWeight: 600,
  },
  highLevelIssue: {
    backgroundColor: colors.red800,
    '&:hover': {
      outline: `1px solid ${colors.red50}`,
      filter: 'drop-shadow(0px 0px 8px rgba(230, 169, 171, 0.5))',
    },
  },
  trendHigh: {
    justifyContent: 'center',
    maxHeight: 35,
    backgroundColor: colors.redLabel,
    borderRadius: 4,
    padding: 4,
    alignItems: 'center',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.redBadgeHover,
    },
  },
  trendMedium: {
    justifyContent: 'center',
    maxHeight: 35,
    backgroundColor: colors.orangeLabel,
    borderRadius: 4,
    padding: 4,
    alignItems: 'center',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.mediumBadgeHover,
    },
  },
  mediumLevelIssue: {
    backgroundColor: colors.orange900,
    '&:hover': {
      outline: `1px solid ${colors.orange100}`,
      filter: 'drop-shadow(0px 0px 8px rgba(255, 211, 105, 0.5))',
    },
  },
  infoLevelIssue: {
    backgroundColor: colors.gray650,
    '&:hover': {
      outline: `1px solid ${colors.gray100}`,
      filter: 'drop-shadow(0px 0px 8px rgba(223, 223, 223, 0.5))',
    },
  },
  trendOverview: {
    backgroundColor: colors.darkGray1,
    borderRadius: 4,
    padding: 4,
    alignItems: 'center',
    color: colors.white,
    justifyContent: 'center',
    maxHeight: 35,
    '&:hover': {
      backgroundColor: colors.gray400,
    },
  },
  divider: {
    backgroundColor: colors.white,
    marginTop: 10,
    width: '100%',
  },
  issuesList: {
    marginTop: 10,
    marginLeft: 5,
  },
  issuesItemCount: {
    marginLeft: 5,
  },
  triggerPopoverPaper: {
    minWidth: 100,
    maxWidth: 200,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    backgroundColor: colors.almostBlack,
  },
  triggerPopoverRoot: {
    maxHeight: '50vh',
    marginTop: theme.spacing(1),
    pointerEvents: 'none',
  },

  menuItemHighSeverity: {
    height: '48px',
    padding: '6px',
    width: '100%',
    backgroundColor: colors.red800,
    '&:hover': {
      backgroundColor: 'rgb(113, 44, 46)',
      '& svg': {
        display: 'inline-block !important',
      },
    },
  },
  showArrow: {
    display: 'none !important',
  },
  menuItemMediumSeverity: {
    height: '48px',
    padding: '6px',
    width: '100%',
    backgroundColor: colors.orange900,
    '&:hover': {
      backgroundColor: 'rgb(90, 62, 27)',
      '& svg': {
        display: 'inline-block !important',
      },
    },
  },
  menuItemLowSeverity: {
    height: '48px',
    padding: '6px',
    width: '100%',
    backgroundColor: colors.gray700,
    '&:hover': {
      backgroundColor: colors.darkGray1,
      '& svg': {
        display: 'inline-block !important',
      },
    },
  },
  menuItemLabel: {
    fontSize: '14px',
  },
  issuesExpanded: {
    width: '100%',
  },
}));

const popOverContainer = makeStyles({
  paper: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
    width: '500px',
  },
});

const Issues = ({
  data,
  routeToServerEndpoints,
  isLog4jScan,
}) => {
  const classes = useStyles();
  const popOverStyles = popOverContainer();
  const wrapperContext = useContext(WrapperContext);
  const issueTypes = wrapperContext.issueTypes;
  const high = data?.high || {};
  const low = data?.low || {};
  const medium = data?.medium || {};
  const [showHighVulMenu, setShowHighVulMenu] = useState(null);
  const [showMedVulMenu, setShowMedVulMenu] = useState(null);
  const [showLowVulMenu, setShowLowVulMenu] = useState(null);
  const handleHignVulnOpen = (e) => {
    showHighVulMenu
      ? setShowHighVulMenu(null)
      : setShowHighVulMenu(e.currentTarget);
  };

  const handleMedVulMenuOpen = (e) => {
    showMedVulMenu
      ? setShowMedVulMenu(null)
      : setShowMedVulMenu(e.currentTarget);
  };

  const handleLowVulMenuOpen = (e) => {
    showLowVulMenu
      ? setShowLowVulMenu(null)
      : setShowLowVulMenu(e.currentTarget);
  };
  const loadHosts = (severity, params) => () => {
    routeToServerEndpoints(severity, params);
  };
  const highCount = abbrFormat(high.total || 0);
  const medCount = abbrFormat(medium.total || 0);
  const lowCount = abbrFormat(low.total || 0);
  const formatNumber = (val) =>
    val ? Intl.NumberFormat('en-US').format(val) : 0;

  const getIssueDescription = (iss) => {
    if (iss.name && issueTypes[iss.name] && issueTypes[iss.name].type) {
      return issueTypes[iss.name].type;
    }
    return ''; // if issue description is not available it will load Discovery page
  };

  const getIssueDisplayName = (iss) => {
    if (iss.name && issueTypes[iss.name] && issueTypes[iss.name].type) {
      return issueTypes[iss.name].description;
    }
    return ''; // if issue description is not available it will load Discovery page
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={4} onClick={handleHignVulnOpen}>
        <Grid className={clsx(classes.issuesContainer, classes.highLevelIssue)}>
          <Grid>
            <div className={classes.centerAlign}>
              <TextWithIcon>
                <ReportProblemIcon
                  auto-id={'hi_sev_icon'}
                  sx={{ fontSize: 14, height: '100%', color: colors.red50 }}
                />
                <Typography
                  auto-id={'hi_sev_iss'}
                  style={{ color: colors.red50 }}
                  variant={'subtitle2'}
                >
                  High
                </Typography>
              </TextWithIcon>
            </div>
            <div className={classes.issuesCount} auto-id={'hi_sev_count'}>
              {highCount}
            </div>
          </Grid>
          <Popover
            open={Boolean(showHighVulMenu)}
            anchorEl={showHighVulMenu}
            elevation={0}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleHignVulnOpen}
            classes={popOverStyles}
          >
            <Grid className={classes.issuesExpanded}>
              {high?.issues?.map((item, i) => {
                const description = getIssueDisplayName(item);
                const issueInfo = item || { name: item, value: 0 };
                return (
                  <MenuItem
                    key={i}
                    onClick={loadHosts(routes.discovery.path, {
                      selectedIssueType: item,
                      selectedIssue: 'High',
                      description: getIssueDescription(item),
                    })}
                  >
                    <Grid item container xs={11}>
                      <Typography
                        auto-id={`high_${description.replace(' ', '-')}`}
                        variant={'body1'}
                        className={classes.menuItemLabel}
                      >
                        {' '}
                        {`${description}: `}
                      </Typography>
                      <Typography
                        auto-id={`high_${description.replace(' ', '-')}_count`}
                        variant={'body2'}
                        className={clsx(
                          classes.issuesItemCount,
                          classes.menuItemLabel
                        )}
                      >
                        {formatNumber(issueInfo.value)}
                      </Typography>
                    </Grid>
                    <Grid item container xs={1}>
                      <KeyboardArrowRightIcon
                        auto-id={`high_${description.replace(' ', '-')}_arrow`}
                        color={colors.white}
                        className={classes.showArrow}
                      />
                    </Grid>
                  </MenuItem>
                );
              })}
            </Grid>
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={4} onClick={handleMedVulMenuOpen}>
        <Grid
          className={clsx(classes.issuesContainer, classes.mediumLevelIssue)}
        >
          <Grid>
            <div className={classes.centerAlign}>
              <TextWithIcon>
                <GppMaybeIcon
                  auto-id={'mid_sev_icon'}
                  sx={{ fontSize: 14, height: '100%', color: colors.orange100 }}
                />
                <Typography
                  auto-id={'mid_sev_iss'}
                  style={{ color: colors.orange100 }}
                  variant={'subtitle2'}
                >
                  Medium
                </Typography>
              </TextWithIcon>
            </div>
            <div className={classes.issuesCount} auto-id={'mid_sev_count'}>
              {medCount}
            </div>
          </Grid>
          <Popover
            open={Boolean(showMedVulMenu)}
            anchorEl={showMedVulMenu}
            elevation={0}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleMedVulMenuOpen}
            classes={popOverStyles}
          >
            <Grid className={classes.issuesExpanded}>
              {medium?.issues?.map((item, i) => {
                const description = getIssueDisplayName(item);
                const issueInfo = item || { name: item, value: 0 };
                return (
                  <MenuItem
                    key={i}
                    onClick={loadHosts(routes.discovery.path, {
                      selectedIssueType: item,
                      selectedIssue: 'Medium',
                      description: getIssueDescription(item),
                    })}
                  >
                    <Grid item container xs={11}>
                      <Typography
                        auto-id={`mid_${description.replace(' ', '-')}`}
                        variant={'body1'}
                        className={classes.menuItemLabel}
                      >
                        {' '}
                        {`${description}: `}
                      </Typography>
                      <Typography
                        auto-id={`mid_${description.replace(' ', '-')}_count`}
                        variant={'body2'}
                        className={clsx(
                          classes.issuesItemCount,
                          classes.menuItemLabel
                        )}
                      >
                        {formatNumber(issueInfo.value)}
                      </Typography>
                    </Grid>
                    <Grid item container xs={1}>
                      <KeyboardArrowRightIcon
                        auto-id={`mid_${description.replace(' ', '-')}_arrow`}
                        color={colors.white}
                        className={classes.showArrow}
                      />
                    </Grid>
                  </MenuItem>
                );
              })}
            </Grid>
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={4} onClick={handleLowVulMenuOpen}>
        <Grid className={clsx(classes.issuesContainer, classes.infoLevelIssue)}>
          <Grid>
            <div className={classes.centerAlign}>
              <TextWithIcon>
                <InfoIcon
                  auto-id={'low_sev_icon'}
                  sx={{ fontSize: 14, height: '100%', color: colors.gray100 }}
                />
                <Typography
                  auto-id={'low_sev_iss'}
                  style={{ color: colors.gray100 }}
                  variant={'subtitle2'}
                >
                  Info
                </Typography>
              </TextWithIcon>
            </div>
            <div className={classes.issuesCount} auto-id={'low_sev_count'}>
              {lowCount}
            </div>
          </Grid>
          <Popover
            open={Boolean(showLowVulMenu)}
            anchorEl={showLowVulMenu}
            elevation={0}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleLowVulMenuOpen}
            classes={popOverStyles}
          >
            <Grid className={classes.issuesExpanded}>
              {low?.issues?.map((item, i) => {
                const description = getIssueDisplayName(item);
                const issueInfo = item || { name: item, value: 0 };
                return (
                  <MenuItem
                    key={i}
                    onClick={loadHosts(routes.discovery.path, {
                      selectedIssueType: item,
                      selectedIssue: 'Info',
                      description: getIssueDescription(item),
                    })}
                  >
                    <Grid item container xs={11}>
                      <Typography
                        auto-id={`low_${description.replace(' ', '-')}`}
                        variant={'body1'}
                        className={classes.menuItemLabel}
                      >
                        {' '}
                        {`${description}: `}
                      </Typography>
                      <Typography
                        auto-id={`low_${description.replace(' ', '-')}_count`}
                        variant={'body2'}
                        className={clsx(
                          classes.issuesItemCount,
                          classes.menuItemLabel
                        )}
                      >
                        {formatNumber(issueInfo.value)}
                      </Typography>
                    </Grid>
                    <Grid item container xs={1}>
                      <KeyboardArrowRightIcon
                        auto-id={`low_${description.replace(' ', '-')}_arrow`}
                        color={colors.white}
                        className={classes.showArrow}
                      />
                    </Grid>
                  </MenuItem>
                );
              })}
            </Grid>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Issues;
