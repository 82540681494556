import React, { useState, useContext } from 'react';

import { TextField, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { setSubDomain } from './providers';
import CqDialog from '../../components/common/CqDialog';
import { colors } from '../../assets/theme';
import isValidDomain from 'is-valid-domain';
import { CrawlContext } from './crawlContext';

const useStyles = makeStyles({
  formContainer: {
    backgroundColor: colors.almostBlack,
    padding: '8px',
  },
  formRow: {
    padding: '8px 0',
  },
  textlabel: {
    paddingBottom: '4px',
    color: colors.gray300,
    fontSize: 12,
    lineHeight: '16px',
  },
  textbox: {
    '& .MuiInputBase-root': {
      height: '32px',
    },
  },
});

const AddSubDomainDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const [subdomainVal, setSubdomain] = useState('');
  const [loading, setLoading] = useState(false);
  const { dispatch, setNotify, domain } = useContext(CrawlContext);
  const handleClose = () => {
    onClose(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    setSubDomain(domain, [subdomainVal])
      .then((res) => {
        dispatch({ type: 'loadData', value: res });
        handleClose();
        setLoading(false);
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          success: true,
          message: `Added ${subdomainVal} to subdomains !`,
        }));
      })
      .catch(() =>
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          error: true,
          message: 'Something went wrong while adding subdomain!',
        }))
      );
  };
  const handleSubdomain = (e) => {
    setSubdomain(e.target.value);
  };
  const showSubmitBtn = isValidDomain(subdomainVal);
  return (
    <CqDialog
      title={'Add Sub Domain'}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitBtnDisabled={!showSubmitBtn}
      submitBtnText="Save"
      maxWidth="sm"
      backdrop={loading}
    >
      <div className={classes.formContainer}>
        <div className={classes.formRow}>
          <InputLabel className={classes.textlabel}>Sub-Domain name</InputLabel>
          <TextField
            fullWidth
            className={classes.textbox}
            variant="outlined"
            placeholder="Enter sub-domain(s) [For example, products.cequence.ai]"
            onChange={handleSubdomain}
            value={subdomainVal}
          />
        </div>
      </div>
    </CqDialog>
  );
};

export default AddSubDomainDialog;
