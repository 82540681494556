import React, { useState, useContext, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  Grid,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import { makeStyles, styled } from '@mui/styles';

import { CrawlContext } from './crawlContext';
import { findRule, getRulesArray } from './helpers';

import { colors, enhancedTableStyles } from '../../assets/theme';
import CqCheckbox from '../../components/common/CqCheckbox';
import CqChip from '../../components/common/CqChip';
import RulesSelect from './Select';
import TextWithIcon from '../../components/common/TextWithIcon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const useStyles = makeStyles((props, theme) => ({
  ruleLinks: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'end',
    '& a': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: colors.blue300,
      textDecoration: 'none',
    },
    '& svg': {
      fontSize: '14px',
    },
  },
  ruleContainer: {
    backgroundColor: colors.gray900,
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: colors.blue300,
      cursor: 'pointer',
    },
  },
}));

const StyledRow = styled(TableRow)(({ checked }) => ({
  '&:hover': {
    '& td': {
      backgroundColor: colors.gray650,
    },
  },
  '& td': {
    backgroundColor: checked ? colors.gray600 + ' !important' : colors.gray700,
  },
}));

const CatalogTable = ({
  subdomains,
  pageNum,
  handlePageChange,
  pageSize,
  handlePageSizeChange,
  sortByName,
  handleSort,
  domainName,
}) => {
  const classes = useStyles();
  const tableClasses = enhancedTableStyles();
  const [simpleRules, setSimpleRules] = useState([]);
  const { dispatch, setDialogOpen, rules } = useContext(CrawlContext);
  const [ruleSearchVal, setRuleSearchVal] = useState('');
  const rulesArray = getRulesArray(rules);

  useEffect(() => {
    const simpleRulesList = [];
    for (const r of rulesArray) {
      if (r.type === 'SIMPLE') {
        simpleRulesList.push(r);
      }
    }
    setSimpleRules(simpleRulesList);
  }, []);

  const setDomainsChecked = (e) => {
    const updatedList = subdomains.map((val) => {
      if (val.subdomain === e.target.name) {
        val.checked = e.target.checked;
      }
      return val;
    });
    dispatch({ type: 'updateSubdomains', value: updatedList });
  };

  const checkedSubdomains = [];
  for (let val of subdomains) {
    if (val.checked) {
      checkedSubdomains.push(val.subdomain);
    }
  }
  const checkedSubdomainsLen = checkedSubdomains.length;

  const getPageData = () => {
    const result = subdomains.slice(
      pageNum * pageSize,
      Math.min(subdomains.length, (pageNum + 1) * pageSize)
    );
    return result;
  };

  const createRule = () => {
    dispatch({
      type: 'setRule',
      value: { type: 'SIMPLE', simple: checkedSubdomains },
    });
    setDialogOpen((state) => ({ ...state, ruleDialog: true }));
  };
  const editRule = (e) => {
    const id = e.target.getAttribute('value');
    if (id) {
      const rule = findRule(rules, id);
      const updatedList = [...rule.simple, checkedSubdomains];
      dispatch({ type: 'setRule', value: { ...rule, simple: updatedList } });
      setDialogOpen((state) => ({ ...state, ruleDialog: true }));
    }
  };

  const getSimpleRulesList = () => {
    const filteredList = simpleRules.filter((rule) => {
      const rulename = rule.name || '';
      return rulename.toLowerCase().includes(ruleSearchVal);
    });
    const simpleRulesList = ruleSearchVal !== '' ? filteredList : simpleRules;
    return simpleRulesList;
  };

  const handleSelectAll = () => {
    const selectedSubdomains = getPageData().map((val) => ({
      ...val,
      checked: val.checked ? false : true,
    }));
    let updatedList = [];
    const lowerLimit = pageNum * pageSize;
    const upperLimit = Math.min(subdomains.length, (pageNum + 1) * pageSize);
    if (pageNum === 0) {
      updatedList = [
        ...selectedSubdomains,
        ...subdomains.slice(upperLimit, subdomains.length),
      ];
    } else {
      updatedList = [
        ...subdomains.slice(0, lowerLimit),
        ...selectedSubdomains,
        ...subdomains.slice(upperLimit, subdomains.length),
      ];
    }
    dispatch({ type: 'updateSubdomains', value: updatedList });
  };

  return (
    <TableContainer className={tableClasses.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">
                <div>
                  {checkedSubdomainsLen > 0 && (
                    <Typography variant="subtitle1">
                      {checkedSubdomainsLen} subdomains selected
                    </Typography>
                  )}
                </div>
              </Typography>
            </TableCell>
            <TableCell colSpan={4}>
              <div className={classes.ruleLinks}>
                {checkedSubdomainsLen > 0 && (
                  <>
                    {simpleRules.length > 0 && (
                      <RulesSelect
                        options={getSimpleRulesList()}
                        onSelect={editRule}
                        searchRuleVal={ruleSearchVal}
                        onSearchRule={setRuleSearchVal}
                      />
                    )}
                    <Link onClick={createRule}>
                      <CheckIcon />
                      <span>Create Rule</span>
                    </Link>
                  </>
                )}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2" className={classes.tableHeader}>
                <CqCheckbox
                  label="Subdomain"
                  onChange={handleSelectAll}
                  indeterminate={checkedSubdomainsLen > 0}
                />
                <Link onClick={handleSort}>
                  {sortByName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </Link>
              </Typography>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Rule</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {getPageData().map((sdomain, i) => {
            const statusColor =
              sdomain.rule === null
                ? colors.green200
                : sdomain?.active
                ? colors.green200
                : colors.red300;
            return (
              <StyledRow
                key={sdomain.subdomain + i}
                className={classes.tableRow}
                checked={sdomain.checked}
              >
                <TableCell>
                  <Typography variant="body2">
                    <CqCheckbox
                      label={`${sdomain.subdomain}.${domainName}`}
                      name={sdomain.subdomain}
                      checked={sdomain.checked}
                      onChange={setDomainsChecked}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <CqChip color={statusColor} borderRadius={16}>
                    <TextWithIcon>
                      {sdomain.rule === null ? (
                        <CheckIcon
                          sx={{
                            color: statusColor,
                            fontSize: 15,
                            width: '20px',
                          }}
                        />
                      ) : sdomain.active ? (
                        <CheckIcon
                          sx={{
                            color: statusColor,
                            fontSize: 15,
                            width: '20px',
                          }}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: statusColor,
                            fontSize: 15,
                            width: '20px',
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        style={{ color: statusColor }}
                      >
                        {sdomain?.rule === null
                          ? 'Enabled'
                          : sdomain.active
                          ? 'Enabled'
                          : 'Disabled'}
                      </Typography>
                    </TextWithIcon>
                  </CqChip>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {sdomain?.rule?.name || ''}
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell />
              </StyledRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={subdomains.length}
        page={pageNum}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </TableContainer>
  );
};

export default CatalogTable;
