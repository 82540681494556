import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { colors } from '../../assets/theme';
import CheckIcon from '@mui/icons-material/Check';
import CqButton from '../../components/common/CqButton';
import { useGlobalState } from '../../store/globalState';
import { graphqlMutation, graphqlQuery } from '../../services/graphql';
import { getLicensesInfoQuery, updateLicenseInfo } from './licensesGraphQL';
import CqLoading from '../../components/common/CqLoading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatDate } from '../../lib/utils';
import ModalWrapper from '../../components/common/modalWrapper/ModalWrapper';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    padding: '0px',
  },
  card: {
    borderRadius: '4px',
    display: 'inline-block',
    height: 500,
    margin: '1rem',
    position: 'relative',
    maxWidth: '30%',
  },
  cardOne: {
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    '&:hover': {
      backgroundColor: colors.darkLabelText,
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  },
  spyderCard: {
    border: `4px solid ${colors.orange200}`,
    borderRadius: '10px',
  },
  itemHeader: {
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: 40,
  },
  contentMargin: {
    marginTop: theme.spacing(2),
    padding: '0 40px 0 40px',
  },
}));

const LicensesPage = () => {
  const classes = useStyles();
  const global = useGlobalState();
  const { userInfo } = global.get();
  const [licensesLoading, setLicensesLoading] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [calendarLink, setCalendarLink] = useState({});
  const [licenseInfo, setLicensesInfo] = useState({});

  useEffect(() => {
    retrievelicenseInfo();
  }, []);

  const retrievelicenseInfo = async () => {
    setLicensesLoading(true);
    try {
      let response = await graphqlQuery(getLicensesInfoQuery, {}, global);
      const data = response.data ? response.data.license : {};
      setLicensesInfo(data);
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setLicensesLoading(false);
    }
  };

  const handleUpgrade = async () => {
    setLoadingCalendar(true);
    const formData = {
      form: {
        FirstName: userInfo?.firstName,
        LastName: userInfo?.lastName,
        Company: userInfo?.tenantName,
        Email: userInfo?.email,
      },
      options: {
        router: 'inbound_router-product',
        map: false,
      },
    };
    try {
      const response = await fetch(
        'https://api.chilipiper.com/marketing/cequence',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      setCalendarLink(result);
      setShowModal(true);
    } catch (error) {
      console.log(`chilipiper query error`, error);
    } finally {
      setLoadingCalendar(false);
      window.addEventListener(
        'message',
        (event) => {
          if (event.data['action'] === 'booking-confirmed') {
            renewLicense(event);
          }
        },
        true
      );
    }
  };

  const removeCalendar = () => {
    setCalendarLink({});
    setShowModal(false);
  };

  const renewLicense = async (data) => {
    const params = {
      upgradeRequestSlot: data.data.args.slot,
      upgradeRequestInfo: JSON.stringify(data.data),
    };
    try {
      let response = await graphqlMutation(updateLicenseInfo, params);
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  const requestDemo = () => {
    window.open('http://cequence.ai/demo', '_blank');
  };

  const expirationDate = formatDate(licenseInfo.expirationTs);

  return (
    <div className={classes.pageContent}>
      <Grid item container className={classes.pageHeader}>
        <Typography variant="h4">Licenses</Typography>
      </Grid>
      {licensesLoading && <CqLoading />}
      {!licensesLoading && licenseInfo && (
        <Grid container>
          <Grid
            item
            container
            className={clsx(classes.card, classes.cardOne, classes.spyderCard)}
          >
            {licenseInfo.name === 'FREE_TRIAL' ? (
              <Grid container style={{ marginTop: '-1px' }}>
                <Grid item container xs={3}></Grid>
                <Grid
                  item
                  container
                  xs={9}
                  style={{
                    justifyContent: 'flex-end',
                    textAlign: 'end',
                    backgroundColor: colors.orange200,
                  }}
                >
                  {licenseInfo.daysLeft > 1 ? (
                    <Typography
                      style={{
                        marginLeft: 20,
                        color: colors.almostBlack,
                      }}
                    >{`${licenseInfo.daysLeft} days remaining in free trial`}</Typography>
                  ) : (
                    <Typography
                      style={{
                        marginLeft: 20,
                        color: colors.almostBlack,
                      }}
                    >{`${licenseInfo.daysLeft} day remaining in free trial`}</Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid>
                  <Typography
                    style={{
                      padding: '4px 8px',
                      backgroundColor: colors.orange200,
                      width: 'fit-content',
                      color: colors.gray900,
                      borderRadius: '0px 0px 0px 4px',
                    }}
                  >
                    Current Plan
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              className={classes.itemHeader}
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  fontFamily: '"Noto Sans"',
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                API Spyder
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Typography style={{ textAlign: 'center' }} variant="body1">
                The only agentless API attack surface discovery solution.
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Find all exposed APIs - known and unknown
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    No agents. No instrumentation. No redirect.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Fast mitigation with risk-based prioritization
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {licenseInfo.name === 'FREE_TRIAL' ? (
              <Grid
                container
                style={{ justifyContent: 'center', marginTop: 65 }}
              >
                <CqButton
                  text={'Upgrade'}
                  height={35}
                  width={240}
                  variant="primary"
                  onClick={() => {
                    handleUpgrade();
                  }}
                ></CqButton>
              </Grid>
            ) : (
              <Grid
                container
                justifyContent={'center'}
                style={{
                  margin: '40px auto 0 auto',
                  padding: 10,
                  width: 300,
                  backgroundColor: colors.darkBlue1000,
                }}
              >
                <Grid item>
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: 16,
                      color: colors.blueButtonBg,
                      height: '1.2em',
                    }}
                  />
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                  <Typography
                    variant={'subtitle2'}
                    style={{ wordWrap: 'wrap' }}
                  >
                    {`Subscription valid until ${expirationDate}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item container className={clsx(classes.card, classes.cardOne)}>
            <Grid container className={classes.itemHeader}>
              <Typography
                style={{
                  fontFamily: '"Noto Sans"',
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                API Sentinel
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Typography style={{ textAlign: 'center' }} variant="body1">
                The only comprehensive API risk and threat detection solution.
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Address OWASP API Top 10
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Catalog and track managed and unmanaged APIs
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Uncover and remediate API vulnerabilities
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: 'center', marginTop: 80 }}>
              <CqButton
                text="Request a demo"
                height={35}
                width={240}
                variant="borderBlue"
                onClick={() => requestDemo()}
              ></CqButton>
            </Grid>
          </Grid>
          <Grid item container className={clsx(classes.card, classes.cardOne)}>
            <Grid container className={classes.itemHeader}>
              <Typography
                style={{
                  fontFamily: '"Noto Sans"',
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                API Spartan
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Typography style={{ textAlign: 'center' }} variant="body1">
                The only API solution to natively block automated threats.
              </Typography>
            </Grid>
            <Grid container className={classes.contentMargin}>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Detect account takeover, credential stuffing and automated
                    business logic abuse.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Eliminate the requirement for any JavaScript or Mobile SDK
                    integration
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={1}>
                  <CheckIcon sx={{ fontSize: 24, color: colors.green200 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" style={{ marginLeft: 10 }}>
                    Native protection by blocking, rate-limiting or deceiving
                    attackers
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: 'center', marginTop: 40 }}>
              <CqButton
                text="Request a demo"
                height={35}
                width={240}
                variant="borderBlue"
                onClick={() => requestDemo()}
              ></CqButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showModal && (
        <ModalWrapper
          header={'Request an upgrade'}
          maxWidth={'sm'}
          noCancel={true}
          close={removeCalendar}
        >
          <Grid>
            <iframe
              id={'demoRequest'}
              src={calendarLink.url}
              frameBorder="0"
              allowtransparency="true"
              width="100%"
              height={'700px'}
              style={{ marginTop: -60 }}
            ></iframe>
          </Grid>
        </ModalWrapper>
      )}
    </div>
  );
};

export default LicensesPage;
