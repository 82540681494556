import React, {useEffect, useState} from "react";
import { Chip, Grid, Typography, Menu, MenuItem, Checkbox, FormControlLabel, Box, styled} from '@mui/material'
import { muiLicenseKey, dataGridStyles } from '@cequence/ui'
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import CqSearch from "../../../components/common/CqSearch";
import CqButton from "../../../components/common/CqButton";
import {SpSwitch} from "../../DiscoveryConfig/styles";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "../../../components/Tooltip";
import FindingsSeverityDialog from "./FindingsSeverityDialog";
import FindingsDialog from "./FindingsDialog";
import TlsFindingsDialog from "./TlsFindingsDialog";
import { cloneDeep } from 'lodash';
import CustomFindingDialog from "./CustomFindingDialog";
import { SeverityBadge } from "../../../components/SeverityBadge";
import { colors } from "../../../assets/theme";
import { IssueDescription } from "../../../lib/constants";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CqDialog from "../../../components/common/CqDialog";
import CheckIcon from '@mui/icons-material/Check';
import { StyledBadge } from "../../../components/StyledBadge";
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextWithIcon from "../../../components/common/TextWithIcon";
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';

import { convertJoinToComparator, removeIdFieldsAndConvertCombinator } from '../../../lib/queryBuilderUtils'

function CustomToolbar(props) {
  return (
    <Grid container sx={{ height: '36px', border: '1px solid #333', paddingBottom: '8px', boxSizing: 'unset' }}>
      <Grid item sx={{display: 'flex', gap: '8px'}}>
        <CqSearch value={props.inputText} placeholder='Search' onChange={props.updateSearchText} />
        <FormControlLabel control={<Checkbox checked={props.includeArchived} onChange={() => props.setIncludeArchived(!props.includeArchived)} />} label="Show Archived Findings" />
      </Grid>
      <Grid item sx={{marginLeft: 'auto', padding: '4px'}}>
        <CqButton
          variant="borderBlue"
          onClick={props.handleFindingDialog}
        >
          <AddCircleOutlineIcon />
          <span>New Finding</span>
        </CqButton>
      </Grid>
    </Grid>
  );
}

const  FindingOp = ({ editFindingsAlgorithm, deleteFinding, findingType, status }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    editFindingsAlgorithm()
    handleClose()
  }
  const handleArchive = () => {
    deleteFinding()
    handleClose()
  }
  const StyledMenuItem = styled(MenuItem)({
    width: '100px',
    paper: {},
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      width: '100%',
    },
  })
  return status !== 'Archived' && (
    <>
      <CqButton onClick={handleClick}>
        <MoreVertIcon />
      </CqButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem>
          <a onClick={handleEdit}>
            <TextWithIcon>
              <EditIcon sx={{fill: colors.gray400 }} />
              <Typography variant="body2">Edit</Typography>
            </TextWithIcon>
          </a>
        </StyledMenuItem>
        {findingType === 'Custom' && <StyledMenuItem>
          <a onClick={handleArchive}>
            <TextWithIcon>
              <ArchiveOutlinedIcon sx={{fill: colors.gray400 }} />
              <Typography variant="body2">Archive</Typography>
            </TextWithIcon>
          </a>
        </StyledMenuItem>}
      </Menu>
    </>
  )
}

const FindingStatus = ({status}) => {
  let val = ''
  if(status === 'Enabled'){
    val = <CheckIcon sx={{color: '#66B391'}}/>
  }
  if(status === 'Disabled'){
    val = <DoNotDisturbAltOutlinedIcon sx={{color: '#DA7F82'}}/>
  }
  if(status === 'Archived'){
    val = <ArchiveOutlinedIcon sx={{color: '#F5AC50'}}/>
  }
  return (
    <Box sx={{display: 'flex', gap: '4px'}}>{val}<Typography variant="body-2">{status}</Typography></Box>
  )
}

const FindingSeverity = ({status}) => {
  const StyledIcon = styled('span')({
    display: 'block',
    width: '8px',
    height: '8px',
    borderRadius: '50px',
    background: status === 'HIGH' ? colors.red100 : status === 'MEDIUM' ? colors.orangeMediumLevel : colors.gray100,
  })
  const textColor = status === 'HIGH' ? '#F3D4D5' : status === 'MEDIUM' ? '#f9cf9b' : '#ababab'
  return (
    <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
      <StyledIcon />
      <Typography variant="body-2" sx={{'&::first-letter': {textTransform: 'uppercase'}, color: textColor}}>{status.toLowerCase()}</Typography>
    </Box>
  )
}

const columns = [
  {
    headerName: 'Name',
    field: 'finding',
    sortingOrder: ['desc', 'asc'],
    width: 400
  },
  {
    headerName: 'Enabled',
    field: 'enabled',
    renderCell: (params) => <FindingStatus status={params.value}/>,
    flex: 1
  },
  {
    headerName: 'Finding Type',
    field: 'findingType',
    renderCell: (params) => <Chip sx={params.value === 'Custom' ? {background: colors.darkBlue400, color: colors.darkBlue300}: {}} label={params.row.findingType} />,
    flex: 1
  },
  {
    headerName: 'Severity',
    field: 'severity',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      if(params.row.finding === 'Insecure SSL/TLS Implementations'){
        return (<>
          <Tooltip title="High"><StyledBadge type='error'>{params.value.high}</StyledBadge></Tooltip>
          <Tooltip title="Medium"><StyledBadge type='warning'>{params.value.medium}</StyledBadge></Tooltip>
          <Tooltip title="Info"><StyledBadge type='info'>{params.value.info}</StyledBadge></Tooltip></>)
      } else {
        return (<FindingSeverity status={params.value} />)
      }
    },
    flex: 1
  },
  {
    headerName: '',
    field: 'deleteFinding',
    renderCell: (param) => <FindingOp status={param.row.enabled} deleteFinding={param?.row?.deleteFinding} findingType={param.row.findingType} editFindingsAlgorithm={param?.row?.editFindingsAlgorithm} />
  },
]

const FindingsTable = ({
  type,
  data,
  handleSubmit,
  openFindingDialog,
  setOpenFindingDialog,
  openTLSFindingDialog,
  setOpenTLSFindingDialog,
  defaultTenantConfiguration,
  openCustomFindingDialog,
  addFinding,
  setOpenCustomFindingDialog,
  deleteUpdateFindings,
  deleteFinding,
  setDeleteFinding
}) => {

    LicenseInfo.setLicenseKey(muiLicenseKey);
    const findingAlgoConfiguration = data || [];
    const [tlsData, setTlsData] = useState();
    const [selectedFinding, setSelectedFinding] = useState({});
    const [tempFindingsData, setTempFindingsData] = useState([]);
    const [queryData, setQueryData] = useState({});
    const [counter, setCounter] =useState(0);
    const [findings, setFindings] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [includeArchived, setIncludeArchived] = useState(false)
    useEffect(() => {
      const tlsList = data.filter(i => i.type === 'TLS')
      setTlsData(tlsList)
      const list = []
      const tlsSeverity = {high: 0, medium:0, info: 0}
      for(const find of tlsList){
        if(find.severity ==='HIGH'){
          tlsSeverity.high = tlsSeverity.high + 1
        } else if(find.severity ==='MEDIUM'){
          tlsSeverity.medium = tlsSeverity.medium + 1
        } else if(find.severity ==='INFO'){
          tlsSeverity.info = tlsSeverity.info + 1
        }
      }

      if(type === 'HOST'){
        list.push({
          id: 0,
          finding: 'Insecure SSL/TLS Implementations',
          editFindingsAlgorithm: () => editFindingsAlgorithm({'type': 'TLS'}),
          catagory: 'HOST',
          enabled: 'Enabled',
          severity: tlsSeverity,
          details: IssueDescription['TLS'].description,
          findingType: 'System'
        })
      }
      let i = 1
      for(const item of data){
        const archivedCondition = includeArchived ?  true : item.status !== 'DEACTIVE'
        if(archivedCondition){
          list.push({
            id: i,
            finding: item.displayName,
            severity: item.severity || '',
            enabled: item.status === 'DEACTIVE' ? 'Archived' : item.enabled ? 'Enabled' : 'Disabled',
            editFindingsAlgorithm: () => editFindingsAlgorithm(item),
            catagory: item.type,
            findingType: item.custom ? 'Custom' : 'System',
            deleteFinding: () => removeFinding(item)
          })
          i++
        }
      }
      setFindings(list)
    }, [data, includeArchived])

    const editFindingsAlgorithm = (finding) => {
        setSelectedFinding(finding);
        setTempFindingsData(finding);
        if (finding?.type === 'TLS') {
            setOpenFindingDialog(false);
            setOpenTLSFindingDialog(true);
        } else {
            let selectedRule = {};
            for (const item of findingAlgoConfiguration) {
                if (item.name === finding.name) {
                    selectedRule =  item;
                    if(selectedRule.algo){
                      setQueryData(convertJoinToComparator(selectedRule?.algo))
                    }
                }
            }
            setOpenTLSFindingDialog(false);
            setOpenCustomFindingDialog(true);
        }

    };

    const handleSaveChanges = (data, type) => {
        if (type === 'RULE') {
            const queryData = removeIdFieldsAndConvertCombinator(data);
            const postData = {...tempFindingsData, algo: queryData};
            handleSubmit(postData, 'RULE');
        }
        if (type === 'TLS') {
            const postData = tlsData;
            handleSubmit(postData, 'TLS');
        }
        setCounter(0);
    };

    const handleRestoreDefaults = (type) => {
        if (type === 'RULE') {
            const data = {...tempFindingsData, restoreToDefaults: true};
            setTempFindingsData(data);
            handleSubmit(data, type);
        }
        if (type === 'TLS') {
            const tlsConfigData = findingAlgoConfiguration.filter(item => item?.type === 'TLS');
            const data = tlsConfigData.map(item => ({...item, restoreToDefaults: true}))
            handleSubmit(data, type);
        }
    };

    const handleFindingsAlgoChange = (event, val, field, item) => {
        let data;

        data = {...tempFindingsData, restoreToDefaults: false};

        if (field === 'severity') {
            data = {...tempFindingsData, severity: val};
            setTempFindingsData(data);
        }

        if (field === 'enabled') {
            data = {...tempFindingsData, enabled: val};
            setTempFindingsData(data);
        }

        if (field === 'tlsEnabled') {
            const tlsDataCopy = cloneDeep(tlsData);
            data = tlsDataCopy.map((i) => {
                if (i.name === item.name) {
                    i.enabled = val;
                }
                return i;
            })
            setTlsData(data);
        }

        if (field === 'tlsSeverity') {
            const tlsDataCopy = cloneDeep(tlsData);
            data = tlsDataCopy.map((i) => {
                if (i.name === item.name) {
                    i.severity = val;
                }
                return i;
            })
            setTlsData(data);
        }
    };

    const resetTlsData = () => {
        setTlsData(data.filter(item => item?.type === 'TLS'));
    };

    const showDefaults = (type) => {
        if (type === 'RULE') {
            const defaults = defaultTenantConfiguration?.findingsConfig?.filter((item) => item.name === selectedFinding.name);
            defaults.description = '';
            setSelectedFinding(defaults[0]);
            setTempFindingsData(defaults[0]);
            setQueryData(convertJoinToComparator(defaults[0]?.algo))
            setCounter(counter + 1);
        }
        if (type === 'TLS') {
            setTlsData(defaultTenantConfiguration?.findingsConfig?.filter(item => item?.type === 'TLS'));
        }
    };

    const createNewFiniding = (data, update) => {
      const queryData = removeIdFieldsAndConvertCombinator(data.algo);
      const postData = {...data, algo: queryData}
      if(update){
        return handleSubmit(postData, 'RULE')
      } else {
        return addFinding(postData)
      }
    }

    let fillteredFindings = findings.filter(i => i.catagory === type)
    if(searchText !== ''){
      fillteredFindings = fillteredFindings.filter(item => {
        let severity = ''
        if(item.finding === 'Insecure SSL/TLS Implementations'){
          severity = item?.severity?.toLowerCase()
        }
        const finding = item?.finding?.toLowerCase()
        return severity.includes(searchText) || finding.includes(searchText)
      })
    }
    const tableInfo = {
      rows: fillteredFindings,
      columns: columns
    }
    const handleFindingDialog = () => {
      setOpenCustomFindingDialog(val => !val)
    }

    const removeFinding = (item) => {
      setDeleteFinding(item)
    } 
    return (
        <div style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
          <DataGridPremium
            rows={fillteredFindings}
            columns={columns}
            initialState={tableInfo}
            headerHeight={36}
            hideFooter
            sx={dataGridStyles}
            disableAggregation
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ toolbar: {
              inputText: searchText,
              updateSearchText: setSearchText,
              tabType: type,
              handleFindingDialog: handleFindingDialog,
              includeArchived: includeArchived,
              setIncludeArchived: setIncludeArchived
            } }}
            disableRowSelectionOnClick
            disableRowGrouping
          />
          <FindingsDialog
            title={selectedFinding.type}
            open={openTLSFindingDialog}
            maxWidth="lg"
            submitBtnText="Save Changes"
            showCloseBtn={true}
            alertType={false}
            showExtraBtn={true}
            disableBackdropClick={true}
            blocking={true}
            CloseIcon={true}
            showToggleButton={true}
            toggleValue={tempFindingsData?.enabled}
            onSubmit={() => handleSaveChanges(tlsData, 'TLS')}
            onClose={() => {setOpenTLSFindingDialog(false); resetTlsData()}}
            showRestoreToDefaults={true}
            onExtraBtnClick={() => showDefaults('TLS')}
            onRestoreDefaults={() => handleRestoreDefaults('TLS')}
          >
              <TlsFindingsDialog tlsData={tlsData} handleFindingsAlgoChange={handleFindingsAlgoChange} />
          </FindingsDialog>
          <CustomFindingDialog
            openCustomFindingDialog={openCustomFindingDialog}
            type={type}
            onSubmit={createNewFiniding}
            setOpenCustomFindingDialog={setOpenCustomFindingDialog}
            tempFindingsData={tempFindingsData}
            setTempFindingsData={setTempFindingsData}
            onExtraBtnClick={() => showDefaults('RULE')}
            findingAlgoConfiguration={findingAlgoConfiguration}
          />
          <CqDialog
            title={`Archive ${deleteFinding.name}`}
            submitBtnText='Archive Finding'
            onSubmit={() => deleteUpdateFindings(deleteFinding)}
            open={deleteFinding.name !== undefined}
            onClose={() => setDeleteFinding({})}
            maxWidth='sm'
          >
            <Typography variant="body2">Are you sure you would like to archive this finding..</Typography>
          </CqDialog>
        </div>
    );
};

export default FindingsTable;
