export const getLicensesInfoQuery = `
query GetLicenseInfo {
    license {
        name
        description
        daysLeft
        hasExpired
        expirationTs
        startTs
        upgradeRequested
        upgradeRequestedBy
        upgradeRequestedTs
    }
}
`;

export const updateLicenseInfo = `
mutation RequestUpgradeLicense($upgradeRequestSlot: RequestUpgradeSlot!, $upgradeRequestInfo: JSON) {
  requestUpgrade(slot: $upgradeRequestSlot, info:$upgradeRequestInfo) {
    success
    message
  }
}
`;
