import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import { colors } from '../../assets/theme';
import { List, ListItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import InfoIcon from '@mui/icons-material/Info';
import CqChip from '../../components/common/CqChip';
import { WrapperContext } from '../../components/wrapper/wrapperContext';

const useStyles = makeStyles((theme) => ({
  severityContainer: {
    backgroundColor: colors.gray700,
    width: 400,
    borderRadius: 4,
    marginTop: 30,
  },
  popMenuList: {
    padding: '4px 0',
  },
  popMenuListItem: {
    cursor: 'pointer',
    height: 35,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&.Mui-selected': {
      backgroundColor: colors.gray600,
      borderRadius: 4,
      border: '1px solid #7F7F7F',
      '&:hover': {
        backgroundColor: colors.gray600,
        borderRadius: 4,
        border: '1px solid #7F7F7F',
      },
    },
    '&:hover': {
      backgroundColor: colors.gray600,
      borderRadius: 4,
      border: '1px solid #7F7F7F',
    },
    '&.Mui-selected > svg': {
      visibility: 'visible',
    },
    borderBottom: '1px solid #414141',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  popMenuListItemText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: colors.gray50,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  vulnerabilityMessage: {
    backgroundColor: colors.darkBlue800,
    borderLeft: `4px solid ${colors.darkBlue200}`,
    borderRadius: 4,
  },
  chipContainer: {
    padding: '0 2px',
  },
}));

const ServerDetailsVulnerabilitiesNav = ({
  selectedServerData,
  setSelectedVulnerability,
  selectedVulnerability,
}) => {
  const classes = useStyles();

  const severityComparator = (a, b) => {
    const order = {
      HIGH: 1,
      MEDIUM: 2,
      INFO: 3,
    };
    return order[a.severity] - order[b.severity];
  };

  const wrapperContext = useContext(WrapperContext);
  const issueTypes = wrapperContext.issueTypes;
  const sortedTLSIssues = selectedServerData?.findings?.issues
    ?.filter((i) => i.type === 'TLS')
    ?.sort(severityComparator);
  const tlsIssue = {
    name: 'TLS',
    severity: sortedTLSIssues?.[0]?.severity,
    value: 1,
    type: 'TLS',
  };

  const nonTlsIssues = selectedServerData?.findings?.issues
    ?.filter((i) => i.type !== 'TLS')
    ?.sort(severityComparator);
  // console.log('nonTlsIssues', nonTlsIssues);

  return (
    <Grid className={classes.severityContainer}>
      <Typography
        variant="subtitle2"
        style={{ color: colors.gray50, padding: 8 }}
      >
        Findings on the server:
      </Typography>

      <List className={classes.popMenuList}>
        <>
          {sortedTLSIssues?.length > 0 && (
            <ListItem
              className={classes.popMenuListItem}
              selected={selectedVulnerability.name === 'TLS'}
              onClick={() => setSelectedVulnerability(tlsIssue)}
              key={`tls`}
            >
              <CqChip
                className={classes.chipContainer}
                color={colors.red200}
                borderRadius={2}
              >
                <WarningIcon
                  sx={{
                    color: colors.red200,
                    fontSize: 20,
                    width: '20px',
                  }}
                />
                <Typography
                  style={{ color: colors.red200, marginTop: -2 }}
                ></Typography>
              </CqChip>

              <Grid item container xs={6}>
                <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
                  {'Insecure SSL/TLS Implementation'}
                </Typography>
              </Grid>
              <KeyboardDoubleArrowRightIcon
                sx={{
                  color: colors.gray200,
                  fontSize: 30,
                  position: 'absolute',
                  right: 8,
                  visibility: 'hidden',
                }}
              />
            </ListItem>
          )}
          {nonTlsIssues?.length > 0 && (
            <>
              {nonTlsIssues?.map((v, index) => {
                v.custom = issueTypes?.[v.name]?.custom
                v.longDes = issueTypes?.[v.name]?.longDescription
                return (
                  <ListItem
                    key={`high-${index}`}
                    className={classes.popMenuListItem}
                    selected={selectedVulnerability.name === v.name}
                    onClick={() => setSelectedVulnerability(v)}
                  >
                    {v.severity === 'HIGH' && (
                      <CqChip
                        className={classes.chipContainer}
                        color={colors.red200}
                        borderRadius={2}
                      >
                        <WarningIcon
                          sx={{
                            color: colors.red200,
                            fontSize: 20,
                            width: '20px',
                          }}
                        />
                        <Typography
                          style={{ color: colors.red200, marginTop: -2 }}
                        >
                          {v.value}
                        </Typography>
                      </CqChip>
                    )}

                    {v.severity === 'MEDIUM' && (
                      <CqChip
                        className={classes.chipContainer}
                        color={colors.yellow}
                        borderRadius={2}
                      >
                        <GppMaybeIcon
                          sx={{
                            color: colors.yellow,
                            fontSize: 20,
                            width: '20px',
                          }}
                        />
                        <Typography
                          style={{ color: colors.yellow, marginTop: -2 }}
                        >
                          {v.value}
                        </Typography>
                      </CqChip>
                    )}

                    {v.severity === 'INFO' && (
                      <CqChip
                        className={classes.chipContainer}
                        color={colors.gray100}
                        borderRadius={2}
                      >
                        <InfoIcon
                          sx={{
                            color: colors.gray100,
                            fontSize: 20,
                            width: '20px',
                          }}
                        />
                        <Typography
                          style={{
                            color: colors.gray100,
                            marginTop: -2,
                          }}
                        >
                          {v.value}
                        </Typography>
                      </CqChip>
                    )}

                    <Grid item container xs={6}>
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: 10 }}
                      >
                        {issueTypes?.[v?.name]?.description}
                      </Typography>
                    </Grid>
                    <KeyboardDoubleArrowRightIcon
                      sx={{
                        color: colors.gray200,
                        fontSize: 30,
                        position: 'absolute',
                        right: 8,
                        visibility: 'hidden',
                      }}
                    />
                  </ListItem>
                );
              })}
            </>
          )}
        </>
      </List>
    </Grid>
  );
};

export default ServerDetailsVulnerabilitiesNav;
