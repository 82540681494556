import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { colors } from '../../assets/theme';
import GraphiQL from 'graphiql';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import fetch from 'isomorphic-fetch';
import ApiOutlined from '@mui/icons-material/ApiOutlined';
import 'graphiql/graphiql.min.css';
import '@graphiql/plugin-explorer/dist/style.css';
import { useTheme } from '@graphiql/react';
import { getSessionJwt } from '../../services/auth';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: `16px !important`,
  },
  dialogInfo: {
    color: colors.white,
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '20px',
  },
  styledTabs: {
    borderBottom: `1px solid ${colors.gray700}`,
  },
  panelBox: {
    paddingTop: '17px',
  },
}));

const ApiDocsPage = () => {
  const classes = useStyles();
  const { theme, setTheme } = useTheme();

  const API_URL =
    window.__APP_RUNTIME_CONFIG__.SPYDER_API_URL ||
    'https://dev.api.apispyder.cequence.ai/v1';

  const activeTenantId = window.localStorage.getItem('activeTenantId') || '';

  const graphQLFetcher = async (graphQLParams) => {
    return fetch(`${API_URL}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getSessionJwt()}`,
        'cq-tenant-id': activeTenantId,
      },
      body: JSON.stringify(graphQLParams),
    }).then((response) => response.json());
  };

  useEffect(() => {
    setTheme('dark');
  }, []);

  const explorer = explorerPlugin();

  return (
    <div className={classes.pageContent}>
      <Grid
        item
        container
        className={classes.pageHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4">API(GraphQL) Documentation</Typography>
        </Grid>
      </Grid>
      <Box display="flex" sx={{ height: '85vh' }}>
        <GraphiQL fetcher={graphQLFetcher} plugins={[explorer]}>
          <GraphiQL.Logo>
            <ApiOutlined />
          </GraphiQL.Logo>
        </GraphiQL>
      </Box>
    </div>
  );
};

export default ApiDocsPage;
