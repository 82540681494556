import { styled } from '@mui/material/styles';
import { Grid, Typography, TableCell, TableRow, Tab } from '@mui/material';
import { colors } from '../../assets/theme';

export const PageContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(3),
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
});

export const StyledHeader = styled(Grid)({
  marginBottom: '14px',
});

export const StyledCircle = styled('div')(({ color }) => ({
  width: '14px',
  borderRadius: '30px',
  height: '14px',
  backgroundColor: color,
}));

export const StyledLink = styled(Typography)({
  color: colors.blue300,
});

export const StyledTabelCellRight = styled(TableCell)({
  backgroundColor: colors.gray800,
  padding: 0,
  color: colors.white,
  padding: '10px',
});

export const StyledTabelCellLeft = styled(TableCell)({
  width: '300px',
  backgroundColor: colors.gray700,
  padding: '10px',
});

export const StyledTableRow = styled(TableRow)({
  border: `0.5px solid ${colors.gray400}`,
  borderBottom: 'none',
  '&:last-of-type': {
    borderBottom: `0.5px solid ${colors.gray400}`,
  },
});

export const StyledTabPanel = styled('div')({
  marginTop: '16px',
});

export const StyledTab = styled(Tab)({
  color: colors.gray300,
  borderBottom: colors.blue300,
  '&.Mui-selected': {
    color: colors.white,
  },
});

export const StyledCount = styled('div')({
  width: '20px',
  borderRadius: '30px',
  height: '20px',
  color: colors.gray800,
  padding: '1px 1px',
  letterSpacing: '1px',
  backgroundColor: colors.gray300,
});
