// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App__App___jD6kp{text-align:center}.App__App-logo___WQhkU{height:40vmin;pointer-events:none}@media(prefers-reduced-motion: no-preference){.App__App-logo___WQhkU{animation:App__App-logo-spin___fFtxe infinite 20s linear}}.App__App-header___Vc87_{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App__App-link___EQnB2{color:#61dafb}@keyframes App__App-logo-spin___fFtxe{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CAGF,uBACE,aAAA,CACA,mBAAA,CAGF,8CACE,uBACE,wDAAA,CAAA,CAIJ,yBACE,wBAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,4BAAA,CACA,UAAA,CAGF,uBACE,aAAA,CAGF,sCACE,KACE,sBAAA,CAEF,GACE,wBAAA,CAAA","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App__App___jD6kp`,
	"App-logo": `App__App-logo___WQhkU`,
	"App-logo-spin": `App__App-logo-spin___fFtxe`,
	"App-header": `App__App-header___Vc87_`,
	"App-link": `App__App-link___EQnB2`
};
export default ___CSS_LOADER_EXPORT___;
