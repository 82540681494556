
const configFields = `{
    crawlConfig {
        userAgent
    }
    discoveryConfig {
        json {
            rules {
                operator
                type
                value
            }
        }
        xml {
            rules {
                operator
                type
                value
            }
        }
        html {
            rules {
                operator
                type
                value
            }
        }
        hostname {
          includes
          excludes
        }
        ignoreWhenAllRedirects
    }
    findingsConfig {
        name
        description
        severity
        enabled
        ignoreWhenAllRedirects
        status
        restoreToDefaults
        algo
        type
        custom
        displayName
    }
    globalConfig {
      cloudProvider
      cloudRegion
      cloudIp
    }
}`
export const getTenantConfig = `
    query GetTenantConfig {
        getTenantConfig {
            status
            message
            config ${configFields}
        }
    }  
`;
export const getTenantDefaultConfiguration = `
    query GetDefaultTenantConfig {
        getDefaultTenantConfig {
            status
            message
            config ${configFields}
        }
    } 
`;

export const updateTenantCrawlConfig = `
    mutation UpdateTenantConfig($userAgent: String!) {
        updateTenantConfig(config: {
            crawlConfig:{
                userAgent: $userAgent
            }
        })
        {
            status
            message
            config ${configFields}
        }
    }`;

export const updateTenantDiscoveryAlgorithm = `
    mutation UpdateTenantDiscoveryConfig($data: TenantDiscoveryAlgoConfigInput) {
        updateTenantDiscoveryConfig(config: {
            discoveryConfig: $data
        })
        {
            status
            message
            config ${configFields}
        }
    }`;

export const updateTenantFindingsAlgorithm = `
    mutation UpdateTenantFindingsConfig($data: [TenantFindingAlgoConfigInput]) {
        updateTenantFindingsConfig(config: {
            findingsConfig: $data
        })
        {
            status
            message
            config ${configFields}
        }
    }`;


export const updateTenantGlobalConfig = `
  mutation updateTenantGlobalConfig($data: TenantGlobalConfigInput) {
    updateTenantGlobalConfig(config: $data)
    {
      status
      message
      config ${configFields}
    }
  }
`
export const testCloudProvider = `
  mutation testCloudProvider($host: String!, $endpoint: String!){
    testCloudProvider(host: $host, endpoint: $endpoint){
      data
    }
  }
`

export const getCrawlRegions = `
  query getCrawlRegions {
    getCrawlRegions {
      regions{
        providerName
        providerId
        country
      }
    }
  }  
`;

export const getApiGatewayDiscoveryConfigs = `
    query GetApiGatewayDiscoveryConfigs {
      getApiGatewayDiscoveryConfigs {
        status
        message
        data {
          status
          name
          displayName
          type
        }
      }
    }
`

export const getApiGatewayDiscoveryConfig = `
    query GetApiGatewayDiscoveryConfig($configName: String) {
      getApiGatewayDiscoveryConfig(configName: $configName) {
        status
        message
        data {
          status
          name
          displayName
          algo
          type
        }
      }
    }
`

export const updateGatewayConfig = `
    mutation updateApiGatewayDiscoveryConfig($config: UpdateApiGatewayDiscoveryConfigInput, $name: String) {
      updateApiGatewayDiscoveryConfig(config: $config, name: $name) {
        status
        message
        data {
          status
          name
          displayName
          algo
        }
      }
    }
`

export const updateGatewayConfigStatus = `
    mutation updateApiGatewayDiscoveryConfigStatus($config: [UpdateApiGatewayDiscoveryConfigStatusInput]) {
      updateApiGatewayDiscoveryConfigStatus(config: $config) {
        status
        message
        data {
          status
          name
          displayName
          algo
        }
      }
    }
`


export const addGatewayConfigQuery = `
    mutation addApiGatewayDiscoveryConfig($config: AddApiGatewayDiscoveryConfigInput) {
      addApiGatewayDiscoveryConfig(config: $config) {
        status
        message
        data {
          status
          name
          displayName
          algo
        }
      }
    }
`

export const getApiGatewayDiscoveryDefaultConfig = `
    query getApiGatewayDiscoveryDefaultConfig($configName: String) {
      getApiGatewayDiscoveryDefaultConfig(configName: $configName) {
        data {
          status
          name
          displayName
          type
          algo
        }
      }
    }
`

const endpointClassificationRes = `
  status
  message
  data {
    rules_include {
      name
      status
      algo
      type
    }
    rules_exclude {
      name
      status
      algo
      type
    }
  }
`

export const getEndpointClassificationDiscoveryDefaultConfig = `
    query GetEndpointClassificationDiscoveryDefaultConfig {
      getEndpointClassificationDiscoveryDefaultConfig {
        ${endpointClassificationRes}
      }
    }
`

export const updateEndpointClassificationDiscoveryConfig = `
  mutation UpdateEndpointClassificationDiscoveryConfig($config: UpdateEndpointClassificationDiscoveryConfigInput){
    updateEndpointClassificationDiscoveryConfig(config: $config){
      ${endpointClassificationRes}
    }
  }
`

export const getEndpointClassificationDiscoveryConfigs = `
    query GetEndpointClassificationDiscoveryConfigs {
      getEndpointClassificationDiscoveryConfigs {
        ${endpointClassificationRes}
      }
    }
`
