import { styled } from "@mui/material/styles";

export const StyledCircle = styled('div')(({color}) => ({
  width: '26px',
  textAlign: 'center',
  height: '24px',
  backgroundColor: color,
  borderRadius: '16px'
}))

export const StyledLine =  styled('span')(({color, width, height}) => ({
  width: width || 0,
  height: height || 0,
  border: `1px solid ${color}`,
  display: 'block'
}))

export const StyledOvel = styled('div')(({color, space, borderColor}) => ({
  minWidth: '33px',
  maxWidth: '50px',
  textAlign: 'center',
  height: '24px',
  backgroundColor: color,
  borderRadius: '16px',
  padding: `0 ${space}`,
  border: borderColor ? `1px solid ${borderColor}` : 'none'
}))

export const StyledRectangle = styled('div')(({color, borderColor}) => ({
  height: '55px',
  borderRadius: '4px',
  padding: '10px',
  backgroundColor: color,
  border: borderColor ? `1px solid ${borderColor}` : 'none'
}))
