import React from "react";

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Chip } from '@mui/material'
import { colors } from "../assets/theme";

const ColoredChip = ({label, type, testId}) => {
  const chipProps = { label: label }
  const chipStyles = {}
  if(type === 'success'){
    chipProps.icon = <CheckIcon  />
    chipStyles.backgroundColor = colors.green300
    chipStyles.color = colors.green100
    chipStyles['& .MuiChip-icon'] = { color: colors.green100 }
  }
  if(type === 'fail'){
    chipProps.icon = <BlockIcon />
    chipStyles.backgroundColor = colors.red100
    chipStyles.color = colors.red500
    chipStyles['& .MuiChip-icon'] = { color: colors.red500 }
  }
  return (
    <Chip data-testid={testId} sx={chipStyles} {...chipProps} />
  )
}

export default ColoredChip
