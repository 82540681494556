import React, { useEffect, useState } from 'react';
import {Grid, List, ListItem, Typography} from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CircleIcon from "@mui/icons-material/Circle";
import LaunchIcon from "@mui/icons-material/Launch";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
    {
        name: 'API Endpoint',
    },
    {
        name: 'Method',
    },
    {
        name: 'Response Code', sort: true
    }
];



const InternalApplicationServerDetails = ({ vulnerabilityServers, getRequestResponse, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false
  }
  const [expand, setExpand] = useState(expandSection)
  useEffect(() => {
      setExpand({
        des: expanded,
        risk: expanded,
        ref: expanded,
        rec: expanded
      })
  }, [expanded])
  const handleChange = (panel) => () => {
    setExpand({...expand, [panel]: !expand[panel]})
  }
    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                            Applications in production typically have additional security measures in place to prevent unauthorized
                            access of sensitive data. This includes encrypting the network between the web and application servers and
                            the database servers where the sensitive data resides. Protecting data in transit, or data in motion, is a
                            common practice for production environments.

                            Non-production servers are development, test, staging or other forms of non-production grade servers that
                            are publicly accessible. These are often easily identified by looking for terms such as "dev", "staging" or "uat"
                            in the DNS name of the servers.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Non-production servers, when exposed publicly, may present the following challenges to an organization:
                            <List>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Lower degree of security implementation: Development or test environments often have lower scales of
                                        security layers protecting them, thereby potentially exposing them to active attacks from external actors.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Use of production test data: Some internal servers have access to production data, thereby jeopardizing
                                        the security of that data due to the lower security posture.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Rapid changes: Development or test servers often iterate through rapid changes, thereby potentially
                                        exposing insufficiently tested code to external actors and presenting an opportunity to attackers to target
                                        them. It also provides an attacker to try and guess the production versions of the servers and laterally target
                                        those for subsequent attacks.
                                    </Typography>
                                </ListItem>
                            </List>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Determine if such servers need to be exposed publicly at all. If they don't, work with network security teams
                            to implement appropriate security policies to disable public access. If they do need to be exposed publicly,
                            ensure that the application security posture (including Web App Firewalls, etc) is comparable to that of
                            production servers.
                        </Typography>
                        <Typography variant={'body2'}>
                            <Link target={'_blank'} href={'https://www.cequence.ai/why-cequence/'} style={{ color: colors.blueButtonBg, cursor: 'pointer' }}>
                                Cequence Unified API Protection
                                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                            </Link>
                            <span style={{ marginLeft: 5 }}>is the only solution that safeguards your organization from losses across
                            your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales
                            quickly. Reach out to us to explore how Cequence can be deployed to protect such non-production servers exposed publicly. </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Cequence:
                            <Link target={'_blank'} href={'https://www.cequence.ai/'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                https://www.cequence.ai
                                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                            </Link>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    );
};

export default InternalApplicationServerDetails;
