import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { getRoutes } from '../../lib/constants';
import CrawlsIndex from '../../pages/crawls';
import DashboardIndex from '../../pages/dashboard';
import DomainsIndex from '../../pages/domains';
import ServersIndex from '../../pages/servers';
import UsersPage from '../../pages/users/Users';
import HelpPage from '../../pages/help/Help';
import LicensesPage from '../../pages/licenses/Licenses';
import NotificationsIndex from '../../pages/notifications';
import CrawlCatalog from '../../pages/crawlCatalog/CrawlCatalog';
import DiscoveryPage from '../../pages/DiscoveryConfig/DiscoveryConfig';
import CspmIntegrations from '../../pages/cspmIntegrations/CspmIntegrations';
import Wrapper from './Wrapper';
import ApiDocsPage from '../../pages/help/ApiDocs';
import FindingAlgorithm from '../../pages/FindingsConfig/FindingAlgorithm';
import HomePage from "../../pages/home";
import SignupContent from "../../pages/home/Signup";
import jwt from 'jsonwebtoken';
import { getSessionJwt } from '../../services/auth';

const routes = getRoutes();

const Content = ({ isAuthenticated, authUser }) => {
  const sessionToken = getSessionJwt();
  const decodedToken = jwt.decode(sessionToken);
  const activeTenants = decodedToken?.cqTenants || [];
  return (
    <>
      {!isAuthenticated ? <Routes>
        <Route path={routes.home.path} element={<HomePage />} />
        <Route path={'/signup'} element={<SignupContent />} />
        <Route path={'*'} element={<HomePage />} />
      </Routes> : <Wrapper authUser={authUser} cqTenants={activeTenants}>
        <Routes>
          <Route path={routes.dashboard.path} element={<DashboardIndex />} />
          <Route path={routes.domains.path} element={<DomainsIndex />} />
          <Route path={routes.crawlCatalog.path} element={<CrawlCatalog />} />
          <Route path={routes.discovery.path} element={<ServersIndex />} />
          <Route
            path={`${routes.discovery.path}?hostname=:hostname`}
            element={<ServersIndex />}
          />
          <Route path={routes.crawls.path} element={<CrawlsIndex />} />
          <Route path={routes.help.path} element={<HelpPage />} />
          <Route path={routes.users.path} element={<UsersPage />} />
          <Route path={routes.licenses.path} element={<LicensesPage />} />
          <Route
            path={routes.notifications.path}
            element={<NotificationsIndex />}
          />
          <Route path={routes.discoveryConfig.path} element={<DiscoveryPage />} />
          <Route path={routes.cspm.path} element={<CspmIntegrations />} />
          {/* <Route path={routes.cspmItem.path} element={<Integration />} /> */}
          <Route path={routes.cspm.path} element={<CspmIntegrations />} />
          <Route path={routes.apiDocs.path} element={<ApiDocsPage />} />
          <Route path={routes.findingsConfig.path} element={<FindingAlgorithm />} />
          <Route path={'*'} element={<DashboardIndex />} />
        </Routes>
      </Wrapper>}
    </>
  );
};

export default Content;
