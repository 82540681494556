import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import {
  getDomainInfo,
  updateDomainRules,
  AddSubdomain,
} from './crawlCatalogQueries';

export const fetchCrawlCatalog = async (domainName) => {
  try {
    let response = await graphqlQuery(getDomainInfo, { domain: domainName });
    const subDomainsInfo = response?.data?.domain?.get?.subdomains || [];
    const rules = response?.data?.domain?.get?.rules || {};
    const config = response?.data?.domain?.get?.config || {};
    let includeRules = rules.include || [];
    let excludeRules = rules.exclude || [];
    const rulesList = { include: includeRules, exclude: excludeRules };
    return { rules: rulesList, subDomains: subDomainsInfo, config: config };
  } catch (e) {
    console.error(e);
    return;
  }
};

export const updateRules = (domain, rules) => {
  return graphqlMutation(updateDomainRules, {
    domain: domain,
    include: rules.include,
    exclude: rules.exclude,
  });
};

export const setSubDomain = async (domainName, subdomainVal) => {
  await graphqlMutation(AddSubdomain, {
    domain: domainName,
    subdomains: subdomainVal,
  });
  return await fetchCrawlCatalog(domainName);
};

const updateRuleObj = (editRule, rules, action) => {
  const newRules = { include: [...rules.include], exclude: [...rules.exclude] };
  if (!editRule.id) {
    newRules[action].push(editRule);
  } else {
    const includeruleIndex = rules['include'].findIndex(
      (obj) => obj.id === editRule.id
    );
    const excluderuleIndex = rules['exclude'].findIndex(
      (obj) => obj.id === editRule.id
    );
    if (includeruleIndex > -1 && action === 'exclude') {
      newRules['include'].splice(includeruleIndex, 1);
    }
    if (excluderuleIndex > -1 && action === 'include') {
      newRules['exclude'].splice(excluderuleIndex, 1);
    }
    if (action === 'include') {
      if (includeruleIndex > -1) {
        newRules['include'][includeruleIndex] = editRule;
      } else {
        newRules['include'].push(editRule);
      }
    }
    if (action === 'exclude') {
      if (excluderuleIndex > -1) {
        newRules['exclude'][excluderuleIndex] = editRule;
      } else {
        newRules['exclude'].push(editRule);
      }
    }
  }
  return newRules;
};

export const updateRuleStatus = async (domain, rules, rule) => {
  const iRules = [...rules.include];
  const includeRuleIndex = iRules.findIndex((obj) => obj.id === rule.id);
  if (includeRuleIndex > -1) {
    iRules[includeRuleIndex] = rule;
  }
  const eRules = [...rules.exclude];
  const excludeRuleIndex = eRules.findIndex((obj) => obj.id === rule.id);
  if (excludeRuleIndex > -1) {
    eRules[excludeRuleIndex] = rule;
  }
  await updateRules(domain, { include: iRules, exclude: eRules });
  return await fetchCrawlCatalog(domain);
};

export const updateRulesData = async (domain, rules, rule, action) => {
  const newRules = updateRuleObj(rule, rules, action);
  await updateRules(domain, newRules);
  return await fetchCrawlCatalog(domain);
};
