import React, { useState, useEffect } from 'react';
import { TextField, Select, ListItemText, MenuItem, InputAdornment, Tooltip } from '@mui/material';
import { colors } from '../assets/theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomOperatorSelector = ({ value, handleOnChange, setQuery, query, ...props }) => {
  const includeParamInput = ['resHeader', 'queryParam', 'reqHeader'].includes(props?.field);
  const operators = props.fieldData.operators;
  const [paramInput, setParamInput] = useState('');
  const updateQuery = () => {
    const ruleId = props.rule.id;
    const prevRules = query.rules || []
    const updatedRules = prevRules.map(rl => {
      if(rl.field === props.field && ruleId === rl.id){
        return {...rl, 'param': paramInput}
      } else {
        return rl
      }
    });
    setQuery({...query, rules: updatedRules})
  }

  useEffect(() => {
    setParamInput(props?.rule?.param || '')
  }, [props.rule])

  return (
    <>
      {includeParamInput && (
        <TextField
          type='text'
          onBlur={updateQuery}
          value={paramInput}
          placeholder='Header name'
          onChange={e => {
            setParamInput(e.target.value)
          }}
          variant='standard'
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" sx={{cursor: 'pointer'}}>
                <Tooltip title='Enter Header key name ex: Content-Type'>
                  <InfoOutlinedIcon sx={{color: colors.gray500}} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Select
        value={value}
        onChange={e => {
          handleOnChange(e.target.value);
        }}>
        {operators?.map(({ name, label }) => (
          <MenuItem key={name} value={name}>
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CustomOperatorSelector;
