import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {lightTheme} from '../../../assets/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: '10px',
    },
}));

const titleStyles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(titleStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ModalWrapper = ({
                          header,
                          close,
                          error,
                          noCancel,
                          actionEnabled,
                          action,
                          actionInProgress,
                          actionButtonText,
                          maxWidth,
                          children,
                          showActionButton=false,
                      }) => {
    const classes = useStyles();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                <Dialog open={true}
                        fullWidth
                        maxWidth={maxWidth}
                        scroll={'paper'}
                >
                    <DialogTitle onClose={close}>{header}</DialogTitle>

                    <DialogContent>
                        {error &&
                        <Alert className={classes.alert} severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
                        }
                        { children }
                    </DialogContent>
                    { showActionButton &&
                        <DialogActions>
                            {actionInProgress && <CircularProgress color="secondary"/>}
                            {!actionInProgress && <React.Fragment>
                                {noCancel ? <div/> :
                                    <Button
                                        variant={'contained'}
                                        className={'modalCancel'}
                                        color={'secondary'}
                                        onClick={() => close()}>Cancel
                                    </Button>
                                }
                                &nbsp;

                                <Button variant={'contained'}
                                        color={'primary'}
                                        disabled={!actionEnabled}
                                        className={'primary'}
                                        onClick={action} title={actionButtonText}
                                >
                                    {actionButtonText}
                                </Button>
                            </React.Fragment>
                        }
                        </DialogActions>
                    }
                </Dialog>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ModalWrapper;
