import { graphqlQuery } from '../../services/graphql';

import { isEmpty, cloneDeep } from 'lodash';
import {
  getServersSummaryQuery,
  getEndpointsSummaryQuery,
  getDomainsQuery,
  getProvidersDataQuery,
  getCrawlInfoQuery,
  getAPIRequestResponseQuery
} from './serversGraphQL';
import { getCrawlHistoryQuery } from '../dashboard/DashboardQueries';
import { responseCode, severity, apiHosts } from './constants';

export const retrieveServers = async (query, filters) => {
  let statusCodesList = [];
  let statusCodeNotIncluded = [];
  let appHostingProvidersList = [];
  let excludeHostingProviders = [];
  let severityList = [];
  let findingsNotIncluded = [];
  let gateways = []

  filters.responseCode.map((i) =>
    i.selected
      ? statusCodesList.push(i.name)
      : statusCodeNotIncluded.push(i.name)
  );

  Object.entries(filters.appHostingProviders).map((obj) => {
    obj[1].forEach((item) =>
      item.selected
        ? appHostingProvidersList.push(item.name)
        : excludeHostingProviders.push(item.name)
    );
  });

  filters.severity.map((item) => {
    item.selected
      ? severityList.push(item.type)
      : findingsNotIncluded.push(item.type);
  });
  if (severityList.length === filters.severity.length) {
    severityList = ['ALL'];
  }

  if(filters.apiGateways && filters.apiGateways.length > 0){
    gateways = filters.apiGateways.filter(i => i.selected).map(val => val.type)
  }

  const params = {
    domains: query.domains,
    includeCSPM: query.includeCSPM,
    includeSentinel: query.includeSentinel,
    includeSpyderCrawls: query.includeSpyderCrawls,
    timeRange: query.timeRange,
    showApiHosts: query.showApiHosts,
    showNonApiHosts: query.showNonApiHosts,
    statusCodes:
      statusCodeNotIncluded.length > 0
        ? (statusCodesList || []).map((s) => `${s}`)
        : 'ALL', // making status codes a string
    hostingProviders:
      excludeHostingProviders.length > 0 ? appHostingProvidersList : 'ALL',
    issueTypes: findingsNotIncluded.length > 0 ? severityList : 'ALL',
    crawlId: query.crawlId,
    searchHost: query.searchHost,
    size: query.size,
    from: query.from,
    hostname: query.hostname,
    gatewayIds: gateways
  };
  try {
    let response = await graphqlQuery(getServersSummaryQuery, params);
    const data = response.data ? response.data.discovery.getServersSummary : {};
    return data;
  } catch (error) {
    console.log(`graphql query error`, error);
    throw new Error('graphql query error');
  }
};

export const retrieveEndpoints = async (query, filters) => {
  let statusCodesList = [];
  let statusCodeNotIncluded = [];
  // let appHostingProvidersList = [];
  // let excludeHostingProviders = [];
  let severityList = [];
  let findingsNotIncluded = [];
  let gateways = []

  filters.responseCode.map((i) =>
    i.selected
      ? statusCodesList.push(i.name)
      : statusCodeNotIncluded.push(i.name)
  );
  // Object.entries(filters.appHostingProviders).map((obj) => {
  //   obj[1].forEach((item) =>
  //     item.selected
  //       ? appHostingProvidersList.push(item.name)
  //       : excludeHostingProviders.push(item.name)
  //   );
  // });
  filters.severity.map((item) => {
    item.selected
      ? severityList.push(item.type)
      : findingsNotIncluded.push(item.type);
  });

  if(filters.apiGateways && filters.apiGateways.length > 0){
    gateways = filters.apiGateways.filter(i => i.selected).map(val => val.type)
  }

  const params = {
    domains: query.domains,
    includeCSPM: query.includeCSPM,
    includeSentinel: query.includeSentinel,
    includeSpyderCrawls: query.includeSpyderCrawls,
    timeRange: query.timeRange,
    statusCodes:
      statusCodeNotIncluded.length > 0
        ? (statusCodesList || []).map((s) => `${s}`)
        : 'ALL', // making status codes a string
    hostingProviders: 'ALL',
    issueTypes: findingsNotIncluded.length > 0 ? severityList : 'ALL',
    crawlId: query.crawlId,
    showApiHosts: filters?.apiHosts?.[0]?.selected,
    showNonApiHosts: filters?.apiHosts?.[1]?.selected,
    hostname: query.hostname,
    size: query.size,
    from: query.from,
    gatewayIds: gateways
  };
  try {
    let response = await graphqlQuery(getEndpointsSummaryQuery, params);
    return response.data ? response?.data?.discovery?.getEndpointsSummary : {};
  } catch (error) {
    console.log(`graphql query error`, error);
    throw new Error('graphql query error');
  }
};

export const retrieveCrawlHistory = async (selectedDomain, setCrawlHistory) => {
  const params = {
    crawlHistoryDomains: [selectedDomain],
  };

  try {
    let response = await graphqlQuery(getCrawlHistoryQuery, params, global);
    const data = response.data ? response.data.crawl.getCrawlHistory : [];
    data.map((crawlItem) => {
      crawlItem.inprogress = crawlItem && crawlItem.status !== 'COMPLETED';
      return crawlItem;
    });
    setCrawlHistory(data);
    return data;
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};

export const retrieveDomains = async (setLoading, setActiveDomains) => {
  setLoading(true);
  try {
    let response = await graphqlQuery(getDomainsQuery);
    const data = response.data ? response.data.domain.getActiveDomains : [];
    const activeDomainsData = data.map((i) => ({ name: i.domain }));
    return activeDomainsData;
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};

export const retrieveHostingProviders = async (domainSelection, domainsList) => {
  const params = {
    domains: domainsList && domainsList.length > 0 ? domainsList : [domainSelection],
  };
  try {
    let response = await graphqlQuery(getProvidersDataQuery, params);
    const data = response?.data
      ? response?.data?.discovery?.getDomainSummary?.providers
      : {};

    const hostingProvidersFiltersData = { 'TOP 10': [], OTHERS: [] };
    data.values.slice(0, 10).map((p) => {
      return hostingProvidersFiltersData['TOP 10'].push({
        name: p.name,
        selected: true,
      });
    });
    if (data.values.length > 10) {
      data.values.slice(11).map((p) => {
        return hostingProvidersFiltersData['OTHERS'].push({
          name: p.name,
          selected: true,
        });
      });
    }
    return hostingProvidersFiltersData;
    // filters.serversFilters.merge({
    //   appHostingProviders: hostingProvidersFiltersData,
    // });
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};

export const setFiltersData = (serversFilters, itypes) => {
  const severityData = severity(itypes);
  const filtersObj = {};
  filtersObj.appHostingProviders = {};
  filtersObj.responseCode = responseCode;
  // filtersObj.uapAwareness = uapAwareness;
  if (isEmpty(filtersObj.apiHosts)) {
    filtersObj.apiHosts = apiHosts;
  }

  if (serversFilters.timeRange) {
    filtersObj.timeRange = serversFilters.timeRange;
  }

  if (serversFilters.apiHosts) {
    filtersObj.apiHosts = serversFilters.apiHosts;
  }
  if (serversFilters.selectedIssueType) {
    const issue = serversFilters?.selectedIssueType?.type;
    const temp = cloneDeep(severityData);
    for (const i of temp) {
      i.selected = i.type === issue;
    }
    filtersObj.severity = temp;
  } else {
    filtersObj.severity = severityData;
  }
  return filtersObj;
};

export const retrieveSelectedServer = async () => {
  const params = {
    hostname: new URLSearchParams(window.location.search).get('hostname'),
    crawlId: new URLSearchParams(window.location.search).get('crawlId'),
    domains: [new URLSearchParams(window.location.search).get('domain')],
  };

  try {
    let response = await graphqlQuery(getServersSummaryQuery, params);
    const data = response.data
      ? response.data.discovery.getServersSummary
      : {};
    return data;
  } catch (error) {
    console.log(`graphql query error`, error);
    return {}
  }
};

export const fetchCrawlInfo = async (crawlId) => {
  try {
    let response = await graphqlQuery(getCrawlInfoQuery, {
      crawlId: crawlId,
    });
    const data = response.data ? response.data.crawl.getCrawlInfo : {};
    return data
  } catch (error) {
    console.log(
      `error while fetching crawl info for ${crawlId}, error=${error}`
    );
    return {}
  }
};

export const retrieveAPIRequestResponse = async (detail, selectedDomain) => {
  const params = {
    domain: selectedDomain,
    crawlId: detail.crawlId,
    requestId: detail.hostId,
    objectKey: detail.objectKey,
  };
  try {
    let response = await graphqlQuery(getAPIRequestResponseQuery, params);
    const entries =
      response?.data?.crawl?.getAPIRequestResponse?.data?.log?.entries;
    return entries
  } catch (error) {
    return []
  }
};
