import { createContext } from 'react';

const defaultContext = {
  showNewCrawl: () => '',
  tenantConfig: {},
  setTenantConfig: () => '',
  defaultTenantConfig: {},
  issueTypes: [],
  userInfo: {},
  isLog4jEnabled: true
};

export const WrapperContext = createContext(defaultContext);
