import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useErrorStyles = makeStyles((theme) => ({
  errorMessage: {
    backgroundColor: 'rgb(48, 48, 52)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    padding: '5px 0',
    width: '100%',
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: '0',
    overflow: 'hidden',
    boxShadow: '0 0 5px black',
    animationDuration: '3s',
    animationTimingFunction: 'ease-in',
  },
}));

const useLoadingStyles = makeStyles((theme) => ({
  ripple: {
    margin: 'auto',
    position: 'relative',
    width: '140px', // changing width necessitates keyframes changes below
    height: '140px', // changing height necessitates keyframes changes below
    '&> div': {
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: '#fff',
      opacity: 1,
      borderRadius: '50%',
      animationName: '$ripple',
      animationDuration: '1.6s', // changing duration necessitates animation-delay change below
      animationTimingFunction: 'cubic-bezier(0, 0.2, 0.8, 1)',
      animationIterationCount: 'infinite',
    },
    '&> div:nth-of-type(2)': {
      animationDelay: '-0.8s', // (animation-duration / 2 * -1)
    },
  },
  '@keyframes ripple': {
    '0%': {
      top: '64px', // ((.ripple width) / 2) - (max borderWidth)
      left: '64px', // ((.ripple height) / 2) - (max borderWidth)
      width: 0,
      height: 0,
      opacity: 1,
      borderWidth: 0,
    },
    '100%': {
      top: '0px',
      left: '0px',
      width: '128px', // (.ripple width) - ((max borderWidth) * 2)
      height: '128px', //  (.ripple height) - ((max borderWidth) * 2)
      opacity: 0,
      borderWidth: '6px',
    },
  },
}));

export const LoadingError = () => {
  const classes = useErrorStyles();
  return (
    <div
      auto-id={'loading-error'}
      className={classes.errorMessage}
      onClick={() => {
        window.location.reload();
      }}
    >
      <div>
        <div>Something happened while loading the page . . .</div>
        <div>Click to reload the page</div>
      </div>
    </div>
  );
};

const CqLoading = ({ mt = 200 }) => {
  const classes = useLoadingStyles();
  return (
    <div
      auto-id={'loading-cq'}
      className={classes.ripple}
      style={{ marginTop: mt }}
    >
      <div></div>
      <div></div>
    </div>
  );
};

export default CqLoading;
