export const initialState = {
  rules: { include: [], exclude: [] },
  domain: '',
  editRule: {},
  subDomains: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'loadData':
      const subdomains = [...action.value.subDomains];
      subdomains.splice(0, 1); // deleting subdomain which dont have name
      return {
        ...state,
        rules: action.value.rules,
        subDomains: subdomains,
        editRule: {},
        config: action.value.config,
      };
    case 'setRule':
      const rule = action.value;
      return { ...state, editRule: rule };
    case 'updateSubdomains':
      return { ...state, subDomains: action.value };
    case 'emptyRule':
      return { ...state, editRule: {} };
    default:
      return state;
  }
};
