import React, { useEffect, useState } from 'react';
import {Grid, Typography, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CircleIcon from '@mui/icons-material/Circle';
import LaunchIcon from "@mui/icons-material/Launch";
import VulnerabilitiesDetailsTable from "./VulnerabilitiesDetailsTable";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
    {
        name: 'API Endpoint',
    },
    {
        name: 'Method',
    },
    {
        name: 'Response Code', sort: true
    }
];



const ExposedLoginEndpointsDetails = ({ vulnerabilityServers, getRequestResponse, expanded }) => {
    const expandSection = {
      des: false,
      risk: false,
      ref: false,
      rec: false
    }
    const [expand, setExpand] = useState(expandSection)
    useEffect(() => {
        setExpand({
          des: expanded,
          risk: expanded,
          ref: expanded,
          rec: expanded
        })
    }, [expanded])
    const handleChange = (panel) => () => {
      setExpand({...expand, [panel]: !expand[panel]})
    }
    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                            This server was found to expose one or more Login or Authentication endpoints, that will allow users to
                            login to the application server with their credentials. Such endpoints are specifically targeted by attackers
                            using sophisticated bots to conduct password spraying or credential stuffing in Targeted Account Takeover
                            (ATO) attacks.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Attackers can target such endpoints with credential stuffing, replaying credentials obtained from another
                            service. Or they could use password spraying by trying to brute-force access to a service by guessing the
                            credentials repeatedly, in a short period of time.

                            Such attacks often cause the application to have an outage due to the targeted denial of service attacks and
                            can also result in compromises of legitimate user accounts.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            <Link target={'_blank'} href={'https://www.cequence.ai/why-cequence/'} style={{ color: colors.blueButtonBg, cursor: 'pointer' }}>
                            Cequence Unified API Protection
                            <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                            </Link>
                            <span style={{ marginLeft: 5 }}>is the only solution that safeguards your organization from losses across
                            your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales
                            quickly. Using Cequence, you can protect your applications from the full range of automated attacks and
                            eliminate bot induced business impacts such as infrastructure cost overruns, site outages and broad
                            damage from lost, frustrated customers.
                            Reach out to us to explore how Cequence can be deployed to protect such servers exposed publicly. </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Cequence API Spartan:
                                    <Link target={'_blank'} href={'https://www.cequence.ai/products/api-spartan/'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                        https://www.cequence.ai/products/api-spartan/
                                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                                    </Link>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                   62% Growth in Account Takeover attacks targeting Login APIs:
                                    <Link target={'_blank'} href={'https://www.cequence.ai/blog/new-api-research-shows-62-growth-in-atos-targeting-login-apis/'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                        https://www.cequence.ai/blog/new-api-research-shows-62-growth-in-atos-targeting-login-apis/
                                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                                    </Link>
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
                <Typography>
                  User Login or Authentication API Endpoints
                </Typography>
                <VulnerabilitiesDetailsTable
                    columns={exposedFilesHeaderColumns}
                    vulnerabilityServers={vulnerabilityServers}
                    getRequestResponse={getRequestResponse}
                />
            </Grid>
        </>
    );
};

export default ExposedLoginEndpointsDetails;
