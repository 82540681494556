export const submitCrawlRequestQuery = `
  mutation SubmitCrawlRequest($domain: String!, $options: CrawlRequestInput) {
    crawl {
      submitCrawlRequest(domain: $domain, options: $options) {
        code
        success
        message
      }
    }
  }
`;

export const getDomainXLSReport = `
  query getDomainXLSReport($domain: [String], $crawlIds: [String!]){
    server {
      getDomainXLSReport(domain: $domain, crawlIds: $crawlIds){
        totalEndpoints
        totalApiServers
        totalHostingProviders
        domainsData
        issueSummary{
          high
            medium
            low
        }
        apiServers {
          hostname
          domain
          hostService
          totalEndpoints
          issues{
            unhandled
            nonProd
            openapi
            exposedFile
            insecure
            monitoring
            graphql
            login
            log4j
          }
          issueSummary{
            high
            medium
            low
          }
          tls{
            authorized
            certs {
              issuer
              subject
            }
          }
          endpoints{
            path
            hostname
            hostService
            status
            issues{
              unhandled
              nonProd
              openapi
              exposedFile
              insecure
              monitoring
              graphql
              login
              log4j
            }
          }
          
        }
      }
    }
  }
`;

