import React from "react";
import { TextField } from "@mui/material";

const CustomInput = (props) => {
  const { item, applyValue } = props;
  const textValue = item.value || ''
  const handleFilterChange = (e) => {
    applyValue({ ...item, value: e.target.value });
  };
  return (
    <TextField value={textValue} variant="standard" sx={{ "& .MuiInputBase-root": { height: "48px" } }} onChange={handleFilterChange} />
  )
}

export const containsFilter = (callback) => ({
  label: 'Contains',
  value: 'contains',
  getApplyFilterFn: (filterItem, column) => {
    if (!filterItem.value) {
      return null;
    }
    return (params) => {
      return callback(params.value, filterItem.value)
    };
  },
  InputComponent: CustomInput,
  InputComponentProps: { val: 'string' },
})