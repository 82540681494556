import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, List } from '@mui/material';
import logo from '../../assets/img/Cequence-logo-bluewhite.png';
import logoNoName from '../../assets/img/cequence-logo-noname.png';
import MenuItem from './MenuItem';
import { getRoutesForParent } from '../../lib/utils';
import { colors } from '../../assets/theme';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from '../../lib/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    maxWidth: 1600,
    margin: 'auto',
  },
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',

    '& button': {
      '&:hover': {
        background: 'none',
      },
    },
  },
  drawerPaper: {
    left: 'unset',
    backgroundColor: theme.palette.background.gray,
    borderRight: 0,
  },
  drawerOpen: {
    width: theme.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
    '& .parentListItem': {
      height: '55px',
      width: '55px',
      border: `1px solid ${colors.gray700}`,
      borderRadius: '4px',
      '&:hover': {
        border: `1px solid ${colors.gray400}`,
        backgroundColor: colors.darkest,
      },
    },
    '& .MuiTypography-root, & .childListItem': {
      display: 'none',
      height: '0',
      width: '0',
      transition: 'none',
    },
  },
  logoContainer: {
    minHeight: 64,
  },
  logo: {
    position: 'absolute',
    marginTop: '21px',
    marginLeft: '26px',
    marginRight: 'auto',
    cursor: 'pointer',
    opacity: 1,
  },
  cqLogo: {
    marginBottom: '6px',
    width: '161px',
  },
  cqLogoNoName: {
    marginBottom: theme.spacing(1),
    height: 32,
  },
  logoHide: {
    opacity: 0,
  },
  content: {
    flexGrow: 1,
    paddingLeft: 40,
    height: 'calc(100vh)',
  },
  navList: {
    backgroundColor: colors.gray850,
    marginTop: 30,
    borderRadius: 4,
  },
}));

const routes = getRoutes();

const Nav = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [showIcon, setShowIcon] = useState(false);

  const homeClicked = () => {
    navigate(routes.dashboard.path);
  };

  return (
    <Drawer
      variant="permanent"
      open={true}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
    >
      <div className={classes.logoContainer}>
        <img
          auto-id={'logo-name'}
          src={logo}
          alt="logo"
          className={`${classes.logo} ${classes.cqLogo} ${
            drawerOpen ? null : classes.logoHide
          }`}
          onClick={homeClicked}
          style={{ zIndex: drawerOpen ? 2 : 1 }}
        />
        <img
          auto-id={'logo-no-name'}
          src={logoNoName}
          alt="small-logo"
          className={`${classes.logo} ${classes.cqLogoNoName} ${
            drawerOpen ? classes.logoHide : null
          }`}
          onClick={homeClicked}
        />
      </div>
      <List
        className={classes.navList}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <>
          <MenuItem
            name="Attack Surface Discovery"
            nested={getRoutesForParent('discovery')}
            showCollapseIcon={true}
            onHover={showIcon}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
          {/* <MenuItem
                        name='Testing'
                        nested={getRoutesForParent('testing')}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                    /> */}
          {/* <MenuItem
            name="Integrations"
            nested={getRoutesForParent('integrations')}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          /> */}
          <MenuItem
            name="Crawl Configuration"
            nested={getRoutesForParent('crawlConfig')}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
          <MenuItem
            name="Settings"
            nested={getRoutesForParent('settings')}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </>
      </List>
    </Drawer>
  );
};

export default Nav;
