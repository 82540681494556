import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, Snackbar, SnackbarContent, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { colors } from '../assets/theme';

const variantIcon = {
  success: CheckCircleIcon,
  warning: InfoIcon,
  error: WarningIcon,
  info: InfoIcon,
};

const contentStyles = makeStyles((theme) => ({
  container: {
    minWidth: 500,
    maxWidth: 500,
    color: colors.darkerGray,
  },
  footer: {
    paddingRight: 25,
  },
  success: {
    backgroundColor: colors.successFill,
    border: `1px solid ${colors.successIcon}`,
    borderRadius: 5,
  },
  error: {
    backgroundColor: colors.errorFill,
    border: `1px solid ${colors.errorIcon}`,
    borderRadius: 5,
  },
  info: {
    backgroundColor: colors.infoBlue,
    border: `1px solid ${colors.syslogBlue}`,
    borderRadius: 5,
  },
  warning: {
    backgroundColor: colors.warningFill,
    border: `1px solid ${colors.warningIcon}`,
    borderRadius: 5,
  },
  infoIcon: {
    fill: colors.syslogBlue,
  },
  successIcon: {
    fill: colors.successIcon,
  },
  warningIcon: {
    fill: colors.warningIcon,
  },
  errorIcon: {
    fill: colors.errorIcon,
  },
  closeIcon: {
    fontSize: 10,
    color: colors.moreMutedAnother,
    cursor: 'pointer',
  },
  iconVariant: {
    fontSize: 16,
    opacity: 0.9,
  },
  paginationText: {
    marginTop: 10,
  },
}));

const SpSnackbarContent = (props) => {
  const classes = contentStyles();
  const { notifications, onClose } = props;
  const [page, setPage] = useState(1);
  const Icon = variantIcon[notifications[page - 1].category];

  const goToPreviousPage = () => {
    setPage((page) => page - 1);
  };

  const goToNextPage = () => {
    setPage((page) => page + 1);
  };

  return (
    <SnackbarContent
      className={clsx(
        classes[notifications[page - 1].category],
        classes.container
      )}
      message={
        <>
          <Grid container className={classes.container}>
            <Grid item container xs={1}>
              <Icon
                className={clsx(
                  classes[`${notifications[page - 1].category}Icon`],
                  classes.iconVariant
                )}
              />
            </Grid>
            <Grid item container xs={10}>
              {notifications[page - 1].message}
            </Grid>
            <Grid item container xs={1}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => {
                  onClose(notifications[page - 1].id);
                  setPage(1);
                }}
              />
            </Grid>
            {notifications.length > 1 && (
              <Grid
                container
                className={classes.footer}
                justifyContent="flex-end"
              >
                {/* prev button */}
                <IconButton disabled={page === 1} size="large">
                  <ArrowBackIos
                    className={clsx(
                      classes.iconVariant,
                      classes[`${notifications[page - 1].category}Icon`]
                    )}
                    onClick={goToPreviousPage}
                  />
                </IconButton>
                <span className={classes.paginationText}>
                  {page} of {notifications.length}
                </span>
                <IconButton
                  disabled={page === notifications.length}
                  size="large"
                >
                  <ArrowForwardIos
                    className={clsx(
                      classes.iconVariant,
                      classes[`${notifications[page - 1].category}Icon`]
                    )}
                    onClick={goToNextPage}
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: -10,
    width: 500,
  },
}));

const SpSnackBar = ({
  notifications,
  isOpen,
  onNotificationDelete,
  autoHideDuration = 5000,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        className={classes.container}
        onClose={onNotificationDelete}
        TransitionComponent={() => (
          <SpSnackbarContent
            notifications={notifications}
            onClose={onNotificationDelete}
          />
        )}
      />
    </div>
  );
};

export default SpSnackBar;
