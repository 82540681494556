import React from 'react';
import { Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors, enhancedTableStyles } from '../../../assets/theme';
import { formatDateTime } from "../../../lib/utils";

const useStyles = makeStyles((theme) => ({
    addLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    noData: {
        marginTop: '250px',
        width: '80%',
        textAlign: 'center',
    },
    severityLabel: {
        width: 'fit-content',
        padding: '2px 10px',
        borderRadius: 16,
        textTransform: 'capitalize',
        color: colors.almostBlack,
    },
    columnName: {
        'white-space': 'nowrap',
    }
}));

const severity = {
    HIGH: colors.red200,
    MEDIUM: colors.yellow1,
    INFO: colors.white1,
};

const NotificationsTable = ({ notifications, columns }) => {
    const tableClasses = enhancedTableStyles();
    const classes = useStyles();

    return (
            <TableContainer className={tableClasses.root}>
                <Table>
                    <TableHead>
                        <TableRow>
                            { columns.map((c, i) => {
                                return (
                                    <TableCell className={classes.columnName} auto-id={`notification_${c}_col`} key={`column-${i}`}><Typography variant='subtitle2'>{c}</Typography></TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { notifications.map((n, i) =>
                            <TableRow key={i}>
                                <TableCell><Typography auto-id={'notification_time'} variant={'subtitle1'}>{formatDateTime(n.timestamp)}</Typography></TableCell>
                                <TableCell><Typography auto-id={'notification_msg'} variant={'subtitle1'}>{n.message}</Typography></TableCell>
                                <TableCell>
                                    <Typography
                                        variant={'subtitle1'}
                                        style={{ backgroundColor: severity[n.severity]}}
                                        className={classes.severityLabel}
                                        auto-id={'notification_severity'}>
                                        {n.severity}
                                    </Typography>
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    );
};

export default NotificationsTable;
