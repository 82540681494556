import React from "react";
import { Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import InfoIcon from '@mui/icons-material/Info';
import { colors } from "../assets/theme";
import CqChip from "./common/CqChip";

export const SeverityBadge = (props) => {
  return (
    <>
    {props.high ? (
      <CqChip color={colors.red200} borderRadius={18}>
        <ReportProblemIcon sx={{ width: '20px', color: colors.red200 }} />
        <Typography
          style={{
            color: colors.red200,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          High
        </Typography>
      </CqChip>
    ) : props.medium ? (
      <CqChip color={colors.orange100} borderRadius={18}>
        <GppMaybeIcon sx={{ width: '20px', color: colors.orange100 }} />
        <Typography
          style={{
            color: colors.orange100,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          Medium
        </Typography>
      </CqChip>
    ) : props.info ? (
      <CqChip color={colors.gray100} borderRadius={18}>
        <InfoIcon sx={{ width: '20px', color: colors.gray100 }} />
        <Typography
          style={{
            color: colors.gray100,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '24px',
            paddingLeft: '7px',
          }}
        >
          Info
        </Typography>
      </CqChip>
    ) : (
      '--'
    )}
    </>
  )
}