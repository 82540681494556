import React, { Component } from "react";
import ErrorPic from '../../assets/img/500Error.svg'
import { styled } from '@mui/material/styles';
import CqButton from "../common/CqButton";
const StyledPageContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center'
}));

const StyledContent = styled('div')({
  textAlign: 'center',
  '& li': {
    textAlign: 'left'
  }
})

const StyledButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <StyledPageContent>
          <StyledContent>
            <ErrorPic />
            <h4>
              Oops! Something went wrong.
            </h4>
            <p>We're sorry, but our server encountered an unexpected error while<br/>processing your request. Here are a few things you can try:</p>
            <ul>
              <li>Refresh the page and try again.</li>
              <li>If the problem persists, come back in a little while.</li>
              <li>Contact our support team if the issue continues.</li>
            </ul>
            <StyledButtonContainer>
              <CqButton variant='primary' onClick={() => window.location.reload()}>Refresh</CqButton>
            </StyledButtonContainer>
          </StyledContent>
        </StyledPageContent>
      )
    }

    return this.props.children; 
  }
}

export default ErrorBoundary
