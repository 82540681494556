import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import { Grid, FormControl, InputLabel, OutlinedInput } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: '4px',
    color: colors.gray300,
  },
  inputField: {
    '&.MuiOutlinedInput-root': {
      paddingLeft: '6px',
      border: `1.5px solid ${colors.gray400}`,
    },
    '& .MuiOutlinedInput-input': {
      padding: '6px 6px 6px 0',
      fontSize: '14px',
    },
    '& fieldset': {
      border: 'none !important',
    },
  },
  disabled: {
    '&.MuiOutlinedInput-root': {
      borderColor: colors.gray700,
      backgroundColor: colors.gray700,
    },
  },
}));

const CqInput = ({
  label,
  value = '',
  onChange,
  width,
  placeholder,
  disabled = false,
  onClick,
  onFocus,
}) => {
  const classes = useStyles();
  return (
    <Grid>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <FormControl style={{ width: width }}>
        <OutlinedInput
          id="input-field"
          className={clsx(classes.inputField, { [classes.disabled]: disabled })}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          onClick={onClick}
          onFocus={onFocus}
        />
      </FormControl>
    </Grid>
  );
};

CqInput.prototype = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
};

export default CqInput;
