import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CqInput from '../../components/common/CqInput';
import CqCheckbox from '../../components/common/CqCheckbox';
import { MenuItem, Popover } from '@mui/material';
import { colors } from '../../assets/theme';

const useStyles = makeStyles((theme) => ({
  menu: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
    width: '300px',
    height: '350px',
    padding: '6px 10px',
    '& .MuiOutlinedInput-root': {
      width: '275px',
    },
  },
  menuItem: {
    padding: '6px',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
}));

const AutoComplete = ({ onSelect, options, label }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [inpValue, setInpValue] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (val) => {
    setInpValue(val);
  };
  const setFocus = (e) => {
    if (!open) {
      setAnchorEl(e.currentTarget);
    }
  };
  const selectedSubdomains = [];
  const list = [];
  for (const sd in options) {
    if (sd === '') {
      // do nothing
    } else if (options[sd]) {
      list.unshift({ subDomain: sd, value: options[sd] });
      selectedSubdomains.push(sd);
    } else {
      list.push({ subDomain: sd, value: options[sd] });
    }
  }
  let listTrimmed = [];
  if (inpValue !== '') {
    listTrimmed = list
      .filter((obj) => obj.subDomain.includes(inpValue))
      .slice(0, 30);
  } else {
    listTrimmed = list.slice(0, 30);
  }
  return (
    <>
      <CqInput
        label={label}
        onClick={handleClick}
        value={selectedSubdomains.join(',')}
        onFocus={setFocus}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.menu }}
        disableAutoFocus
        disableEnforceFocus
      >
        <CqInput
          onChange={handleChange}
          value={inpValue}
          placeholder="Search Sub-Domain"
        />
        {listTrimmed.map((item, idx) => (
          <MenuItem
            className={classes.menuItem}
            key={item.subDomain}
            onClick={() => onSelect(item.subDomain)}
          >
            <CqCheckbox checked={item.value} />
            <span>{item.subDomain}</span>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default AutoComplete;
