import React from 'react';
import {
  createTheme,
  darken,
  lighten,
  adaptV4Theme,
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import NotoSansTtf from './fonts/NotoSans/NotoSans-Regular.ttf';
import NotoSansLightTtf from './fonts/NotoSans/NotoSans-Light.ttf';
import NotoSansBoldTtf from './fonts/NotoSans/NotoSans-Bold.ttf';
import NotoSansSemiBoldTtf from './fonts/NotoSans/NotoSans-SemiBold.ttf';
import NotoSansDisplayTtf from './fonts/NotoSansDisplay/NotoSansDisplay-VariableFont_wdth,wght.ttf';

const notoSans = {
  fontFamily: 'Noto Sans',
  src: `
    local('Noto Sans'),
    url(${NotoSansTtf}) format('truetype')
  `,
};

const notoSansLight = {
  fontFamily: 'Noto Sans Light',
  src: `
    local('Noto Sans Light'),
    url(${NotoSansLightTtf}) format('truetype')
  `,
};
const notoSansBold = {
  fontFamily: 'Noto Sans Bold',
  src: `
    local('Noto Sans Bold'),
    url(${NotoSansBoldTtf}) format('truetype')
  `,
};

const notoSansSemiBold = {
  fontFamily: 'Noto Sans SemiBold',
  src: `
    local('Noto Sans SemiBold'),
    url(${NotoSansSemiBoldTtf}) format('truetype')
  `,
};

const notoSansDisplay = {
  fontFamily: 'Noto Sans Display',
  src: `
    local('Noto Sans Display'),
    url(${NotoSansDisplayTtf}) format('truetype')
  `,
};

export const colors = {
  darkest: '#000000',
  primary: '#ffffff',
  nearlyWhite: '#f2f2f2',
  whitish: '#e6e6e6',
  darkLabelText: '#333333',
  white2: '#dfdfdf',
  muted: '#c8c8c8',
  fontWhitish: '#d7d7d7',
  muteder: '#b3b3b3',
  white: '#ffffff',
  white1: '#ababab',
  white3: '#C2C2C2',
  white4: '#CCCCCC',
  lightGray: '#aaaaaa',
  lightGray2: '#adadad',
  lightGray1: '#626262',
  gray: '#808080',
  fontGray: '#7f7f7f',
  grayReddish: '#575757',
  moreMutedAnother: '#555555',
  gray900: '#171717',
  gray800: '#242424',
  gray700: '#333333',
  gray750: '#2A2A2A',
  gray650: '#414141',
  gray600: '#474747',
  gray550: '#4E4E4E',
  gray500: '#555555',
  gray400: '#7F7F7F',
  gray300: '#ABABAB',
  gray200: '#AAAAAA',
  gray100: '#DFDFDF',
  gray150: '#A5A5A5',
  gray250: '#C5C5C5',
  gray350: '#393939',
  gray450: '#6B6B6B',
  darkGray1: '#4A4949',
  gray850: '#1E1E1E',
  gray900: '#202020',
  gray50: '#F2F2F2',
  yellow600: '#C79D21',
  surface800: '#424242',

  moreMuted: '#47474c',
  inputBorderColor: '#808080',
  paleRed: '#ee5253',
  red200: '#DA7F82',
  red500: '#C1292E',
  red600: '#911F23',
  yellow: '#FFCB6A',
  red800: '#611517',
  red50: '#F3D4D5',
  red100: '#E6A9AB',
  redLabel: '#712C2E',
  orangeLabel: '#5A3E1B',
  lightBlue100: '#99E7ED',
  lightBlue200: 'rgba(0, 187, 211, 0.5)',
  lightBlue300: 'rgba(51, 201, 220, 1)',
  lightOrange: '#f59a23',
  orange900: '#482902',
  orangeMediumLevel: '#FFD369',
  orangish: '#f44336',
  orange100: '#F9CF9B',
  orange200: '#F7B869',
  orange500: '#F18805',
  orange800: '#794403',
  danger: '#bf2f29',
  green: '#329647',
  syslogBlue: '#2364AA',
  infoBlue: '#A7C1DD',
  successFill: '#99CDB6',
  successIcon: '#008148',
  warningIcon: '#F18805',
  warningFill: '#F9CF9B',
  errorFill: '#E6A9AB',
  errorIcon: '#C1292E',
  yellowChart: '#FDC526',
  yellow1: '#FFE19B',
  neutralFill: '#D9E9FA',
  magenta200: '#E1C6D4',
  magenta300: '#A45C84',
  green100: '#99CDB6',
  green300: '#339A6D',
  green600: '#006136',
  green200: '#66B391',
  red300: '#CD5458',
  redBadgeHover: '#885051',
  mediumBadgeHover: '#765E41',
  darkBlue1000: '#0B1E33',
  darkBlue800: '#123255',
  darkBlue200: '#7BA2CC',
  darkBlue300: '#33C9DC',
  darkBlue400: '#00BBD31F',

  // BG
  default: '#242424',
  lightGray3: '#404040',
  dialogWhiteBg: '#FFFFFF',
  dialogWhitishBg: '#F0F0F0',
  grayBrown: '#4a4849',
  almostBlack: '#171717',
  darkGray: '#272727',
  mediumDarkGray: '#2e2e2e',
  darkerGray: '#242424',
  darkierGray: '#2d2d2d',
  blueBlack: '#25262f',
  grayBlue: '#2f3641',
  grayPurple: '#2f2d36',
  redBg: '#a10000',
  orangeBg: '#dc851f',
  orangeBanner: '#B56604',
  greenBg: '#a2a600',
  greenButtonBg: '#66b391',
  green800: '#004124',
  paleBlue: '#cbe5fc',
  blueHeaven: '#b2e7f6',
  brightBlue: '#6aa3d8',
  blueButtonBg: '#04B2E2',
  blueButton: '#05b3e3',
  blueLagoonOnSunset: '#3cb2e2',
  blueHoverBg: '#d1f1fa',
  blue300: '#68D1EE',
  blue600: '#1A4B80',
  blue: '#2575b0',
  purple: '#81215E',
  charts: [
    '#177B86',
    '#2660A4',
    '#433167',
    '#7327B9',
    '#740732',
    '#C10B54',
    '#872A1B',
    '#C44434',
    '#A7611E',
    '#3E6E4B',
    '#1A4B80',
    '#A45C84',
  ],
  issuesChart: [
    '#C1292E',
    '#CD5458',
    '#E6A9AB',
    '#B56604',
    '#F18805',
    '#F4A037',
    '#F9CF9B',
    '#1A4B80',
    '#2364AA',
    '#4F83BB',
    '#A7C1DD',
    '#00929D',
    '#A45C84',
    '#63A375',
    '#b26300',
    '#008792',
    '#6a0a13',
    '#006331',
  ],
  backgroundSelected: '#253B3E',
};

export const blackVerticalGradient =
  'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0) 100%)';

export const blueHorizontalGradient = `linear-gradient(90deg, ${colors.darkGray} 0%, ${colors.blueButtonBg} 50%, ${colors.darkGray} 100%)`;

export const blueVerticalGradient = `linear-gradient(to bottom, ${colors.darkGray} 30%, ${colors.blueButtonBg} 50%, ${colors.darkGray} 70%)`;

export const defaultBlueVerticalGradient = `linear-gradient(to bottom, ${colors.default} 10%, ${colors.blueButtonBg} 50%, ${colors.default} 90%)`;

const radioIcon = {
  width: 16,
  height: 16,
  color: colors.primary,
  border: '1px solid',
  borderColor: colors.whitish,
  borderRadius: '10px',
};

const checkedRadioIcon = Object.assign(
  { ...radioIcon },
  { backgroundColor: colors.muted }
);

const checkedRadioIconBlue = Object.assign(
  { ...radioIcon },
  { backgroundColor: colors.blue }
);

const MuiRadio = {
  icon: <span style={radioIcon} />,
  checkedIcon: <span style={checkedRadioIcon} />,
  disableRipple: true,
};

const MuiRadioBlue = {
  icon: <span style={radioIcon} />,
  checkedIcon: <span style={checkedRadioIconBlue} />,
  disableRipple: true,
};

const typography = {
  fontFamily: [
    '"Noto Sans"',
    'Nunito',
    'OpenSans-Regular',
    'Open Sans',
    'sans-serif',
  ].join(','),
  fontSize: 13,
  color: colors.fontWhitish,
  fontWeight: 400,

  h1: {
    textTransform: 'uppercase',
    fontFamily: [
      '"Noto Sans Light"',
      'NunitoLight',
      'OpenSans-Light',
      'Open Sans Light',
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontWeight: 200,
    letterSpacing: '8px',
    fontSize: 14,
  },
  h2: {
    textTransform: 'uppercase',
    fontFamily: [
      '"Noto Sans Semi Bold"',
      'NunitoSemiBold',
      'OpenSans-Semibold',
      'Open Sans Semibold',
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontSize: 28,
    fontWeight: 650,
    color: colors.fontGray,
  },
  h3: {
    fontFamily: [
      '"Noto Sans"',
      'NunitoBold',
      'OpenSans',
      'Open Sans',
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: colors.white,
    fontSize: 24,
    fontWeight: 400,
  },
  h4: {
    fontSize: 20,
    color: colors.white,
    letterSpacing: 0.15,
    fontWeight: 500,
  },
  h5: {
    textTransform: 'uppercase',
    fontFamily: [
      '"Noto Sans"',
      'Nunito',
      'ArialMT',
      'Arial',
      'sans-serif',
    ].join(','),
    color: colors.white,
  },
  h6: {
    textTransform: 'uppercase',
    fontFamily: [
      '"Noto Sans Bold"',
      'NunitoBold',
      'OpenSans-Bold',
      'Open Sans Bold',
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: colors.white,
    fontSize: 13,
    fontWeight: 700,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.white,
  },
  body2: {
    fontSize: 14,
    color: colors.white,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 16,
    color: colors.white,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 13,
    fontFamily: [
      '"Noto Sans"',
      'NunitoBold',
      'OpenSans-Bold',
      'Open Sans Bold',
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: colors.white,
    fontWeight: 500,
  },
  overline: {
    fontWeight: 500,
    lineHeight: '16px', /* 133.333% */
    letterSpacing: '1.5px'
  }
};
const typographyLight = JSON.parse(JSON.stringify(typography));
typographyLight.color = colors.darkLabelText;
typographyLight.h1.color = colors.darkLabelText;
typographyLight.h2.color = colors.darkLabelText;
typographyLight.h3.color = colors.darkLabelText;
typographyLight.h4.color = colors.darkLabelText;
typographyLight.h5.color = colors.darkLabelText;
typographyLight.h6.color = colors.darkLabelText;
typographyLight.body1.color = colors.darkLabelText;
typographyLight.body2.color = colors.darkLabelText;
typographyLight.subtitle1.color = colors.darkLabelText;
typographyLight.subtitle2.color = colors.darkLabelText;

const text = {
  primary: colors.primary,
  white: colors.primary,
  secondary: colors.darkest,
  nearlyWhite: colors.nearlyWhite,
  muted: colors.muted,
  muteder: colors.muteder,
  gray: colors.gray,
  moreMuted: colors.moreMuted,
  moreMutedAnother: colors.moreMutedAnother,
  yellow: colors.yellow,
  orangish: colors.orangish,
  danger: colors.danger,
  green: colors.green,
  lightGray: colors.lightGray,
  blue: colors.blueButtonBg,
};
const background = {
  default: colors.default,
  white: colors.primary,
  whitish: colors.dialogWhitishBg,
  gray: colors.default,
  black: colors.darkest,
  almostBlack: colors.almostBlack,
  lightGray: colors.lightGray,
  lightGray3: colors.lightGray3,
  mediumDarkGray: colors.mediumDarkGray,
  darkGray: colors.darkGray,
  grayBlue: colors.grayBlue,
  brightBlue: colors.brightBlue,
  paleBlue: colors.paleBlue,
  blueBlack: colors.blueBlack,
  grayBrown: colors.grayBrown,
  grayPurple: colors.grayPurple,
  orangish: colors.orangish,
  blueButtonBg: colors.blueButtonBg,
  blueButton: colors.blueButton,
  blueHover: colors.blueHoverBg,
  dialogWhite: colors.dialogWhiteBg,
  cancelButton: colors.fontWhitish,
  moreMutedAnother: colors.moreMutedAnother,
  red: colors.redBg,
};

const cssBaseline = {
  '@font-face': notoSans,
  fallbacks: [
    { '@font-face': notoSansLight },
    { '@font-face': notoSansBold },
    { '@font-face': notoSansSemiBold },
    { '@font-face': notoSansDisplay },
  ],
};

const darkOverrides = {
  MuiCssBaseline: {
    styleOverrides: cssBaseline,
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      textPrimary: {
        color: '#04b2e2',
      },
      containedPrimary: {
        backgroundColor: colors.blueButtonBg,

        '&:hover': {
          backgroundColor: darken(colors.blueButtonBg, 0.2),
        },
      },
      containedSecondary: {
        color: colors.whitish,
        borderColor: colors.inputBorderColor,
        borderStyle: 'solid',
        borderWidth: '1px',
        backgroundColor: 'transparent',
        '&:active': {
          backgroundColor: colors.blueButtonBg,
          borderColor: colors.blueButtonBg,
        },
        '&:focus-visible': {
          backgroundColor: colors.blueButtonBg,
          borderColor: colors.blueButtonBg,
        },
        '&:hover': {
          borderColor: colors.blueButtonBg,
          backgroundColor: 'transparent',
        },
      },
      contained: {
        textTransform: 'none',
      },
    },
  },
  // table
  MuiTableContainer: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '& .MuiPaper-elevation1': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: colors.darkGray,
        border: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 'none',
        borderBottomStyle: 'none',
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        border: 'none',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderSpacing: '0',
        border: 'none',
        height: '30px',
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&:hover': {
          color: colors.muted,
        },
        '& .Mui-active': {
          color: colors.muted,
        },
      },
      icon: {
        color: 'inherit !important',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      colorPrimary: {
        '& .Mui-disabled': {
          color: colors.muted,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        marginBottom: '10px',
        padding: '5px',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: '5px',
        paddingBottom: '10px',
        '&:last-child': {
          padding: '2px',
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          padding: '5px',
          paddingBottom: '10px',
        },
      },
    },
  },
  MuiCardContentHeader: {
    styleOverrides: {
      root: {
        padding: '0',
        '&:last-child': {
          padding: '10px',
        },
      },
    },
  },

  MuiFilledInput: {
    styleOverrides: {
      input: {
        color: colors.fontWhitish,
      },
      underline: {
        '& .Mui-disabled': {
          '&:before': {
            borderBottom: 'none',
          },
        },
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:before': {
          borderBottom: 'none',
        },
      },
      root: {
        backgroundColor: 'transparent',
        borderColor: lighten(colors.darkest, 0.3),
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '3px',
        '& .Mui-underline:after': {
          border: 'none',
        },
        '& .Mui-disabled': {
          backgroundColor: 'inherit',
          fontWeight: '400',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: colors.blueButtonBg,
          borderStyle: 'solid',
          borderWidth: '1px',
        },
        '& .Mui-focused': {
          backgroundColor: 'transparent',
          borderColor: colors.blueButtonBg,
          borderStyle: 'solid',
          borderWidth: '1px',
        },
        '&:after': {
          borderStyle: 'unset',
          borderWidth: '0',
        },
      },
    },
  },
  // input
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.gray,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.muted,
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.muted,
        },
        '& .MuiInputLabel-outlined': {
          color: colors.muted,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: colors.gray400,
        fontSize: '13px',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: colors.fontGray,
        '& Mui-focused': {
          color: colors.muted,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        color: colors.gray200,
      },
      filled: {
        '& .Mui-shrink': {
          fontWeight: '700',
        },
        fontSize: 16,
        fontWeight: '400',
        color: colors.gray200,
        '& .Mui-focused': {
          color: colors.muteder,
          fontWeight: '700',
        },
        '& Mui-disabled': {
          color: lighten(colors.darkLabelText, 0.4),
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        color: colors.white,
        fontSize: 16,
      },
    },
  },

  // dropdown list
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: colors.gray700,
        borderBottom: '1px solid transparent',
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gray500,
        },
        '& .Mui-selected': {
          backgroundColor: colors.gray600,
          '&.Mui-focusVisible': { backgroundColor: colors.gray600 },
          '&:hover': {
            backgroundColor: colors.gray500,
          },
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '& button': {
          '&:hover': {
            backgroundColor: colors.paleBlue,
          },
        },
        '& .Mui-selected': {
          backgroundColor: colors.blueButtonBg,
          color: colors.whitish,
          '&:hover': {
            backgroundColor: colors.blueButtonBg,
          },
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        color: colors.white1,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.blue300,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.blueButtonBg,
        '&.Mui-checked': {
          color: colors.blueButtonBg,
        },
      },
      colorSecondary: {
        color: colors.blueButtonBg,
        '&.Mui-checked': {
          color: colors.blueButtonBg,
        },
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      sticky: {
        backgroundColor: colors.surface800,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: `1px solid ${colors.gray400}`,
        boxShadow: colors.darkest,
      },
    },
  },
};

const lightOverrides = {
  MuiCssBaseline: cssBaseline,
  MuiButton: {
    contained: {
      lineHeight: 'none',
      color: colors.primary,
      boxShadow: 'none',
      '&$disabled': {
        color: colors.lightGray2,
        backgroundColor: colors.white2,
        textTransform: 'none',
      },
    },
    containedPrimary: {
      color: colors.whitish,
      backgroundColor: colors.blueButtonBg,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.blueButtonBg,
        boxShadow: 'none',
      },
    },
    containedSecondary: {
      color: colors.lightGray1,
      backgroundColor: 'transparent',
      textTransform: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.lightGray1,
      '&:hover': {
        color: colors.lightGray1,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderColor: colors.blueButtonBg,
      },
    },
  },
  MuiInputLabel: {
    filled: {
      '&$shrink': {
        fontWeight: '700',
      },
      fontSize: 16,
      fontWeight: '400',
      color: colors.darkLabelText,
      '&$focused': {
        color: colors.darkLabelText,
        fontWeight: '700',
      },
      '&$disabled': {
        color: colors.darkLabelText,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      color: colors.moreMutedAnother,
    },
  },
  MuiFilledInput: {
    input: {
      color: colors.moreMutedAnother,
    },
    underline: {
      '&$disabled': {
        '&:before': {
          borderBottom: 'none',
        },
      },
      '&:before': {
        borderBottom: 'none',
      },
      '&:hover:before': {
        borderBottom: 'none',
      },
    },
    root: {
      backgroundColor: 'transparent',
      borderColor: colors.inputBorderColor,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '3px',
      '&$underline:after': {
        border: 'none',
      },
      '&$disabled': {
        backgroundColor: colors.inputDisabledBg,
        fontWeight: '400',
      },
      '&$focused': {
        borderColor: colors.blueButtonBg,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      '&:before': {
        borderBottom: 'none',
      },
      '&$underline:before': {
        borderbottom: 'none',
      },
      '&:after': {
        border: 'none',
        borderStyle: 'unset',
        borderWidth: '0',
      },
    },
  },
  // dropdown list
  MuiMenuItem: {
    root: {
      backgroundColor: colors.primary,
      borderBottom: '1px solid #b3b3b3', // muteder
      color: colors.moreMuted,
      '&:hover': {
        backgroundColor: colors.blueHoverBg,
      },
      '&$selected': {
        color: colors.whitish,
        backgroundColor: colors.blueButtonBg,
        '&:hover': {
          color: colors.moreMuted,
        },
      },
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiSelect: {
    filled: {
      color: colors.fontGray,
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      color: colors.blueButtonBg,
      '&$checked': {
        color: colors.blueButtonBg,
      },
    },
  },
  MuiTableContainer: {
    root: {
      boxShadow: 'none',
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
  },
  MuiTableHead: {
    root: {
      border: 'none',
    },
  },
  MuiTableCell: {
    root: {
      border: 'none',
      borderBottomStyle: 'none',
    },
  },
  MuiTableBody: {
    root: {
      border: 'none',
    },
  },

};

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.blueBlack,
    },
    text: text,
    background: background,
  },
  typography: typography,
  components: darkOverrides,
  drawerWidth: 220,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      // overriding default 1920px
      xl: 1500,
    },
  },
};

const theme = createTheme(themeOptions);
export default theme;

export const enhancedTableStyles = makeStyles((theme) => ({
  root: {
    '& table': {
      borderCollapse: 'separate',
      borderSpacing: '0 .1em',

      '& thead > tr th': {
        backgroundColor: colors.gray900,
        padding: '6px 24px 6px 16px',

        '&:first-of-type': {
          borderTopLeftRadius: '4px',
          left: 0,
        },

        '&:last-of-type': {
          borderTopRightRadius: '4px',
        },
      },

      '& tbody tr': {
        borderBottom: '1px solid transparent',
        // borderTop: '1px solid transparent',
        height: '44px',

        '& td': {
          backgroundColor: colors.darkLabelText,
          padding: '6px 24px 6px 16px',

          '& > *': {
            fontSize: '13px',
          },

          '&:first-of-type': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid transparent',
            left: 0,
          },

          '&:last-of-type': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid transparent',

            '& button': {
              padding: '4px',
              opacity: '0',
              transition: 'all .4s ease-in-out',
            },
          },

          '& button': {
            padding: '4px',
          },
        },
      },
    },
  },

  selectable: {
    '& table': {
      '& thead > tr th': {
        '&:first-of-type': {
          width: '55px',
          maxWidth: '55px',
        },

        '& span': {
          padding: '0',
        },
      },

      '& tbody tr': {
        '& th': {
          '&:first-of-type': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            width: '55px',
            minWidth: '55px',

            '& span': {
              padding: '0',
            },
          },
        },

        '& td': {
          '&:first-of-type': {
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderLeft: '1px solid transparent',
          },
        },
      },
    },
  },
}));

export const enhancedFixedColumnTableStyles = makeStyles((theme) => ({
  root: {
    '& table': {
      borderCollapse: 'separate',
      borderSpacing: '0 .1em',

      '& thead > tr th': {
        backgroundColor: colors.gray900,
        padding: '6px 24px 6px 16px',

        '&:first-of-type': {
          borderTopLeftRadius: '4px',
          position: 'sticky',
          left: 0,
          minWidth: 245,
        },

        '&:last-of-type': {
          borderTopRightRadius: '4px',
        },
      },

      '& tbody tr': {
        borderBottom: '1px solid transparent',
        // borderTop: '1px solid transparent',
        height: '44px',

        '& td': {
          backgroundColor: colors.darkLabelText,
          padding: '6px 24px 6px 16px',

          '& > *': {
            fontSize: '13px',
          },

          '&:first-of-type': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid transparent',
            position: 'sticky',
            left: 0,
            minWidth: 245,
          },

          '&:last-of-type': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid transparent',

            '& button': {
              padding: '4px',
              opacity: '0',
              transition: 'all .4s ease-in-out',
            },
          },

          '& button': {
            padding: '4px',
          },
        },

        // '&:hover': {
        //
        //     '& td': {
        //         transition: 'all .4s ease-in-out',
        //         backgroundColor: colors.gray600,
        //         borderTop: `1px solid ${colors.gray400}`,
        //         borderBottom: `1px solid ${colors.gray400}`,
        //
        //         '&:first-of-type': {
        //             borderLeft: `1px solid ${colors.gray400}`,
        //         },
        //
        //         '&:last-of-type': {
        //             borderRight: `1px solid ${colors.gray400}`,
        //
        //             '& button': {
        //                 opacity: '1',
        //                 transition: 'all .4s ease-in-out',
        //             },
        //         },
        //     },
        // },
      },
    },
  },

  selectable: {
    '& table': {
      '& thead > tr th': {
        '&:first-of-type': {
          width: '55px',
          maxWidth: '55px',
        },

        '& span': {
          padding: '0',
        },
      },

      '& tbody tr': {
        '& th': {
          '&:first-of-type': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            width: '55px',
            minWidth: '55px',

            '& span': {
              padding: '0',
            },
          },
        },

        '& td': {
          '&:first-of-type': {
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderLeft: '1px solid transparent',
          },
        },
      },
    },
  },
}));

/**
 * Custom Button Styles
 */
export const customButtonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderRadius: '4px',
    color: colors.blue300,
    fontSize: '14px',
    fontFamily: 'Noto Sans',
    fontWeight: 500,
    lineHeight: '16px',
    border: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: '1 !important',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    display: 'flex',
    gap: '6px',
    '&:hover': {
      backgroundColor: colors.gray500,
    },
    '&:disabled': {
      color: colors.gray200,
      backgroundColor: colors.gray650,
      textTransform: 'none',
      cursor: 'default',
    },
  },
  primaryBtn: {
    color: colors.gray700,
    backgroundColor: colors.blue300,

    '&:hover': {
      backgroundColor: colors.blueButtonBg,
    },
    '&:disabled': {
      color: colors.gray200,
      backgroundColor: colors.gray650,
      textTransform: 'none',
      cursor: 'default',
    },
  },
  grayBtn: {
    color: colors.gray200,
    backgroundColor: colors.gray700,
    '&:hover': {
      backgroundColor: colors.gray500,
    },
  },
  borderBlueBtn: {
    color: colors.blue300,
    backgroundColor: colors.gray700,
    border: `1px solid ${colors.blue300}`,
  },
}));

/**
 * CqDialog Input Styles
 */
export const cqDialogInputStyles = makeStyles((theme) => ({
  dialogLabel: {
    '&.MuiFormLabel-root': {
      color: colors.gray300,
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: '4px',
    },
  },
  dialogInfo: {
    '&.MuiFormLabel-root': {
      color: colors.gray100,
      fontSize: 12,
      lineHeight: '16px',
      marginTop: '4px',
    },
  },
  dialogInput: {
    '&.MuiFormControl-root': {
      backgroundColor: colors.gray700,
      border: `2px solid ${colors.gray400}`,
      borderRadius: 4,

      '& .MuiInputBase-root': {
        padding: 0,
        color: colors.white,
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        lineHeight: '20px',

        '&::placeholder': {
          color: colors.gray200,
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
}));

themeOptions.palette.mode = 'light';
themeOptions.overrides = lightOverrides;
themeOptions.typography = typographyLight;
themeOptions.components.MuiRadio = MuiRadioBlue;
export const lightTheme = createTheme(adaptV4Theme(themeOptions));
