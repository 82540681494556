import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import { DOMAIN_STATUS } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  hoverItem: {
    '&:hover': {
      background: colors.gray500,
    },
  },
  icon: {
    color: colors.gray300,
    fontSize: '16px !important',
  },
  expanded: {
    transform: 'rotate(90deg)',
  },
  active: {
    color: colors.blueButtonBg,
  },
}));

const ExpanderIcon = ({
  name,
  status,
  isExpanded,
  onClick,
  height = 36,
  width = 36,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.hoverItem]: status === DOMAIN_STATUS.ACTIVE,
      })}
      onClick={onClick}
      style={{ height: height, width: width }}
    >
      <ArrowForwardIosIcon
        auto-id={`arrow-${name.replace(' ', '-')}-icon`}
        className={clsx(classes.icon, {
          [classes.expanded]: isExpanded,
          [classes.active]: status === DOMAIN_STATUS.ACTIVE,
        })}
      />
    </div>
  );
};

export default ExpanderIcon;
