import React, { useState, useEffect, useMemo } from 'react';
import { useUser } from '@descope/react-sdk';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBoundary from './ErrorBoundary';
import { Typography, Grid, Switch } from '@mui/material';
import Header from './Header';
import Content from './Content';
import Nav from './Nav';
import { useGlobalState } from '../../store/globalState';
import CqDialog from '../common/CqDialog';
import { colors } from '../../assets/theme';
import {
  getApiGatewayDiscoveryConfigs,
  getTenantConfig,
  getTenantDefaultConfiguration,
} from '../../pages/DiscoveryConfig/discoveryQueries';
import { WrapperContext } from './wrapperContext';
import { newCrawl } from '../../lib/newCrawl';
import { graphqlQuery } from '../../services/graphql';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { styled } from '@mui/styles';
import { hideNavMenu } from '../../services/auth';
import { getDomainsQuery } from '../../pages/domains/domainGraphQL';
import { isEmpty } from 'lodash';

const isLog4j = (findings) => {
  const log4jFinding = findings.find((item) => item.name === 'LOG4J_LONG4J');
  return log4jFinding?.enabled ? true : false;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    margin: 'auto',
  },
  content: {
    flexGrow: 1,
    paddingLeft: hideNavMenu() ? 0 : 24,
    height: 'calc(100vh)',
    minWidth: 0,
  },
  accessDeniedDialogInfo: {
    color: colors.white,
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '20px',
  },
  crawlTextContainer: {
    paddingBottom: '15px',
  },
  scanContainer: {
    padding: '15px 0',
    borderTop: `1px solid ${colors.gray450}`,
    borderBottom: `1px solid ${colors.gray450}`,
  },
  pageContent: hideNavMenu() ? {} : {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(8),
  }
}));

const StyledTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const logout = () => {
  window.location.href = '/logout';
};

const demoPage = () => {
  window.location.href = 'http://cequence.ai/demo';
};

const Wrapper = ({ children, authUser, cqTenants }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [openCrawlDialog, setCrawlDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [crawlDomain, setCrawlDomain] = useState('');
  const [isIntrusive, setIntrusive] = useState(false);
  const [activeDomains, setActiveDomains] = useState([]);
  const [loadingData, setLoadingData] = useState(false)
  const [showEmptyState, setEmptyState] = useState(false)
  const [isLog4jEnabled, setLog4jEnabled] = useState(false);
  const global = useGlobalState();
  const { sessionTimeout } = global.get();
  const [tenantConfig, setTenantConfig] = useState({});
  const [defaultTenantConfig, setDefaultTenantConfig] = useState({});
  const [gatewayConfig, setGatewayConfig] = useState([])

  const user = authUser || {};
  
  useEffect(async () => {
    setLoadingData(true)
    try {
      const res = await graphqlQuery(getTenantConfig);
      const gateway = await graphqlQuery(getApiGatewayDiscoveryConfigs);
      let domainsResponse = await graphqlQuery(getDomainsQuery, {}, global);
      const resDomains = domainsResponse.data.domain.list ?? [];
      const activeDomainsObj = resDomains.filter(val => val.status === 'ACTIVE')
      setActiveDomains(activeDomainsObj)
      setGatewayConfig(gateway?.data?.getApiGatewayDiscoveryConfigs?.data || [])
      setTenantConfig(res?.data?.getTenantConfig?.config || {});
      setLog4jEnabled(
        isLog4j(res?.data?.getTenantConfig?.config?.findingsConfig)
      );
      setEmptyState(resDomains.length === 0)
      const defaultConfigRes = await graphqlQuery(
        getTenantDefaultConfiguration
      );
      setDefaultTenantConfig(
        defaultConfigRes?.data?.getDefaultTenantConfig?.config || {}
      );
    } catch (e) {
      console.error(e);
    }
    setLoadingData(false)
  }, []);
  let currentTenantObj = [];
  let activeTenantId = localStorage.getItem('activeTenantId');
  currentTenantObj = cqTenants?.filter((val) =>
    val?.id?.includes(activeTenantId)
  );
  if (
    activeTenantId === null ||
    activeTenantId === 'null' ||
    activeTenantId === undefined ||
    activeTenantId === 'undefined' ||
    currentTenantObj.length === 0
  ) {
    activeTenantId = cqTenants?.[0]?.id;
    localStorage.setItem('activeTenantId', cqTenants?.[0]?.id);
  }
  const activeTenantName =
    cqTenants?.find((val) => val.id.includes(activeTenantId))
      ?.name || activeTenantId;

  if(user.userId != null) {
    pendo.initialize({
      visitor: {
        id: user.userId,
      },
      account: {
        id: activeTenantId,
        name: activeTenantName,
      },
    });
  }

  const userObj = { ...user, tenant: currentTenantObj };

  const getIssuestypes = useMemo(() => {
    let issuesList = defaultTenantConfig?.findingsConfig || [];
    if (tenantConfig?.findingsConfig) {
      issuesList = tenantConfig?.findingsConfig;
    }
    const obj = {
      TLS: {
        name: 'TLS',
        description: 'Insecure SSL/TLS Implementation',
      },
    };
    for (const issue of issuesList) {
      obj[issue.name] = {
        name: issue?.name,
        severity: issue?.severity,
        description: issue?.displayName,
        custom: issue?.custom,
        longDescription: issue.description,
        type: issue.type
      };
    }
    return obj;
  }, [defaultTenantConfig, tenantConfig]);

  async function createNewCrawl() {
    if (crawlDomain !== '') {
      setLoading(true);
      try {
        const result = await newCrawl(crawlDomain, isIntrusive);
        const response = result?.data?.crawl?.submitCrawlRequest;
        if (response.success === false) {
          global.notifications.merge([
            {
              id: `domains_${crawlDomain}`,
              message: `${response.message}`,
              category: 'warning',
            },
          ]);
        } else {
          global.notifications.merge([
            {
              id: `domains_${crawlDomain}`,
              message: `The crawl has been successfully submitted for ${crawlDomain}`,
              category: 'success',
            },
          ]);
        }
      } catch (e) {}
      setCrawlDialog(false);
      setIntrusive(false);
      setLoading(false);
    }
  }

  const handleNewCrawlDialogClose = () => {
    setCrawlDomain('');
    setCrawlDialog(false);
    setIntrusive(false);
  };

  const handleNewCrawlDialogopen = (domain, config) => {
    setCrawlDialog(true);
    setCrawlDomain(domain);
  };

  const handleIntrusiveSetting = async () => {
    const intrusive = !isIntrusive;
    setIntrusive(intrusive);
  };

  return (
    <div className={classes.root}>
      {hideNavMenu() ? <></> : <Nav />}
      <main className={classes.content}>
        {hideNavMenu() ? <></> : <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />}
        <ErrorBoundary>
          {! loadingData && <WrapperContext.Provider
            value={{
              showNewCrawl: handleNewCrawlDialogopen,
              tenantConfig: tenantConfig,
              setTenantConfig: setTenantConfig,
              defaultTenantConfig: defaultTenantConfig,
              issueTypes: getIssuestypes,
              userInfo: userObj,
              isLog4jEnabled: isLog4jEnabled,
              setLog4jEnabled: setLog4jEnabled,
              gatewayConfig: gatewayConfig,
              setGatewayConfig: setGatewayConfig,
              activeDomains: activeDomains,
              showEmptyState: showEmptyState
            }}
          >
            <div className={classes.pageContent}>
              {children}
            </div>
          </WrapperContext.Provider>}
        </ErrorBoundary>
      </main>

      {/* Access Denied Dialog */}
      <CqDialog
        title={'Access Denied'}
        open={Object.keys(userObj).length === 0 && sessionTimeout}
        onSubmit={demoPage}
        maxWidth="sm"
        submitBtnText="OK"
        showCloseBtn={false}
        alertType={true}
        disableBackdropClick={true}
        blocking={true}
        CloseIcon={false}
      >
        <Typography
          auto-id={'account-not-approved'}
          className={classes.accessDeniedDialogInfo}
        >
          Cequence has not approved your account. You will receive an email
          notification when your account has been approved.
        </Typography>
      </CqDialog>
      <CqDialog
        title={'Session Expired'}
        open={userObj.tenantStatus == 'ACTIVE' && sessionTimeout}
        onSubmit={logout}
        maxWidth="sm"
        submitBtnText="Login"
        showCloseBtn={false}
        alertType={true}
        disableBackdropClick={true}
        blocking={true}
        CloseIcon={false}
      >
        <Typography
          auto-id={'session-timeout'}
          className={classes.accessDeniedDialogInfo}
        >
          Your session has been timeout. Please login again.
        </Typography>
      </CqDialog>
      <CqDialog
        title="Crawl Domain Now"
        open={openCrawlDialog}
        maxWidth="sm"
        onClose={handleNewCrawlDialogClose}
        onSubmit={createNewCrawl}
        backdrop={loading}
        submitBtnText="Crawl Now"
        titleIcon={<PublishedWithChangesIcon />}
        mediumRiskType
      >
        <Grid container>
          <Grid item xs={12} className={classes.crawlTextContainer}>
            <Typography>
              You are about to initiate a one-time crawl on the subdomains
              enabled. Crawl may take up to 3 hours to complete. Select Crawl
              now to continue.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.scanContainer}>
              <Grid item xs={9}>
                <Typography variant="subtitle1">Advanced Crawl</Typography>
                <Typography variant="caption">
                  Advanced crawls generate API requests that are specifically
                  crafted to detect Log4j vulnerabilities in your environment.
                  These crawls generate more API requests than regular or
                  standard Spyder crawls, that may trigger network security
                  alarms. Please use the UserAgent string that is embedded in
                  the crawl requests to identify and allow these requests,
                  should they get blocked by your security controls.
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Switch
                  checked={isIntrusive}
                  onChange={handleIntrusiveSetting}
                  disabled={!isLog4jEnabled}
                ></Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CqDialog>
    </div>
  );
};

export default Wrapper;
