import { styled } from '@mui/material/styles';
import { Grid, Box, Link, Select, TableCell, TableRow } from '@mui/material';
import { colors } from '../../assets/theme';
import MuiInput from "@mui/material/Input";
import { Switch, TextField } from "@mui/material";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';


export const StyledPageContent = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const StyledPanelBox = styled(Box)({
  paddingTop: '17px',
});

export const StyledContainer = styled(Grid)({
  border: `1px solid ${colors.gray650}`,
  borderRadius: '4px',
  padding: '10px',
  position: 'relative',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  marginBottom: '20px'
});

export const StyledContainerElm = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export const StyledGroup = styled(Grid)({
  border: `1px solid ${colors.gray650}`,
  borderRadius: '4px',
  padding: '10px',
});

export const StyledLeftOperator = styled('div')({
  position: 'absolute',
  top: '20px',
  left: '-50px',
  display: 'flex',
  '& span': {
    marginTop: '10px'
  }
});

export const StyledVerticalOperator = styled('div')({
  marginLeft: '50px',
  '& span': {
    marginLeft: '23px'
  },
  display: 'block'
})

export const StyledlastOperator = styled('div')({
  display: 'flex',
  '& span': {
    margin: '10px 4px'
  }
})

export const StaticInput = styled('div')({
  background: colors.gray600,
  borderRadius: '4px',
  padding: '8px 10px',
  color: colors.white,
  height: '40px',
  width: '100%',
  position: 'relative',
});

const baseStyles = {
  position: 'absolute',
  borderRadius: '40px',
  width: '36px',
  height: '36px',
  cursor: 'pointer',
  '& svg': {
    color: colors.gray400,
    margin: '5px 7px',
  },
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
    '& svg': {
      color: colors.blue300,
    },
  },
};

export const StaticEdit = styled(Link)({
  top: '2px',
  right: '11px',
  ...baseStyles,
});

export const StaticClip = styled(Link)({
  top: '2px',
  right: '50px',
  ...baseStyles,
});

export const StyledButtonContainer = styled('div')({
  justifyContent: 'end',
  padding: '6px 0',
  display: 'flex',
  gap: '4px',
  '& svg': {
    width: '14px',
    height: '14px',
  },
});

export const Input = styled(MuiInput)(({ theme }) => ({
  width: 150,
  height: 30,
  padding: '0px 0px 0px 6px',
  '&:before, &:after': {
    border: 'none !important',
  },
  border: `1.5px solid ${colors.gray500}`,
  borderRadius: 4,
}))

export const SpSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  padding: 8,
  '&.MuiSwitch-root': {
    width: '66px',
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
    transform: 'unset',
    WebkitTransform: 'unset',
    left: '26px',
    color: colors.blueButtonBg,
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: colors.gray550,
    opacity: 1,
    '&:before': {
      content: '"ON"',
      color: colors.blueButtonBg,
    },
    '&:after': {
      content: '""',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: colors.gray500,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 52,
      height: 16,
      fontFamily: 'Noto Sans',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      color: 'red',
    },
    '&:before': {
      content: '""',
      left: 12,
      color: colors.blueButtonBg,
    },
    '&:after': {
      content: '"OFF"',
      right: -16,
      color: colors.gray200,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const StyledDiscoveryGrid = styled(Grid)({
  backgroundColor: colors.gray750,
  borderRadius: '4px',
  padding: '24px',
  border: `1px solid ${colors.gray700}`
})

export const useStyles = makeStyles((theme) => ({
  conditionColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: theme.spacing(1),
    letterSpacing: '1px',
    fontSize: '14px',
  },
  conditionBox: {
    display: 'flex',
    backgroundColor: colors.default,
    borderRadius: 4
  },
  headerText: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.gray200
  },
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  rowSelection: {
    cursor: 'default',
    '&.Mui-selected > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div > svg': {
      color: colors.blueButtonBg,
    },
    '&:hover > td:nth-last-of-type(2) > button': {
      backgroundColor: colors.gray650,
    },
  },
  chipContainer: {
    padding: '0 2px',
  },
  styledIcon: {
    cursor: 'pointer',
    fontSize: `24px !important`,
    '&:hover': {
      color: colors.blueButtonBg,
      background: colors.gray500,
      borderRadius: 50,
    }
  },
  lastFindingsColumn: {
    borderBottom: `1px solid ${colors.surface800} !important`,
  },
  deleteIconContainer: {
    visibility: 'hidden'
  },
  findingsColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    border: `1px solid ${colors.surface800}`,
    borderBottom: 'none',
    backgroundColor: colors.gray700,
    padding: theme.spacing(1),
    color: colors.gray200,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: '14px',
    '& .MuiTextField-root': {
      '& .MuiOutlinedInput-root': {
        border: 'none !important',
        '& input': {
          padding: 0,
          border: 'none !important',
        },
        '& fieldset': {
          border: 'none !important',
        }
      }
    }
  },
  darkColumn: {
    color: colors.white,
    backgroundColor: colors.gray800,
    textTransform: 'capitalize',
    letterSpacing: 'inherit',
    '&:hover > div > div ': {
      cursor: 'pointer',
      visibility: 'visible',
    }
  },
  deleteIcon: {
    color: colors.gray400,
    padding: 2,
    '&:hover': {
      color: colors.red300,
      background: colors.gray500,
      borderRadius: 50,
    }
  },
  bottomBorder: {
    borderBottom: `1px solid ${colors.surface800}`,
  },
  menu: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
    width: '200px',
  },
  pathList: {
    marginRight: theme.spacing(2),
    '& >ul': {
      padding: theme.spacing(0),
    },
  },
  pathItems: {
    '& .MuiMenuItem-root': {
      color: theme.palette.common.white,
      fontSize: '0.8rem'
    },
  },
  severityRadioButton: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    color: colors.white,
    '&.Mui-checked': {
      color: colors.blue300
    }
  },
  statusCodeInput: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: 0,
    },
    '& .MuiInputBase-root': {
      border: 'none'
    }
  }
}));

export const StyledEndpointGroup = styled('div')({
  background: colors.default,
  borderRadius: '15px',
  padding: '16px',
  marginBottom: '8px',
  flex: '1 1 auto'
})

export const StyledTextField = styled(TextField)({
  marginLeft: '25px',
  marginBottom: '8px',
  "& .MuiOutlinedInput-root": {
    height: "40px"
  },
  "& .MuiInputLabel-root": {
    lineHeight: "12px"
  }
})

export const StyledGroupAttContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

export const StyledCell = styled(TableCell)(({background}) => ({
  backgroundColor: background === 'true' ? colors.gray750 : 'none',
  // width: '300px',
  borderRadius: '4px',
  padding: '10px'
}))

export const StyledTableRow = styled(TableRow)({
  border: `0.5px solid ${colors.gray500}`
})
