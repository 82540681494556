import {
  AuthProvider,
  useSession,
  useUser,
  useDescope,
  getSessionToken,
} from '@descope/react-sdk';
import React, { useEffect, useState } from 'react';
import App from './App';

const AUTH_PROJECT_ID = window.__APP_RUNTIME_CONFIG__.AUTH_PROJECT_ID;
const AUTH_BASE_URL = window.__APP_RUNTIME_CONFIG__.AUTH_BASE_URL;

const AppInternal = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const { user, isUserLoading } = useUser();
  const [showSessionMess, setShowSessionMess] = useState(false);
  const [isLoggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    if (user?.userId) {
      setLoggedin(true);
      sessionStorage.setItem('sessionJwt', getSessionToken());
    }
  }, [user]);

  const descopeSdk = useDescope();

  useEffect(() => {
    descopeSdk.onSessionTokenChange((session) => {
      if (isAuthenticated && !session) {
        setShowSessionMess(true);
      } else if (!session) {
        setLoggedin(false);
      }
      sessionStorage.setItem('sessionJwt', getSessionToken());
    });
  }, [descopeSdk]);

  return (
    <App
      authObj={{
        isUserLoading,
        isSessionLoading,
        isLoggedIn,
        showSessionMess,
        setShowSessionMess,
        user,
      }}
    />
  );
};

const AppDescope = () => {

  return (
    <AuthProvider projectId={AUTH_PROJECT_ID} baseUrl={AUTH_BASE_URL}>
      <AppInternal />
    </AuthProvider>
  );
};

export default AppDescope;
