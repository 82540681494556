import React from 'react';
import TextWithIcon from '../../../components/common/TextWithIcon';
import CqChip from '../../../components/common/CqChip';
import { colors } from '../../../assets/theme';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import { Typography } from '@mui/material';

const Status = ({ flag }) => {
  const statusColor = flag === 'ACTIVE' ? colors.green200 : colors.red300;
  return (
    <CqChip color={statusColor} borderRadius={16}>
      <TextWithIcon>
        {flag ? (
          <CheckIcon
            sx={{
              color: statusColor,
              fontSize: 15,
              width: '20px',
            }}
          />
        ) : (
          <BlockIcon
            sx={{
              color: statusColor,
              fontSize: 15,
              width: '20px',
            }}
          />
        )}
        <Typography variant="caption" style={{ color: statusColor }}>
          {flag === 'ACTIVE' ? 'Enabled' : 'Disabled'}
        </Typography>
      </TextWithIcon>
    </CqChip>
  );
};

export default Status;
