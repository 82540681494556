export const getSessionJwt = () => {
  return sessionStorage.getItem(useDescopeAuth() ? 'sessionJwt' : 'kct');
};

export const useDescopeAuth = () => {
  return window.__APP_RUNTIME_CONFIG__?.SPYDER_AUTH_ISSUER === 'DESCOPE';
};

export const hideNavMenu = () => {
  return window.__APP_RUNTIME_CONFIG__?.SPYDER_HIDE_NAV === 'true';
};
