import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { colors } from '../../assets/theme';

const CqMenuItem = styled(MenuItem)(() => ({
  '&.Mui-selected': {
    backgroundColor: colors.gray600,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.gray500,
      color: colors.white,
    },
    '&.Mui-focusVisible': {
      backgroundColor: colors.gray600,
    },
  },
}));

export default CqMenuItem;
