import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { colors } from '../../../assets/theme';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: colors.gray700,
  borderBottom: `2px solid ${colors.gray500}`,
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', color: colors.gray100 }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: colors.gray700,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
    marginLeft: theme.spacing(1),
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
  {
    name: 'API Endpoint',
  },
  {
    name: 'Method',
  },
  {
    name: 'Response Code',
    sort: true,
  },
];

const CustomFindingDetails = ({ selectedFinding, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false,
  };
  const [expand, setExpand] = useState(expandSection);
  useEffect(() => {
    setExpand({
      des: expanded,
      // risk: expanded,
      // ref: expanded,
      // rec: expanded
    });
  }, [expanded]);
  const handleChange = (panel) => () => {
    setExpand({ ...expand, [panel]: !expand[panel] });
  };
  return (
    <>
      <Grid style={{ marginTop: 20, minWidth: '800px', minHeight: '500px' }}>
        {selectedFinding.longDes && (
          <Accordion expanded={expand.des} onChange={handleChange('des')}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                variant={'subtitle1'}
                style={{ color: colors.gray100 }}
              >
                Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={'body2'}>
                {selectedFinding.longDes}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            The risk of enabling API-based access to private keys in PKCS12 files (to unauthorized users) is equivalent to that of
                            granting unfettered access to an authentication token or API key - an attacker can impersonate legitimate actors
                            on the network, leak or modify data in transit or snoop into encrypted communications on the network. If the
                            credential is associated with a wildcard certificate, this can be used to mis-represent <b>ANY</b> actor on the network, not
                            just the endpoint that the exposed file was found on. Left unaddressed, this is a major vulnerability that
                            undermines the integrity of communications on the network, and the security of the overall network itself.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Ensure that cryptographic credentials, like PKCS12 files, are either stored encrypted or only accessible to
                            authorized actors on the network. Security-conscious organizations typically use hardware security modules
                            (HSMs) to protect sensitive information from unauthorized or malicious actors. Best practices also recommend the
                            periodic rotation (much like with user passwords) of these machine identities to mitigate the impact of a key
                            compromise.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            What is a PKCS12 file?
                            <Link target={'_blank'} href={'https://www.ssl.com/how-to/export-certificates-private-key-from-pkcs12-file-with-openssl/'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                https://www.ssl.com/how-to/export-certificates-private-key-from-pkcs12-file-with-openssl/
                                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                            </Link>
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
      </Grid>
    </>
  );
};

export default CustomFindingDetails;
