import React, { useContext } from 'react';
import DashboardPage from './Dashboard';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import EmptyState from '../../components/EmptyState';

const DashboardIndex = () => {
  const { activeDomains, showNewCrawl, showEmptyState } = useContext(WrapperContext)
  return (
    activeDomains.length > 0 ? <DashboardPage activeDomains={activeDomains} showNewCrawl={showNewCrawl} /> : <EmptyState showEmptyState={showEmptyState} />
  )
}

export default DashboardIndex