import React, { useContext, useEffect, useState } from 'react';
import { styled, StyledEngineProvider } from '@mui/material/styles';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  Link,
  Grid,
  SvgIcon,
  Popover,
  List,
  ListItem,
  Badge,
  Typography,
  Tooltip,
  Box,
  TextField,
  InputAdornment
} from '@mui/material';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui';
import spyderEnabledImg from '../../../assets/img/spyder-enabled.svg';
import cspmDisabledImg from '../../../assets/img/cspm-disabled.svg';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CqDialog from '../../../components/common/CqDialog';
import { colors } from '../../../assets/theme';
import { WrapperContext } from '../../../components/wrapper/wrapperContext';
import DiscoveryConfig from '../../../components/DiscoveryConfig';
import FindingsCell from '../../../components/FindingsCell';
import { useStyles } from '../styles';
import { abbrFormat, findingsSort } from '../../../lib/utils';
import { containsFilter } from '../gridHelpers/CustomFilters';
import GatewayCell from '../../../components/GatewayCell';
import { fetchCrawlInfo } from '../provider';
import { SearchContext } from '../serverHelpers';

const StyledSvg = styled(SvgIcon)({
  width: '20px',
  height: '20px',
  margin: 5,
});

const StyledHostBox = styled('div')({
  display: 'flex',
  gap: '10px',
  '& svg': {
    color: colors.moreMutedAnother,
  },
  '& p': {
    display: 'block',
  },
});

const StyledEllipsis = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

function CustomToolbar(props) {
  const { totalServersCount } = props;
  const apiRef = useGridApiContext();
  const searchcontext = useContext(SearchContext)
  const searchStr = searchcontext.searchHostStr
  const filteredRowCount = apiRef.current.getRowsCount();
  const [hostVal, setHostVal] = useState(searchStr)

  const handleHostVal = (e) => {
    setHostVal(e.target.value)
  }

  const handleHostSearch = () => {
    searchcontext.searchHost(hostVal)
  }

  const submitSearch = (e) => {
    if(e.key === 'Enter') {
      searchcontext.searchHost(hostVal)
    }
  }

  return (
    <Grid
      container
      sx={{
        height: '36px',
        border: '1px solid #333',
        paddingBottom: '8px',
        boxSizing: 'unset',
      }}
    >
      <Grid
        item
        xs={8}
        sx={{ alignItems: 'center', display: 'flex', padding: '4px' }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <TextField
            placeholder="Search Host"
            value={hostVal}
            onChange={handleHostVal}
            onKeyDown={submitSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{cursor: 'pointer'}} onClick={handleHostSearch} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{width: '300px'}}
          />
          <Typography variant='body2'>
            {Intl.NumberFormat('en-us').format(totalServersCount)} {searchcontext.hostType} Hosts
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ justifyContent: 'end', display: 'flex' }}>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Grid>
    </Grid>
  );
}

const filterHost = (val, filter) => {
  return val.serverData.hostname.includes(filter);
};

const filterIpAddresses = (val, filter) => {
  const ip = val.join(' ');
  return ip.includes(filter);
};

const columns = [
  // {
  //   headerName: '',
  //   field: 'crawlType',
  //   minWidth: '50',
  //   sortable: false,
  //   filterable: false,
  //   renderCell: () => (
  //     <>
  //       <StyledSvg
  //         component={spyderEnabledImg}
  //       />
  //       <StyledSvg
  //         component={cspmDisabledImg}
  //       />
  //     </>
  //   ),
  // },
  {
    headerName: 'API Host',
    field: 'hostname',
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) =>
      v1.serverData.hostname > v2.serverData.hostname ? 1 : -1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value.serverData.hostname}>
          <StyledEllipsis>
            <Link
              onClick={() => params.value.callback(params.value.serverData)}
              sx={{ color: colors.blue300 }}
            >
              {params.value.serverData.hostname}
            </Link>
          </StyledEllipsis>
        </Tooltip>
      );
    },
    minWidth: '250',
    filterOperators: [containsFilter(filterHost)],
  },
  {
    headerName: 'Provider',
    field: 'providers',
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) => (v1[0] > v2[0] ? 1 : -1),
    renderCell: (params) => <span>{params.value}</span>,
    minWidth: '200',
    filterable: false,
  },
  {
    headerName: 'API Gateway',
    field: 'apiGateways',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => <GatewayCell list={params.value} />,
    width: '100',
    filterable: false,
  },
  {
    headerName: 'Findings',
    field: 'findings',
    renderCell: FindingsCell,
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) => findingsSort(v1?.findings, v2?.findings),
    filterable: false,
    minWidth: '180',
  },
  {
    headerName: 'API Endpoints',
    field: 'endpoints',
    sortingOrder: ['desc', 'asc'],
    minWidth: '100',
  },
  {
    headerName: 'First Seen',
    field: 'firstSeen',
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) => (Date.parse(v1) > Date.parse(v2) ? -1 : 1),
    minWidth: '150',
  },
  {
    headerName: 'Host Type',
    field: 'isApi',
    renderCell: (params) => (
      <>
        <StyledHostBox>
          <span>{params.value.isApi ? 'API' : 'Non API'}</span>
          <span
            onClick={() => {
              const {
                endpoints,
                hostname,
                isApi,
                crawlId,
                fetchCrawlConfig
              } = params.value;
              endpoints.hostname = hostname;
              endpoints.isApi = isApi;
              fetchCrawlConfig(crawlId, endpoints)
            }}
          >
            <InfoOutlinedIcon />
          </span>
        </StyledHostBox>
      </>
    ),
    filterable: false,
    minWidth: '100',
    sortComparator: (v1, v2) => (v1.isApi > v2.isApi ? 1 : -1),
  },
  {
    headerName: 'Protocol',
    field: 'tlsInfo',
    sortingOrder: ['desc', 'asc'],
    minWidth: '100',
  },
  {
    headerName: 'CNAME / IP Addresses',
    field: 'ips',
    sortable: false,
    minWidth: '100',
    filterOperators: [containsFilter(filterIpAddresses)],
    renderCell: (params) => (
      <Tooltip title={params?.value?.join(', ') || ''}>
        <StyledEllipsis>{params?.value?.join(', ') || ''}</StyledEllipsis>
      </Tooltip>
    ),
  },
  {
    headerName: 'HTML Response Percentage',
    field: 'htmlResponse',
    sortingOrder: ['desc', 'asc'],
    minWidth: '50',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
  {
    headerName: 'XML Response Percentage',
    field: 'xmlResponse',
    sortingOrder: ['desc', 'asc'],
    minWidth: '50',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
  {
    headerName: 'JSON Response Percentage',
    field: 'jsonResponse',
    sortingOrder: ['desc', 'asc'],
    minWidth: '50',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
  {
    headerName: 'Other Response Percentage',
    field: 'otherResponse',
    sortingOrder: ['desc', 'asc'],
    minWidth: '50',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
];

const ServersTable = ({
  servers,
  routeToServerDetails,
  totalServersCount,
  filtersState,
  setFilters
}) => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const [serverData, setServerData] = useState([]);
  const [openEndpointsDialog, setOpenEndpointsDialog] = useState(false);
  const [crawlInfoLoading, setCrawlInfoLoading] = useState(false);
  const [endpointData, setEndpointData] = useState({});
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [showPopover, setIssuesPopover] = useState(null);
  const [findingsIssues, setFindingsIssues] = useState({ type: '', list: [] });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    xmlResponse: false,
    jsonResponse: false,
    otherResponse: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: filtersState.from || 0,
    pageSize: filtersState.size || 100,
  });
  
  const handlePaginationModel = (pagData) => {
    setPaginationModel(prev => ({ ...prev, page: pagData.page, pageSize: pagData.pageSize }))
    setFilters({...filtersState, from: pagData.page, size: pagData.pageSize })
  }
  const wrapperContext = useContext(WrapperContext);
  const issTypes = wrapperContext.issueTypes;
  const gatewayConfigData = wrapperContext.gatewayConfig;
  const gatewayConfigObj = {};
  for (const obj of gatewayConfigData) {
    gatewayConfigObj[obj.name] = obj;
  }

  const fetchCrawlConfig = async (crawlId, endpointInfo) => {
    try {
      setCrawlInfoLoading(true)
      setOpenEndpointsDialog(true)
      const crawlInfo = await fetchCrawlInfo(crawlId)
      if (crawlInfo?.config?.discovery) {
        endpointInfo.discovery = JSON.parse(
          Buffer.from(crawlInfo.config.discovery, 'base64').toString('utf-8')
        );
      }
      setEndpointData(endpointInfo)
      setCrawlInfoLoading(false)
    } catch(e) {
      console.log(e)
    }
  }
  

  const classes = useStyles();
  const showIssuePopover = (e, type, data, list) => {
    let showpopover = false;
    for (const iss of list) {
      if (type === iss.severity) {
        showpopover = true; // show popover if atleast one item belong to severity
        break;
      }
    }
    if (showpopover) {
      setFindingsIssues({ type: type, list: list });
      setIssuesPopover(e.target);
    }
  };

  useEffect(() => {
    let i = 0;
    const serversList = [];
    for (const server of servers) {
      const htmlResponsePercent =
        server?.endpoints?.attempted > 0
          ? (
              (server.endpoints.html / server.endpoints.attempted) *
              100
            ).toFixed(1)
          : 0;
      const xmlResponsePercent =
        server?.endpoints?.attempted > 0
          ? ((server.endpoints.xml / server.endpoints.attempted) * 100).toFixed(
              1
            )
          : 0;
      const jsonResponsePercent =
        server?.endpoints?.attempted > 0
          ? (
              (server.endpoints.json / server.endpoints.attempted) *
              100
            ).toFixed(1)
          : 0;
      const otherRes = server.endpoints.text + server.endpoints.yaml;
      const otherResponsePercent =
        server?.endpoints?.attempted > 0
          ? ((otherRes / server.endpoints.attempted) * 100).toFixed(1)
          : 0;
      const gateways = server.apiGateways || [];
      const cnameIps = server.ips
      if(server.cname){
        cnameIps.unshift(server.cname)
      }
      serversList.push({
        id: i,
        crawlType: 'spyder',
        hostname: { serverData: server, callback: routeToServerDetails },
        providers: server.providers.join(', '),
        firstSeen: server.firstSeen,
        findings: {
          findings: server.findings,
          issues: server?.findings?.issues,
          callback: showIssuePopover,
        },
        isApi: {
          isApi: server.isApi,
          hostname: server.hostname,
          endpoints: server.endpoints,
          crawlId: server.crawlId,
          fetchCrawlConfig: fetchCrawlConfig
        },
        endpoints: server.endpoints.attempted,
        tlsInfo: server?.tlsInfo?.protocol,
        ips: cnameIps,
        htmlResponse: htmlResponsePercent,
        xmlResponse: xmlResponsePercent,
        jsonResponse: jsonResponsePercent,
        otherResponse: otherResponsePercent,
        apiGateways: gateways
          .map((val) => gatewayConfigObj?.[val]?.displayName || '')
          .join(','),
      });
      i++;
    }
    setServerData(serversList);
  }, [servers]);

  const data = {
    rows: serverData,
    columns: columns,
  };

  const getSeverityClass = (severity) => {
    if (severity == 'HIGH') {
      return classes.severityHighLabel;
    }
    if (severity == 'MEDIUM') {
      return classes.severityMediumLabel;
    }
    if (severity == 'INFO') {
      return classes.severityLowLabel;
    }
  };

  return (
    <Box style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
      <DataGridPremium
        {...data}
        headerHeight={36}
        disableSelectionOnClick
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            count: data.rows.length,
            totalServersCount: totalServersCount,
          },
          pagination: {
            labelDisplayedRows: function defaultLabelDisplayedRows(obj) {
              let label = ''
              if(obj.count > 0 && paginationModel.pageSize > 0){
                const pageCount = Math.round(obj.count/paginationModel.pageSize)
                label = `Page ${obj.page+1} of ${Intl.NumberFormat('en-us').format(pageCount+1)}`
              }
              return label
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: paginationModel,
          },
          rows: [],
          columns: columns
        }}
        pagination
        rowCount={totalServersCount}
        pageSizeOptions={[10, 50, 100]}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        disableRowGrouping
        sx={dataGridStyles}
        disableAggregation
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModel}
      />
      <CqDialog
        title={`${endpointData.hostname}`}
        open={openEndpointsDialog}
        onClose={() => {
          setOpenEndpointsDialog(false);
          setTimeout(() => {
            setEndpointData({ endpointData });
          }, 100);
        }}
        maxWidth="lg"
        closeIcon={true}
        showSubmitBtn={false}
        showCloseBtn={false}
        backdrop={crawlInfoLoading}
      >
        <DiscoveryConfig
          discoveryConfig={endpointData.discovery}
          endpointData={endpointData}
          selectedServerHost={endpointData.hostname}
          discoveryConfigResult={endpointData.isApi}
        />
      </CqDialog>
      <Popover
        open={Boolean(showPopover)}
        anchorEl={showPopover}
        elevation={0}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setFindingsIssues({ type: '', list: [] });
          setIssuesPopover(null);
        }}
      >
        <List className={classes.popMenuList}>
          {findingsIssues.list.map((iss) => {
            if (findingsIssues.type === iss.severity) {
              const badgeClass =
                iss.severity === 'HIGH'
                  ? classes.alertBadge
                  : iss.severity === 'MEDIUM'
                  ? classes.warningBadge
                  : classes.infoBadge;
              return (
                <ListItem key={iss.name} className={classes.popMenuListItem}>
                  <Typography
                    variant="subtitle2"
                    className={getSeverityClass(iss.severity)}
                  >
                    {issTypes?.[iss.name]?.description}
                    <Badge
                      badgeContent={iss.value}
                      classes={{ badge: badgeClass }}
                      style={{ position: 'relative', marginLeft: 14 }}
                      invisible={false}
                    ></Badge>
                  </Typography>
                </ListItem>
              );
            }
          })}
        </List>
      </Popover>
    </Box>
  );
};

export default ServersTable;
