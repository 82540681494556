import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import Content from './components/wrapper/Content';
import CqGlobalStyles from './components/CqGlobalStyles';
import { useGlobalState } from './store/globalState';
import theme, { colors } from './assets/theme';
import './App.css';
import * as am4core from '@amcharts/amcharts4/core';
import { graphqlQuery } from './services/graphql';
import { getLicensesInfoQuery } from './pages/licenses/licensesGraphQL';
import { Helmet } from 'react-helmet-async';

import SpSnackBar from './components/SpSnackBar';
import { hideNavMenu } from './services/auth';

am4core.addLicense('CH200312631065154');

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: colors.darkerGray,
    padding: hideNavMenu() ? 0 : '24px'
  },
}));

const SessionTimedout = () => (
  <p>
    Session timed out. Please <a href="/">Click here</a> to login!
  </p>
);

const App = ({ authObj = {} }) => {
  const classes = useStyles();
  const global = useGlobalState();

  useEffect(() => {
    if (authObj.user?.userId) {
      getLicenseInfo();
    }
  }, [authObj.user]);

  const getLicenseInfo = async () => {
    try {
      let response = await graphqlQuery(getLicensesInfoQuery);
      const data = response.data ? response.data.license : {};
      global.merge({ licenseInfo: data });
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  const handleChangeClientState = () => {
    try {
      //
    } catch (e) {}
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CqGlobalStyles />
          <CssBaseline />
          <>
            <Router>
              <Container className={classes.container}>
                <Helmet onChangeClientState={handleChangeClientState}></Helmet>
                {!authObj.isLoading && (
                  <Content isAuthenticated={authObj.isLoggedIn} authUser={authObj.user} />
                )}
              </Container>
            </Router>
            <SpSnackBar
              isOpen={authObj.showSessionMess}
              autoHideDuration={100000}
              notifications={[
                {
                  id: '1',
                  message: <SessionTimedout />,
                  category: 'error',
                },
              ]}
              onNotificationDelete={() => authObj.setShowSessionMess(false)}
            />
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
