import React, { useState } from 'react';
import { graphqlMutation } from '../../services/graphql';

import { TestConnectivity } from './graphQueries';

import { styled } from '@mui/material/styles';
import { InputLabel, TextField, Grid, Typography, Select } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { colors } from '../../assets/theme';
import CqDialog from '../../components/common/CqDialog';
import CqMenuItem from '../../components/common/CqMenuItem';

const FormContainer = styled('div')({
  backgroundColor: colors.almostBlack,
  padding: '8px',
});

const FormRow = styled('div')({
  padding: '8px 0',
});

const FlexRow = styled('div')({
  display: 'flex',
  gap: '28px',
  alignItems: 'center',
  padding: '8px 0',
});
const FlexColumn = styled('div')({
  width: '50%',
  lineHeight: 1,
});

const Textlabel = styled(InputLabel)({
  paddingBottom: '4px',
  color: colors.gray300,
  fontSize: 12,
  lineHeight: '16px',
});

const Textbox = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '32px',
  },
});

const StyledConnection = styled('div')({
  width: '100%',
  height: '38px',
  backgroundColor: colors.green800,
  color: colors.white,
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  gap: '4px',
});

const NewIntegration = (props) => {
  const initState = {
    name: '',
    type: 'AWS',
    accessKeyId: '',
    secretAccessKey: '',
  };
  const [formData, setFormData] = useState(initState);
  const [testConnectionStatus, setTestConnectionStatus] = useState(false);
  const [testConnectionMsg, setTestConnectionMsg] = useState();
  const { id, open, handleClose, handleSubmit } = props;

  const handleNameChange = (e) => {
    setFormData((state) => ({ ...state, name: e.target.value }));
  };
  const handleTypeChange = (e) => {
    setFormData((state) => ({ ...state, type: e.target.value }));
  };
  const handleAccessKeyIdChange = (e) => {
    setFormData((state) => ({ ...state, accessKeyId: e.target.value }));
  };
  const handleSecretAccessKeyChange = (e) => {
    setFormData((state) => ({ ...state, secretAccessKey: e.target.value }));
  };
  const resetState = () => {
    setFormData(initState);
    setTestConnectionStatus(false);
  };
  const submitForm = () => {
    handleSubmit(formData, resetState);
    setFormData(initState);
  };
  const checkConnection = async () => {
    try {
      const res = await graphqlMutation(TestConnectivity, {
        type: formData.type,
        config: {
          accessKeyId: formData.accessKeyId,
          secretAccessKey: formData.secretAccessKey,
        },
      });
      const data = res.data.cspm.testConnectivity;
      setTestConnectionStatus(data.status !== 'ERROR');
      setTestConnectionMsg(data.message);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CqDialog
      title={id ? `Edit CSPM Integration` : 'New CSPM Integration'}
      open={open}
      onClose={handleClose}
      onSubmit={submitForm}
      maxWidth="md"
      showCloseBtn={false}
      showExtraBtn
      submitBtnText="Add Integration"
      extraBtnText="Test Connection"
      onExtraBtnClick={checkConnection}
    >
      <FormContainer>
        <FormRow>
          <Grid>
            <Textlabel>Name your Integration</Textlabel>
            <Textbox
              fullWidth
              variant="outlined"
              onChange={handleNameChange}
              value={formData.name}
            />
          </Grid>
        </FormRow>
        <FormRow>
          <Grid>
            <Textlabel>Integration Type</Textlabel>
            <Select
              value={formData.type}
              sx={{ width: '100%', height: '35px' }}
              onChange={handleTypeChange}
            >
              <CqMenuItem value="AWS">AWS</CqMenuItem>
              <CqMenuItem value="AZURE">Azure</CqMenuItem>
              <CqMenuItem value="GCP">GCP</CqMenuItem>
            </Select>
          </Grid>
        </FormRow>
        <FlexRow>
          <FlexColumn>
            <Textlabel>Access Key Id</Textlabel>
            <Textbox
              fullWidth
              variant="outlined"
              onChange={handleAccessKeyIdChange}
              value={formData.accessKeyId}
            />
            <Typography variant="caption">
              Read only credentials should be provided. These keys are stored
              securely in our vault.
            </Typography>
          </FlexColumn>
          <FlexColumn>
            <Textlabel>Secret Access Key</Textlabel>
            <Textbox
              fullWidth
              variant="outlined"
              onChange={handleSecretAccessKeyChange}
              value={formData.secretAccessKey}
            />
            <Typography variant="caption">
              Read only credentials should be provided. These keys are stored
              securely in our vault.
            </Typography>
          </FlexColumn>
        </FlexRow>
        <FormRow>
          {testConnectionStatus && (
            <StyledConnection>
              <CheckCircleIcon sx={{ fill: colors.green100 }} />
              <Typography variant="subtitle-2">{testConnectionMsg}</Typography>
            </StyledConnection>
          )}
        </FormRow>
      </FormContainer>
    </CqDialog>
  );
};

export default NewIntegration;
