export const getUsersQuery = `
  query GetUsers {
    users {
      list {
        status
        message
        users {
          id
          email
          name
          status
          saml
          createdAt
          lastLogin
        }
      }
    }
  }
`;

export const getUserInfoQuery = `
  query GetUserInfo {
    users {
      get(email:"abraham@stealthsec.com") {
        status
        message
        user {
          id
          email
          name
          status
          saml
          createdAt
          lastLogin
        }
      }
    }
  }
`;

export const addUserQuery = `
  mutation AddUser($addUserInfo: AddUserInput!) {
    users {
      addUser(user:$addUserInfo) {
        status
        message
        user {
          email
          firstName
          lastName
          status
          lastLogin
          createdAt
        }
      }
    }
  }
`;

export const updateUserQuery = `
  mutation UpdateUser($updateUserEmail: String!, $updateUserInfo: UpdateUserInput!) {
    users {
      updateUser(email: $updateUserEmail, user:$updateUserInfo) {
        status
        message
        user {
          email
          firstName
          lastName
          status
          lastLogin
          createdAt
        }
      }
    }
  }
`;

export const disableUserQuery = `
  mutation DisableUser($email: String!) {
    users {
      disableUser(email: $email) {
        status
        message
        email
      }
    }
  }
`;

export const enableUserQuery = `
  mutation EnableUser($email: String!) {
    users {
      enableUser(email: $email) {
        status
        message
        email
      }
    }
  }
`;

export const removeUserQuery = `
  mutation RemoveUser($email: String!) {
    users {
      removeUser(email: $email) {
        status
        message
        email
      }
    }
  }
`;

export const requestChangePasswordEmailQuery = `
  mutation RequestChangePasswordEmail($email: String!) {
    users {
      requestChangePasswordEmail(email: $email) {
        status
        message
        email
      }
    }
  }
`;

export const getClientsList = `
  query GetClientsList {
    users {
      clientsList {
        data {
          clientName
          expiration
          clientId
          createdBy
          createdDate
          status
          id
        }
      }
    }
  }
`;

export const createNewClient = `
  mutation CreateClient ($clientName: String!, $expiration: Long!) {
    users {
      createClient(clientName: $clientName, expiration: $expiration) {
        clientName
        expiration
        clientid
        clientSecret
      }
    }
  }
`

export const deleteClient = `
  mutation deleteClient ($id: String!) {
    users {
      deleteClient(id: $id) {
        message
      }
    }
  }
`

export const getSsoConfig = `
  query getSsoConfig {
    users {
      getSsoConfig {
        tenantId
        idpEntityId
        idpSSOUrl
        idpCertificate
        idpMetadataUrl
        spEntityId
        spACSUrl
        spCertificate
        redirectUrl
        domain
      }
    }
  }
`
