import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import {
  TableCell,
  TableRow,
  Typography,
  TextField,
  Grid,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SyncIcon from '@mui/icons-material/Sync';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import SettingsIcon from '@mui/icons-material/Settings';

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Switch } from '@mui/base';
import { graphqlQuery } from '../../services/graphql';
import { getCrawlInfo } from './domainGraphQL';
import CqButton from '../../components/common/CqButton';
import CrawlPercent from '../../components/CrawlPercent'
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import TextWithIcon from '../../components/common/TextWithIcon';
import { Root } from '../../components/SwitchRoot';
import { colors } from '../../assets/theme';

const StyledTableDiv = styled('div')(() => ({
  width: '316px',
  marginRight: '14px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '8px',
  '&:hover': {
    backgroundColor: colors.default,
  },
  height: '248px',
  position: 'relative',
}));
const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: colors.gray850,
}));
const StyledSpinner = styled(TableCell)(() => ({
  '& .MuiCircularProgress-root': {
    marginLeft: '40%',
  },
  height: '200px',
}));


const StyledTextWithIcon = styled(TextWithIcon)(({ auto }) => ({
  color: auto ? '#66B391' : colors.gray100,
  border: `1px solid ${auto ? colors.green200 : colors.gray100}`,
  borderRadius: '16px',
  padding: '6px',
  width: 'fit-content',
  '& p': {
    color: auto ? colors.green200 : colors.gray100,
  },
}));

const StyledScanDiv = styled('div')(() => ({
  borderTop: `2px solid ${colors.darkLabelText}`,
  paddingTop: '8px',
}));

const StyledEdit = styled('span')(() => ({
  top: '4px',
  right: '4px',
  position: 'absolute',
  cursor: 'pointer',
  color: colors.blue300,
}));

const StyledLink = styled('span')(() => ({
  cursor: 'pointer',
  color: colors.blue300,
}));

const StyledScanContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

const StyledMiddleItem = styled(Grid)({
  // borderLeft: `1px solid ${colors.gray300}`,
  // borderRight: `1px solid ${colors.gray300}`,
  paddingLeft: '14px',
  marginRight: '14px',
});

const StyledButton = styled(CqButton)({
  width: '115px',
});

const ExpandedRow = ({ config, setConfigDialogStatus, domain }) => {
  const { scheduleEnabled, log4jScan, proxy } = config;
  const newCrawlContext = useContext(WrapperContext);
  const [schedulerEdit, enableSchedulerEdit] = useState(false);
  const [proxyEdit, enableProxyEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [crawlData, setCrawlInfo] = useState({
    submittedAt: 0,
    crawlPercent: 0,
    activeSubdomains: 0,
    totalSubdomains: 0,
  });
  useEffect(async () => {
    setLoading(true);
    try {
      const res = await graphqlQuery(getCrawlInfo, { domain: domain.domain });
      const crawlInfo = res?.data?.crawl?.getCrawlInfo || {};
      const { activeSubdomains, totalSubdomains, submittedAt } = crawlInfo;
      const data = { ...crawlData };
      data.submittedAt = submittedAt;
      data.activeSubdomains = activeSubdomains;
      data.totalSubdomains = totalSubdomains;
      if (activeSubdomains > 0 && totalSubdomains > 0) {
        data.crawlPercent = Math.round(
          (activeSubdomains / totalSubdomains) * 100
        );
      }
      setCrawlInfo(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);
  const showEditScheduler = () => {
    enableSchedulerEdit(true);
  };
  const showEditProxy = () => {
    enableProxyEdit(true);
  };
  const hideEditScheduler = () => {
    enableSchedulerEdit(false);
  };
  const hideEditProxy = () => {
    enableProxyEdit(false);
  };
  const openScheduler = () => {
    setConfigDialogStatus((state) => ({
      ...state,
      domain: domain,
      open: true,
      edit: { ...state.edit, scheduler: true },
    }));
  };
  const openProxy = () => {
    setConfigDialogStatus((state) => ({
      ...state,
      domain: domain,
      open: true,
      edit: { ...state.edit, proxy: true },
    }));
  };
  const openScan = () => {
    setConfigDialogStatus((state) => ({
      ...state,
      domain: domain,
      open: true,
      edit: { ...state.edit, scan: true },
    }));
  };
  const handleNewCrawl = () => {
    newCrawlContext.showNewCrawl(domain.domain, config);
  };
  
  return (
    <StyledTableRow>
      {loading && (
        <StyledSpinner colSpan={6}>
          <CircularProgress />
        </StyledSpinner>
      )}
      {!loading && (
        <TableCell colSpan={6}>
          <Grid container>
            <Grid item>
              <StyledTableDiv>
                {crawlData.crawlPercent > 0 ? (
                  <CrawlPercent crawlData={crawlData} />
                ) : (
                  'Crawl Info not available'
                )}
                <StyledButton variant="borderBlue" onClick={handleNewCrawl}>
                  <PublishedWithChangesIcon />
                  <span>Crawl Now</span>
                </StyledButton>
              </StyledTableDiv>
            </Grid>
            <StyledMiddleItem item>
              <StyledTableDiv
                onMouseEnter={showEditScheduler}
                onMouseLeave={hideEditScheduler}
              >
                {schedulerEdit && (
                  <StyledEdit onClick={openScheduler}>
                    <EditIcon />
                  </StyledEdit>
                )}
                <TextWithIcon>
                  <ScheduleIcon />
                  <Typography variant="body2">Crawl Schedule</Typography>
                </TextWithIcon>
                <StyledTextWithIcon auto={config.scheduleEnabled}>
                  <SyncIcon />
                  <Typography variant="body2">
                    {scheduleEnabled ? 'Automatic' : 'Manual'}
                  </Typography>
                </StyledTextWithIcon>
                <Typography variant="body2">Next Scheduled Crawl </Typography>
                <TextField
                  disabled
                  size="small"
                  variant="outlined"
                  value={
                    config?.schedule?.nextSchedule
                      ? moment(config?.schedule?.nextSchedule).format(
                          'MM/DD/YYYY [at] ha Z'
                        )
                      : '-'
                  }
                />
                <StyledScanDiv>
                  <TextWithIcon>
                    <WifiTetheringIcon />
                    <Typography variant="body2">Advanced Crawl</Typography>
                  </TextWithIcon>
                  <StyledScanContainer>
                    <Switch
                      component={Root}
                      checked={log4jScan}
                      disabled
                    ></Switch>
                    {schedulerEdit && (
                      <StyledLink onClick={openScan}>
                        <EditIcon />
                      </StyledLink>
                    )}
                  </StyledScanContainer>
                </StyledScanDiv>
              </StyledTableDiv>
            </StyledMiddleItem>
            <Grid item>
              <StyledTableDiv
                onMouseEnter={showEditProxy}
                onMouseLeave={hideEditProxy}
              >
                {proxyEdit && (
                  <StyledEdit onClick={openProxy}>
                    <EditIcon />
                  </StyledEdit>
                )}
                <TextWithIcon>
                  <SettingsIcon />
                  <Typography variant="body2">Proxy URL</Typography>
                </TextWithIcon>
                <TextField
                  size="small"
                  variant="outlined"
                  value={proxy || ''}
                  disabled
                />
              </StyledTableDiv>
            </Grid>
          </Grid>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default ExpandedRow;
