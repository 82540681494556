import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Typography } from '@mui/material';

import { colors } from '../../../assets/theme';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LaunchIcon from '@mui/icons-material/Launch';
import CircleIcon from '@mui/icons-material/Circle';
import Log4jVulnerabilitiesTable from './Log4jVulnerabilitiesTable';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: colors.gray700,
  borderBottom: `2px solid ${colors.gray500}`,
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', color: colors.gray100 }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: colors.gray700,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
    marginLeft: theme.spacing(1),
    fontFamily: 'Noto Sans',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
  {
    name: 'API Endpoint',
  },
  {
    name: 'Method',
  },
  {
    name: 'Log4j/LoNg4j',
  },
];

const Log4jEndpointsDetails = ({ vulnerabilityServers, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false,
  };
  const [expand, setExpand] = useState(expandSection);
  useEffect(() => {
    setExpand({
      des: expanded,
      risk: expanded,
      ref: expanded,
      rec: expanded,
    });
  }, [expanded]);
  const handleChange = (panel) => () => {
    setExpand({ ...expand, [panel]: !expand[panel] });
  };
  return (
    <>
      <Grid style={{ marginTop: 20 }}>
        <Accordion expanded={expand.des} onChange={handleChange('des')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>
              Description
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body2'}>
              Log4Shell is a high severity vulnerability (CVE-2021-44228, CVSSv3
              10.0) impacting multiple versions of the
              <Link
                target={'_blank'}
                style={{
                  color: colors.blueButtonBg,
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
              >
                Apache Log4j 2
                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
              </Link>{' '}
              utility. It was disclosed publicly via the project's GitHub on
              December 9, 2021. This vulnerability impacts Apache Log4j 2
              versions 2.0 to 2.14.1. This vulnerability allows for
              unauthenticated remote code execution. Log4j is an open-source
              Java logging library and is widely used in many enterprise
              applications and numerous cloud services. LoNg4j is a variation of
              the Log4Shell vulnerability discovered by Cequence Security that
              extends the vulnerability finding across the broader digital
              supply chain of the organization. LoNg4j discovers the
              vulnerability in open-source software, 3rd party software and
              API-driven cloud software services that are linked to the
              organization's servers.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>
              Risk
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body2'}>
              This vulnerability is exploitable via a multitude of application
              specific methods. Any scenario that allows a remote connection to
              supply arbitrary data that is written to log files by an
              application utilizing the Log4j library is susceptible to
              exploitation.
              <b>
                This vulnerability poses a significant and active real-world
                risk to affected systems - PLEASE TAKE IMMEDIATE ACTION.
              </b>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>
              Recommendation
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body2'}>
              Upgrading to the patched versions of Log4j 2 or impacted
              applications will eliminate this vulnerability. The corrected
              version of this code is now available as Log4j 2.17.1 (Java 8),
              2.12.4 (Java 7), and 2.3.2 (Java 6). Additional mitigation and
              risk information is available from Apache.org:
              <Link
                target={'_blank'}
                href={'https://logging.apache.org/log4j/2.x/security.html'}
                style={{
                  color: colors.blueButtonBg,
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
              >
                https://logging.apache.org/log4j/2.x/security.html
                <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
              </Link>
              . Cequence recommends that you follow official CISA guidance to
              urgently remediate this.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>
              References
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  Apache:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    JNDI Lookup plugin support
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  LunaSec blog: Guide:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    How To Detect nd Mitigate the Log4Shell Vulnerability
                    (CVE-2021-44228 & CVE-2021-45046
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  NIST:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    CVE-2021-44228
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  NIST:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    CVE-2021-45046
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  NIST:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    CVE-2021-4104
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon
                  sx={{ color: colors.white, fontSize: 6, marginRight: 2 }}
                />
                <Typography variant={'body2'}>
                  NIST:
                  <Link
                    target={'_blank'}
                    style={{
                      color: colors.blueButtonBg,
                      marginLeft: 5,
                      cursor: 'pointer',
                    }}
                  >
                    CVE-2021-45105
                    <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                  </Link>
                </Typography>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item container style={{ marginTop: 20 }}>
        <Typography>API Endpoints with Log4j/LoNg4j Vulnerabilities</Typography>
        <Log4jVulnerabilitiesTable
          columns={exposedFilesHeaderColumns}
          vulnerabilityServers={vulnerabilityServers}
        />
      </Grid>
    </>
  );
};

export default Log4jEndpointsDetails;
