import React from "react";
import { colors } from "../../../assets/theme";
import CqChip from "../../../components/common/CqChip";
import TextWithIcon from "../../../components/common/TextWithIcon";
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from "@mui/material";

const StatusChip = ({pass}) => {
  const statusColor = pass ? colors.green200 : colors.red300;
  return (
    <CqChip color={statusColor} borderRadius={16}>
      <TextWithIcon>
        {pass ? (
          <CheckIcon
            sx={{
              color: statusColor,
              fontSize: 15,
              width: '20px',
            }}
          />
        ) : (
          <BlockIcon
            sx={{
              color: statusColor,
              fontSize: 15,
              width: '20px',
            }}
          />
        )}
        <Typography
          variant="caption"
          style={{ color: statusColor }}
        >
          {pass ? 'Passed' : 'Failed'}
        </Typography>
      </TextWithIcon>
    </CqChip>)
}

export default StatusChip