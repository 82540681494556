import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import CqLoading from '../../components/common/CqLoading';
import NotificationsTable from './NotificationsTable';
import Filters from './Filters/Filters';

import { graphqlQuery } from '../../services/graphql';
import { getNotificationsQuery, getDomainsQuery } from './notificationGraphQL';
import { colors } from '../../assets/theme';
import { useFiltersState, useGlobalState } from '../../store/globalState';
import { nowDate, daysAgoTimestamp } from '../../lib/utils';
import { timeRangeFilters, severityFilters } from './constants';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  dialogInfo: {
    color: colors.white,
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '20px',
  },
}));

const TABLE_HEADER_COLUMNS = [
  {
    name: 'Date and Time',
    id: 'timestamp',
    sortable: true,
    sortType: 'numeric',
  },
  {
    name: 'Notifications',
    id: 'severity',
    sortable: false,
  },
  {
    name: 'Risk Level',
    id: 'message',
    sortable: true,
    sortType: 'string',
  },
];

const timeRangeMapping = {
  'Last Week': 7,
  'Last Day': 1,
  'Last 30 days': 30,
};

const NotificationsPage = ({domains}) => {
  const classes = useStyles();
  const location = useLocation();
  const global = useGlobalState();
  const globalFilters = useFiltersState();
  const filters = globalFilters.get();

  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [params, setParams] = useState({domains: [], severity: [], timeRange: []})

  useEffect(async() => {
    setLoading(true);
    try {
      let response = await graphqlQuery(getDomainsQuery, {}, global);
      const data = response.data ? response.data.domain.list : [];
      let activeDomains = domains.map((i) => ({
        name: i.domain,
        selected: false,
      }));

      if (location?.state?.selectedDomain) {
        activeDomains = activeDomains.map((i) =>
          i.name === location.state.selectedDomain
            ? { ...i, selected: true }
            : { ...i, selected: false }
        );
      }
      if (isEmpty(filters.notificationFilters.severity)) {
        globalFilters['notificationFilters'].severity.set([...severityFilters]);
      }
      if (isEmpty(filters.notificationFilters.timeRange)) {
        globalFilters['notificationFilters'].timeRange.set([
          ...timeRangeFilters,
        ]);
      }
      if (isEmpty(filters.notificationFilters.domains)) {
        globalFilters['notificationFilters'].domains.set([...activeDomains]);
      }
      setParams(val => ({...val, domains: activeDomains, severity: severityFilters, timeRange: timeRangeFilters}))
      const notifications = await retrieveNotificationList({notificationFilters:{
        domains: activeDomains,
        severity: severityFilters,
        timeRange: timeRangeFilters
      }})
      setNotificationList(notifications);
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }, []);

  

  const retrieveNotificationList = async (data) => {
    let selectedDomains = [];
    let selectedSeverity = [];
    let selectedTimeRange;
    let params = {};

    if (data) {
      data.notificationFilters.domains.filter(
        (d) => d.selected === true && selectedDomains.push(d.name)
      );
      data.notificationFilters.severity.filter(
        (d) => d.selected === true && selectedSeverity.push(d.name)
      );
      data.notificationFilters.timeRange.filter((t) => {
        if (t.selected === true) {
          selectedTimeRange = timeRangeMapping[t.name];
        }
      });
      params = {
        selectedDomains: selectedDomains,
        selectedSeverity: selectedSeverity,
        startTime: daysAgoTimestamp(selectedTimeRange),
        endTime: nowDate(),
      };
    }

    try {
      const response = await graphqlQuery(getNotificationsQuery, params);
      const notificationList = response.data.notification.list ?? [];
      return notificationList;
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  const updateFiltersState = () => {
    globalFilters['notificationFilters'].domains.set([...params.domains]);
    globalFilters['notificationFilters'].severity.set([...params.severity]);
    globalFilters['notificationFilters'].timeRange.set([...params.timeRange]);
    setLoading(true);
    retrieveNotificationList({notificationFilters: params})
      .then((result) => {
        setNotificationList(result);
      })
      .finally(() => setLoading(false));
  };

  const resetFilters = () => {
    const domains = activeDomains.map(item => {
      item.selected = false
      return item
    });
    globalFilters['notificationFilters'].domains.set([...domains]);
    globalFilters['notificationFilters'].severity.set([...severityFilters]);
    globalFilters['notificationFilters'].timeRange.set([...timeRangeFilters]);
    const data = {
      notificationFilters: {
        domains: domains,
        severity: severityFilters,
        timeRange: timeRangeFilters,
      },
    };
    setLoading(true)
    retrieveNotificationList(data)
    .then((result) => {
      setNotificationList(result);
    })
    .finally(() => setLoading(false));
  };

  return (
    <div className={classes.pageContent}>
      <Grid
        item
        container
        className={classes.pageHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4">Notifications</Typography>
        </Grid>
      </Grid>

      {loading && <CqLoading />}
      {!loading && (
        <Grid container>
          <Grid item container xs={12}>
            <Filters
              setFilters={setParams}
              clearFilters={resetFilters}
              filters={params}
              updateNotifications={updateFiltersState}
            />
          </Grid>
          <Grid item container style={{ marginTop: 20 }}>
            <NotificationsTable
              notifications={notificationList}
              columns={TABLE_HEADER_COLUMNS}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default NotificationsPage;
