import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Grid,
  
 } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  StyledContainer,
  StyledButtonContainer,
  StaticInput,
  StaticEdit,
  StaticClip,
} from '../styles';
import { updateTenantUserAgent } from '../provider';
import CqInput from '../../../components/common/CqInput';
import CqButton from '../../../components/common/CqButton';
import TextWithIcon from '../../../components/common/TextWithIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { DiscoveryContext } from '../DiscoveryContext';
import CrawlRegion from './CrawlRegion';
import CrawlGateway from './CrawlGateway';

const UserAgentEdit = ({ saveUserAgent, userAgent, loading }) => {
  const [userAgentEdit, setUserAgentEdit] = useState('');
  useEffect(() => {
    setUserAgentEdit(userAgent);
  }, [userAgent]);

  const handleUserAgentEdit = (val) => {
    setUserAgentEdit(val);
  };
  const handleSubmit = () => {
    saveUserAgent(userAgentEdit);
  };
  return (
    <>
      <CqInput onChange={handleUserAgentEdit} value={userAgentEdit} />
      <StyledButtonContainer>
        <CqButton
          variant="primary"
          onClick={handleSubmit}
          disabled={
            userAgentEdit === userAgent || userAgentEdit === '' || loading
          }
        >
          <TextWithIcon>
            {loading ? '...Saving' : <CheckOutlinedIcon />}
            {!loading && <span>Save</span>}
          </TextWithIcon>
        </CqButton>
      </StyledButtonContainer>
    </>
  );
};

const GeneralConfig = ({ tenantConfigurations, setTenantConfigurations }) => {
  const [userAgent, setUserAgent] = useState( tenantConfigurations?.crawlConfig?.userAgent || '');
  const [showEdit, showEditField] = useState(false);
  const [loading, setLoading] = useState(false);
  const discoverContext = useContext(DiscoveryContext);

  const handleEdit = () => {
    showEditField((val) => !val);
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(userAgent);
      discoverContext.setNotify({
        open: true,
        message: 'User Agent copied!',
        success: true,
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  const saveUserAgent = (val) => {
    setLoading(true);
    updateTenantUserAgent(val)
      .then((res) => {
        const ua =
          res?.data?.updateTenantConfig?.config?.crawlConfig?.userAgent || '';
        const configData = res?.data?.updateTenantConfig?.config;
        setUserAgent(ua);
        setTenantConfigurations(configData);
        discoverContext.setNotify({
          open: true,
          message: 'Saved User Agent!',
          success: true,
        });
        showEditField(false);
      })
      .catch(() => {
        discoverContext.setNotify({
          open: true,
          message: 'Some thing went wrong please try again!',
          success: false,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <StyledContainer container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">User Agent String</Typography>
          <Typography variant="caption">
            Copy or edit your user agent string for API Discovery
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption">User Agent String</Typography>
          {!showEdit && (
            <StaticInput>
              <Typography variant="body2">{userAgent}</Typography>
              <StaticEdit onClick={handleEdit}>
                <EditOutlinedIcon />
              </StaticEdit>
              <StaticClip onClick={handleCopy}>
                <ContentCopyOutlinedIcon />
              </StaticClip>
            </StaticInput>
          )}
          {showEdit && (
            <UserAgentEdit
              loading={loading}
              saveUserAgent={saveUserAgent}
              userAgent={userAgent}
            />
          )}
        </Grid>
      </StyledContainer>
      {/* <CrawlRegion globalConfig={tenantConfigurations?.globalConfig || {}} setTenantConfigurations={setTenantConfigurations} /> */}
      <CrawlGateway />
    </>
  );
};

export default GeneralConfig;
