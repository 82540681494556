import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const PREFIX = 'CQTooltip';
const classes = {
  root: `${PREFIX}-root`,
};
const Root = styled(Tooltip)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // fontSize: "1rem",
    maxWidth: 300,
  },
}));

const CustomTooltip = (props) => {
  return <Root className={classes.root} {...props} />;
};

export default CustomTooltip;
