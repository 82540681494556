import React from 'react';

import TextWithIcon from '../../../components/common/TextWithIcon';
import { StyledCount } from '../styles';
import { colors } from '../../../assets/theme';

const Regions = ({ list }) => {
  let region = '-';
  let showIcon = false;
  if (list.length > 0) {
    region = list[0];
  }
  if (list.length > 1) {
    showIcon = true;
  }
  return (
    <TextWithIcon>
      {region !== '' && <span>{region}</span>}
      {showIcon && (
        <StyledCount color={colors.gray300}>+{list.length - 1}</StyledCount>
      )}
    </TextWithIcon>
  );
};

export default Regions;
