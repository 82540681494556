import React from "react";
import { StyledBadge } from "./StyledBadge";
import { abbrFormat } from "../lib/utils";

const emptyFunction = () => {}

const FindingsCell = (params) => {
  const findings = params?.value?.findings;
  const issues = params?.value?.issues || []
  const callback = params?.value?.callback || emptyFunction
  return findings && findings.total ? 
    (<div style={{display: 'flex', gap: '4px'}}>
      <div>
        {findings.high > 0 && <StyledBadge type='error' onClick={(e) => callback(e, 'HIGH', params.row, issues)}>
          {abbrFormat(findings.high)}
        </StyledBadge>}
      </div>
      <div>
        {findings.medium > 0 && <StyledBadge type='warning' onClick={(e) => callback(e, 'MEDIUM', params.row, issues)}>
          {abbrFormat(findings.medium)}
        </StyledBadge>}
      </div>
      <div>
        {findings.info > 0 && <StyledBadge type='info' onClick={(e) => callback(e, 'INFO', params.row, issues)}>
          {abbrFormat(findings.info)}
        </StyledBadge>}
      </div>
    </div>) : !findings ? '--' : <div onClick={(e) => callback(e, 'INFO', params.row, issues)}>0</div>
}

export default FindingsCell;