import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(1),
  },
  childContainer: {
    marginTop: '12px',
  },
  domainContainer: {
    backgroundColor: colors.gray750,
    border: `1px solid ${colors.gray700}`,
  },
  subTitleContainer: {
    marginBottom: 12,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  overviewContainer: {
    backgroundColor: colors.gray700,
    marginTop: 20,
    borderRadius: 5,
  },
  overviewContent: {
    padding: theme.spacing(1),
    backgroundColor: colors.gray700,
    borderRadius: 5,
    color: colors.white,
  },
  issuesContainer: {
    padding: theme.spacing(1),
    borderRadius: 5,
    color: colors.white,
  },
  highLevelIssue: {
    backgroundColor: colors.red800,
  },
  mediumLevelIssue: {
    backgroundColor: colors.orange900,
  },
  infoLevelIssue: {
    backgroundColor: colors.gray700,
  },
  trendOverview: {
    backgroundColor: colors.darkGray1,
    borderRadius: 5,
    padding: 5,
    alignItems: 'center',
    color: colors.white,
  },
  divider: {
    backgroundColor: colors.white,
    marginTop: 10,
    width: '100%',
  },
  triggerPopoverPaper: {
    minWidth: 100,
    maxWidth: 200,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    backgroundColor: colors.almostBlack,
  },
  triggerPopoverRoot: {
    maxHeight: '50vh',
    marginTop: theme.spacing(1),
    pointerEvents: 'none',
  },
  notificationsLink: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    marginTop: 10,
    fontSize: 14,
    fontWeight: 500,
  },
  link: {
    color: colors.blueButtonBg,
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    paddingLeft: 10,
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& caption': {
        captionSide: 'top'
      },
      '& thead > tr th': {
        backgroundColor: colors.gray800,
        padding: '6px 24px 6px 0px',
        '&:nth-of-type(2)': {
          padding: '6px 24px 6px 0px',
        },
      },

      '& tbody tr': {
        borderBottom: `1px solid ${colors.gray650}`,
        '& td': {
          backgroundColor: colors.darkLabelText,
          padding: '6px 24px 6px 16px',
          '&:nth-of-type(2)': {
            padding: '6px 24px 6px 0px',
          },
          '& > *': {
            fontSize: '13px',
          },
        },
      },
    },
  },
  domainSelection: {
    height: 20,
    color: colors.white,
    '&.Mui-checked': {
      color: colors.blueButtonBg,
    },
  },
  chartContainer: {
    backgroundColor: colors.gray750,
    border: `1px solid ${colors.gray700}`,
    borderRadius: 4,
    height: '450px',
    overflow: 'scroll'
  },
  discoveryBox: {
    paddingLeft: '8px',
    lineHeight: '36px',
    paddingTop: '8px',
    '& h6': {
      lineHeight: '24px', /* 150% */
      letterSpacing: '0.15px',
      color: colors.gray200,
    },
    '& svg': {
      fill: colors.gray600,
      fontSize: '24px'
    }
  },
  dashboardHeader: {
    background: colors.default
  },
  hostingContainer: {
    borderRadius: '4px',
    border: `1px solid ${colors.darkLabelText}`,
    background: colors.gray750,
  },
  numberCountStyles: {
    ...numberCountStyles,
    paddingLeft: '4px'
  },
  subtitleContent: subtitleContent
}));

export const useCrawlDialogStyles = makeStyles((theme) => ({
  contentDescription: {
    color: colors.gray100,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '20px',
  },
  customAction: {
    marginRight: 'auto',
    color: colors.blue300,
    display: 'flex',
    fontSize: 14,
    lineHeight: '16px',
    cursor: 'pointer',
  },
}));

export const numberCountStyles = {fontWeight: 700, lineHeight: '36px', fontSize: '32px'}

export const subtitleContent = {
  '& h6': {
    lineHeight: '24px', /* 150% */
    letterSpacing: '0.15px',
    color: colors.gray200,
  },
  '& svg': {
    fill: colors.gray600,
    fontSize: '24px'
  }
};
