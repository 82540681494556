import React, { useState, useContext, useEffect } from "react";
import CqDialog from "../../components/common/CqDialog";
import { pageStyles } from "./styles";
import {
  Grid,
  Typography,
  Divider,
  Pagination,
  SvgIcon,
  Box,
} from '@mui/material';
import { colors } from "../../assets/theme";
import getMethodImg from '../../assets/img/get-method.svg';
import postMethodImg from '../../assets/img/post-method.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDateTime } from '../../lib/utils';
import { retrieveAPIRequestResponse } from "./provider";


const RequestResponseDialog = ({ data, setOpenReqResDialog,  openReqResDialog, selectedDomain, routeToServerDetails}) => {
  const classes = pageStyles()
  const [riskModelType, setRiskModelType] = useState({});
  const [reqResponseEntries, setReqResponseEntries] = useState([]);
  const [reqResponsePage, setReqResponsePage] = useState(1);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [loading, setLoading] = useState(false)

  const setReqResponseValues = (value) => {
    setReqResponsePage(value);
    setSelectedEntry(reqResponseEntries[value - 1]);
  };

  const handleCopyRequest = async (val) => {
    let json =
      val === 'request'
        ? JSON.stringify(selectedEntry.request)
        : JSON.stringify(selectedEntry.response);
    await navigator.clipboard.writeText(json);
  };


  useEffect(async () => {
    setLoading(true)
    const {detail, risk} = data
    setRiskModelType(risk);
    const entries = await retrieveAPIRequestResponse(detail, selectedDomain)
    setReqResponseEntries(entries);
    setSelectedEntry(entries[0]);
    setReqResponsePage(1);
    setLoading(false)
  }, [data])

  const serverdata = data?.detail?.hostname?.data || {}

  return (
    <CqDialog
      title={riskModelType?.description || 'Request/Response'}
      open={openReqResDialog}
      onClose={() => setOpenReqResDialog(false)}
      maxWidth="lg"
      closeIcon={true}
      showSubmitBtn={false}
      showCloseBtn={false}
      mediumRiskType={riskModelType?.severity === 'MEDIUM'}
      highRiskType={riskModelType?.severity === 'HIGH'}
      infoRiskType={riskModelType?.severity === 'LOW'}
      backdrop={loading}
    >
      <Grid container style={{ backgroundColor: colors.gray900 }}>
        <Grid item container xs={6} style={{ justifyContent: 'start' }}>
          {selectedEntry?.request?.method === 'get' ? (
            <SvgIcon
              className={classes.methodImg}
              component={getMethodImg}
            />
          ) : (
            <SvgIcon
              className={classes.methodImg}
              component={postMethodImg}
            />
          )}
          {selectedEntry?.request?.url && (
            <Typography
              variant={'body1'}
              style={{ color: colors.gray100, padding: 10 }}
            >
              {new URL(selectedEntry?.request?.url).pathname}
            </Typography>
          )}
        </Grid>
        <Grid item container xs={6} style={{ justifyContent: 'end' }}>
          {selectedEntry?.request?.url && (
            <Typography
              variant={'body1'}
              className={classes.blueCell}
              onClick={() =>
                routeToServerDetails(serverdata)
              }
            >
              {new URL(selectedEntry?.request?.url).hostname}
            </Typography>
          )}
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ borderColor: colors.gray650 }}
          />
          {selectedEntry?.response?.status && (
            <Typography
              variant={'body1'}
              style={{
                color: colors.gray100,
                fontSize: 12,
                padding: 10,
                margin: 5,
              }}
            >
              {selectedEntry?.response?.status}
            </Typography>
          )}
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ borderColor: colors.gray650 }}
          />
          {reqResponseEntries[reqResponsePage - 1]?.time && (
            <Typography
              variant={'body1'}
              style={{
                color: colors.gray100,
                fontSize: 12,
                padding: 10,
                margin: 5,
              }}
            >
              {reqResponseEntries[reqResponsePage - 1]?.time > 0
                ? `${reqResponseEntries[reqResponsePage - 1]?.time} ms`
                : '--'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container columnGap={2} style={{ marginTop: 10 }}>
        <Grid
          item
          xs
          style={{
            height: 'auto',
            maxHeight: 600,
            backgroundColor: colors.gray900,
            overflow: 'scroll',
          }}
        >
          <Grid item container xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant={'subtitle1'}
                style={{
                  color: colors.gray100,
                  marginLeft: 10,
                  height: 30,
                  marginTop: 10,
                }}
              >
                Request
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'body2'}
                style={{
                  color: colors.gray100,
                  height: 30,
                  paddingTop: 16,
                }}
              >
                {selectedEntry?.startedDateTime
                  ? formatDateTime(selectedEntry.startedDateTime)
                  : '-'}
              </Typography>
            </Box>
          </Grid>
          <Divider />
          <Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <ContentCopyIcon
                onClick={() => handleCopyRequest('request')}
                className={classes.copyIcon}
              />
            </div>
            <Grid item container style={{ marginTop: '-20px' }}>
              {selectedEntry?.request?.method === 'get' ? (
                <SvgIcon
                  sx={{ width: '90px', marginLeft: '-20px' }}
                  component={getMethodImg}
                />
              ) : (
                <SvgIcon
                  sx={{ width: '90px', marginLeft: '-20px' }}
                  component={postMethodImg}
                />
              )}
              {selectedEntry?.request?.url && (
                <Typography
                  style={{
                    color: colors.gray100,
                    marginLeft: 10,
                    fontSize: 12,
                  }}
                >
                  {new URL(selectedEntry?.request?.url).pathname}
                </Typography>
              )}
            </Grid>
            {selectedEntry?.request?.headers?.map((i) => {
              return (
                <Typography
                  key={`request-${i}`}
                  style={{ marginLeft: 10, fontSize: 12 }}
                >
                  <span style={{ color: colors.lightBlue100 }}>
                    {i.name}:{' '}
                  </span>
                  <span style={{ color: colors.orange100 }}>
                    {i.value}
                  </span>
                </Typography>
              );
            })}
            {selectedEntry?.request?.queryString?.map((i) => {
              return (
                <Grid style={{ whiteSpace: 'pre-line' }}>
                  <br />
                  <Typography
                    key={`queryString-${i}`}
                    style={{
                      color: colors.orange100,
                      marginLeft: 10,
                      fontSize: 12,
                    }}
                  >
                    {i.value}
                  </Typography>
                </Grid>
              );
            })}
            {selectedEntry?.request?.postData && (
              <Grid style={{ whiteSpace: 'pre-line' }}>
                <br />
                <Typography
                  style={{
                    color: colors.orange100,
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  {window.atob(
                    decodeURIComponent(selectedEntry?.request?.postData)
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          style={{
            height: 'auto',
            maxHeight: 600,
            backgroundColor: colors.gray900,
            overflow: 'scroll',
          }}
        >
          <Grid item container xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant={'subtitle1'}
                style={{
                  color: colors.gray100,
                  marginLeft: 10,
                  height: 30,
                  marginTop: 10,
                }}
              >
                Response
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'body2'}
                style={{
                  color: colors.gray100,
                  height: 30,
                  paddingTop: 16,
                }}
              >
                Server IP: {selectedEntry?.server?.ipAddr || '-'}
              </Typography>
            </Box>
          </Grid>
          <Divider />
          <Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <ContentCopyIcon
                onClick={() => handleCopyRequest('response')}
                className={classes.copyIcon}
              />
            </div>
            <Typography
              style={{
                color: colors.orange100,
                fontSize: 12,
                marginLeft: 10,
                marginTop: '-20px',
              }}
            >
              {selectedEntry?.response?.httpVersion}{' '}
              {selectedEntry?.response?.status}
            </Typography>
            {selectedEntry?.response?.headers?.map((i) => {
              return (
                <Typography
                  key={`response-${i}`}
                  style={{ fontSize: 12, marginLeft: 10 }}
                >
                  <span style={{ color: colors.lightBlue100 }}>
                    {i.name}:{' '}
                  </span>
                  <span style={{ color: colors.orange100 }}>
                    {i.value}
                  </span>
                </Typography>
              );
            })}
            {selectedEntry?.response?.content?.text && (
              <Grid style={{ whiteSpace: 'pre-line' }}>
                <br />
                <Typography
                  style={{
                    color: colors.orange100,
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  {window.atob(
                    decodeURIComponent(
                      selectedEntry?.response?.content?.text
                    )
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {reqResponseEntries?.length > 1 && (
        <Grid style={{ marginTop: 10, justifyContent: 'end' }}>
          <Pagination
            count={reqResponseEntries.length}
            page={reqResponsePage}
            onChange={(e, value) => setReqResponseValues(value)}
            variant="outlined"
            color="primary"
            size="small"
          />
        </Grid>
      )}
    </CqDialog>
  )
}

export default RequestResponseDialog