import React, {
  useState,
  useEffect,
  memo,
  useLayoutEffect,
  useRef,
} from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import makeStyles from '@mui/styles/makeStyles';

import { isEqual } from 'lodash';
import { colors } from '../assets/theme';
import {apiHosts} from "../pages/servers/constants";
import { getRoutes } from '../lib/constants';
import { useGlobalState } from '../store/globalState';

const useStyles = makeStyles({
  chartContainer: {
    maxHeight: '400px',
    overflow: 'auto',
  },
});

const routes = getRoutes();

const SpyBarChart = ({
  chartId,
  data,
  otherServerData,
  routeToServerEndpoints,
  logScale,
  sortServers,
  hostTypeFilter
}) => {
  const chartRef = useRef(null);
  const classes = useStyles();
  const propsData = [...data, ...otherServerData];
  const global = useGlobalState();
  const routeToServersPage = (serverName) => {
    const filters = propsData.map((server) => {
      if (server.name !== serverName) {
        server.selected = false;
      }
      return server;
    });
    const otherServers = otherServerData.map((server) => {
      if (server.name !== serverName) {
        server.selected = false;
      }
      return server;
    });
    global.userPreferences['serverView'].set(true);
    routeToServerEndpoints(routes.discovery.path, { description: 'Discovery' }, filters, otherServers);
  };
  useEffect(() => {
    window.loadServer = (server) => {
      routeToServersPage(server);
    };
    return () => {
      window.consoleCategory = null;
    };
  }, []);

  const setData = (propsData) => {
    const chartData = propsData.map((val, i) => {
      return {
        name: val.name,
        count: hostTypeFilter.allFilter ? val.countAttempted : hostTypeFilter.nonApiFilter ? val.nonApiCount : val.count,
        color: colors.charts[i] || colors.charts[i % 10],
      };
    });
    return chartData;
  };
  const createChart = (propsData, logScale) => {
    let chart = am4core.create(chartId, am4charts.XYChart);
    chart.data = setData(propsData);
    chart.padding = 100;
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'name';
    categoryAxis.title.fontSize = '12px';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.cursorTooltipEnabled = false;
    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 240;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.logarithmic = logScale === 'logarithmic' ? true : false;
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.maxLabelPosition = 0.99;
    valueAxis.cursorTooltipEnabled = false;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'count';
    series.dataFields.categoryY = 'name';
    series.columns.template.propertyFields.fill = 'color';
    series.columns.template.propertyFields.stroke = 'color';
    series.columns.template.column.cornerRadiusTopRight = 4;
    series.columns.template.column.cornerRadiusBottomRight = 4;
    series.columns.template.height = am4core.percent(90);
    series.columns.template.column.paddingBottom = 3;
    series.columns.template.column.paddingTop = 3;
    series.columns.template.tooltipX = 800;
    series.tooltip.background.pointerLength = 0;
    series.tooltip.background.filters.clear();
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = false;
    series.tooltip.background.stroke = false;
    series.tooltip.label.interactionsEnabled = true;
    series.tooltip.keepTargetHover = true;
    series.columns.template.tooltipHTML = `<a style="display: flex;border: none;align-items: center;vertical-align: middle;cursor: pointer" onClick="loadServer('{name}')"><span>View API Hosts</span> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 17L15 12L10 7V17Z" fill="white"/></svg></a>`;
    series.columns.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer; // series.tooltip.dx = 500;
    chart.plotContainer.events.on('hit', () => {
      const serverName = series.tooltipDataItem.categoryY;
      routeToServersPage(serverName);
    });

    let valueLabel = series.columns.template.createChild(am4core.Label);
    valueLabel.text = `{count}`;
    valueLabel.fontSize = 14;
    valueLabel.stroke = am4core.color(colors.white);
    valueLabel.valign = 'middle';
    valueLabel.dx = 10;
    valueLabel.locationX = 1;
    valueLabel.truncate = false;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.yAxis = categoryAxis;
    chart.cursor.fullWidthLineY = true;
    chart.cursor.lineY.strokeWidth = 0;
    chart.cursor.lineX.strokeWidth = 0;
    chart.cursor.lineY.fill = am4core.color(colors.gray900);
    chart.cursor.lineY.fillOpacity = 0.3;
    chart.plotContainer.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    return chart;
  };

  const [chartHeight, setChartHeight] = useState(0);

  useLayoutEffect(() => {
    const othersIndex = propsData.findIndex((val) => val.name === 'Others');
    if (othersIndex > -1) {
      propsData.splice(othersIndex, 1);
    }
    setChartHeight(propsData.length * 55);
    const chart = createChart(propsData, logScale);
    chart.events.on('beforedatavalidated', function () {
      chart.data.sort(function (a, b) {
        return sortServers ? b.count - a.count : a.count - b.count;
      });
    });
    chartRef.current = chart;
    return () => {
      if (chart) chart.dispose();
    };
  }, [data, sortServers, logScale, hostTypeFilter]);
  return (
    <>
      <div className={classes.chartContainer}>
        <div
          id={chartId}
          style={{
            width: '100%',
            minHeight: chartHeight < 250 ? 250 : chartHeight,
            overflow: 'scroll',
            marginTop: 20,
          }}
        ></div>
      </div>
    </>
  );
};

function arePropsEqual(prevProps, nextProps) {
  if (isEqual(prevProps, nextProps)) {
    return true;
  } else {
    return false;
  }
}

export default memo(SpyBarChart, arePropsEqual);
