import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { colors } from '../../../assets/theme';
import CqButton from "../../../components/common/CqButton";
import FilterItem from "./FilterItem";
import { useFiltersState } from "../../../store/globalState";

const useStyles = makeStyles(theme => ({
	filterContainer: {
		backgroundColor: colors.gray700,
		borderRadius: 5,
		height: 72,
		display: 'flex',
		width: '100%',
		gap: '20px'
	},
	filterItem: {
		width: '200px'
	},
	filterButtonContainer: {
		marginTop: '35px',
		marginLeft: '20px',
		display: 'flex',
		gap: '20px'
	},
	inputRoot: {
		margin: '10px 0px 10px 10px',
		fontSize: 32,
		fontWeight: 600,
		color: colors.white,
		paddingTop: 20,
		paddingLeft: 10,
		paddingRight: 30,
		'&.MuiSelect-select': {
			paddingRight: 40,
		}
	},
	domainSelection: {
		'& .MuiSelect-icon': {
			fontSize: 36,
			top: 'calc(50% - 17px)',
		},
	},
	menuItem: {
		'&.Mui-selected': {
			backgroundColor: colors.gray600,
			color: 'white',
		},
	},
	dateContent: {
		backgroundColor: colors.gray650,
		borderRadius: 5,
		margin: '20px 0px 20px 0px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		verticalAlign: 'middle',
		display: 'inline-flex',
	},
	triggerPopoverPaper: {
		minWidth: 278,
		maxWidth: 278,
		pointerEvents: 'auto',
		padding: theme.spacing(1),
		backgroundColor: colors.almostBlack,
		border: `1px solid ${colors.white}`,
	},
	triggerPopoverRoot: {
		maxHeight: '50vh',
		marginTop: theme.spacing(1),
		pointerEvents: 'none',
	},
	menuPaper: {
		maxHeight: 200,
		border: `1px solid ${colors.white}`,
	},
	subTitleContainer: {
		verticalAlign: 'middle',
		display: 'inline-flex',
	},
}));

const Filters = ({ setFilters, updateNotifications, clearFilters, filters }) => {
	const classes = useStyles();

	const selectSingle = (option, pageKey, stateKey) => {
    const obj = {}
    obj[stateKey] = option
    setFilters(val => ({...val, ...obj}))
	};
	return (
		<div className={classes.filterContainer}>
			<div className={classes.filterItem}>
				<FilterItem
					label='Domains'
					options={filters.domains}
					multiChoice={false}
					showSearch={false}
					pageKey={'notificationFilters'}
					stateKey={'domains'}
					selectOptions={selectSingle}
				/>
			</div>
			<div className={classes.filterItem}>
				<FilterItem
					label='Risk Level'
					options={filters.severity}
					multiChoice={false}
					showSearch={false}
					pageKey={'notificationFilters'}
					stateKey={'severity'}
					selectOptions={selectSingle}
				/>
			</div>
			<div className={classes.filterItem}>
				<FilterItem
					label='Detection Timeframe'
					options={filters.timeRange}
					showAllOption={false}
					multiChoice={false}
					showSearch={false}
					pageKey={'notificationFilters'}
					stateKey={'timeRange'}
					selectOptions={selectSingle}
					placeholder='Last Week'
				/>
			</div>
			<div className={classes.filterButtonContainer}>
				<CqButton
					text={'Apply'}
					height={26}
					onClick={() => updateNotifications()}
					variant={'primary'}
				/>
				<CqButton
					text={'Clear Filters'}
					height={26}
					onClick={() => clearFilters()}
					variant={'borderBlue'}
				/>
			</div>
		</div>
	);
};

export default Filters;
