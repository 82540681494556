import React, { useState, useEffect, useReducer, useContext } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui'
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../store/globalState';
import { initialState, reducer } from '../crawlCatalog/rulesReducer';
import CqButton from '../../components/common/CqButton';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { abbrFormat } from '../../lib/utils';
import { colors } from '../../assets/theme';
import moment from 'moment';
import FindingsCell from '../../components/FindingsCell';
import { findingsSort } from '../../lib/utils';
import { getRoutes } from '../../lib/constants';

const routes = getRoutes();

const DomainsTable = ({domains, handleDomainChange, activeDomains, hostTypeFilter}) => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const navigate = useNavigate();
  const dataGridInitialState = {
    rows: [],
    columns: [],
    pagination: {
      paginationModel: {
        pageSize: 10
      }
    },
    sorting: {
      sortModel: [{ field: 'domain', sort: 'desc' }],
    },
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const newCrawlContext = useContext(WrapperContext);
  const [domainsData, setDomainData] = useState(dataGridInitialState.rows)
  const [sortModel, setSortModel] = useState([{field: 'findings', sort: 'desc'}])
  const global = useGlobalState();

  const { config } = state;
  const handleNewCrawl = (domain) => {
    newCrawlContext.showNewCrawl(domain, config);
  };
  const NewDomainCell = (params) => {
    return (
      <CqButton variant="text" onClick={() => {
        handleNewCrawl(params.row.domain);
     }}>
        <PublishedWithChangesIcon />
        <span>Crawl Now</span>
      </CqButton>
    )
  };
  useEffect(() => {
    try {
      const rowList = []
      const notCrawledDomains = []
      let index = 0;
      const crawlesDomainsMap = new Map()
      for(const d of domains){
        crawlesDomainsMap.set(d.name, d)
      }
      for(const tenantDomain of activeDomains){
        const domainInfo = crawlesDomainsMap.get(tenantDomain.domain)
        let totalFinding = domainInfo?.findings || {high: 0, medium: 0, info: 0}
        if(domainInfo?.nonApiHostsFindings){
          totalFinding = {high: totalFinding.high + domainInfo?.nonApiHostsFindings?.high, medium: totalFinding.medium + domainInfo?.nonApiHostsFindings?.medium, info: totalFinding.info + domainInfo?.nonApiHostsFindings?.info, total: totalFinding.total + domainInfo?.nonApiHostsFindings?.total}
        }
        if(domainInfo){
          const hostCount = hostTypeFilter.allFilter ? domainInfo.hostsAttempted : hostTypeFilter.nonApiFilter ? domainInfo.hostsAttempted-domainInfo.hosts : domainInfo.hosts
          const endpointsCount = hostTypeFilter.allFilter ? domainInfo.endPointsAttempted : hostTypeFilter.nonApiFilter ? domainInfo.endPointsAttempted-domainInfo.endpoints : domainInfo.endpoints
          const crawlDateTime = domainInfo.crawlDate ? moment(domainInfo.crawlDate).format('MMM Do YYYY - hh:mm a') : ''
          rowList.push({
            id: index,
            domain: domainInfo.name,
            crawldate: {str: crawlDateTime, timestamp: domainInfo.crawlDate},
            apiServersCount: hostCount,
            apiEndpointsCount: abbrFormat(endpointsCount || 0),
            hostingProviders: domainInfo.providers,
            findings: {findings: hostTypeFilter.allFilter ? totalFinding : hostTypeFilter.nonApiFilter ? domainInfo?.nonApiHostsFindings || {} : domainInfo?.findings || {high: 0, medium: 0, info: 0}}
          })
          index++
        } else {
          notCrawledDomains.push(tenantDomain.domain)
        }
      }
      for(const dom of notCrawledDomains){
        rowList.push({
          id: index,
          domain: dom,
          crawldate: '--',
          apiServersCount: '--',
          apiEndpointsCount: '--',
          hostingProviders: '--',
          findings: undefined
        })
        index++
      }
      setDomainData(rowList)
    } catch(e){
      console.log(e)
    }
  }, [domains, hostTypeFilter])

  let hostHeader = 'API Hosts'
  let endpointsHeader = 'API Endpoints'

  if(hostTypeFilter.nonApiFilter){
    hostHeader = 'Non-API Hosts'
    endpointsHeader = 'Non-API Endpoints'
  }
  if(hostTypeFilter.allFilter){
    hostHeader = 'Hosts'
    endpointsHeader = 'Endpoints'
  }

  const columns = [
    {field: 'domain',  headerName: 'Domain',  minWidth: 196, sortingOrder: ['desc', 'asc'], renderCell: (params) => <a style={{color: colors.blue300}}>{params.value}</a>, flex: 1},
    {field: 'crawldate',  headerName: 'Last Crawled',  minWidth: 205, maxWidth: 300, sortingOrder: ['desc', 'asc'], filterable: false,sortComparator: (v1,v2) => v1.timestamp - v2.timestamp, renderCell: (params) => params?.value?.str, flex: 1},
    {field: 'apiServersCount',  headerName: hostHeader, minWidth: 196, sortingOrder: ['desc', 'asc'], filterable: false, flex: 1},
    {field: 'apiEndpointsCount',  headerName: endpointsHeader,  minWidth: 196, maxWidth: 300, sortingOrder: ['desc', 'asc'], filterable: false, flex: 1},
    {field: 'hostingProviders',  headerName: 'Hosting Providers',  minWidth: 196, maxWidth: 300, sortingOrder: ['desc', 'asc'], filterable: false, flex: 1},
    {field: 'findings',  headerName: 'Findings',  minWidth: 200, filterable: false, renderCell: FindingsCell, sortingOrder: ['desc', 'asc'],  sortComparator: (v1, v2) => findingsSort(v1?.findings, v2?.findings), flex: 1},
    {field: 'crawlNow',  headerName: 'Initiate Crawl',  minWidth: 150, renderCell: NewDomainCell, sortable: false, filterable: false, flex: 1 }
  ];

  const handleColumnClick = (params) => {
    const { field, row } = params;
    switch (field){
      case 'domain':
        handleDomainChange(row.domain)
        break
      case 'crawlsLastWeek':
        navigate(routes.crawls.path, {state: {selectedDomain: row.domain}})
        break
      case 'apiServersCount':
        global.userPreferences['serverView'].set(true);
        navigate(routes.discovery.path, {state: {selectedDomain: row.domain}})
        break
      case 'apiEndpointsCount':
        global.userPreferences['serverView'].set(false);
        navigate(routes.discovery.path, {state: {selectedDomain: row.domain}})
        break
      default:
        break
    }
  }

  const data = {
    rows: domainsData,
    columns: columns
  }
  return (
    <div style={{ height: 400, width: 'calc(100% - 12px)' }}>
      <DataGridPremium
        {...data}
        initialState={dataGridInitialState}
        headerHeight={36}
        onCellClick={handleColumnClick}
        rowCount={domainsData.length}
        pageSizeOptions={[10, 20]}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        hideFooter
        sx={dataGridStyles}
        disableAggregation
        disableRowGrouping
      />
    </div>
  );
}

export default DomainsTable;
