import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { colors } from '../../assets/theme';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFiltersState } from '../../store/globalState';
import CqMultiSelect from '../../components/common/CqMultiSelect';
import { CRAWL_SCHEDULE, DOMAIN_STATUS } from '../../store/constants';
import CqSearch from '../../components/common/CqSearch';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '8px 8px 12px 12px',
    background: colors.gray700,
    borderRadius: 4,
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-end',
    gap: '23px',
  },
}));

// Domains
const $FormControlLabel = withStyles(() => ({
  label: {
    fontSize: '16px',
  },
}))(FormControlLabel);

const FilterBar = () => {
  const classes = useStyles();
  const filters = useFiltersState();
  const {
    domainSearchName,
    showDeletedDomains,
    domainStatus,
    domainCrawlTypes,
  } = filters.get();

  const toggleShowingDeletedDomains = () => {
    filters.showDeletedDomains.set(!showDeletedDomains);
  };

  const updateDomainSearchName = (name) => {
    filters.domainSearchName.set(name);
  };

  const updateDomainStatus = (selectedStatus) => {
    filters.domainStatus.set(
      selectedStatus.reduce((obj, status) => {
        obj[status] = true;
        return obj;
      }, {})
    );
  };

  const updateDomainCrawlTypes = (selectedTypes) => {
    filters.domainCrawlTypes.set(
      selectedTypes.reduce((obj, type) => {
        obj[type] = true;
        return obj;
      }, {})
    );
  };

  return (
    <div className={classes.wrapper}>
      {/* Domain search */}
      <CqSearch
        label="Domain Name"
        value={domainSearchName}
        width={240}
        placeholder="Search Domain"
        onChange={updateDomainSearchName}
      />

      {/* Domain status */}
      <CqMultiSelect
        label="Status"
        options={Object.values(DOMAIN_STATUS)}
        value={domainStatus}
        width={190}
        placeholder="All"
        onChange={updateDomainStatus}
      />

      {/* Domain crawl schedule */}
      <CqMultiSelect
        label="Crawl Schedule"
        options={Object.values(CRAWL_SCHEDULE)}
        value={domainCrawlTypes}
        width={190}
        placeholder="All"
        onChange={updateDomainCrawlTypes}
      />
    </div>
  );
};

export default FilterBar;
