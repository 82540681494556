import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { colors } from '../assets/theme';
import { useFiltersState } from '../store/globalState';
import { useDebounce } from '../lib/utils';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    color: colors.darkest,
    '& .MuiInputBase-input': {
      padding: 0,
      color: colors.white,
    },
  },
  inputHeight: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    color: colors.darkest,
  },
  blueIcon: {
    color: colors.blueButtonBg,
    marginRight: 5,
    marginTop: -2,
  },
  greyIcon: {
    color: colors.white,
    marginRight: 5,
    marginTop: -2,
  },
  clearIcon: {
    cursor: 'pointer',
    paddingLeft: 5,
    marginTop: -2,
  },
  container: {
    border: `1px solid ${colors.gray}`,
    borderRadius: '2px',
    backgroundColor: colors.moreMutedAnother,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  blueOutlineBox: {
    border: `1px solid ${colors.blueButtonBg}`,
    borderRadius: '2px',
    backgroundColor: 'transparent',
  },
  greyOutlineBox: {
    border: `1px solid ${colors.fontGray}`,
    borderRadius: '2px',
    backgroundColor: colors.darkLabelText,
  },
}));

const Search = React.forwardRef(
  (
    {
      searchLabel,
      localSearchOp,
      width,
      searchColor = true,
      searchField = '',
      currentTheme,
      marginLeft = 10,
      height = 30,
      onFocus,
      onArrowDown,
    },
    ref
  ) => {
    const classes = useStyles();
    const [value, setValue] = useState('');

    const globalFilters = useFiltersState();
    const filters = globalFilters.get();

    const debouncedValue = useDebounce(value, 200);

    useEffect(() => {
      localSearchOp(debouncedValue);
    }, [debouncedValue]);

    const vowel = (c) => {
      return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase()) !== -1;
    };

    const aOrAn = (word) => {
      return vowel(word[0]) ? 'an ' : 'a ';
    };

    const label = 'Search ' + searchLabel;

    const handleKeyDown = (e) => {
      e.stopPropagation();
      if (value && e.key === 'Enter') {
        if (!filters.search[searchField]?.includes(value.trim())) {
          globalFilters.search[searchField].merge([value.trim()]);
        }
        setValue('');
      }
    };

    const onKeyArrowDown = (e) => {
      if (onArrowDown && e.key === 'ArrowDown') {
        onArrowDown(e);
      }
    };

    return (
      <React.Fragment>
        <TextField
          auto-id={'domain-search'}
          placeholder={label}
          value={value}
          variant="standard"
          inputRef={ref}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: height !== 30 ? classes.inputHeight : classes.inputRoot,
            },
            startAdornment: (
              <SearchIcon
                auto-id={'search-icon'}
                fontSize={'small'}
                className={searchColor ? classes.blueIcon : classes.greyIcon}
              />
            ),
            style: {
              width: width || 250,
              color: currentTheme === 'dark' ? colors.muteder : '',
            },
          }}
          className={clsx(
            classes.container,
            searchColor ? classes.blueOutlineBox : classes.greyOutlineBox
          )}
          onChange={(e) => setValue(e.target.value)}
          style={{ height: height, marginLeft: marginLeft }}
          onKeyDown={
            localSearchOp
              ? (e) => {
                  onKeyArrowDown(e);
                  e.stopPropagation();
                }
              : handleKeyDown
          }
          onFocus={onFocus ? (e) => onFocus(true) : (e) => e.stopPropagation()}
          onBlur={onFocus ? (e) => onFocus(false) : (e) => e.stopPropagation()}
        />
      </React.Fragment>
    );
  },
  {}
);

export default Search;
