export const getDomainsQuery = `
query GetActiveDomains {
  domain {
    getActiveDomains {
	    domain
	    status
	  }
	}
}
`;

export const getCrawlInfoQuery = `
query GetCrawlInfo($crawlId: String) {
  crawl {
    getCrawlInfo(crawlId: $crawlId) {
      domain
      crawlId
      submittedAt
      totalSubdomains,
      activeSubdomains,
      config
    }
  }
}
`;

export const getAPIRequestResponseQuery = `
query GetAPIRequestResponse($domain: String!, $crawlId: String, $requestId: String, $objectKey: String) {
    crawl {
        getAPIRequestResponse(domain: $domain,  crawlId: $crawlId, requestId: $requestId, objectKey: $objectKey) {
	        status
	        data
	    }
    }
}
`;

export const getServersSummaryQuery = `
query GetServersSummary($domains: [String], $crawlId: String, $showApiHosts: Boolean, $showNonApiHosts: Boolean, $hostname: String, $hostingProviders: [String!], $statusCodes: [String!], $issueTypes: [String], $includeCSPM: Boolean, $includeSentinel: Boolean, $includeSpyderCrawls: Boolean, $timeRange: String, $size: Int, $from: Int, $gatewayIds: [String]) {
    discovery {
        getServersSummary(domains: $domains, crawlId: $crawlId, showApiHosts: $showApiHosts, showNonApiHosts: $showNonApiHosts, hostname: $hostname, filters: { hostingProviders: $hostingProviders, statusCodes: $statusCodes, issueTypes: $issueTypes, includeCSPM: $includeCSPM, includeSentinel: $includeSentinel, includeSpyderCrawls: $includeSpyderCrawls, timeRange: $timeRange, gatewayIds: $gatewayIds }, size: $size, from: $from) {
          total
           servers {
                total
                hostname
                crawlId
                hostId
                firstSeen
                providers
                domain
                findings {
                    total
                    high
                    medium
                    info
                    issues {
                        name
                        severity
                        value
                        type
                    }
                }
                endpoints {
                  discovered
                  attempted
                  xml
                  redirects
                  yaml
                  html
                  json
                  text
                  status4xx
                  status5xx
                  status404
                  status200
                }
                tlsInfo {
                    isTls
                    authorized
                    certs
                    serverMatched
                    protocol
                    cipher
                }
                isApi
                apiGateways
                ips
                cname
            }
       }
   }
}
`;

export const getEndpointsSummaryQuery = `
query GetEndpointsSummary($domains: [String], $crawlId: String, $showApiHosts: Boolean, $showNonApiHosts: Boolean, $hostname: String, $hostingProviders: [String!], $statusCodes: [String!], $issueTypes: [String], $includeCSPM: Boolean, $includeSentinel: Boolean, $includeSpyderCrawls: Boolean, $timeRange: String, $size: Int, $from: Int, $gatewayIds: [String]) {
    discovery {
        getEndpointsSummary(domains: $domains, crawlId: $crawlId, showApiHosts: $showApiHosts, showNonApiHosts: $showNonApiHosts, hostname: $hostname, size: $size, from: $from, filters: { hostingProviders: $hostingProviders, statusCodes: $statusCodes, issueTypes: $issueTypes, includeCSPM: $includeCSPM, includeSentinel: $includeSentinel, includeSpyderCrawls: $includeSpyderCrawls, timeRange: $timeRange, gatewayIds: $gatewayIds }) {
          total
          endpoints {
                total
                hostname
                crawlId
                hostId
                isApi
                domain
                method
                path
                status
                redirects
                providers
                redirectInfo {
                    hostname
                    path
                    status
                }
                contentType
                objectKey
                requestId
                apiGateways
                findings {
                    total
                    high
                    medium
                    info
                    issues {
                        name
                        severity
                    }
                }
            }
       }
   }
}
`;

export const getProvidersDataQuery = `
query GetProvidersData($domains: [String]) {
    discovery {
        getDomainSummary(domains: $domains) {
            total
             hosts {
                total
                attempted
                discovered
             }
            endpoints {
                attempted
                discovered
            }
            providers {
                total
                values {
                    discovered
                    total
                    name
                }
            }
            findings {
                total
                high
                medium
                info
            }
            domains {
                name
                hosts
                endpoints
                providers
                    findings {
                        total
                        high
                        medium
                        info
                    }
                }
            }
        }
    }
`;

export const getDomainServersPageXLSReport = `
  query getDomainServersPageXLSReport($domain: [String], $crawlDate: String){
    discovery {
      getDomainServersPageXLSReport(domain: $domain, crawlDate: $crawlDate){
        totalEndpoints
        totalApiServers
        totalHostingProviders
        domainsData
        issueSummary{
          high
          medium
          info
        }
        apiServers
        endpoints {
          discovered
          attempted
          xml
          redirects
          yaml
          html
          json
          text
        }
      }
    }
  }
`;

export const getApiServerLog4jData = `
query GetApiServerLog4jData($domain: String, $hostname: String!, $crawlId: String) {
  crawl {
    getApiServerLog4jData(domain: $domain, hostname: $hostname, crawlId: $crawlId) {
      domain
      crawlId
      timestamp
      servers {
        hostname
        requests {
          path
          method
          status
          requestTime
          hasLog4j
          hasLong4j
          dnsResponses {
            timestamp
            type
            data {
              ip
              org
              pName
              pType
              pValue
              paramType
              paramName
            }
          }
          reqCommand
        }
        totalDnsResponses
      }
    }
  }
 }
`;
