import { vulnerabiltiesTypes } from '../../lib/constants';

export const updateContextSheet = (contextSheet) => {
  const contextRows = [];
  for (const vulnerability of vulnerabiltiesTypes.high) {
    contextRows.push([
      vulnerability.name,
      vulnerability.description,
      vulnerability.risk,
      vulnerability.recommendation,
      vulnerability.reference,
    ]);
  }
  for (const vulnerability of vulnerabiltiesTypes.medium) {
    contextRows.push([
      vulnerability.name,
      vulnerability.description,
      vulnerability.risk,
      vulnerability.recommendation,
      vulnerability.reference,
    ]);
  }
  for (const vulnerability of vulnerabiltiesTypes.low) {
    contextRows.push([
      vulnerability.name,
      vulnerability.description,
      vulnerability.risk,
      vulnerability.recommendation,
      vulnerability.reference,
    ]);
  }
  contextSheet.columns = [
    { header: 'Finding', width: 40 },
    { header: 'Description', width: 50 },
    { header: 'Risk', width: 50 },
    { header: 'Recommendation', width: 50 },
    { header: 'Reference', width: 50 },
  ];

  contextSheet.lastRow.eachCell(function (cell) {
    cell.font = { bold: true };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  for (const contextRow of contextRows) {
    contextSheet.addRow(contextRow);
    const row = contextSheet.lastRow;
    row.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
  }
};
