import { defaultOperators } from 'react-querybuilder';

export const stringTypeAttributeOperators = [
  { name: 'in', label: 'includes' },
  { name: 'notIn', label: 'not includes' },
  { name: 'regex', label: 'regex' },
  { name: 'keywords', label: 'matches keywords' },
];

const numberTypeOperators = defaultOperators.filter(
  (op) =>
    op.name === '=' ||
    op.name === '!=' ||
    op.name === '>' ||
    op.name === '<' ||
    op.name === '>=' ||
    op.name === '<='
);

const methodTypeOperators = [
  { name: 'in', label: 'includes' },
  { name: 'notIn', label: 'not includes' },
];

const contentTypeOperators = [
  { name: '=', label: '=' },
  { name: '!=', label: '!=' },
  { name: 'regex', label: 'regex' },
];

const responseHeaderOperators = [
  { name: '=', label: '=' },
  { name: '!=', label: '!=' },
  { name: 'in', label: 'includes' },
  { name: 'regex', label: 'regex' },
];

const statusCodeOperators = defaultOperators.filter(
  (op) =>
    op.name === '=' ||
    op.name === '!=' ||
    op.name === '>' ||
    op.name === '<' ||
    op.name === '>=' ||
    op.name === '<=' ||
    op.name === 'between' ||
    op.name === 'notBetween'
);

const validator = (r) =>
  r.value && r.value !== undefined && r.value !== null && r.value !== '';

const methodValues = [
  {
    options: [
      { name: 'GET', label: 'GET' },
      { name: 'POST', label: 'POST' },
      { name: 'PUT', label: 'PUT' },
      { name: 'PATCH', label: 'PATCH' },
      { name: 'DELETE', label: 'DELETE' },
    ],
  },
];

export const endpointLevelFields = [
  {
    name: 'hostname',
    label: 'Hostname',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'domain',
    label: 'Domain',
    operators: stringTypeAttributeOperators,
    validator,
  },
  {
    name: 'cname',
    label: 'CNAME',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'status',
    label: 'Status Code',
    operators: statusCodeOperators,
    validator,
  },
  {
    name: 'hasDirects',
    label: 'Has Redirects',
    operators: defaultOperators.filter((op) => op.name === '='),
    valueEditorType: 'select',
    values: [
      { name: true, label: 'True' },
      { name: false, label: 'False' },
    ],
  },
  {
    name: 'resContentType',
    label: 'Response Content Type',
    valueEditorType: 'multiselect',
    operators: [{ name: '=', label: '=' }, { name: '!=', label: '!=' }],
    values: [
      { name: 'HTML', label: 'HTML' },
      { name: 'JSON', label: 'JSON' },
      { name: 'XML', label: 'XML' },
      { name: 'TEXT', label: 'TEXT' },
      { name: 'BINARY', label: 'BINARY' },
      { name: 'YAML', label: 'YAML' },
      { name: 'OTHER', label: 'OTHER' },
    ],
  },
  {
    name: 'resContentLength',
    label: 'Response Content Length',
    operators: numberTypeOperators,
    validator,
  },
  {
    name: 'resBody',
    label: 'Response Body',
    valueEditorType: 'textarea',
    operators: stringTypeAttributeOperators,
    validator,
  },
  {
    name: 'exposedFile',
    label: 'Exposed File',
    valueEditorType: 'select',
    operators: defaultOperators.filter((op) => op.name === '='),
    values: [
      { name: true, label: 'True' },
      { name: false, label: 'False' },
    ],
  },
  {
    name: 'path',
    label: 'Path',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'htmlTitle',
    label: 'HTML Title',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  { name: 'redirects',
    label: 'Redirects',
    datatype: 'number', 
    operators: numberTypeOperators
  },
  {
    name: 'openApi',
    label: 'OpenAPI',
    operators: defaultOperators.filter((op) => op.name === '='),
    valueEditorType: 'select',
    values: [
      { name: true, label: 'True' },
      { name: false, label: 'False' },
    ],
  },
  {
    name: 'reqBody',
    label: 'Request Body',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'reqHeaderNames',
    label: 'Request Header Names',
    operators: [
      { name: 'in', label: 'includes' },
      { name: 'notIn', label: 'not includes' },
      { name: 'keywords', label: 'matches keywords' },
    ],
    validator,
  },
  {
    name: 'reqHeader',
    label: 'Request Header',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' },
      { name: 'in', label: 'includes' },
      { name: 'notIn', label: 'not includes' },
      { name: 'keywords', label: 'matches keywords' },
    ],
    validator,
  },
  {
    name: 'resHeaderNames',
    label: 'Response Header Names',
    operators: [
      { name: 'in', label: 'includes' },
      { name: 'notIn', label: 'not includes' },
      { name: 'keywords', label: 'matches keywords' },
    ],
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'resHeader',
    label: 'Response Header',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' },
      { name: 'in', label: 'includes' },
      { name: 'notIn', label: 'not includes' },
      { name: 'keywords', label: 'matches keywords' },
    ],
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'reqContentType',
    label: 'Request Content Type',
    operators: contentTypeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'method',
    label: 'Method',
    operators: methodTypeOperators,
    defaultValue: 'GET',
    valueEditorType: 'multiselect',
    values: methodValues,
  },
].sort((v1,v2) => v1.label > v2.label ? 1 : -1);

export const hostLevelFields = [
  {
    name: 'hostname',
    label: 'Hostname',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'domain',
    label: 'Domain',
    operators: stringTypeAttributeOperators,
    validator,
  },
  {
    name: 'cname',
    label: 'CNAME',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
  {
    name: 'provider',
    label: 'Hosting Provider',
    operators: stringTypeAttributeOperators,
    valueEditorType: 'textarea',
    validator,
  },
].sort((v1,v2) => v1.label > v2.label ? 1 : -1);

export const convertJoinToComparator = (jsonObj) => {
  if (jsonObj?.hasOwnProperty("join")) {
      jsonObj.combinator = jsonObj?.join?.toLowerCase();
      delete jsonObj?.join;
  }

  if (jsonObj?.hasOwnProperty("rules")) {
      jsonObj.rules.forEach((rule) => {
          convertJoinToComparator(rule);
      });
  }
  return jsonObj;
};

export const removeIdFieldsAndConvertCombinator =(obj) => {
  if (Array.isArray(obj)) {
      return obj.map(item => removeIdFieldsAndConvertCombinator(item));
  } else if (typeof obj === 'object' && obj !== null) {
      const result = {};
      for (const key in obj) {
          if (key === 'combinator') {
              result['join'] = obj.combinator.toUpperCase(); // Convert 'combinator' to 'join'
          } else if (key !== 'id') {
              result[key] = removeIdFieldsAndConvertCombinator(obj[key]);
          }
          
      }
      return result;
  }
  return obj;
};