import React, { useEffect, useState } from 'react';
import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { GetCSPMIntegrations, AddIntegration } from './graphQueries';
import { useGlobalState } from '../../store/globalState';

import { enhancedTableStyles, colors } from '../../assets/theme';
import CqButton from '../../components/common/CqButton';
import NewIntegration from './NewIntergration';
import Regions from './components/Regions';
import Status from './components/Status';
import Connectivity from './components/Connectivity';

import {
  PageContent,
  ButtonContainer,
  StyledHeader,
  StyledLink,
} from './styles';

const CspmIntegrations = () => {
  const tableClasses = enhancedTableStyles();
  const global = useGlobalState();
  const navigate = useNavigate();
  const [cspmList, setCspmList] = useState([]);
  const [newIntegrationDialog, setNewIntegrationDialog] = useState(false);

  const getIntegrations = async () => {
    try {
      const response = await graphqlQuery(GetCSPMIntegrations, {
        integrationIds: [],
      });
      const integrations = response?.data?.cspm?.getIntegrations?.data;
      setCspmList(integrations);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  const handleNewIntegration = () => {
    setNewIntegrationDialog((open) => !open);
  };

  const handleNewIntegrationSubmit = async (formData, callback) => {
    try {
      const res = await graphqlMutation(AddIntegration, {
        name: formData.name,
        type: formData.type,
        config: {
          accessKeyId: formData.accessKeyId,
          secretAccessKey: formData.secretAccessKey,
        },
      });
      const data = res.data.cspm.addIntegration;
      handleNewIntegration();
      global.notifications.merge([
        {
          id: new Date(),
          message: `${data.message}`,
          category: data.status === 'ERROR' ? 'error' : 'success',
        },
      ]);
    } catch (e) {
      console.error(e);
      handleNewIntegration();
      global.notifications.merge([
        {
          id: new Date(),
          message: `Something went wrong please try again later!`,
          category: 'error',
        },
      ]);
    }
    callback();
  };

  return (
    <PageContent>
      <StyledHeader container>
        <Grid item xs={8}>
          <Typography variant="h4">CSPM Integrations</Typography>
        </Grid>
        <Grid item xs={4}>
          <ButtonContainer>
            <CqButton variant="primary" onClick={handleNewIntegration}>
              <AddCircleOutlineIcon />
              <span>Add New Integration</span>
            </CqButton>
          </ButtonContainer>
        </Grid>
      </StyledHeader>
      <TableContainer className={tableClasses.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Regions</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Connectivity</TableCell>
              <TableCell>API Hosts</TableCell>
              <TableCell>API Endpoints</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cspmList.map((val, i) => {
              const navigateTo = () =>
                navigate(`/cspm-integrations/${val.id}`, { state: val });
              return (
                <TableRow key={i.toString()}>
                  <TableCell>
                    <a onClick={navigateTo}>
                      <StyledLink sx={{ cursor: 'pointer' }}>
                        {val.name}
                      </StyledLink>
                    </a>
                  </TableCell>
                  <TableCell>
                    <Regions list={val.regions} />
                  </TableCell>
                  <TableCell>{val.type}</TableCell>
                  {/* status value can be ACTIVE */}
                  <TableCell>
                    <Status flag={val.status} />
                  </TableCell>
                  <TableCell>
                    <Connectivity
                      status={val.connectionStatus}
                      time={val.connectionCheckedDate}
                    />
                  </TableCell>
                  <TableCell>{val.apiHosts}</TableCell>
                  <TableCell>{val.apiEndpoints}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <NewIntegration
        open={newIntegrationDialog}
        handleClose={handleNewIntegration}
        handleSubmit={handleNewIntegrationSubmit}
      />
    </PageContent>
  );
};

export default CspmIntegrations;
