import React, { memo, useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { isEqual } from 'lodash';
import {colors} from "../assets/theme";

const SpyPieChart = ({
  chartId,
  data,
  handleChart,
  chartHeight,
  chartConfig,
  otherServersCount,
  hostCount,
  hostTypeFilter
}) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const chart = createChart();
    setChartData(chart, data, hostTypeFilter);
    chartRef.current = chart;
    return () => {
      if (chart) chart.dispose();
    };
  }, [data, hostTypeFilter]);

  const createChart = () => {
    if (!chartId || !data) return;

    const totalCount =
      data?.map((i) => i.count).reduce((a, b) => a + b, 0) || 1;
    let config = Object.assign(
      {
        type: '3d',
        disableLabels: true,
        disableLegend: true,
      },
      chartConfig
    );
    let is3D = config.type === '3d';

    const chart = am4core.create(
      chartId,
      is3D ? am4charts.PieChart3D : am4charts.PieChart
    );

    chart.innerRadius = 80;
    chart.depth = 10;

    // map chart to data properties
    let pieSeries = chart.series.push(
      is3D ? new am4charts.PieSeries3D() : new am4charts.PieSeries()
    );
    pieSeries.dataFields.value = 'count';
    pieSeries.dataFields.category = 'name';

    pieSeries.slices.template.propertyFields.fill = 'color';

    pieSeries.labels.template.disabled = true;
    pieSeries.labels.template.fontSize = 14;
    pieSeries.labels.template.text = '{name}';
    pieSeries.ticks.template.disabled = false;

    /* This creates initial animation */
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 700) {
          return true;
        }
        return false;
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.PieSeries) {
          var state = target.states.create(stateId);

          var labelState = target.labels.template.states.create(stateId);
          chart.radius = chart.data.length < 5 ? 120 : 180;
          chart.innerRadius = chart.data.length < 5 ? 40 : 60;
        }

        return null;
      },
    });

    return chart;
  };

  const setChartData = (chart, data, hostTypeFilter) => {
    if (!data || !chart) return;
    chart.data = [];
    data.filter(item => item.count !== 0).forEach((i, index) => {
        chart.data.push({
          name: i.name,
          count: hostTypeFilter.allFilter ? i.countAttempted : hostTypeFilter.nonApiFilter ? i.nonApiCount : i.count,
          color: i.color || colors.charts[index % 10],
        });
    });
  };

  return (
    <div>
      <div
        id={chartId}
        style={{
          maxWidth: '100%',
          marginLeft: 0,
          height: data.length < 5 ? '250px' : '350px',
        }}
      />
    </div>
  );
};

export default SpyPieChart;
