import React from 'react';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  '& svg': {
    fontSize: '14px',
  },
}));

const TextWithIcon = ({ children, className, onClick }) => {
  const args = {}
  if(onClick){
    args.onClick = onClick
  }
  return <StyledDiv className={className} {...args}>{children}</StyledDiv>;
};

export default TextWithIcon;
