import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, IconButton, Grid } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useGlobalState } from '../../store/globalState';
import { colors } from '../../assets/theme';
import CqIcon from '../common/CqIcon';
import UserMenu from './UserMenu';
import SpSnackBar from '../SpSnackBar';
import { none } from '@hookstate/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.gray,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: 10,
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    padding: 0,
    minHeight: 0, // allows the header to expand vertically (it's a flex container)
    position: 'relative',
  },
  title1: {
    color: theme.palette.text.nearlyWhite,
    fontWeight: 700,
    fontSize: '16px',
  },
  rightMenu: {
    right: 0,
    position: 'absolute',
    marginLeft: 'auto',
  },
  drawerIcon: {
    marginLeft: '-5px',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  arrowIcon: {
    marginTop: 1,
  },
}));

const Header = ({ drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const global = useGlobalState();
  const { notifications } = global.get();

  const deleteNotification = (id) => {
    const deletedItem = notifications.findIndex((item) => item.id === id);
    global.notifications[deletedItem].set(none);
  };

  return (
    <AppBar
      position={'relative'}
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen,
      })}
    >
      {notifications?.length > 0 && (
        <SpSnackBar
          notifications={notifications}
          isOpen={notifications.length > 0}
          onNotificationDelete={deleteNotification}
          autoHideDuration={2000}
        />
      )}
      <Toolbar className={classes.toolbar}>
        <div className={classes.rightMenu}>
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
