import React, { useState, useContext, useEffect } from "react";
import CqDialog from "../../components/common/CqDialog";
import {
  Grid,
  Link,
  Typography,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  TableContainer,
  InputLabel,
  CircularProgress,
  styled
} from '@mui/material';
import { colors } from "../../assets/theme";
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { pageStyles } from "./styles";
import downloadServersPageExcel from '../../components/downloadServersPageExcel';
import SpSnackBar from '../../components/SpSnackBar';
import Search from '../../components/Search';

const StyledDomainListItem = styled('span')(({ disabled }) => ({
  color: disabled ? colors.gray400 : colors.white,
}));

const XLSDialog = ({openXLSDialog, handleExportClose, domains, userInfo, issueTypes}) => {
  const classes = pageStyles()
  const contextData = useContext(WrapperContext)
  const userData = contextData.userInfo;
  const [XLSDialogError, setXLSDialogError] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [dialogInProgress, setDialogInProgress] = useState(false);
  const [selectedDomainsXLSList, setSelectedDomainsXLSList] = useState([]);

  useEffect(() => {
    const selectedXLSDomains = domains.map((i) => {
      return { name: i.name, selected: false };
    });
    setSelectedDomainsXLSList(selectedXLSDomains);
  }, [domains])

  const filteredXLSDomainData = selectedDomainsXLSList?.filter((d) =>
    d.name?.includes(searchValue)
  );

  const downloadServersXLS = async () => {
    setDialogInProgress(true);
    let selectedDomains = selectedDomainsXLSList
      .filter((i) => i.selected === true)
      .map((i) => i.name);
    const params = {
      domains: selectedDomains, // single domain is allowed
      // statusCodes:
      //   statusCodesList.length === 8
      //     ? []
      //     : (statusCodesList || []).map((s) => `${s}`), // making status codes a string
      hostingProviders: [],
      issueTypes: [],
      userInfo: userData,
      authUserData: userInfo,
    };

    downloadServersPageExcel(params, issueTypes)
      .catch((error) => {
        setXLSDialogError(true);
        console.error(error);
      })
      .finally(() => {
        setDialogInProgress(false);
        handleClose()
      });
  };

  const selectedDomainsCount = selectedDomainsXLSList.filter(
    (i) => i.selected === true
  ).length;

  const handleClose = () => {
    const data = selectedDomainsXLSList.map((i) => {
      return { ...i, selected: false };
    });
    setSelectedDomainsXLSList(data);
    handleExportClose()
    setDialogInProgress(false);
  }

  const handleDomainsSelection = (event, item) => {
    const data = selectedDomainsXLSList.map((i) =>
      i.name === item.name ? { ...i, selected: !i['selected'] } : i
    );
    setSelectedDomainsXLSList(data);
  };

  const handleDomainsAllSelectClick = (event) => {
    const data = selectedDomainsXLSList.map((i) =>
      event.target.checked
        ? { ...i, selected: true }
        : { ...i, selected: false }
    );
    setSelectedDomainsXLSList(data);
  };

  const unselectDomainSelection = () => {
    const data = selectedDomainsXLSList.map((i) => {
      i.selected = false;
      return i;
    });
    setSelectedDomainsXLSList(data);
  };

  return (
    <>
      <CqDialog
        title={'Export as XLS'}
        open={openXLSDialog}
        onClose={handleClose}
        onSubmit={downloadServersXLS}
        submitBtnDisabled={selectedDomainsCount <= 0 || dialogInProgress}
        maxWidth="sm"
        closeIcon={true}
        noScroll={'true'}
      >
        <Grid container>
          {dialogInProgress && (
            <div className={classes.dialogProgressContainer}>
              <CircularProgress />
            </div>
          )}
          {!dialogInProgress && (
            <Grid item xs={12}>
              {/*<Grid container item>*/}
              {/*    <Typography variant={'body2'}>*/}
              {/*        Select up to 10 domains to create XLS report.*/}
              {/*    </Typography>*/}
              {/*</Grid>*/}
              <Search
                auto-id={'domain-search-bar'}
                localSearchOp={(x) => setSearchValue(x)}
                searchLabel="Domains"
                searchColor={false}
                width={537}
                height={40}
                marginLeft={0}
              />
              <TableContainer
                className={classes.root}
                style={{ height: 300, overflowY: 'scroll' }}
              >
                <Table>
                  <TableBody>
                    {filteredXLSDomainData.map((c, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Checkbox
                            className={classes.domainSelection}
                            checked={!!c.selected}
                            onClick={(event) =>
                              handleDomainsSelection(event, c)
                            }
                          />
                          <StyledDomainListItem>
                            {c.name}
                          </StyledDomainListItem>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid className={classes.domainSelectionContainer}>
                <Grid container className={classes.domainSelectionText}>
                  <Grid item>
                    <Checkbox
                      auto-id={'select-all'}
                      id="select-all"
                      className={classes.domainSelection}
                      checked={
                        selectedDomainsXLSList.filter(
                          (i) => i.selected === false
                        ).length <= 0
                      }
                      onChange={(event) =>
                        handleDomainsAllSelectClick(event)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant={'body2'}
                      className={classes.domainSelectionText}
                    >
                      <InputLabel htmlFor="select-all">
                        <Typography variant={'body2'}>
                          Select All
                        </Typography>
                      </InputLabel>
                    </Typography>
                  </Grid>
                  <Grid item>
                    {selectedDomainsCount > 0 && (
                      <>
                        <Typography
                          variant={'body2'}
                          className={classes.domainSelectionText}
                        >
                          <span>
                            {' '}
                            {selectedDomainsCount} domains selected{' '}
                          </span>
                          <Link onClick={unselectDomainSelection}>
                            unselect all
                          </Link>
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CqDialog>
      <SpSnackBar
        isOpen={XLSDialogError}
        notifications={[
          {
            id: '1',
            message: `XLS report generation failed. Please refresh the page and try again.`,
            category: 'error',
          },
        ]}
        onNotificationDelete={() => setXLSDialogError(false)}
      />
    </>
  )
}

export default XLSDialog