import { styled } from '@mui/material/styles';
import { Grid, Typography, SvgIcon } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { colors } from '../../assets/theme';

export const StyledContainer = styled(Grid)({
  height: 'auto',
  maxHeight: 600,
  backgroundColor: colors.gray900,
  overflow: 'scroll',
})

export const StylesSubtitle = styled(Typography)({
  color: colors.gray100,
  marginLeft: 10,
  height: 30,
  marginTop: 10,
})

export const StylesIp = styled(Typography)({
  color: colors.gray100,
  height: 30,
  paddingTop: 16,
})

export const StyledCopyContent = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '10px',
})

export const StatusContent = styled(Typography)({
  color: colors.orange100,
  fontSize: 12,
  marginLeft: 10,
})

export const StyledContentCopyIcon = styled(ContentCopyIcon)({
  cursor: 'pointer',
    '&:hover': {
      color: colors.blueButtonBg,
    },
})

export const StyledSvg =styled(SvgIcon)({
  width: '90px',
  marginLeft: '-20px',
  marginTop: '13px',
})

export const StyledUrl = styled(Typography)({
  color: colors.blue300,
  cursor: 'pointer',
  padding: '10px',
  '&:hover': {
    textDecoration: 'underline',
  },
})
