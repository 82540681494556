import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { colors } from '../assets/theme';

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  minHeight: theme.spacing(4.5),
  '&.MuiTabs-indicator': {
    color: colors.blue300,
  },
}));

export const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    minHeight: theme.spacing(4.5),
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    padding: theme.spacing(0),
    margin: theme.spacing(0, 4, 0, 0),
    '&.Mui-selected': {
      color: colors.white,
    },
    color: colors.gray300,
    lineHeight: '24px',
    fontWeight: 500,
  })
);

export const CustomTabPanel = (props) => {
  const { children, value, tabId, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== tabId} {...other}>
      {value === tabId && <Box sx={{ p: 0 }}>{children}</Box>}
    </Box>
  );
};
