import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import saveAs from 'file-saver';
import moment from 'moment';

import { graphqlQuery } from '../../services/graphql';

import { updateServersSheet } from './serverSheet';
import { updateEndpointsSheet } from './endpointsSheet';
import { updateContextSheet } from './contextSheet';
import { getDomainServersPageXLSReport } from '../../pages/servers/serversGraphQL';

const createServersSheet = (domain, workbook, i) => {
  domain = domain.length <= 20 ? domain : domain.substring(0, 18);
  let str = `${i}-${domain} API Hosts`;
  const serversSheet = workbook.addWorksheet(str);
  serversSheet.columns = [
    { header: 'Server', width: 60 },
    { header: 'App Hosting Provider', width: 40 },
    { header: 'CNAME', width: 60 },
    { header: 'IP Addresses', width: 60 },
    { header: 'Protocol', width: 40 },
    { header: 'API Host', width: 40 },
    { header: 'HTML Response Percentage', width: 40 },
    { header: 'XML Response Percentage', width: 40 },
    { header: 'JSON Response Percentage', width: 40 },
    { header: 'Other Response (Include Text) Percentage', width: 40 },
    { header: 'Findings: High', width: 40 },
    { header: 'Findings: Medium', width: 40 },
    { header: 'Findings: Low', width: 40 },
  ];

  serversSheet.lastRow.eachCell(function (cell) {
    cell.font = { bold: true };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  return serversSheet;
};

const createApiEndpointsSheet = (domain, workbook, i) => {
  domain = domain.length <= 20 ? domain : domain.substring(0, 18);
  let str = `${i}-${domain} API Endpoints`;

  const sheet = workbook.addWorksheet(str);
  sheet.columns = [
    { header: 'Server', width: 60 },
    { header: 'Path', width: 40 },
    { header: 'Method', width: 40 },
    { header: 'Status', width: 20 },
    { header: 'Findings: High', width: 40 },
    { header: 'Findings: Medium', width: 40 },
    { header: 'Findings: Low', width: 40 },
  ];
  sheet.lastRow.eachCell(function (cell) {
    cell.font = { bold: true };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  return sheet;
};

const createSummarySheet = (workbook) => {
  const summarySheet = workbook.addWorksheet('Summary');
  summarySheet.columns = [
    { header: 'Domain', width: 30 },
    { header: 'API Hosts', width: 30 },
    { header: 'API Endpoints', width: 30 },
    { header: 'Hosting Providers', width: 30 },
    { header: 'High Vulnerabilties', width: 30 },
    { header: 'Medium Vulnerabilties', width: 30 },
    { header: 'Low Vulnerabilties', width: 30 },
    { header: 'Total', width: 30 },
  ];
  summarySheet.lastRow.eachCell(function (cell) {
    cell.font = { bold: true };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  return summarySheet;
};

const updateSummarySheet = (summarySheet, data) => {
  const aggregate = {
    apiServersCount: 0,
    endpointsCount: 0,
    total: 0,
    high: 0,
    medium: 0,
    info: 0,
    hostingServersCount: 0,
  };
  for (const i of data) {
    aggregate.apiServersCount = i.apiServersCount + aggregate.apiServersCount;
    aggregate.endpointsCount = i.apiEndpointsCount + aggregate.endpointsCount;
    aggregate.hostingServersCount = i.hostCount + aggregate.hostingServersCount;
    aggregate.high = aggregate.high + i.high;
    aggregate.medium = aggregate.medium + i.medium;
    aggregate.info = aggregate.info + i.info;
    aggregate.total = aggregate.total + i.total;
    summarySheet.addRow([
      i.domain,
      i.apiServersCount || '--',
      i.apiEndpointsCount || '--',
      i.hostCount || '--',
      i.high || '--',
      i.medium || '--',
      i.info || '--',
      i.total || '--',
    ]);
  }
  summarySheet.addRow([
    'Aggregate',
    aggregate.apiServersCount.toLocaleString(),
    aggregate.endpointsCount.toLocaleString(),
    aggregate.hostingServersCount.toLocaleString(),
    aggregate.high.toLocaleString(),
    aggregate.medium.toLocaleString(),
    aggregate.info.toLocaleString(),
    aggregate.total.toLocaleString(),
  ]);
  summarySheet.lastRow.eachCell(function (cell) {
    cell.font = { bold: true };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
};

const downloadServersPageExcel = async (params, issueTypes) => {
  const workbook = new ExcelJS.Workbook();
  const summarySheet = createSummarySheet(workbook);
  const contextSheet = workbook.addWorksheet('Context');
  updateContextSheet(contextSheet);
  const req = {
    domain: params.domains,
  };
  if (params.crawlDate) {
    req.crawlDate = params.crawlDate;
  }
  const response = await graphqlQuery(getDomainServersPageXLSReport, req);
  const reportData =
    response?.data?.discovery?.getDomainServersPageXLSReport || {};
  let serverIndex = 1;
  let endpointIndex = 1;
  const summaryReport = reportData.domainsData;
  const serversData = new Map();
  const endpointsData = new Map();

  reportData.domainsData.forEach((i) => {
    serversData.set(i.domain, []);
    endpointsData.set(i.domain, []);
  });
  if (reportData.apiServers.length > 0) {
    for (const server of reportData.apiServers) {
      const dList = serversData.get(server.domain) || [];
      dList.push({
        hostname: server.hostname,
        findings: server.findings,
        issues: server.findings.issues,
        providers: server.providers[0],
        ips: server.ips,
        cname: server.cname,
        protocol: server?.tlsInfo?.protocol || '',
        endpoints: server?.endpoints,
        isApi: server?.isApi,
      });
    }
  }

  for (const [k, v] of serversData.entries()) {
    if (v.length > 0) {
      const serversSheet = createServersSheet(k, workbook, serverIndex);
      updateServersSheet(v, serversSheet, issueTypes);
    }
    serverIndex++;
  }

  updateSummarySheet(summarySheet, summaryReport);
  const buffer = await workbook.xlsx.writeBuffer();
  const downloadDate = params.crawlDate
    ? moment(params.crawlDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');
  const tenant = localStorage.getItem('activeTenantId');
  const tenantInfo = params?.userInfo?.userTenants?.find(
    (val) => (val.tenantId = tenant)
  );
  saveAs(
    new Blob([buffer], { type: 'application/octet-stream' }),
    `${
      tenantInfo?.tenantName || params.authUserData.tenantName
    } ${downloadDate}.xlsx`
  );
};

export default downloadServersPageExcel;
