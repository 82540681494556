import React, { useState, useEffect, useReducer, useContext } from 'react';

import { Link } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { initialState, reducer } from './rulesReducer';
import { colors } from '../../assets/theme';
import { CrawlContext } from './crawlContext';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CrawlRuleDialog from './CrawlRuleDialog';
import AddSubDomainDialog from './AddSubDomainDialog';
import CatalogFilters from './CatalogFilters';
import CatalogTable from './CatalogTable';
import CqLoading from '../../components/common/CqLoading';
import SpSnackBar from '../../components/SpSnackBar';
import CqDialog from '../../components/common/CqDialog';
import { deleteRuleObj } from './helpers';
import CqButton from '../../components/common/CqButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  updateRuleStatus,
  fetchCrawlCatalog,
  updateRulesData,
  updateRules,
} from './providers';
import { WrapperContext } from '../../components/wrapper/wrapperContext';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
  },
  backButton: {
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.blue300,
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    display: 'inline-flex',
    '& span': {
      marginLeft: 4,
    },
  },
  titleContainer: {
    paddingTop: '4px',
  },
  subTitleContainer: {},
  buttonContainer: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'end',
    paddingBottom: '4px',
  },
  deleteTitle: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}));

const DeleteTitle = (
  <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
    <DeleteIcon /> <span>Delete Rule</span>
  </div>
);

const CrawlCatalog = () => {
  const classes = useStyles();
  const { domainId } = useParams();
  const newCrawlContext = useContext(WrapperContext);
  const [openDialog, setDialogOpen] = useState({
    ruleDialog: false,
    subdomainDialog: false,
    deleteDialog: false,
  });
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [notify, setNotify] = useState({
    open: false,
    error: false,
    success: false,
    message: '',
    loading: false,
  });
  const [filters, setFilters] = useState({
    status: '',
    search: '',
    sortSubdomain: false,
  });

  const domainName = domainId.replaceAll('_', '.');

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(async () => {
    setNotify((notify) => ({ ...notify, loading: true }));
    const data = await fetchCrawlCatalog(domainName);
    dispatch({ type: 'loadData', value: data });
    setNotify((notify) => ({ ...notify, loading: false }));
  }, []);
  const { rules, subDomains, config } = state;

  const deleteRule = () => {
    const newrules = deleteRuleObj(rules, state.editRule);
    setNotify((state) => ({ ...state, loading: true }));
    setDialogOpen((state) => ({ ...state, deleteDialog: false }));
    updateRules(domainName, newrules)
      .then(() => fetchCrawlCatalog(domainName))
      .then((res) => {
        dispatch({ type: 'loadData', value: res });
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          success: true,
          message: 'Deleted Crawl rule successfully!',
        }));
      })
      .catch(() => {
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          error: true,
          message: 'Something went wrong while deleting Crawl rule!',
        }));
        dispatch({});
      });
  };

  const getSubdomains = () => {
    let filterData = subDomains;

    if (filters.search !== '') {
      filterData = subDomains.filter((d) =>
        d.subdomain.includes(filters.search)
      );
    }
    if (filters.status !== '') {
      if (filters.status === 'All') {
        filterData = subDomains;
      } else {
        const statusFilter = filters.status === 'Enabled';
        filterData = subDomains.filter((d) => statusFilter === d.active);
      }
    }
    return filterData;
  };

  const closeRuleDialog = () => {
    setDialogOpen((state) => ({ ...state, ruleDialog: false }));
  };

  const setSubDomainDialogOpen = (val) => {
    setDialogOpen((state) => ({ ...state, subdomainDialog: val }));
  };

  const handleOpenSubDomain = () => {
    setSubDomainDialogOpen(true);
  };

  const handleNewCrawl = () => {
    newCrawlContext.showNewCrawl(domainName, config);
  };

  const handlePageChange = (event, page) => {
    setPageNum(page);
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    setPageSize(value);
    setPageNum(0);
  };

  const handleSort = () => {
    const sortDomain = !filters.sortSubdomain;
    setFilters((filters) => ({ ...filters, sortSubdomain: sortDomain }));
    const sortedList = [...subDomains];
    if (sortDomain) {
      sortedList.sort((prev, curr) =>
        prev.subdomain - curr.subdomain ? 1 : -1
      );
    } else {
      sortedList.sort((prev, curr) =>
        prev.subdomain - curr.subdomain ? 1 : -1
      );
    }
    dispatch({ type: 'updateSubdomains', value: sortedList });
  };
  return (
    <CrawlContext.Provider
      value={{
        rules,
        subDomains,
        setDialogOpen,
        setNotify,
        dispatch,
        updateRuleStatus,
        updateRulesData,
        domain: domainName,
      }}
    >
      <div className={classes.pageContent}>
        <Link to="/domains" className={classes.link}>
          <Typography className={classes.backButton}>
            <ArrowBackIcon sx={{ fontSize: 20 }} />
            <span>Back To Domains </span>
          </Typography>
        </Link>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={8}>
            <Typography variant="h4" className={classes.subTitleContainer}>
              Crawl Catalog ({domainName})
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.buttonContainer}>
            <CqButton variant="borderBlue" onClick={handleOpenSubDomain}>
              <AddCircleOutlineIcon />
              <span>Add Subdomain</span>
            </CqButton>
            <CqButton variant="borderBlue" onClick={handleNewCrawl}>
              <PublishedWithChangesIcon />
              <span>Crawl Now</span>
            </CqButton>
          </Grid>
        </Grid>
        {notify.loading ? (
          <CqLoading />
        ) : (
          <>
            <CatalogFilters filters={filters} updateFilters={setFilters} />
            <CatalogTable
              subdomains={getSubdomains()}
              setSubDomainDialogOpen={setSubDomainDialogOpen}
              pageNum={pageNum}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              sortByName={filters.sortSubdomain}
              handleSort={handleSort}
              domainName={domainName}
            />
          </>
        )}
        <CrawlRuleDialog
          open={openDialog.ruleDialog}
          onClose={closeRuleDialog}
          ruleInfo={state.editRule}
        />
        <AddSubDomainDialog
          open={openDialog.subdomainDialog}
          onClose={setSubDomainDialogOpen}
        />
        <CqDialog
          title={DeleteTitle}
          open={openDialog.deleteDialog}
          onClose={() =>
            setDialogOpen((state) => ({ ...state, deleteDialog: false }))
          }
          onSubmit={deleteRule}
          submitBtnText="Delete Rule"
          maxWidth="sm"
        >
          Are you sure you would like to delete this rule? You will not be able
          to access this rule again once it has been deleted.
        </CqDialog>
        <SpSnackBar
          isOpen={notify.open}
          autoHideDuration={5000}
          notifications={[
            {
              id: '1',
              message: notify.message,
              category: notify.success ? 'success' : 'error',
            },
          ]}
          onNotificationDelete={() =>
            setNotify({ open: false, error: false, success: false })
          }
        />
      </div>
    </CrawlContext.Provider>
  );
};

export default CrawlCatalog;
