import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { colors, enhancedTableStyles } from '../../../assets/theme';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { SwapHoriz } from '@mui/icons-material';
import TextWithIcon from '../../../components/common/TextWithIcon';

const useStyles = makeStyles((theme) => ({
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  rowSelection: {
    cursor: 'pointer',
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div': {
      visibility: 'visible',
    },
  },
  scrollTable: {
    height: '500px !important',
    overflowY: 'auto',
  },
  actionsWrapper: {
    visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
  },
}));

const CustomTableHeaderCell = ({ column, callback, type }) => {
  const classes = useStyles();
  const updateTable = () => {
    if (column.sort) {
      callback();
    }
  };
  return (
    <TableCell className={classes.headerRowCell}>
      <Typography variant="subtitle2">
        <TextWithIcon onClick={updateTable}>
          <span>{column.name}</span>
          {column.sort && (
            <>{type === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</>
          )}
        </TextWithIcon>
      </Typography>
    </TableCell>
  );
};

const VulnerabilitiesDetailsTable = ({
  columns,
  vulnerabilityServers,
  getRequestResponse,
}) => {
  const tableClasses = enhancedTableStyles();
  const classes = useStyles();
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortStatus, setSortStatus] = useState('desc');
  const [vulnServers, setVulnServers] = useState(
    vulnerabilityServers instanceof Array
      ? vulnerabilityServers?.sort((a, b) => (a?.status < b?.status ? 1 : -1))
      : []
  );

  const handlePageChange = (event, page) => {
    setPageNum(page);
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    setPageSize(value);
    setPageNum(0);
  };

  const updateSort = () => {
    const sort = sortStatus === 'asc' ? 'desc' : 'asc';
    setSortStatus(sort);
    setVulnServers((endpoints) => {
      const endpointsCopy = [...endpoints];
      return endpointsCopy.sort((a, b) => {
        if (sort === 'desc') {
          return a.status < b.status ? 1 : -1;
        } else {
          return a.status > b.status ? 1 : -1;
        }
      });
    });
  };

  return (
    <Grid
      style={{ marginTop: 20, width: '100%' }}
      className={vulnerabilityServers > 10 ? classes.scrollTable : ''}
    >
      <TableContainer className={tableClasses.root}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((c, i) => (
                <CustomTableHeaderCell
                  key={`column-${i + 1}`}
                  column={c}
                  callback={updateSort}
                  type={sortStatus}
                />
              ))}
              <TableCell style={{ width: '60px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {vulnServers?.map((d, i) => {
              return (
                <TableRow className={classes.rowSelection} key={`${d}-${i}`}>
                  <TableCell>
                    <Typography variant={'subtitle1'}>
                      <Link
                        target={'_blank'}
                        href={`https://${d.hostname}${d.path}`}
                        style={{ color: colors.blue300, cursor: 'pointer' }}
                      >
                        {d.path}
                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'subtitle1'}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {d.method}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'subtitle1'}>{d.status}</Typography>
                  </TableCell>
                  <TableCell>
                    <div className={classes.actionsWrapper}>
                      <SwapHoriz
                        onClick={() =>
                          getRequestResponse(d, vulnerabilityServers.server)
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {vulnerabilityServers > 10 && (
          <TablePagination
            component="div"
            count={vulnerabilityServers || 0}
            page={pageNum}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
        )}
      </TableContainer>
    </Grid>
  );
};

export default VulnerabilitiesDetailsTable;
