import React, { useState, useEffect } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import moment from 'moment';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui'
import { Box, InputLabel, TextField, Select, MenuItem, InputAdornment } from '@mui/material';
import CqButton from '../../components/common/CqButton';
import TextWithIcon from '../../components/common/TextWithIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CqDialog from '../../components/common/CqDialog';
import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import { getClientsList, createNewClient, deleteClient } from './userGraphQL'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { colors } from '../../assets/theme';
import CqLoading from '../../components/common/CqLoading';

function CustomToolbar(props) {
  const {totalCount, setClientDialog} = props
  return (
    <Box sx={{ height: '36px', border: '1px solid #333', paddingBottom: '8px', boxSizing: 'unset', display: 'flex' }}>
      <Box sx={{ alignItems: 'center', display: 'flex', paddingLeft: '4px' }}>
        <>{totalCount} Rows</>
      </Box>
      <Box sx={{marginLeft: 'auto'}}>
        <CqButton onClick={()=> setClientDialog(true)}>
          <TextWithIcon>
            <AddCircleOutlineIcon />
            <span>Add New Client</span>
          </TextWithIcon>
        </CqButton>
      </Box>
    </Box>
  );
}

const ClientsTable = () => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const [addClientDialog, setClientDialog] = useState(false)
  const [expiration, setExpiration] = useState(-1)
  const [clientName, setClientName] = useState('')
  const [clientData, setClientData] = useState([])
  const [clientSecret, setClientSecret] = useState({id: '', secret: ''})
  const [clipBoard, setClipboard] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listLoading, setListloading] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const fetchClients = async() => {
    try {
      const clientresponse = await graphqlQuery(getClientsList)
      const clientIdData = clientresponse?.data?.users?.clientsList?.data || []
      setClientData(clientIdData)
      setListloading(false)
    } catch(e) {
      console.log(e)
    }
  }
  
  useEffect(() => {
    setListloading(true)
    fetchClients()
  }, [])

  const createClient = async(name, exp) => {
    try {
      const res = await graphqlMutation(createNewClient, {clientName: name, expiration: exp})
      const data = res?.data?.users?.createClient
      setClientSecret(val => ({...val, id: data?.clientid || '', secret: data?.clientSecret || ''}))
    } catch (e) {
      console.log(e)
    }
  }

  const updateClientsList = async () => {
    setLoading(true)
    setListloading(true)
    await createClient(clientName, parseInt(expiration))
    fetchClients()
    handleDialogClose()
  }

  const handleDialogClose = () => {
    setClientDialog(false)
    setClientName('')
    setExpiration('')
    setLoading(false)
  }

  const copyToClipBoard = () => {
    navigator?.clipboard?.writeText(clientSecret.secret)
    setClipboard(true)
  }

  const deleteAccess = async() => {
    try {
      setListloading(true)
      if(deleteId !== ''){
        await graphqlMutation(deleteClient, {id: deleteId})
        fetchClients()
        setDeleteId('')
      }
    }
    catch(e) {
      console.log(e)
      setDeleteId('')
    }
  }

  const columns = [
    {field: 'clientName',  headerName: 'Name',  sortingOrder: ['desc', 'asc'], flex: 1},
    {field: 'clientId',  headerName: 'Client ID',  sortingOrder: ['desc', 'asc'], flex: 1},
    {field: 'createdDate',  headerName: 'Created Date',  sortingOrder: ['desc', 'asc'], flex: 1},
    {field: 'status',  headerName: 'Status',  sortingOrder: ['desc', 'asc'], flex: 1},
    {field: 'expiration',  headerName: 'Expiration', sortingOrder: ['desc', 'asc'], flex: 1},
    {field: 'action',  headerName: 'Actions',  minWidth: 150, sortable: false, filterable: false, flex: 1, renderCell: (params) =>  <a onClick={() => setDeleteId(params?.row?.id)}><DeleteIcon /></a> }
  ];

  const dataGridInitialState = {
    rows: [],
    columns: [],
  }
  let ind = 0
  const data = {
    rows: clientData.map(val => {
      val.id = val.id
      val.expirationDate = val.expirationDate + ' Days'
      ind++
      val.action = ''
      return val
    }),
    columns: columns,
  }

  let deleteKeyObj = data.rows.find(val => val.id === deleteId)
  return (
    <Box sx={{ height: 'calc(100vh)', width: 'calc(100% - 12px)' }}>
      {listLoading ? <CqLoading/> :
        <DataGridPremium
          {...data}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{toolbar: {totalCount: clientData.length, setClientDialog: setClientDialog}}}
          initialState={dataGridInitialState}
          headerHeight={36}
          rowCount={clientData.length}
          hideFooter
          sx={dataGridStyles}
          disableAggregation
          disableRowGrouping
        />
      }
      <CqDialog
        title={'Add New Client'}
        open={addClientDialog}
        onClose={handleDialogClose}
        onSubmit={updateClientsList}
        submitBtnDisabled={clientName === '' && expiration === -1}
        maxWidth="md"
        closeIcon={true}>
          {loading ? <CqLoading /> : <>
            <InputLabel>Name</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Client Name"
              onChange={(e) => setClientName(e.target.value)}
              value={clientName}
            />
            <Box sx={{padding: '20px'}}/>
            <InputLabel id='expiration-field'>Expiration</InputLabel>
            <Select
              labelId='expiration-field'
              fullWidth
              value={expiration}
              label="Select Expiration Date"
              onChange={(e) => setExpiration(e.target.value)}
            >
              <MenuItem value={-1}>Select</MenuItem>
              <MenuItem value={7}>7 Days</MenuItem>
              <MenuItem value={14}>14 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={180}>180 Days</MenuItem>
              <MenuItem value={360}>360 Days</MenuItem>
              <MenuItem value={0}>Never</MenuItem>
            </Select>
          </>}
      </CqDialog>
      <CqDialog
        open={clientSecret.id !== '' && clientSecret.secret !== ''}
        closeIcon={true}
        showSubmitBtn={false}
        onClose={() => {
          setClientSecret({id: '', secret: ''})
          setClipboard(false)
        }}
        title={'Access key successfully created.'}
      >
        {clipBoard && <Box sx={{textAlign: 'center', color: colors.gray300}}>
          Copied to clipboard
        </Box>}
        <InputLabel>Client ID</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          disabled
          value={clientSecret.id}
        />
        <InputLabel>Client Secret</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          disabled
          value={clientSecret.secret}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{cursor: 'pointer'}} onClick={copyToClipBoard}>
                <ContentCopyIcon />
              </InputAdornment>
            )
          }}
        />
      </CqDialog>
      <CqDialog
        open={deleteId !== ''}
        closeIcon={true}
        onClose={() => {
          setDeleteId('')
        }}
        onSubmit={deleteAccess}
        title='Delete Client'
        submitBtnText='Confirm'
      >
        Deleting Client <b>{deleteKeyObj?.clientName || ''}</b>.
      </CqDialog>
  </Box>
  )
}

export default ClientsTable