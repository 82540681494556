import React from 'react';

import TextWithIcon from '../../../components/common/TextWithIcon';
import { StyledCircle } from '../styles';
import { colors } from '../../../assets/theme';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const Connectivity = ({ status, time }) => {
  return (
    <Box>
      <TextWithIcon>
        <StyledCircle
          color={status === 'CONNECTED' ? colors.green200 : colors.red300}
        />
        <Typography variant="subtitle2">
          {status === 'CONNECTED' ? 'Connected' : 'Not Connected'}
        </Typography>
      </TextWithIcon>
      <Typography variant="caption" display={'block'} sx={{pl: 1}}>
        ({moment(time).fromNow()})
      </Typography>
    </Box>
  );
};

export default Connectivity;
