import React from 'react';
import { Link, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import { colors } from '../../assets/theme';

import CqSelect from '../../components/common/CqSelect';
import CqSearch from '../../components/common/CqSearch';
import RulesMenu from './RulesMenu';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    backgroundColor: colors.gray850,
    alignItems: 'center',
  },
  filterContainer: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
    padding: '8px 0',
    '& a': {
      cursor: 'pointer',
    },
  },
  filterItem: {
    padding: '0 10px',
    borderRight: `1px solid ${colors.gray600}`,
  },
  noBorder: {
    border: 'none',
  },
  resetLink: {
    color: colors.blue300,
    textDecoration: 'none',
  },
});

const CatalogFilters = ({ filters, updateFilters }) => {
  const classes = useStyles();
  const setStatus = (val) => {
    updateFilters((state) => ({ ...state, status: val }));
  };
  const setSearch = (val) => {
    updateFilters((state) => ({ ...state, search: val }));
  };
  const resetFilters = () => updateFilters({ status: '', search: '' });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={8}>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <CqSearch
              placeholder="Search Sub-Domains"
              value={filters.search}
              onChange={setSearch}
            />
          </div>
          <div className={clsx(classes.filterItem, classes.noBorder)}>
            <CqSelect
              width={130}
              height={35}
              options={['All', 'Enabled', 'Disabled']}
              onChange={setStatus}
              value={filters.status}
              placeholder="Status"
            />
          </div>
          <Link className={classes.resetLink} onClick={resetFilters}>
            Reset Filters
          </Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <RulesMenu />
      </Grid>
    </Grid>
  );
};

export default CatalogFilters;
