export const timeRangeFilters = [
  {
    name: 'Last Day',
    selected: false,
  },
  {
    name: 'Last Week',
    selected: true,
  },
  {
    name: 'Last 30 days',
    selected: false,
  },
];

export const severityFilters = [
  {
    name: 'HIGH',
    selected: false,
  },
  {
    name: 'MEDIUM',
    selected: false,
  },
  {
    name: 'INFO',
    selected: false,
  },
];
