import React, { useContext } from 'react';
import Domains from './Domains';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import EmptyState from '../../components/EmptyState';

const DomainsIndex = () => {
  const { showEmptyState } = useContext(WrapperContext)
  return (
    !showEmptyState ? <Domains /> : <EmptyState showEmptyState={showEmptyState} />
  )
}

export default DomainsIndex