import React, { useState } from 'react';
import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';


import CqDialog from './common/CqDialog';
import { colors, cqDialogInputStyles } from '../assets/theme';
import { addDomain } from '../lib/newDomain';

export const NewDomainDialog = ({ newDomainRequestDialogOpened, setNewDomainRequestDialogOpened, global, retrieveData }) => {

  const [isAutomaticCrawl, setIsAutomaticCrawl] = useState(true);
  const [isIntrusiveCrawl, setIsIntrusiveCrawl] = useState(false);
  const [newDomainName, setNewDomainName] = useState('');
  const [newJustification, setNewJustification] = useState('');
  const [dialogInProgress, setDialogInProgress] = useState(false);

  const addNewDomain = async () => {
    addDomain(setDialogInProgress, newDomainName, newJustification, isAutomaticCrawl, isIntrusiveCrawl, global, setNewDomainRequestDialogOpened, retrieveData)
  };

  const dialogClasses = cqDialogInputStyles();
  return (
    <CqDialog
      title={'Request New Domain'}
      open={newDomainRequestDialogOpened}
      onClose={() => setNewDomainRequestDialogOpened(false)}
      onSubmit={addNewDomain}
      submitBtnDisabled={!newDomainName || !newJustification}
      maxWidth="sm"
      backdrop={dialogInProgress}
      closeIcon={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <InputLabel className={dialogClasses.dialogLabel}>
            Domain Name{' '}
            <span style={{ color: colors.red300, fontSize: 16 }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            value={newDomainName}
            size={'small'}
            className={dialogClasses.dialogInput}
            placeholder={'exampledomain.com'}
            onChange={(e) => setNewDomainName(e.target.value)}
          />
          <InputLabel className={dialogClasses.dialogInfo}>
            Enter a top level domain name (TLDN)
          </InputLabel>
        </Grid>
        <Grid item container style={{ marginTop: 10 }}>
          <Grid item>
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={isAutomaticCrawl}
                          onChange={() => setIsAutomaticCrawl(!isAutomaticCrawl)}
                          style ={{
                              color: colors.blueButtonBg,
                          }}
                      />}
                  label={<Typography>Crawl on Domain approval</Typography>}
              />
          </Grid>
          {isAutomaticCrawl &&
          <Grid item>
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={isIntrusiveCrawl}
                          onChange={() => setIsIntrusiveCrawl(!isIntrusiveCrawl)}
                          style={{
                              color: colors.blueButtonBg,
                          }}
                      />}
                  label={<Typography>Advanced Crawl</Typography>}
              />
          </Grid>
          }
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <InputLabel className={dialogClasses.dialogLabel}>
            Justification{' '}
            <span style={{ color: colors.red300, fontSize: 16 }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            value={newJustification}
            size={'small'}
            multiline
            minRows={4}
            className={dialogClasses.dialogInput}
            placeholder={
              'Enter a brief justification of why you are requesting to crawl this domain'
            }
            onChange={(e) => setNewJustification(e.target.value)}
          />
        </Grid>
      </Grid>
    </CqDialog>
  )
}