import {
  retrieveCrawlHistory,
  retrieveHostingProviders,
  setFiltersData,
} from './provider';

import { createContext } from 'react';

export const getFilters = async(serversFilters, activeDomains, issueTypes, setCrawlHistory, gatewayConfigData) => {
  let domain = '';
  let crawlId = '';

  if (serversFilters.selectedDomain !== '') {
    domain = serversFilters.selectedDomain;
  } else {
    domain = 'All'
  }
  let crawlData
  let hostingProvidersFiltersData

  try {
    if (domain !== '' && domain !== 'All') {
      crawlData = await retrieveCrawlHistory(domain, setCrawlHistory);
      hostingProvidersFiltersData = await retrieveHostingProviders(domain);
      if (serversFilters.crawlId) {
        crawlId = serversFilters.crawlId;
      } else {
        crawlId = crawlData?.[0]?.crawlId
      }
    } else {
      const domainsList = activeDomains.map(val => val.name)
      hostingProvidersFiltersData = await retrieveHostingProviders(domain, domainsList);
    }
  } catch (e){
    console.log(e)
  }
  const filtersVal = setFiltersData(serversFilters, issueTypes)
  filtersVal.domains = [{name: 'All'}, ...activeDomains];
  if (serversFilters.selectedServers) {
    const topTenServers =
      serversFilters.selectedServers.length === 11
        ? serversFilters.selectedServers.slice(0, -1)
        : serversFilters.selectedServers;
    hostingProvidersFiltersData = {
      'TOP 10': topTenServers,
      OTHERS: serversFilters.selectedOtherServers,
    };
    filtersVal.appHostingProviders = hostingProvidersFiltersData;
  } else {
    filtersVal.appHostingProviders = hostingProvidersFiltersData;
  }

  if(serversFilters.apiGateways && serversFilters.apiGateways.length > 0){
    filtersVal.apiGateways = serversFilters.apiGateways
  } else {
    filtersVal.apiGateways = gatewayConfigData ? gatewayConfigData.map((i) => ({
      selected: serversFilters?.i?.name,
      name: i.displayName,
      type: i.name
    })) : []
  }

  filtersVal.selectedDomain = domain;
  filtersVal.crawlId = crawlId;
  filtersVal.size = 100;
  filtersVal.from = 0;
  return filtersVal
}

export const getQuery = (data, domains) => {
  let from = data.from
  if(data.from > 0){
    from = (data.from*data.size) + 1
  }
  return {
    domains: data.selectedDomain !== 'All' ? [data.selectedDomain] : domains.map(d => d.name),
    timeRange: '24h',
    crawlId: data?.crawlId,
    includeSpyderCrawls: false,
    includeCSPM: false,
    includeSentinel: false,
    showApiHosts: data.apiHosts?.[0]?.selected,
    showNonApiHosts: data.apiHosts?.[1]?.selected,
    from: from,
    size: data.size
  };
}

export const SearchContext = createContext({
  searchHost: () => '',
  searchHostStr: '',
  hostType: true
})

export const hostType = (obj) => {
  if (obj?.[0]?.selected && obj?.[1]?.selected) {
    return 'All'
  } else if (obj?.[0]?.selected && !obj?.[1]?.selected) {
    return 'API'
  } else {
    return 'Non API'
  }
}