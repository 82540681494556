import React, {useContext, useEffect, useState} from 'react';
import { Grid, Typography, Box, styled } from '@mui/material';
import {
  CustomTabs as Tabs,
  CustomTab as Tab,
  CustomTabPanel as TabPanel,
} from '../../components/Tabs';
import SpSnackBar from '../../components/SpSnackBar';
import { StyledPageContent, StyledPanelBox } from './styles';
import { DiscoveryContext } from './DiscoveryContext';
import GeneralConfig from './DiscoveryAlgorithm/GeneralConfig';
import DiscoveryAlgorithm from './DiscoveryAlgorithm/DiscoveryAlgorithm';
import {getTenantInfo} from "./provider";
import CqLoading from "../../components/common/CqLoading";
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { colors } from '../../assets/theme';
import EndpointAlgorithm from './EndpointAlgorithm'
import EndpointCatalog from './EndpointCatalog';

const DiscoveryPage = () => {
  const [tabId, setTabId] = useState(0);
  const [notify, setNotify] = useState({});
  const [tenantConfigurations, setTenantConfigurations] = useState({});
  const [defaultTenantConfiguration, setDefaultTenantConfiguration] = useState({});
  const [loading, setLoading] = useState(false);
  const wrapperContext = useContext(WrapperContext)
  useEffect(() => {
    retrieveTenantConfiguration();
  }, []);

  useEffect(() => {
    //coming from wrapper.js stored as the global state
    // setTenantConfigurations(wrapperContext.tenantConfig);
    setDefaultTenantConfiguration(wrapperContext.defaultTenantConfig)
  }, [wrapperContext]);

  const handleTabChange = (e, val) => {
    setTabId(val);
  };

  const retrieveTenantConfiguration = async() => {
    setLoading(true);
    try {
      const res = await getTenantInfo();
      const configData = res?.data?.getTenantConfig?.config;
      setTenantConfigurations(configData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const StyledTabs = styled(Tabs)({
    borderBottom: `1px solid ${colors.gray700}`
  })

  return (
    <DiscoveryContext.Provider value={{ setNotify: setNotify }}>
      <StyledPageContent>
        <Grid item xs={8}>
          <Typography variant="h4">Discovery Configuration</Typography>
        </Grid>
        {loading && <CqLoading />}
        {!loading && Object.keys(tenantConfigurations).length !== 0 &&
        <>
          <Box>
            <StyledTabs value={tabId} onChange={handleTabChange}>
              <Tab id="disc_tab_0" label="General"/>
              {/* <Tab id="disc_tab_1" label="Endpoint Catalog"/> */}
              <Tab id="disc_tab_1" label="API Host Classification"/>
              <Tab id="disc_tab_2" label="API Endpoint Classification"/>
            </StyledTabs>
          </Box>
          <StyledPanelBox>
            <TabPanel value={0} tabId={tabId}>
              <GeneralConfig
                tenantConfigurations={tenantConfigurations}
                setTenantConfigurations={setTenantConfigurations}
              />
            </TabPanel>
            {/* <TabPanel value={1} tabId={tabId}>
              <EndpointCatalog />
            </TabPanel> */}
            <TabPanel value={1} tabId={tabId}>
              <DiscoveryAlgorithm
                tenantConfigurations={tenantConfigurations}
                setTenantConfigurations={setTenantConfigurations}
                defaultTenantConfiguration={defaultTenantConfiguration}
              />
            </TabPanel>
            <TabPanel value={2} tabId={tabId}>
              <EndpointAlgorithm />
            </TabPanel>
          </StyledPanelBox>
        </>
        }
      </StyledPageContent>

      <SpSnackBar
        isOpen={notify.open}
        autoHideDuration={10000}
        notifications={[
          {
            id: '1',
            message: notify.message,
            category: notify.success ? 'success' : 'error',
          },
        ]}
        onNotificationDelete={() =>
          setNotify({ open: false, error: false, success: false })
        }
      />
    </DiscoveryContext.Provider>
  );
};

export default DiscoveryPage;
