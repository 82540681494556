import React, { useState, useContext } from 'react';
import { Grid, Typography, Link } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import { useGlobalState } from '../../store/globalState';
import { localStore } from '../../store/localStore';
import ExposedFilesDetails from './VulnerabilityType/ExposedFilesDetails';
import UnhandledServerDetails from './VulnerabilityType/UnhandledServerDetails';
import ExposedLoginEndpointsDetails from './VulnerabilityType/ExposedLoginEndpointsDetails';
import ExposedGraphQLEndpointsDetails from './VulnerabilityType/ExposedGraphQLEndpointsDetails';
import ExposedOpenAPIEndpointsDetails from './VulnerabilityType/ExposedOpenAPIEndpointsDetails';
import ExposedMonitoringEndpointsDetails from './VulnerabilityType/ExposedMonitoringEndpointsDetails';
import InternalApplicationServerDetails from './VulnerabilityType/InternalApplicationServerDetails';
import SslEndpointsDetails from './VulnerabilityType/SslEndpointsDetails';
import Log4jEndpointsDetails from './VulnerabilityType/Log4jEndpointsDetails';
import AllFindings from './VulnerabilityType/AllFindings';
import TextWithIcon from '../../components/common/TextWithIcon';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { colors } from '../../assets/theme';
import CustomFindingDetails from './VulnerabilityType/CustomFinding';

const ServerVulnerabilityDetails = ({
  selectedVulnerability,
  vulnerabilityServers,
  getRequestResponse,
  selectedServerData,
  endpointsCount
}) => {
  const global = useGlobalState();
  const store = localStore();
  const { userPreferences } = global.get();
  const [expand, setExpand] = useState(userPreferences.serverDetailsVulExpand);
  const wrapperContext = useContext(WrapperContext)
  const issueTypes = wrapperContext.issueTypes
  const handleExpand = () => {
    setExpand((flag) => {
      global.userPreferences['serverDetailsVulExpand'].set(!flag);
      store.update('userPreferences', 'serverDetailsVulExpand', !flag);
      return !flag;
    });
  };
  const issueDescription = issueTypes[selectedVulnerability?.name]?.description || ''
  return (
      <>
      {selectedVulnerability?.hasOwnProperty('name') &&
      <Grid style={{marginTop: 30}}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant={'h4'}>
              {selectedVulnerability?.name === 'Log4j Vulnerabilities' ||
              selectedVulnerability?.name === 'LoNg4j Vulnerabilities'
                  ? 'Log4j/LoNg4j Vulnerabilities'
                  : issueDescription}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {selectedVulnerability && selectedVulnerability?.value > 0 && selectedVulnerability.name !== 'ALL' && <Link
                onClick={handleExpand}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: '4px',
                  color: colors.blue300,
                  cursor: 'pointer',
                }}
            >
              <TextWithIcon>
                {expand ? (
                    <>
                      <UnfoldLessIcon/>
                      <span>Collapse All</span>
                    </>
                ) : (
                    <>
                      <UnfoldMoreIcon/>
                      <span>Expand All</span>
                    </>
                )}
              </TextWithIcon>
            </Link>}
          </Grid>
        </Grid>
        {selectedVulnerability?.name === 'EXPOSED_FILE' && (
            <ExposedFilesDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'UNHANDLED' && (
            <UnhandledServerDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'NON_PROD_OR_ORIGIN' && (
            <InternalApplicationServerDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'LOGIN' && (
            <ExposedLoginEndpointsDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'GRAPHQL' && (
            <ExposedGraphQLEndpointsDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'OPENAPI_SWAGGER' && (
            <ExposedOpenAPIEndpointsDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'HEALTH_MONITORING' && (
            <ExposedMonitoringEndpointsDetails
                vulnerabilityServers={vulnerabilityServers}
                getRequestResponse={getRequestResponse}
                expanded={expand}
            />
        )}
        {selectedVulnerability?.name === 'TLS' && (
            <SslEndpointsDetails
                selectedServerData={selectedServerData}
                vulnerabilityServers={vulnerabilityServers}
                expanded={expand}
            />
        )}
        {(selectedVulnerability?.name === 'LOG4J_LONG4J') && (
            <Log4jEndpointsDetails
                vulnerabilityServers={vulnerabilityServers}
                expanded={expand}
            />
        )}
        {(selectedVulnerability?.custom) && (
            <CustomFindingDetails
                selectedFinding={selectedVulnerability}
                expanded={expand}
            />
        )}
      </Grid>
      }
    </>
  );
};

export default ServerVulnerabilityDetails;
