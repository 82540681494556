import React from 'react';
import { colors } from '../assets/theme';
import { styled } from '@mui/material/styles';

import { QueryBuilderMaterial } from '@react-querybuilder/material';
import { QueryBuilder,
  ActionWithRulesProps,
  add,
  Field,
  formatQuery} from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import CustomOperatorSelector from './CustomerOperator';
import { Button } from '@mui/material';

export const QueryBuilderContainerClasses = (theme) => ({
    border: 'none',
    padding: `0 ${theme.spacing(3)}`,
    '& .queryBuilder': {
      '& .queryFieldsStyle': {
        border: `1px solid ${colors.gray550}`,
        width: 200,
        paddingLeft: 10,
        '& div:before': {
          borderBottom: 'none',
        },
      },
      '& .queryOperatorsStyle': {
        border: `1px solid ${colors.gray550}`,
        width: 300,
        paddingLeft: 10,
        '& div:before': {
          borderBottom: 'none'
        },
      },
      '& .queryValueStyle:before': {
        borderBottom: 'none'
      },
      '& .queryValueStyle': {
        width: 200,
        '& div:before': {
          borderBottom: 'none'
        },
        '&.MuiCheckbox-root': {
          border: 'none',
        },
        '& .MuiInput-input': {
          border: `1px solid ${colors.gray550}`,
          paddingLeft: 10,
        }
      },
      '& .ruleGroup': {
        '& .ruleGroup-remove': {
          border: 0,
          boxShadow: 'none',
          color: colors.gray550,
          fontSize: 24,
        },
        'background': colors.gray800,
        'borderColor': colors.gray550,
        '& .ruleGroup-header': {
          '& .ruleGroup-combinators': {
            width: 109,
            border: `1px solid ${colors.gray550}`,
            borderRadius: 2,
            paddingLeft: 5,
            '& div:before': {
              borderBottom: 'none'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
              border: 0,
            }
          },
          '& .ruleGroup-addRule': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            textIndent: '-9999px'
          },
          '& .ruleGroup-addRule::after': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            content: '"Add Rule"',
            textIndent: 0
          },
          '& .ruleGroup-addGroup': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            textIndent: '-9999px'
          },
          '& .ruleGroup-addGroup::after': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            content: '"Add Group"',
            textIndent: 0
          }
        },
        '& .ruleGroup-header:third-child': {
          '&button': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            textIndent: '-9999px'
          },
          '&button::after': {
            backgroundColor: colors.blueButtonBg,
            color: colors.almostBlack,
            content: '"Add Group"',
            textIndent: 0
          }
        },
        '& .ruleGroup-body': {
          '& .rule-remove': {
            border: 0,
            boxShadow: 'none',
            color: colors.gray550,
            fontSize: 24,
          },
          '& .rule-operators': {
            width: 150,
            marginLeft: 15,
          },
          '& .rule-fields': {
            width: 250,
            marginLeft: 15,
          },
          '& .MuiSelect-select': {
            '&.MuiInputBase-input': {
              '&.MuiOutlinedInput-input': {
                padding: '0 32px 0 8px'
              }
            }
          },
          '& .MuiOutlinedInput-input': {
            paddingTop: '5px',
            border: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0
          }
        },
        '& textarea': { 
          background: 'inherit',
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
          color: '#fff',
          width: '500px',
          font: 'inherit',
          '&:focus-visible': {
            outline: 'none',
          }
        }
      }
    }
  });

const StyledQueryBuilderContainer = styled('div')(({ theme }) => QueryBuilderContainerClasses(theme))


const QueryBuilderAlgo = ({fields, query, setQuery, style, disabled}) => {
  return (
    <StyledQueryBuilderContainer style={style}>
      <QueryBuilderMaterial>
        <QueryBuilderDnD dnd={{...ReactDnD, ...ReactDndHtml5Backend}}>
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={setQuery}
            showNotToggle
            controlElements={{
              // addRuleAction: () => <Button disabled={disabled} onClick={addRule} >Add Condition</Button>,
              // addGroupAction: () => <Button disabled={disabled} onClick={addGroup} >Add Group</Button>,
              operatorSelector: props => <CustomOperatorSelector {...props} query={query} setQuery={setQuery} />,
              // notToggle: () => null
            }}
            disabled={disabled}
          />
        </QueryBuilderDnD>
      </QueryBuilderMaterial>
    </StyledQueryBuilderContainer>
  )
}

export default QueryBuilderAlgo;