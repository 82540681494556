import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SettingsIcon from '@mui/icons-material/Settings';
import { Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RuleIcon from '@mui/icons-material/Rule';
import DeleteIcon from '@mui/icons-material/Delete';
import CqButton from '../../components/common/CqButton';
import TextWithIcon from '../../components/common/TextWithIcon';
import { colors } from '../../assets/theme';
import { WrapperContext } from '../../components/wrapper/wrapperContext';

const useStyles = makeStyles(() => ({
  paper: {},
  link: {
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      width: '100%',
    },
  },
}));

const menuPaperStyles = {
  border: `1px solid ${colors.gray400}`,
  boxShadow: colors.darkest,
};

const DomainsMenu = ({
  setOpenCatalog,
  domain,
  setConfigDialogStatus,
  global,
  onRemove,
}) => {
  const classes = useStyles();
  const newCrawlContext = useContext(WrapperContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenCatalog(true);
  };
  const domainNameEncoded = domain.name.replaceAll('.', '_');
  const handleSchedulerDialog = () => {
    setConfigDialogStatus((state) => {
      return {
        ...state,
        open: true,
        edit: { scheduler: true, proxy: false, scan: false },
        domain: domain,
      };
    });
    handleClose();
  };
  const handleProxyDialog = () => {
    setConfigDialogStatus((state) => {
      return {
        ...state,
        open: true,
        edit: { scheduler: false, proxy: true, scan: false },
        domain: domain,
      };
    });
    handleClose();
  };
  const handleScanDialog = () => {
    setConfigDialogStatus((state) => {
      return {
        ...state,
        open: true,
        edit: { scheduler: false, proxy: false, scan: true },
        domain: domain,
      };
    });
    handleClose();
  };

  const handleNewCrawlDialogOpen = () => {
    newCrawlContext.showNewCrawl(domain.domain);
  };

  const handleRemove = () => {
    onRemove(domain.domain);
  };

  return (
    <>
      <CqButton onClick={handleClick}>
        <MoreVertIcon />
      </CqButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className={classes.link}>
          <a className={classes.link} onClick={handleNewCrawlDialogOpen}>
            <TextWithIcon>
              <PublishedWithChangesIcon />
              <Typography variant="body2">New Crawl</Typography>
            </TextWithIcon>
          </a>
        </MenuItem>
        <MenuItem className={classes.link}>
          <Link to={`crawlCatalog/${domainNameEncoded}`}>
            <TextWithIcon>
              <RuleIcon />
              <Typography variant="body2">Manage Crawl Catalog</Typography>
            </TextWithIcon>
          </Link>
        </MenuItem>
        <MenuItem className={classes.link}>
          <a className={classes.link} onClick={handleSchedulerDialog}>
            <TextWithIcon>
              <ScheduleIcon />
              <Typography variant="body2">Crawl Scheduler</Typography>
            </TextWithIcon>
          </a>
        </MenuItem>
        <MenuItem className={classes.link}>
          <a className={classes.link} onClick={handleProxyDialog}>
            <TextWithIcon>
              <SettingsIcon />
              <Typography variant="body2">Configure Proxy URL</Typography>
            </TextWithIcon>
          </a>
        </MenuItem>
        <MenuItem className={classes.link}>
          <a className={classes.link} onClick={handleScanDialog}>
            <TextWithIcon>
              <WifiTetheringIcon />
              <Typography variant="body2">Advanced Crawl</Typography>
            </TextWithIcon>
          </a>
        </MenuItem>
        <MenuItem className={classes.link}>
          <a className={classes.link} onClick={handleRemove}>
            <TextWithIcon>
              <DeleteIcon />
              <Typography variant="body2">Delete {domain.domain}</Typography>
            </TextWithIcon>
          </a>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DomainsMenu;
