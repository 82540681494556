import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CqLoading from '../../components/common/CqLoading';
import CqDialog from '../../components/common/CqDialog';
import CrawlsTable from './CrawlsTable';
import { graphqlQuery } from '../../services/graphql';
import {
  getCrawlSummaryHistogramQuery,
  getDomainsQuery,
} from './crawlsGraphQL';
import Filters from './Filters/Filters';
import { useFiltersState, useGlobalState } from '../../store/globalState';
import { discoveryFilters, timeRangeFilters, getDiscoveryIssues } from './constants';
import {getHighIssues, getMediumIssues, getLowIssues} from '../../store/constants'
import { useLocation } from 'react-router-dom';
import LineChart from '../../components/LineChart';
import CqButton from '../../components/common/CqButton';
import { CSVLink } from 'react-csv';
import { formatDate, groupFindings } from '../../lib/utils';
import downloadServersPageExcel from '../../components/downloadServersPageExcel';
import SpSnackBar from '../../components/SpSnackBar';
import { WrapperContext } from '../../components/wrapper/wrapperContext';

export const MANUAL = 'manual';
export const AUTOMATIC = 'automatic';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  dialogProgressContainer: {
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px 0',
  },
}));

const Crawls = ({domains, tenantConfig, issueTypes, userInfo}) => {
  const classes = useStyles();
  const location = useLocation();
  const global = useGlobalState();
  const globalFilters = useFiltersState();
  const filters = globalFilters.get();
  const { crawlHistoryFilters } = JSON.parse(JSON.stringify(filters));
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isTableView, setIsTableView] = useState(true);
  const [activeDomains, setActiveDomains] = useState([]);
  const [crawlSummaryHistogram, setCrawlSummaryHistogram] = useState({});
  const [openXLSDialog, setOpenXLSDialog] = useState(false);
  const [XLSDialogError, setXLSDialogError] = useState(false);
  let discoveryIssues = {}
  let findingsGroups = {high: [], medium: [], low: []}
  if(tenantConfig.findingsConfig){
    discoveryIssues = getDiscoveryIssues(tenantConfig.findingsConfig)
    findingsGroups = groupFindings(tenantConfig.findingsConfig)
  }

  useEffect(() => {
    updateFilters(domains);
  }, [domains]);

  useEffect(() => {
    if (activeDomains.filter((i) => i.selected).length > 0) {
      retrieveCrawlSummary();
    }
  }, [activeDomains]);

  const downloadReport = async (date) => {
    setOpenXLSDialog(true);
    let domains = [];
    crawlHistoryFilters.domains.map((i) => domains.push(i.name));
    const params = {
      domains: domains,
      userInfo: userInfo,
      crawlDate: date,
    };
    try {
      await downloadServersPageExcel(params, issueTypes);
    } catch (e) {
      setXLSDialogError(true);
      console.error(e);
    }
    setOpenXLSDialog(false);
  };
  const timeRangeMapping = {
    'Last Week': 7,
    'Last Day': 1,
    'Last 30 days': 30,
  };

  const updateFilters = (domains) => {
    setLoading(true);
    try {
      const data = domains;
      const activeDomainsData = data.map((i) => ({
        name: i.domain,
        selected: true,
      }));
      if (location?.state?.selectedDomain) {
        const singleDomain = activeDomainsData.map((i) =>
          i.name === location.state.selectedDomain
            ? { ...i, selected: true }
            : { ...i, selected: false }
        );
        globalFilters['crawlHistoryFilters'].merge({ domains: singleDomain });
        setActiveDomains(singleDomain);
        globalFilters['crawlHistoryFilters'].merge({
          discovery: discoveryFilters,
        });
        globalFilters['crawlHistoryFilters'].merge({
          timeRange: timeRangeFilters,
        });
      } else {
        if (isEmpty(filters.crawlHistoryFilters.domains)) {
          globalFilters['crawlHistoryFilters'].merge({
            domains: activeDomainsData,
          });
        }
        if (isEmpty(filters.crawlHistoryFilters.discovery)) {
          globalFilters['crawlHistoryFilters'].merge({
            discovery: discoveryFilters,
          });
        }
        if (isEmpty(filters.crawlHistoryFilters.timeRange)) {
          globalFilters['crawlHistoryFilters'].merge({
            timeRange: timeRangeFilters,
          });
        }
        setActiveDomains(activeDomainsData);
      }
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setLoading(false);
    }
  };

  const retrieveCrawlSummary = async (data) => {
    setTableLoading(true);
    let selectedDomains = [];
    let selectedTimeRange;
    let params = {};
    if (data) {
      data.crawlHistoryFilters.domains.filter(
        (i) => i.selected === true && selectedDomains.push(i.name)
      );
      data.crawlHistoryFilters.timeRange.filter((i) => {
        if (i.selected === true) {
          selectedTimeRange = timeRangeMapping[i.name];
        }
      });
      params = {
        crawlHistogramDomains: selectedDomains,
        days: selectedTimeRange,
      };
    } else {
      crawlHistoryFilters.domains.filter(
        (i) => i.selected === true && selectedDomains.push(i.name)
      );
      crawlHistoryFilters.timeRange.filter((i) => {
        if (i.selected === true) {
          selectedTimeRange = timeRangeMapping[i.name];
        }
      });
      params = {
        crawlHistogramDomains: selectedDomains,
        days: selectedTimeRange,
      };
    }

    try {
      let response = await graphqlQuery(getCrawlSummaryHistogramQuery, params);
      const data = response.data
        ? response.data.discovery.getCrawlHistoryHistogram
        : {};
      const transform = {
        domains: data.domains,
        histogram: data.histogram,
        timestamp: data.timestamp
      }
      transform.histogram = transform.histogram.map(crawl => {
        return {
        date: crawl.date,
        crawlIds: [crawl.crawlId],
        timestamp: Date.parse(crawl.date),
        data: {
          apiServers: crawl.hosts.discovered,
          hostingProviders: crawl.providers.discovered,
          highSeverityIssues: {
            total: crawl.findings.high,
            issues: getHighIssues(crawl.findings.issues)
          },
          mediumSeverityIssues: {
            total: crawl.findings.medium,
            issues: getMediumIssues(crawl.findings.issues)
          },
          lowSeverityIssues: {
            total: crawl.findings.info,
            issues: getLowIssues(crawl.findings.issues)
          }
        }
      }})
      setCrawlSummaryHistogram(transform);
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setTableLoading(false);
    }
  };

  const updateFiltersState = (data) => {
    globalFilters.merge({ ...data });
    retrieveCrawlSummary(data);
  };

  const csvCrawlHistoryHeaders = []
  csvCrawlHistoryHeaders.push({ label: 'Date', key: 'date' });
  csvCrawlHistoryHeaders.push({ label: 'High', key: 'highSeverityIssues' });
  for(const highIssue of findingsGroups.high){
    csvCrawlHistoryHeaders.push({ label: highIssue.description, key: highIssue.name });
  }
  csvCrawlHistoryHeaders.push({ label: 'Medium', key: 'mediumSeverityIssues' });
  for(const medIssue of findingsGroups.medium){
    csvCrawlHistoryHeaders.push({ label: medIssue.description, key: medIssue.name });
  }
  csvCrawlHistoryHeaders.push({ label: 'Info', key: 'lowSeverityIssues' });
  for(const lowIssue of findingsGroups.low){
    csvCrawlHistoryHeaders.push({ label: lowIssue.description, key: lowIssue.name });
  }
  csvCrawlHistoryHeaders.push({ label: 'API Hosts', key: 'apiServers' });
  csvCrawlHistoryHeaders.push({ label: 'App Hosting Providers', key: 'appHostingProviders' });
  const histogramData = crawlSummaryHistogram?.histogram || []
  const csvCrawlHistoryData = histogramData.map((item) => {
      const obj = {}
      obj.date = item?.date ?? '--'
      obj.highSeverityIssues = item?.data?.highSeverityIssues?.total ?? '--'
      for(const highIssue of findingsGroups.high){
        obj[highIssue.name] = item['data']?.['highSeverityIssues']?.['issues']?.[highIssue.name]?.value ?? '--'
      }
      obj.mediumSeverityIssues = item?.data?.mediumSeverityIssues?.total ?? '--'
      for(const medIssue of findingsGroups.medium){
        obj[medIssue.name] = item['data']?.['mediumSeverityIssues']?.['issues']?.[medIssue.name]?.value ?? '--'
      }
      obj.lowSeverityIssues = item?.data?.lowSeverityIssues?.total ?? '--'
      for(const lowIssue of findingsGroups.low){
        obj[lowIssue.name] = item['data']?.['lowSeverityIssues']?.['issues']?.[lowIssue.name]?.value ?? '--'
      }
      obj.apiServers = item?.data?.apiServers ?? '--'
      obj.appHostingProviders = item?.data?.hostingProviders ?? '--'
      return obj
    });

  return (
    <div className={classes.pageContent}>
      <Grid
        item
        container
        className={classes.pageHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4">Crawl History</Typography>
        </Grid>
        {!loading && !tableLoading && activeDomains.length > 0 && (
          <Grid item>
            <CSVLink
              style={{ textDecoration: 'none' }}
              data={csvCrawlHistoryData}
              filename={`CrawlSummaryReport-${formatDate(Date.now())}.csv`}
              target="_blank"
              headers={csvCrawlHistoryHeaders}
            >
              <CqButton
                text={'Export As CSV'}
                height={26}
                variant="primary"
                onClick={() => {}}
              />
            </CSVLink>
          </Grid>
        )}
      </Grid>
      {loading || (tableLoading && <CqLoading />)}
      {!loading && !tableLoading && activeDomains.length > 0 && (
        <Grid container>
          <Grid item container xs={12}>
            <Filters
              tableView={isTableView}
              setView={setIsTableView}
              data={activeDomains}
              setFilters={updateFiltersState}
            />
          </Grid>
          <Grid item container style={{ marginTop: 20 }}>
            {isTableView ? (
              <CrawlsTable
                discoveries={crawlSummaryHistogram}
                downloadReport={downloadReport}
                discoveryIssues={discoveryIssues}
                findingsGroups={findingsGroups}
              />
            ) : (
              <Grid>
                <LineChart
                  chartId={'lineChartId'}
                  data={crawlSummaryHistogram}
                  chartHeight={750}
                  findingsGroups={findingsGroups}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <CqDialog
        title={'Downloading Report'}
        open={openXLSDialog}
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
        showCloseBtn={false}
        showSubmitBtn={false}
      >
        <div className={classes.dialogProgressContainer}>
          <CircularProgress />
        </div>
      </CqDialog>
      <SpSnackBar
        isOpen={XLSDialogError}
        notifications={[
          {
            id: '1',
            message: `XLS report generation failed. Please refresh the page and try again.`,
            category: 'error',
          },
        ]}
        onNotificationDelete={() => setXLSDialogError(false)}
      />
    </div>
  );
};

export default Crawls;
