import React, { useEffect, useState } from 'react';
import {Grid, Typography, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import VulnerabilitiesDetailsTable from "./VulnerabilitiesDetailsTable";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CircleIcon from "@mui/icons-material/Circle";
import LaunchIcon from "@mui/icons-material/Launch";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
    {
        name: 'API Endpoint',
    },
    {
        name: 'Method',
    },
    {
        name: 'Response Code', sort: true
    }
];



const ExposedOpenAPIEndpointsDetails = ({ vulnerabilityServers, getRequestResponse, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false
  }
  const [expand, setExpand] = useState(expandSection)
  useEffect(() => {
      setExpand({
        des: expanded,
        risk: expanded,
        ref: expanded,
        rec: expanded
      })
  }, [expanded])
  const handleChange = (panel) => () => {
    setExpand({...expand, [panel]: !expand[panel]})
  }
    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                            Swagger specification (also known as OpenAPI) is an API description format for REST APIs. A Swagger file
                            describes the entire API, including available endpoints, operations on each endpoint, operation parameters
                            input, and output for each operation.

                            The server was found to expose one or more OpenAPI endpoints publicly, allowing external users to access
                            the API description format and gaining vital information about the exposed APIs.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            OpenAPI/Swagger files can often contain misconfigurations that provide vital information to attackers to try
                            and gan access to sensitive APIs. These can include:
                            <List>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Hardcoded keys
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Internal data regarding users, data or internal server information
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                    <Typography variant={'body2'}>
                                        Information about insecure API endpoints that do not expect any authentication or security, or weak forms
                                        of security, in incoming requests
                                    </Typography>
                                </ListItem>
                            </List>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Review all exposed OpenAPI/Swagger files to ensure that no information is exposed in them that may
                            potentially jeopardize the security of the APIs. Use security schemes within the OpenAPI/Swagger files to
                            ensure that the APIs are secured.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Redhat OpenAPI guidance:
                                    <Link target={'_blank'} href={'https://appdev.consulting.redhat.com/tracks/contract-first/security-with-openapi.html#security-schemes'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                        https://appdev.consulting.redhat.com/tracks/contract-first/security-with-openapi.html#security-schemes
                                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                                    </Link>
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
                <Typography>
                    OpenAPI/Swagger API Endpoints
                </Typography>
                <VulnerabilitiesDetailsTable
                    columns={exposedFilesHeaderColumns}
                    vulnerabilityServers={vulnerabilityServers}
                    getRequestResponse={getRequestResponse}
                />
            </Grid>
        </>
    );
};

export default ExposedOpenAPIEndpointsDetails;
