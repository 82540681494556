export const getNotificationsQuery = `
  query GetNotifications($selectedDomains: [String!], $selectedSeverity: [String!], $startTime: Long, $endTime: Long) {
    notification {
      list(query: {domains: $selectedDomains, types: $selectedSeverity, startTime: $startTime, endTime: $endTime}) {
        type
        message
        timestamp
        severity
      }
    }
  }
`;

export const getDomainsQuery = `
   query GetDomainsData {
    timezones {
      name
      offset
    }
    domain {
      list {
        domain
        status
        config {
          log4jScan
          standardScan
          proxy
          scheduleEnabled
          timezone {
            name
            offset
          }
          schedule {
            cron
            lastCrawl
            nextSchedule
          }
        }
      }
    }
  }
`;
