import React from 'react'
import { Box, FormControlLabel } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import QueryBuilderAlgo from "../../../components/QueryBuilderAlgo";
import { endpointLevelFields } from "../../../lib/queryBuilderUtils";

const removeIdFieldsAndConvertCombinator =(obj) => {
  if (Array.isArray(obj)) {
      return obj.map(item => removeIdFieldsAndConvertCombinator(item));
  } else if (typeof obj === 'object' && obj !== null) {
      const result = {};
      for (const key in obj) {
          if (key !== 'id') {
              result[key] = removeIdFieldsAndConvertCombinator(obj[key]);
          }
          
      }
      return result;
  }
  return obj;
};

import {
  StyledEndpointGroup,
  StyledTextField,
  SpSwitch,
  StyledGroupAttContainer,
} from '../styles';

import CqButton from "../../../components/common/CqButton";

const Rules = ({ rulesList, addRule, deleteRule, updateRules }) => {

  const handleRuleChange = (prev, query, i) => {
    if(JSON.stringify(prev) !== JSON.stringify(removeIdFieldsAndConvertCombinator(query))){
      updateRules(query, i, 'algo')
    }
  }

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <Box sx={{ml: 'auto', pr: '25px', mb: '8px'}}>
          <CqButton variant='primary' onClick={addRule} text='Add Rule' />
        </Box>
      </Box>
      {rulesList.length > 0 && rulesList.map((val, i) =>  <div  key={i.toString()}>
        <StyledEndpointGroup key={i.toString()}>
          <StyledGroupAttContainer>
            <StyledTextField
              label="Rule Name *"
              variant="outlined"
              value={val.name}
              onChange={(e) => updateRules(e.target.value, i, 'name')}
            />
            <FormControlLabel
              control={<SpSwitch sx={{ m: 1 }} />}
              onChange={e => updateRules(e.target.checked, i, 'status')}
              checked={val.status === 'ENABLED'}
            />
            {val.type === 'CUSTOM' && <Box sx={{ml: 'auto', pr: '25px'}}>
              <a onClick={deleteRule(i)}><DeleteIcon sx={{cursor: 'pointer'}} /></a>
            </Box>}
          </StyledGroupAttContainer>
          {val.algo && val.algo.rules && <QueryBuilderAlgo
            fields={endpointLevelFields}
            query={val.algo}
            setQuery={query => handleRuleChange(val.algo, query, i)}
          />}
        </StyledEndpointGroup>
      </div>)}
    </>
  )
}

export default Rules