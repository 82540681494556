import React from "react";
import { StyledSvg, StatusContent, StyledUrl, } from './styles'
import { Grid, Typography, Divider } from "@mui/material";
import getMethodImg from '../../assets/img/get-method.svg';
import postMethodImg from '../../assets/img/post-method.svg';
import { colors } from '../../assets/theme';

const ResponseHeader = ({method, url, status, time}) => {
  return (
    <Grid container style={{ backgroundColor: colors.gray900 }}>
      <Grid item container xs={6} style={{ justifyContent: 'start' }}>
        {method === 'get' ? (
          <StyledSvg component={getMethodImg} />
        ) : (
          <StyledSvg
            component={postMethodImg}
          />
        )}
        {url && (
          <Typography
            variant={'body1'}
            style={{ color: colors.gray100, padding: 10 }}
          >
            {new URL(url).pathname}
          </Typography>
        )}
      </Grid>
      <Grid item container xs={6} style={{ justifyContent: 'end' }}>
        {url && (
          <StyledUrl
            variant={'body1'}
            onClick={() => setServerOpenReqResDialog(false)}
          >
            {new URL(url).hostname}
          </StyledUrl>
        )}
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          style={{ borderColor: colors.gray650 }}
        />
        
          <StatusContent
            variant={'body1'}
            style={{padding: 10, margin: 5,}}
          >
            {status}
          </StatusContent>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          style={{ borderColor: colors.gray650 }}
        />
        
          <StatusContent
            variant={'body1'}
            style={{padding: 10, margin: 5,}}
          >
            {time}
          </StatusContent>
      </Grid>
    </Grid>
  )
}

export {ResponseHeader}