import { styled } from '@mui/material/styles';
import { Grid, Box, Tabs } from '@mui/material';
import { colors } from '../../assets/theme';


export const StyledPageContent = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const StyledPanelBox = styled(Box)({
  paddingTop: '17px',
});

export const StyledTabs = styled(Tabs)({
  borderBottom: `1px solid ${colors.gray700}`
})