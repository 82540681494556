import React, { useState } from 'react';
import { DataGridPremium, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import moment from 'moment';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui';
import { Box, Chip } from '@mui/material';
import CqButton from '../../components/common/CqButton';
import CqSearch from '../../components/common/CqSearch';
import TextWithIcon from '../../components/common/TextWithIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { colors } from '../../assets/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import CqDialog from '../../components/common/CqDialog';
import { graphqlMutation } from '../../services/graphql';
import { deleteClient } from './userGraphQL';
import CqLoading from '../../components/common/CqLoading';

const UserType = (params) => {
  const style = params?.row?.saml
    ? {
        border: `1px solid ${colors.lightBlue200}`,
        color: colors.lightBlue300,
        fontWeight: 400,
      }
    : null;
  return <Chip sx={style} label={params.row.saml ? 'SAML' : 'Local'} />;
};

function CustomToolbar(props) {
  const { totalCount, openNewUserDialog } = props;
  return (
    <Box
      sx={{
        height: '36px',
        border: '1px solid #333',
        paddingBottom: '8px',
        boxSizing: 'unset',
        display: 'flex',
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex', paddingLeft: '4px', gap: '4px' }}>
        <GridToolbarQuickFilter
         variant='outlined'
         sx={{
          '& .MuiOutlinedInput-root': {
            height: '40px'
          }
        }}  />
        <span>{totalCount} Rows</span>
      </Box>
      <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '8px' }}>
        <CqButton onClick={() => openNewUserDialog()}>
          <TextWithIcon>
            <AddCircleOutlineIcon />
            <span>Add New User</span>
          </TextWithIcon>
        </CqButton>
      </Box>
    </Box>
  );
}

const UsersTable = ({ users, openNewUserDialog, removeUser }) => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const [userInput, setUserInput] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [listLoading, setListloading] = useState(false);

  const searchUser = (e) => {
    setUserInput(e);
  };
  const columns = [
    {
      field: 'name',
      headerName: 'User Name',
      sortingOrder: ['desc', 'asc'],
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortingOrder: ['desc', 'asc'],
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Added Date',
      sortingOrder: ['desc', 'asc'],
      flex: 1,
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      sortingOrder: ['desc', 'asc'],
      flex: 1,
    },
    {
      field: 'saml',
      headerName: 'Type',
      sortingOrder: ['desc', 'asc'],
      flex: 1,
      renderCell: UserType,
    },
    {
      field: 'action',
      headerName: 'Actions',
      minWidth: 150,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <a onClick={() => setDeleteId(params?.row?.email)}>
          <DeleteIcon />
        </a>
      ),
    },
  ];

  const dataGridInitialState = {
    rows: [],
    columns: [],
  };
  let ind = 0;
  let filteredUsers = users;
  if (userInput !== '') {
    filteredUsers = users.filter((item) => {
      if (item.name) {
        return item.name.toLowerCase().indexOf(userInput) > -1;
      }
    });
  }

  const data = {
    rows: filteredUsers.map((val) => ({
      id: ind++,
      saml: val.saml,
      name: val.name,
      email: val.email,
      createdAt: val.createdAt ? moment(val.createdAt * 1000).format('MM/DD/YYYY') : '',
      lastLogin:
        val.lastLogin && val.lastLogin > 0
          ? moment(val.lastLogin).calendar()
          : 'Never',
    })),
    columns: columns,
  };

  const deleteUser = async () => {
    try {
      setListloading(true);
      if (deleteId !== '') {
        await removeUser(deleteId);
        setDeleteId('');
      }
    } catch (e) {
      console.log(e);
      setDeleteId('');
    }
  };

  return (
    <Box sx={{ height: 'calc(100vh)', width: 'calc(100% - 12px)' }}>
      {listLoading ? (
        <CqLoading />
      ) : (
        <>
          <DataGridPremium
            {...data}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                totalCount: users.length,
                openNewUserDialog: openNewUserDialog,
                userInput: userInput,
                searchUser: searchUser,
              },
            }}
            initialState={dataGridInitialState}
            headerHeight={36}
            rowCount={users.length}
            hideFooter
            sx={dataGridStyles}
            disableAggregation
            disableRowGrouping
          />
          <CqDialog
            open={deleteId !== ''}
            closeIcon={true}
            onClose={() => {
              setDeleteId('');
            }}
            onSubmit={deleteUser}
            title="Delete User"
            submitBtnText="Confirm"
          >
            Deleting User <b>{deleteId || ''}</b>.
          </CqDialog>
        </>
      )}
    </Box>
  );
};

export default UsersTable;
