import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Html from 'react-pdf-html';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { Page, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../assets/img/Cequence-logo-bluewhite.png';
import freeUserImg from '../assets/img/free-user.png';
import paidUserImg from '../assets/img/paid-user.png';
import coverImg from '../assets/img/Cover-tabloid.png';
import nonameLogo from '../assets/img/cequence-logo-noname.png';
import contentPageImg from '../assets/img/Content-page-tabloid.png';
import { colors } from '../assets/theme';
import NunitoSansLight from '../assets/fonts/NunitoSans/NunitoSans-Light.ttf';
import NunitoSansBold from '../assets/fonts/NunitoSans/NunitoSans-Bold.ttf';
import NunitoSansSemiBold from '../assets/fonts/NunitoSans/NunitoSans-SemiBold.ttf';
import NotoSansLight from '../assets/fonts/NotoSans/NotoSans-Light.ttf';
import NotoSans from '../assets/fonts/NotoSans/NotoSans-Regular.ttf';
import NotoSansBold from '../assets/fonts/NotoSans/NotoSans-Bold.ttf';
import NotoSansSemiBold from '../assets/fonts/NotoSans/NotoSans-SemiBold.ttf';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';

am4core.useTheme(am4themes_dark);

Font.register({
  family: 'NunitoSans',
  fonts: [
    { src: NunitoSansLight },
    { src: NunitoSansBold, fontStyle: 'bold' },
    { src: NunitoSansSemiBold, fontStyle: 'semiBold' },
  ],
});
Font.register({
  family: 'NotoSans',
  fonts: [
    { src: NotoSansLight },
    { src: NotoSansBold, fontStyle: 'bold' },
    { src: NotoSansSemiBold, fontStyle: 'semiBold' },
  ],
});

const formatNumber = (num) => {
  return Intl.NumberFormat('en-US', {
    notation: "compact",
      maximumFractionDigits: 1
  }).format(num);
};

const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flex: '0 0 120px',
    width: '100%',
    marginLeft: '40px',
    marginTop: 20,
  },
  content: {
    flex: 1,
    width: '100%',
    flexGrow: 1,
  },
  contentAttackSurface: {
    width: '100%',
  },
  footer: {
    flex: '0 0 270px',
    width: '100%',
    marginTop: 'auto',
  },
  logoContainer: {
    width: '150',
    height: '32',
  },
  reportName: {
    color: colors.gray150,
    fontFamily: 'NotoSans',
    fontStyle: 'bold',
    marginTop: 150,
  },
  companyName: {
    color: colors.white,
    marginTop: 12,
    fontFamily: 'NotoSans',
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateGeneration: {
    flex: 1,
    color: colors.gray250,
    alignSelf: 'flex-end',
    fontFamily: 'NunitoSans',
  },
  footerContent: {
    color: colors.white,
    marginLeft: '26px',
    fontFamily: 'NunitoSans',
  },
  tableOfContents: {
    color: colors.white,
    marginRight: 21,
    fontFamily: 'NunitoSans',
  },
  contentTableWrapper: {
    width: 256,
    height: 250,
    marginRight: 21,
    backgroundColor: colors.gray350,
    border: `1px solid ${colors.gray350}`,
    borderRadius: 5,
    marginTop: '-100px',
    paddingBottom: 20,
  },
  contentTableHeader: {
    fontWeight: 700,
    fontSize: 18,
    color: colors.white,
    paddingLeft: 32,
    marginTop: 32,
    fontFamily: 'NotoSans',
    fontStyle: 'bold',
  },
  contentTableItem: {
    color: colors.white,
    fontSize: 14,
    paddingLeft: 32,
    paddingTop: 20,
    fontFamily: 'NotoSans',
  },
  nonameContainer: {
    width: '60',
    height: '60',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tab: {
    marginLeft: 25,
    fontSize: 22,
    color: colors.gray150,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
  },
  selectedTab: {
    marginLeft: 25,
    color: colors.white,
    borderBottom: 4,
    fontSize: 22,
    borderBottomColor: colors.blueButtonBg,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
  },
  logoImg: {
    width: '220',
    height: '50',
    marginRight: 50,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 28,
    marginTop: 15,
  },
  contentLeft: {
    flex: 1,
    color: colors.gray150,
    fontSize: 50,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    alignItems: 'flex-start',
    height: 400,
    background: colors.gray350,
    borderRadius: 10,
    marginRight: 100,
    marginLeft: 100,
  },
  contentRight: {
    flex: 1,
    fontSize: 50,
    width: 300,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    alignItems: 'flex-start',
    marginRight: 100,
  },
  dateGeneration1: {
    color: colors.gray250,
    fontFamily: 'NotoSans',
  },
  attackSurfaceChart: {
    maxWidth: '1200px',
    height: '400px',
  },
  pageContent: {
    flex: 1,
    width: '100%',
    flexGrow: 1,
  },
  pageFooter: {
    flex: '0 0 170px',
    width: '100%',
    marginTop: 'auto',
  },
  crawlCompleted: {
    color: colors.white,
    marginLeft: '26px',
    fontFamily: 'NotoSans',
    fontSize: '14',
  },
  crawlScheduled: {
    color: colors.white,
    marginLeft: '26px',
    fontFamily: 'NotoSans',
    fontSize: '14',
  },
  pageFooterWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  crawlTimestamp: {
    flex: '0 0 900px',
    marginTop: '100px',
  },
  footerLogo: {
    marginTop: '100px',
    marginLeft: 100,
  },
  itemList: {
    margin: 'auto',
  },
  domainItems: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  log4jChart: {
    width: 300,
    height: 300,
  },
  trendChart: {
    width: 800,
    height: 400,
  },
  noDataImg: {
    width: 1000,
    height: 550,
    margin: 'auto',
  },
  pieChartLeft: {
    fontFamily: 'NunitoSans',
    marginLeft: 60,
  },
  issuesPieChartLeft: {
    fontFamily: 'NunitoSans',
    marginLeft: 30,
  },
  trend_contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.gray150,
    fontSize: 50,
    fontStyle: 'bold',
    fontFamily: 'NotoSans',
    alignItems: 'flex-start',
    marginRight: 100,
    marginLeft: 100,
  },
  crawlTrend_content_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 28,
    marginTop: 15,
  },
  recommendations_content_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    position: 'absolute',
    width: 1052,
    height: 466,
    marginLeft: 80,
  },
  crawlTrend_content: {
    padding: 0,
    margin: '0px 40px',
  },
  crawlTrend_contentChart: {
    padding: 0,
    width: '768px',
    height: '466px',
    margin: '0px 40px',
  },
  crawlTrend_content_itemLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 24px',
    width: '468px',
    maxHeight: '135px',
    background: colors.gray350,
    borderRadius: '6px',
    color: colors.whitish,
    marginTop: 40,
  },
  crawlTrend_content_itemRight: {
    padding: '16px 24px',
    width: '468px',
    height: '325px',
    background: colors.gray350,
    borderRadius: '6px',
    color: colors.whitish,
    marginTop: 40,
  },
  crawlTrend_servers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  recommendations_content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'absolute',
    padding: '0px',
    width: '1042px',
  },
  contentItem1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px 32px',
    width: '1042px',
    background: '#393939',
    borderRadius: '8px',
    color: '#ffffff',
  },
  contentItem2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 32px',
    width: '1042px',
    left: '0px',
    marginTop: 10,
    borderRadius: '8px',
    color: '#ffffff',
    fontSize: 12,
  },
  contactBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '13px 27px',
    width: '978px',
    margin: '10px',
    top: '20',
    color: '#ffffff',
    background: colors.darkest,
    opacity: '0.5',
    borderRadius: '8px',
  },
  listItem: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'NunitoSans',
    textIndent: 5,
  },
  domainsCount: {
    margin: 'auto',
    fontFamily: 'NunitoSans',
    fontStyle: 'bold',
    fontSize: 52,
    color: colors.white,
  },
  domainsText: {
    margin: 'auto',
    fontFamily: 'NunitoSans',
    fontStyle: 'bold',
    fontSize: 18,
    color: colors.gray150,
  },
});

const severity = {
  high: colors.red200,
  medium: colors.yellow1,
  low: colors.infoBlue,
  none: colors.gray500,
  decrease: colors.green600,
  increase: colors.red600,
};

const serversMapping = {
  log4j: 'Log4j Vulnerabilities',
  long4j: 'LoNg4j Vulnerabilities',
  exposed_files: 'Exposed Keystores',
  tls: 'Insecure SSL/TLS Implementation',
  unhandled: 'Unhandled Or Internal Errors',
  non_prod_or_origin: 'Non-production Or internal Application Server',
  health_monitoring: 'Application Health Monitoring Endpoints Exposed',
  login: 'User Login or Authentication Endpoints Exposed',
  openapi_swagger: 'OpenAPI/Swagger Endpoints Exposed',
  graphql: 'GraphQL Endpoints Detected',
};

const CoverPage = ({ reportGeneratedDate, organizationName }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img src={logo} style={styles.logoContainer} />
      </div>
      <div style={styles.content}>
        <div style={{ textAlign: 'right', paddingRight: 200 }}>
          <h1 style={styles.reportName}>API Spyder Executive Report</h1>
          <h2 style={styles.companyName}>{organizationName}</h2>
        </div>
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.contentTableWrapper}>
            <div style={styles.contentTableHeader}>Table of Contents</div>
            <div style={styles.contentTableItem}>1. Attack Surface</div>
            <div style={styles.contentTableItem}>2. Findings</div>
            <div style={styles.contentTableItem}>3. App Hosting Providers</div>
            <div style={styles.contentTableItem}>4. Weekly Crawling Trends</div>
            <div style={styles.contentTableItem}>5. Recommendations</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AttackSurfacePage = ({ imgData, reportGeneratedDate, data }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.tabContainer}>
          <img src={nonameLogo} style={styles.nonameContainer} />
          <h3 style={styles.selectedTab}>Attack Surface</h3>
          <h3 style={styles.tab}>Findings</h3>
          <h3 style={styles.tab}>App Hosting Providers</h3>
          <h3 style={styles.tab}>Weekly Crawling Trends</h3>
          <h3 style={styles.tab}>Recommendations</h3>
        </div>
      </div>
      <div style={styles.contentAttackSurface}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            background: colors.gray650,
            margin: '0px 80px 0px 80px',
            borderRadius: 10,
            paddingBottom: 20,
          }}
        >
          <div style={{ margin: 'auto' }}>
            <div style={styles.domainsCount}>
              {data['attack_surface']['total']}
            </div>
            <div style={styles.domainsText}>Domains monitored</div>
          </div>
          <div style={{ margin: 'auto' }}>
            <div style={styles.domainsCount}>
              {formatNumber(data['attack_surface']['endpoints'])}
            </div>
            <div style={styles.domainsText}>Endpoints discovered</div>
          </div>
          <div style={{ margin: 'auto' }}>
            <div style={styles.domainsCount}>
              {formatNumber(data['attack_surface']['servers'])}
            </div>
            <div style={styles.domainsText}>API hosts</div>
          </div>
          <div style={{ margin: 'auto' }}>
            <div style={styles.domainsCount}>
              {data['attack_surface']['hosting_providers']}
            </div>
            <div style={styles.domainsText}>Application hosting providers</div>
          </div>
        </div>
        <div style={{ margin: 'auto' }}>
          <img src={imgData} style={styles.attackSurfaceChart} />
        </div>
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.footerLogo}>
            <img src={logo} style={styles.logoContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

const IssuesPage = ({ reportGeneratedDate, issuesImg, data }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.tabContainer}>
          <img src={nonameLogo} style={styles.nonameContainer} />
          <h3 style={styles.tab}>Attack Surface</h3>
          <h3 style={styles.selectedTab}>Findings</h3>
          <h3 style={styles.tab}>App Hosting Providers</h3>
          <h3 style={styles.tab}>Weekly Crawling Trends</h3>
          <h3 style={styles.tab}>Recommendations</h3>
        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.contentWrapper}>
          <div style={styles.issuesPieChartLeft}>
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              <img src={issuesImg} style={styles.log4jChart} />
              {data.issues?.total_servers_at_risk > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '55%',
                    left: '35%',
                    transform: 'translate(-20%, -50%)',
                  }}
                >
                  <div
                    style={{
                      fontSize: 22,
                      fontFamily: 'NotoSans',
                      fontStyle: 'bold',
                      margin: 'auto',
                      color: colors.white,
                    }}
                  >
                  Findings
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      fontFamily: 'NotoSans',
                      fontStyle: 'bold',
                      margin: 'auto',
                      color: colors.gray50,
                    }}
                  >
                    ({data.issues?.total_servers_at_risk} Total)
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: 20,
                fontFamily: 'NotoSans',
                fontStyle: 'normal',
                color: colors.white,
              }}
            >
              <p>
                Servers with findings: {data.issues.total_servers_at_risk} out
                of {data['issues']['total_servers']} total servers
              </p>
            </div>
          </div>
          <div style={styles.issuesPieChartLeft}>
            <div
              style={{
                fontFamily: 'NotoSans',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <span
                style={{
                  fontSize: 22,
                  color: colors.white,
                  fontStyle: 'normal',
                  textAlign: 'right',
                }}
              >
                {`High: `}
              </span>
              <span
                style={{
                  fontSize: 22,
                  color: colors.white,
                  fontStyle: 'normal',
                  textAlign: 'right',
                }}
              >
                {formatNumber(data?.issues?.total_high) || 0}
              </span>
            </div>
            <div
              style={{
                fontFamily: 'NotoSans',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              {Object.entries(data['issues']['high']).map((e, i) => {
                return (
                  <div
                    key={`issue-${i}`}
                    style={{ fontFamily: 'NotoSans', textAlign: 'right' }}
                  >
                    <span
                      key={i}
                      style={{
                        fontSize: 22,
                        color: severity['high'],
                        fontStyle: 'bold',
                        textAlign: 'right',
                      }}
                    >
                      {`${serversMapping[e[0]]}: `}
                    </span>
                    <span
                      style={{
                        fontSize: 20,
                        color: colors.white,
                        fontStyle: 'normal',
                        textAlign: 'right',
                      }}
                    >
                      {e[1]}
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                fontSize: 22,
                fontFamily: 'NotoSans',
                fontStyle: 'normal',
                color: colors.white,
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginTop: 40,
              }}
            >
              <span
                style={{
                  fontSize: 22,
                  color: colors.white,
                  fontStyle: 'normal',
                  textAlign: 'right',
                }}
              >
                {`Medium: `}
              </span>
              <span
                style={{
                  fontSize: 22,
                  color: colors.white,
                  fontStyle: 'normal',
                  textAlign: 'right',
                }}
              >
                {formatNumber(data?.issues?.total_medium) || 0}
              </span>
            </div>
            <div
              style={{
                fontFamily: 'NotoSans',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              {Object.entries(data['issues']['medium']).map((e, i) => {
                return (
                  <div style={{ fontFamily: 'NotoSans', textAlign: 'right' }} key={i}>
                    <span
                      style={{
                        fontSize: 22,
                        color: severity['medium'],
                        fontStyle: 'bold',
                        textAlign: 'right',
                      }}
                    >
                      {`${serversMapping[e[0]]}: `}
                    </span>
                    <span
                      style={{
                        fontSize: 20,
                        color: colors.white,
                        fontStyle: 'normal',
                        textAlign: 'right',
                      }}
                    >
                      {e[1]}
                    </span>
                  </div>
                );
              })}
            </div>
            <div style={{ textAlign: 'right', marginTop: 40 }}>
              <div
                style={{
                  fontSize: 22,
                  fontFamily: 'NotoSans',
                  fontStyle: 'semiBold',
                  color: colors.white,
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 22,
                    color: colors.white,
                    fontStyle: 'normal',
                    textAlign: 'right',
                  }}
                >
                  {`Info: `}
                </span>
                <span
                  style={{
                    fontSize: 22,
                    color: colors.white,
                    fontStyle: 'normal',
                    textAlign: 'right',
                  }}
                >
                  {formatNumber(data?.issues?.total_low) || 0}
                </span>
              </div>
              <div
                style={{
                  fontFamily: 'NotoSans',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                {Object.entries(data['issues']['low']).map((e, i) => {
                  return (
                    <div
                      style={{
                        fontFamily: 'NotoSans',
                        textAlign: 'right',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                      key={i}
                    >
                      <span
                        key={i}
                        style={{
                          fontSize: 22,
                          color: severity['low'],
                          fontStyle: 'bold',
                          textAlign: 'right',
                        }}
                      >
                        {`${serversMapping[e[0]]}: `}
                      </span>
                      <span
                        style={{
                          fontSize: 20,
                          color: colors.white,
                          fontStyle: 'normal',
                          textAlign: 'right',
                        }}
                      >
                        {formatNumber(e[1])}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.footerLogo}>
            <img src={logo} style={styles.logoContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AppHostingProvidersPage = ({
  reportGeneratedDate,
  appHostingImgData,
  appHosting2ImgData,
  appHosting3ImgData,
  data,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.tabContainer}>
          <img src={nonameLogo} style={styles.nonameContainer} />
          <h3 style={styles.tab}>Attack Surface</h3>
          <h3 style={styles.tab}>Findings</h3>
          <h3 style={styles.selectedTab}>App Hosting Providers</h3>
          <h3 style={styles.tab}>Weekly Crawling Trends</h3>
          <h3 style={styles.tab}>Recommendations</h3>
        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.contentWrapper}>
          <div style={styles.pieChartLeft}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <img src={appHostingImgData} style={styles.log4jChart} />
              <div
                style={{
                  position: 'absolute',
                  top: '65%',
                  left: '35%',
                  transform: 'translate(-35%, -65%)',
                }}
              >
                <div
                  style={{
                    fontSize: 22,
                    margin: 'auto',
                    color: colors.gray50,
                    fontStyle: 'bold',
                    font: 'NotoSans',
                  }}
                >
                  IaaS Providers
                </div>
              </div>
            </div>
            <div style={{ width: '80%' }}>
              {data['iaas'].map((i) => {
                if (i.count > 0)
                  return (
                    <div
                      style={{
                        fontFamily: 'NotoSans',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                      }}
                      key={i}
                    >
                      <span
                        key={i}
                        style={{
                          fontSize: 22,
                          color: colors.orange200,
                          fontStyle: 'bold',
                        }}
                      >
                        {`${i.name}: `}
                      </span>
                      <span style={{ fontSize: 20, color: colors.white }}>
                        {i.count}
                      </span>
                    </div>
                  );
              })}
              <hr
                style={{
                  color: severity['low'],
                  backgroundColor: colors.orange200,
                  height: 2,
                }}
              />
              <div
                style={{
                  fontFamily: 'NotoSans',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 22,
                    color: colors.orange200,
                    fontStyle: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {`Total: `}
                </span>
                <span
                  style={{
                    fontSize: 20,
                    color: colors.white,
                    fontStyle: 'normal',
                    textAlign: 'right',
                  }}
                >
                  {data['iaas_total']}
                </span>
              </div>
            </div>
          </div>
          <div style={styles.pieChartLeft}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <img src={appHosting2ImgData} style={styles.log4jChart} />
              <div
                style={{
                  position: 'absolute',
                  top: '65%',
                  left: '35%',
                  transform: 'translate(-35%, -65%)',
                }}
              >
                <div
                  style={{
                    fontSize: 22,
                    margin: 'auto',
                    color: colors.gray50,
                    fontStyle: 'bold',
                    font: 'NotoSans',
                  }}
                >
                  CDN Providers
                </div>
              </div>
            </div>
            <div style={{ width: '80%' }}>
              {data['cdn'].map((i) => {
                if (i.count > 0)
                  return (
                    <div
                      style={{
                        fontFamily: 'NotoSans',
                        textAlign: 'right',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                      key={i}
                    >
                      <span
                        key={i}
                        style={{
                          fontSize: 22,
                          color: colors.magenta200,
                          margin: 'auto',
                          fontStyle: 'bold',
                        }}
                      >
                        {`${i.name}: `}
                      </span>
                      <span style={{ fontSize: 20, color: colors.white }}>
                        {i.count}
                      </span>
                    </div>
                  );
              })}
              <hr
                style={{
                  color: severity['low'],
                  backgroundColor: colors.magenta200,
                  height: 2,
                }}
              />
              <div
                style={{
                  fontFamily: 'NotoSans',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 22,
                    color: colors.magenta200,
                    fontStyle: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {`Total: `}
                </span>
                <span
                  style={{
                    fontSize: 20,
                    color: colors.white,
                    fontStyle: 'normal',
                    textAlign: 'right',
                  }}
                >
                  {data['cdn_total']}
                </span>
              </div>
            </div>
          </div>
          <div style={styles.pieChartLeft}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <img src={appHosting3ImgData} style={styles.log4jChart} />
              <div
                style={{
                  position: 'absolute',
                  top: '65%',
                  left: '35%',
                  transform: 'translate(-35%, -65%)',
                }}
              >
                <div
                  style={{
                    fontSize: 22,
                    margin: 'auto',
                    color: colors.gray50,
                    fontStyle: 'bold',
                    font: 'NotoSans',
                  }}
                >
                  ISP Providers
                </div>
              </div>
            </div>
            <div style={{ width: '80%' }}>
              {data['isp'].map((i) => {
                if (i.count > 0)
                  return (
                    <div
                      style={{
                        fontFamily: 'NotoSans',
                        textAlign: 'right',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                      key={i}
                    >
                      <span
                        key={i}
                        style={{
                          fontSize: data['isp'].filter((element) => element.count > 0).length < 8 ? 20 : 10,
                          color: colors.green100,
                          margin: 'auto',
                          fontStyle: 'bold',
                        }}
                      >
                        {`${i.name}: `}
                      </span>
                      <span style={{ fontSize: data['isp'].filter((element) => element.count > 0).length < 8 ? 18 : 8, color: colors.white }}>
                        {i.count}
                      </span>
                    </div>
                  );
              })}
              <hr
                style={{
                  color: severity['low'],
                  backgroundColor: colors.green100,
                  height: 2,
                }}
              />
              <div
                style={{
                  fontFamily: 'NotoSans',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 22,
                    color: colors.green100,
                    fontStyle: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {`Total: `}
                </span>
                <span
                  style={{
                    fontSize: 20,
                    color: colors.white,
                    fontStyle: 'normal',
                    textAlign: 'right',
                  }}
                >
                  {data['isp_total']}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.footerLogo}>
            <img src={logo} style={styles.logoContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CrawlingTrendsPage = ({
  reportGeneratedDate,
  weeklyCrawlTrendsImgData,
  licenseInfo,
  data
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.tabContainer}>
          <img src={nonameLogo} style={styles.nonameContainer} />
          <h3 style={styles.tab}>Attack Surface</h3>
          <h3 style={styles.tab}>Findings</h3>
          <h3 style={styles.tab}>App Hosting Providers</h3>
          <h3 style={styles.selectedTab}>Weekly Crawling Trends</h3>
          <h3 style={styles.tab}>Recommendations</h3>
        </div>
      </div>
      <div style={styles.content}>
        {weeklyCrawlTrendsImgData ? (
          <div style={styles.crawlTrend_content_wrapper}>
            <img src={weeklyCrawlTrendsImgData} style={styles.trendChart} />
          </div>
        ) : licenseInfo?.name === 'FREE_TRIAL' ? (
          <img src={freeUserImg} style={styles.noDataImg} />
        ) : (
          <img src={paidUserImg} style={styles.noDataImg} />
        )}
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.footerLogo}>
            <img src={logo} style={styles.logoContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

const RecommendationsPage = ({
  reportGeneratedDate,
  contactName,
  contactEmail,
  data,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.tabContainer}>
          <img src={nonameLogo} style={styles.nonameContainer} />
          <h3 style={styles.tab}>Attack Surface</h3>
          <h3 style={styles.tab}>Findings</h3>
          <h3 style={styles.tab}>App Hosting Providers</h3>
          <h3 style={styles.tab}>Weekly Crawling Trends</h3>
          <h3 style={styles.selectedTab}>Recommendations</h3>
        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.recommendations_content_wrapper}>
          <div style={styles.recommendations_content}>
            <div style={styles.contentItem1}>
              <div
                style={{
                  fontFamily: 'NotoSans',
                  fontSize: 28,
                  fontStyle: 'bold',
                  color: colors.white,
                }}
              >
                Recommendations
              </div>
              <ul style={{ lineHeight: '180%', listStyleType: 'circle', width: '1042px' }}>
                {data['high']?.map((i) => {
                  return (
                    <li key={i} style={styles.listItem}>
                      {i}
                    </li>
                  );
                })}
                {data['medium']?.map((i) => {
                  return (
                    <li key={i} style={styles.listItem}>
                      {i}
                    </li>
                  );
                })}
                {data['low']?.map((i) => {
                  return (
                    <li key={i} style={styles.listItem}>
                      {i}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div style={styles.contentItem2}>
              <div style={styles.contactBox}>
                <div
                  style={{
                    width: 250,
                    borderRight: '3px solid white',
                    float: 'left',
                  }}
                >
                  <div>Contact:</div>
                  {contactName && contactName !== '' && (
                    <div style={{ marginTop: 10 }}>{contactName}</div>
                  )}
                  <div style={{ marginTop: 10 }}>
                    {contactEmail ? contactEmail : 'support@cequence.ai'}
                  </div>
                </div>
                <div style={{ float: 'right', marginLeft: 20 }}>
                  Cequence Security, Inc.
                  <br />
                  100 S Murphy Ave #300,
                  <br />
                  Sunnyvale, CA 94086
                  <br />
                  (650)-437-6338
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.pageFooter}>
        <div style={styles.pageFooterWrapper}>
          <div style={styles.crawlTimestamp}>
            <div style={styles.crawlCompleted}>
              Data based on the latest crawls as of {reportGeneratedDate}
            </div>
          </div>
          <div style={styles.footerLogo}>
            <img src={logo} style={styles.logoContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Reports = (props) => {
  const {
    data,
    imgData,
    issuesImg,
    appHostingImgData,
    appHosting2ImgData,
    appHosting3ImgData,
    weeklyCrawlTrendsImgData,
    licenseInfo,
  } = props;
  const reportGeneratedDate = moment().format('MMM DD, hh:mm a');
  const coverContent = ReactDOMServer.renderToStaticMarkup(
    <CoverPage
      reportGeneratedDate={reportGeneratedDate}
      organizationName={data['report_org_name'] || data['org_name']}
    />
  );

  const attackSurfaceContent = ReactDOMServer.renderToStaticMarkup(
    <AttackSurfacePage
      imgData={imgData}
      data={data}
      reportGeneratedDate={reportGeneratedDate}
    />
  );

  const issuesContent = ReactDOMServer.renderToStaticMarkup(
    <IssuesPage
      issuesImg={issuesImg}
      data={data}
      reportGeneratedDate={reportGeneratedDate}
    />
  );

  const appHostingProvidersContent = ReactDOMServer.renderToStaticMarkup(
    <AppHostingProvidersPage
      appHostingImgData={appHostingImgData}
      appHosting2ImgData={appHosting2ImgData}
      appHosting3ImgData={appHosting3ImgData}
      data={data['hosting_providers']}
      reportGeneratedDate={reportGeneratedDate}
    />
  );

  const weeklyTrends = {
    api_servers: data?.attack_surface?.servers || 0,
    high_severity_issues: data?.issues?.total_high || 0,
    hosting_providers: data?.hosting_providers || {},
    low_severity_issues: data?.issues?.total_low || 0,
    medium_severity_issues: data?.issues?.total_medium || 0,
  //   missing_api_servers
  //   new_api_servers
  //   new_high_severity_issues
  //   new_hosting_providers
  //   missing_hosting_providers
  //   new_low_severity_issues
  //   new_medium_severity_issues
  //   new_api_server_names
  }
  const crawlingTrends = ReactDOMServer.renderToStaticMarkup(
    <CrawlingTrendsPage
      data={weeklyTrends}
      reportGeneratedDate={reportGeneratedDate}
      weeklyCrawlTrendsImgData={weeklyCrawlTrendsImgData}
      licenseInfo={licenseInfo}
    />
  );

  const recommendations = ReactDOMServer.renderToStaticMarkup(
    <RecommendationsPage
      data={data['recommendations']}
      contactName={data['contact_name']}
      contactEmail={data['contact_email']}
      reportGeneratedDate={reportGeneratedDate}
    />
  );

  return (
    <Document title={'API Spyder Executive Report'}>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={coverImg} style={styles.pageBackground} />
        <Html>{coverContent}</Html>
      </Page>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={contentPageImg} style={styles.pageBackground} />
        <Html>{attackSurfaceContent}</Html>
      </Page>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={contentPageImg} style={styles.pageBackground} />
        <Html>{issuesContent}</Html>
      </Page>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={contentPageImg} style={styles.pageBackground} />
        <Html>{appHostingProvidersContent}</Html>
      </Page>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={contentPageImg} style={styles.pageBackground} />
        <Html>{crawlingTrends}</Html>
      </Page>
      <Page size={'TABLOID'} orientation={'landscape'}>
        <Image src={contentPageImg} style={styles.pageBackground} />
        <Html>{recommendations}</Html>
      </Page>
    </Document>
  );
};

export default Reports;
