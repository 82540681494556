import React, { useEffect, useState, useContext } from 'react';
import { SwapHoriz } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { colors } from '../../assets/theme';
import {
  Link,
  Grid,
  SvgIcon,
  Typography,
  Popover,
  List,
  ListItem,
  Tooltip,
} from '@mui/material';
import { muiLicenseKey, dataGridStyles } from '@cequence/ui';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import CqSearch from '../../components/common/CqSearch';
import getMethodImg from '../../assets/img/get-method.svg';
import postMethodImg from '../../assets/img/post-method.svg';
import FindingsCell from '../../components/FindingsCell';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import GatewayCell from '../../components/GatewayCell';
import { findingsSort } from '../../lib/utils';

const PopMenuList = styled(List)({
  border: `1px solid ${colors.gray400}`,
  backgroundColor: colors.gray700,
  padding: '4px 0',
});
const PopMenuListItem = styled(ListItem)({
  padding: '10px 12px',
  minWidth: 214,
});

const PopListItem = styled(ListItem)({
  justifyContent: 'space-between',
});

function CustomToolbar(props) {
  const apiRef = useGridApiContext();
  const filteredRowCount = apiRef.current.getRowsCount();
  return (
    <Grid
      container
      sx={{
        height: '36px',
        border: '1px solid #333',
        paddingBottom: '8px',
        boxSizing: 'unset',
      }}
    >
      <Grid
        item
        xs={8}
        sx={{ alignItems: 'center', display: 'flex', paddingLeft: '4px' }}
      >
        <GridToolbarContainer>
          <CqSearch
            value={props.inputText}
            placeholder="Search"
            onChange={props.searchEndpoints}
          />
          <>{Intl.NumberFormat('en-us').format(filteredRowCount)} Endpoints</>
        </GridToolbarContainer>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ justifyContent: 'end', display: 'flex', paddingRight: '8px' }}
      >
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </Grid>
    </Grid>
  );
}

const columns = [
  {
    headerName: 'Method',
    field: 'method',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => (
      <SvgIcon
        sx={{ width: 75, height: 20, margin: 0 }}
        component={params.value === 'get' ? getMethodImg : postMethodImg}
      />
    ),
    valueGetter: (params) => params.row.method,
    flex: 1,
  },
  {
    headerName: 'API Endpoint',
    field: 'path',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`//${params.value.host}${params.value.path}`}
        sx={{ color: colors.blue300 }}
      >
        {params.value.path}
      </Link>
    ),
    sortComparator: (v1, v2) => (v1.path > v2.path ? -1 : 1),
    flex: 1,
  },
  {
    headerName: 'API Gateway',
    field: 'apiGateways',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => <GatewayCell list={params.value} />,
    width: '100',
    filterable: false,
  },
  {
    headerName: 'Endpoint Type',
    field: 'isApi',
    sortingOrder: ['desc', 'asc'],
    flex: 1,
  },
  {
    headerName: 'Content Type',
    field: 'contentType',
    sortingOrder: ['desc', 'asc'],
    flex: 1,
  },
  {
    headerName: 'Response',
    field: 'status',
    sortingOrder: ['desc', 'asc'],
    flex: 1,
  },
  {
    headerName: 'Findings',
    field: 'findings',
    renderCell: FindingsCell,
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1, v2) => findingsSort(v1?.findings, v2?.findings),
    filterable: false,
    flex: 1,
  },
  {
    headerName: '',
    field: 'requestResponse',
    renderCell: (params) => (
      <Tooltip title="View Request Response">
        <SwapHoriz
          onClick={() => {
            params.value.callback(params.row);
          }}
        />
      </Tooltip>
    ),
    filterable: false,
    minWidth: '25',
    sortable: false,
  },
];

const EndpointTable = ({
  endpoints,
  routeToServerDetails,
  getRequestResponse,
}) => {
  LicenseInfo.setLicenseKey(muiLicenseKey);
  const [endpointsData, setEndpointsData] = useState([]);
  const [findingsIssues, setFindingsIssues] = useState({ type: '', list: [] });
  const [issuesPopover, setIssuesPopover] = useState(null);
  const [inputText, setInputText] = useState('');
  const wrapperContext = useContext(WrapperContext);
  const issueTypes = wrapperContext.issueTypes;
  const gatewayConfigData = wrapperContext.gatewayConfig;
  const gatewayConfigObj = {};
  for (const obj of gatewayConfigData) {
    gatewayConfigObj[obj.name] = obj;
  }
  const searchEndpoints = (val) => {
    setInputText(val);
  };

  const showIssuePopover = (e, type, data, list) => {
    let issuesPopover = false;
    for (const iss of list) {
      if (type === iss.severity) {
        issuesPopover = true; // show popover if atleast one item belong to severity
        break;
      }
    }
    if (issuesPopover) {
      setFindingsIssues({ type: type, list: list });
      setIssuesPopover(e.target);
    }
  };

  useEffect(() => {
    let i = 0;
    const data = [];
    if (endpoints && endpoints.length > 0) {
      let endpointsList = endpoints;
      if (inputText !== '') {
        endpointsList = endpoints.filter((val) => {
          const path = val?.path || '';
          const ctype = val?.contentType || '';
          const method = val?.method || '';
          const st = `${val?.status}` || '';
          if (
            path.includes(inputText) ||
            method.includes(inputText) ||
            ctype.includes(inputText) ||
            st.includes(inputText)
          ) {
            return val;
          }
        });
      }
      for (const endpoint of endpointsList) {
        const gateways = endpoint.apiGateways || [];
        data.push({
          method: endpoint.method,
          path: { path: endpoint.path, host: endpoint.hostname },
          hostname: { data: endpoint, callback: routeToServerDetails },
          contentType: endpoint.contentType,
          isApi: endpoint.isApi ? 'API' : 'Non API',
          findings: {
            findings: endpoint.findings,
            issues: endpoint?.findings?.issues,
            callback: showIssuePopover,
          },
          status: endpoint.status,
          crawlId: endpoint.crawlId,
          hostId: endpoint.hostId,
          objectKey: endpoint.objectKey,
          id: i,
          requestResponse: {
            callback: (endpointInfo) => getRequestResponse(endpointInfo),
          },
          apiGateways: gateways
            .map((val) => gatewayConfigObj?.[val]?.displayName || '')
            .join(','),
        });
        i++;
      }
      setEndpointsData(data);
    }
  }, [endpoints, inputText]);
  const data = {
    rows: endpointsData,
    columns: columns,
  };

  return (
    <div style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
      <DataGridPremium
        {...data}
        initialState={data}
        headerHeight={36}
        hideFooter
        sx={dataGridStyles}
        disableAggregation
        disableRowGrouping
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: { inputText: inputText, searchEndpoints: searchEndpoints },
        }}
      />
      <Popover
        open={Boolean(issuesPopover)}
        anchorEl={issuesPopover}
        elevation={0}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setFindingsIssues({ type: '', list: [] });
          setIssuesPopover(null);
        }}
      >
        <PopMenuList>
          {findingsIssues.list.map((iss, idx) => {
            return (
              <>
                {findingsIssues.type === iss.severity && (
                  <PopMenuListItem key={iss.name}>
                    <PopListItem item container>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {issueTypes?.[iss.name]?.description}
                        </Typography>
                      </Grid>
                    </PopListItem>
                  </PopMenuListItem>
                )}
              </>
            );
          })}
        </PopMenuList>
      </Popover>
    </div>
  );
};

export default EndpointTable;
