import { colors } from '../assets/theme';
import { styled } from '@mui/system';
import { switchClasses } from '@mui/material/Switch';

export const Root = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${colors.gray600};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: ${colors.blueButtonBg};
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${colors.gray500};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 32px;
      top: 3px;
      background-color: #fff;
    }

    .${switchClasses.track} {
      background: ${colors.blueButtonBg};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
);
