export const getDomainsQuery = `
query GetActiveDomains {
  domain {
    getActiveDomains {
	    domain
	    status
	  }
	}
}
`;

export const getCrawlSummaryHistogramQuery = `
query GetCrawlSummaryHistogram($crawlHistogramDomains: [String!], $days: Int) {
  discovery {
    getCrawlHistoryHistogram(domains: $crawlHistogramDomains, filters: { days: $days }) {
      domains
      histogram
      timestamp
    }
  }
}
`;
