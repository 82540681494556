import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wildCardRule } from '../../lib/utils';
import { isEmpty } from 'lodash';
import CqDialog from '../../components/common/CqDialog';
import CqSelect from '../../components/common/CqSelect';
import CqMultiSelect from '../../components/common/CqMultiSelect';
import AutoComplete from './AutoComplete';
import { colors } from '../../assets/theme';
import { CrawlContext } from './crawlContext';
import { getRulecatagory } from './helpers';

const useStyles = makeStyles({
  formContainer: {
    backgroundColor: colors.almostBlack,
    padding: '8px',
  },
  formRow: {
    borderBottom: `1px solid ${colors.darkLabelText}`,
    display: 'flex',
    gap: '28px',
    alignItems: 'center',
    padding: '8px 0',
  },
  switchField: {
    '& .Mui-checked': {
      color: colors.blue300,
    },
  },
  switchContainer: {
    padding: '15px',
    textAlign: 'right',
  },
  textlabel: {
    paddingBottom: '4px',
    color: colors.gray300,
    fontSize: 12,
    lineHeight: '16px',
  },
  textbox: {
    '& .MuiInputBase-root': {
      height: '32px',
    },
  },
  caption: {
    display: 'block',
  },
  formColumn: {
    height: '75px',
  },
});

const CrawlRuleDialog = ({ open, onClose, ruleInfo }) => {
  const classes = useStyles();
  const defaultState = {
    name: '',
    action: '',
    wildcard: '',
    regex: '',
    active: false,
    id: null,
    type: '',
    simple: [],
    except: [],
  };
  const [ruleDetails, setRuleDetails] = useState(defaultState);
  const { dispatch, setNotify, domain, updateRulesData, rules, subDomains } =
    useContext(CrawlContext);
  const subDomainsList = subDomains.map((sd) => sd.subdomain);
  useEffect(() => {
    if (ruleInfo?.id || ruleInfo?.type) {
      const except = {};
      const simpleList = {};
      if (ruleInfo.except && ruleInfo.except.length > 0) {
        for (const item of ruleInfo.except) {
          except[item] = true;
        }
      }
      if (ruleInfo.simple && ruleInfo.simple.length > 0) {
        for (const item of ruleInfo.simple) {
          simpleList[item] = true;
        }
      }
      let { category } = getRulecatagory(rules, ruleInfo);
      setRuleDetails(() => {
        const rule = {
          name: ruleInfo.name || '',
          wildcard: ruleInfo.wildcard || '',
          active: ruleInfo.active || false,
          type: ruleInfo.type,
          regex: ruleInfo.regex || '',
          action: category,
          simple: simpleList,
          except: except,
        };
        if (ruleInfo?.id) {
          rule.id = ruleInfo.id;
        }
        return rule;
      });
    }
  }, [ruleInfo]);

  const updateRuleName = (e) =>
    setRuleDetails((details) => {
      return { ...details, ...{ name: e.target.value } };
    });

  const setRuleStatus = () =>
    setRuleDetails((details) => {
      return { ...details, ...{ active: !details.active } };
    });

  const updateAction = (val) =>
    setRuleDetails((details) => {
      return { ...details, ...{ action: val } };
    });

  const updateExcludeRule = (e) =>
    setRuleDetails((details) => {
      const newDetails = { ...details };
      if (e.target.value === '') {
        newDetails.except = []; // when wildcard/regex rule string is null reset
      }
      if (details.type === 'WILDCARD') {
        newDetails.wildcard = e.target.value;
        newDetails.regex = '';
        newDetails.simple = '';
        if (details.regex !== '') {
          newDetails.except = []; // when rule type is changed reset except list
        }
        return newDetails;
      }
      if (details.type === 'REGEX') {
        const newDetails = { ...details };
        newDetails.wildcard = '';
        newDetails.regex = e.target.value;
        newDetails.simple = '';
        if (details.regex !== '') {
          newDetails.except = []; // when rule type is changed reset except list
        }
        return newDetails;
      }
    });

  const updateSimpleRules = (val) =>
    setRuleDetails((details) => {
      const simpleRule = { ...details.simple };
      if (simpleRule[val]) {
        delete simpleRule[val];
      } else {
        simpleRule[val] = true;
      }
      return {
        ...details,
        simple: simpleRule,
        regex: '',
        wildcard: '',
        except: [],
      };
    });
  const updatetype = (val) =>
    setRuleDetails((details) => {
      return {
        ...details,
        ...{ type: val, regex: '', simple: [], wildcard: '', except: [] },
      };
    });

  const updateExcludes = (val) =>
    setRuleDetails((details) => {
      const obj = {};
      for (const item of val) {
        obj[item] = true;
      }
      return { ...details, except: obj };
    });

  let exceptDetails = [];
  if (ruleDetails.wildcard && ruleDetails.wildcard !== '') {
    const isMatch = wildCardRule(ruleDetails.wildcard);
    for (let sd of subDomainsList) {
      if (isMatch(sd)) {
        exceptDetails.push(sd);
      }
    }
  }

  if (ruleDetails.regex && ruleDetails.regex !== '') {
    let regex;
    try {
      regex = new RegExp(ruleDetails.regex);
      for (let sd of subDomainsList) {
        if (regex.test(sd)) {
          exceptDetails.push(sd);
        }
      }
    } catch (e) {
      // if user type invalid error this prevent from crash
    }
  }

  const subdomainsImpact =
    exceptDetails.length - Object.keys(ruleDetails.except).length;

  const handleSubmit = () => {
    const rule = {};
    if (ruleDetails.wildcard && ruleDetails.wildcard !== '') {
      rule.wildcard = ruleDetails.wildcard;
    }
    if (ruleDetails.regex && ruleDetails.regex !== '') {
      rule.regex = ruleDetails.regex;
    }
    rule.simple = Object.keys(ruleDetails.simple) || [];
    rule.name = ruleDetails.name;
    rule.type = ruleDetails.type;
    rule.except = Object.keys(ruleDetails.except) || [];
    rule.active = ruleDetails.active;
    if (ruleDetails.id) {
      rule.id = ruleDetails.id;
    }
    const action = ruleDetails.action.toLowerCase();
    handleClose();
    setNotify((state) => ({ ...state, loading: true }));
    updateRulesData(domain, rules, rule, action)
      .then((res) => {
        dispatch({ type: 'loadData', value: res });
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          success: true,
          message: `Updated Crawl rule ${rule.name} successfully!`,
        }));
      })
      .catch(() => {
        setRuleDetails(defaultState);
        handleClose();
        setNotify((state) => ({
          ...state,
          loading: false,
          open: true,
          error: true,
          message: 'Something went wrong while updating Crawl rule!',
        }));
      });
  };

  const handleClose = () => {
    onClose(false);
    setRuleDetails(defaultState);
    dispatch({ type: 'emptyRule' });
  };

  let validRuleData = false;
  if (
    ruleDetails.type &&
    ruleDetails.type === 'WILDCARD' &&
    subdomainsImpact === 0
  ) {
    validRuleData = true;
  }
  if (
    ruleDetails.type &&
    ruleDetails.type === 'REGEX' &&
    subdomainsImpact === 0
  ) {
    validRuleData = true;
  }
  if (
    ruleDetails.type &&
    ruleDetails.type === 'SIMPLE' &&
    isEmpty(ruleDetails.simple)
  ) {
    validRuleData = true;
  }

  let ruleVal = '';
  if (ruleDetails.type === 'WILDCARD') {
    ruleVal = ruleDetails.wildcard;
  }
  if (ruleDetails.type === 'REGEX') {
    ruleVal = ruleDetails.regex;
  }

  const disableSubmit =
    ruleDetails.name === '' ||
    ruleDetails.type === '' ||
    ruleDetails.action === '' ||
    validRuleData;
  const diableRuleText =
    ruleDetails.type === 'SIMPLE' ||
    ruleDetails.type === '' ||
    ruleDetails.action === '';

  const getDomainWithSimpleRules = () => {
    const simpleList = { ...ruleDetails.simple };
    for (const sd of subDomainsList) {
      if (!simpleList[sd]) {
        simpleList[sd] = false;
      }
    }
    return simpleList;
  };

  return (
    <CqDialog
      title={ruleInfo?.id ? `Edit ${ruleDetails.name}` : 'Create Rule'}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitBtnText="Save"
      maxWidth="md"
      submitBtnDisabled={disableSubmit}
      disableEnforceFocus
    >
      <div className={classes.formContainer}>
        <div className={classes.formRow}>
          <Grid container>
            <Grid item xs={10}>
              <InputLabel className={classes.textlabel}>Rule Name*</InputLabel>
              <TextField
                fullWidth
                className={classes.textbox}
                variant="outlined"
                onChange={updateRuleName}
                value={ruleDetails.name}
                disabled={ruleDetails.name === 'default'}
              />
            </Grid>
            <Grid item xs={2} className={classes.switchContainer}>
              <Switch
                checked={ruleDetails.active}
                onChange={setRuleStatus}
                className={classes.switchField}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formColumn}>
            <CqSelect
              label="Action*"
              width={160}
              height={35}
              options={['', 'Exclude', 'Include']}
              onChange={updateAction}
              value={ruleDetails.action}
            />
          </div>
          <div className={classes.formColumn}>
            <CqSelect
              label="Type*"
              width={160}
              height={35}
              options={['', 'SIMPLE', 'WILDCARD', 'REGEX']}
              onChange={updatetype}
              value={ruleDetails.type}
            />
          </div>
          {ruleDetails.type === '' && (
            <>
              <div className={classes.formColumn}>
                <InputLabel className={classes.textlabel}>
                  Must {ruleDetails.action}*
                </InputLabel>
                <TextField
                  className={classes.textbox}
                  variant="outlined"
                  value=""
                  disabled
                />
              </div>
            </>
          )}
          {(ruleDetails.type === 'WILDCARD' ||
            ruleDetails.type === 'REGEX') && (
            <>
              <div className={classes.formColumn}>
                <InputLabel className={classes.textlabel}>
                  Must {ruleDetails.action}*
                </InputLabel>
                <TextField
                  className={classes.textbox}
                  variant="outlined"
                  onChange={updateExcludeRule}
                  value={ruleVal}
                  disabled={diableRuleText}
                />
                <span className={classes.caption}>
                  <Typography variant="caption">
                    {exceptDetails.length} Sub-Domains matches this rule
                  </Typography>
                </span>
              </div>
              <Typography>Except</Typography>
              <div className={classes.formColumn}>
                <CqMultiSelect
                  displayEmpty={ruleDetails.wildcard === ''}
                  label="Sub-Domains"
                  options={exceptDetails}
                  value={ruleDetails.except}
                  onChange={updateExcludes}
                  width={160}
                />
              </div>
            </>
          )}
          {ruleDetails.type === 'SIMPLE' && (
            <div className={classes.formColumn}>
              <AutoComplete
                options={getDomainWithSimpleRules()}
                onSelect={updateSimpleRules}
                label={`Must ${ruleDetails.action}*`}
              />
              <Typography variant="caption">
                {Object.keys(ruleDetails.simple).length} Sub-Domains selected
              </Typography>
            </div>
          )}
        </div>
      </div>
    </CqDialog>
  );
};

export default CrawlRuleDialog;
