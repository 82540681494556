import React from 'react';
import { styled } from '@mui/material';
import { Descope } from "@descope/react-sdk";
import { colors } from '../../assets/theme';

const StyledContainer = styled('div')({
  padding: '12px 24px',
  background: colors.darkBlue800,
  height: 'calc(100vh)',
});

const StyledFlex = styled('div')({
  display: 'flex',
  gap: '20px',
  alignItems: 'start',
  justifyContent: 'center',
  paddingTop: '100px'
});

const StyledBox = styled('div')({
  padding: '12px',
  backgroundColor: '#1A4B80',
  borderRadius: '4px',
  marginTop: '28px',
  fontSize: '16px',
});

const StyledHeading = styled('h1')({
  fontSize: '48px',
  margin: 0
});

const StyledContent = styled('div')({
  maxWidth: '500px'
});

const StyledList = styled('li')({
  fontSize: '18px',
  color: '#fff',
  marginTop: '20px',
  fontSize: '16px',
});

const StyledSignup = styled('div')({
  maxWidth: '400px'
});

const SignupContent = () => {
  const flowSuccess = (e) => {
    console.log(e)
  };

  return (
    <StyledContainer>
      <StyledFlex>
        <StyledContent>
          <StyledHeading>API Spyder</StyledHeading>
          <StyledList>
            Start in minutes. No credit card required. No agents to deploy, no
            network changes
          </StyledList>
          <StyledList>
            Discover your public-facing API attack surface including API servers
            and hosting providers
          </StyledList>
          <StyledList>
            Automate periodic discovery of changes to attack surface and generate
            executive summary reports
          </StyledList>
          <StyledBox>
            API Spyder is the first step in the API protection lifecycle,
            providing understanding of the external attack surface, what APIs can
            be accessed, the hosting environments, and any accidental exposures.
          </StyledBox>
        </StyledContent>
        <StyledSignup>
          <Descope
            flowId="sign-up-cq"
            theme="light"
            onSuccess={flowSuccess}
            onError={(e) => console.log('Could not log in!', e)}
          />
        </StyledSignup>
      </StyledFlex>
    </StyledContainer>
  );
};

export default SignupContent;