import React, { useState, useCallback } from 'react';
import clsx from 'clsx';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  List,
  ListItem,
  Popover,
  Link,
  ListItemButton,
} from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDescope, useUser, getSessionToken } from '@descope/react-sdk';
import { useGlobalState } from '../../store/globalState';
import { localStore } from '../../store/localStore';
import darkTheme, { colors } from '../../assets/theme';
import { useNavigate } from 'react-router-dom';
import CqButton from '../common/CqButton';
import CqSearch from '../common/CqSearch';
import jwt from 'jsonwebtoken';
import { getRoutes } from '../../lib/constants';

const popOverContainer = makeStyles({
  paper: {
    border: `1px solid ${colors.gray400}`,
    boxShadow: colors.darkest,
  },
});

const useStyles = makeStyles((theme) => ({
  menuButton: {
    padding: '2px 4px',
    '& svg': {
      fill: colors.gray100,
    },
  },
  menuPopover: {
    backgroundColor: colors.gray700,
  },
  userList: {
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0',
    alignItems: 'center',
    gap: '6px',
  },
  primaryText: {
    color: colors.gray100,
  },
  captionText: {
    color: colors.gray300,
  },
  userAvatar: {
    color: colors.gray900,
    textTransform: 'uppercase',
    '&.MuiAvatar-root': {
      backgroundColor: '#AE7EDB',
    },
  },
  popMenuListItem: {
    padding: '12px',
    minWidth: 214,
    cursor: 'pointer',
    '&.Mui-selected': {
      backgroundColor: colors.gray500,
    },
    '&:hover': {
      backgroundColor: colors.gray400,
    },
  },
  popMenuListItemBorder: {
    borderTop: `1px solid ${colors.gray500}`,
  },
  popMenuListItemText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: colors.gray100,
    textDecoration: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'none',
    },
    display: 'block',
    alignItems: 'center',
    width: '100%',
  },
  tenantList: {
    paddingBottom: '10px',
    maxHeight: '350px',
    overflow: 'scroll',
  },
  popMenuBackLink: {
    cursor: 'pointer',
    display: 'flex',
    color: colors.blue300,
    fontSize: '14px',
    padding: '10px 14px',
    alignItems: 'center',
    gap: '4px',
    '& svg': {
      fontSize: '14px',
      fill: colors.blue300,
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '& p': {
      color: colors.blue300,
    },
  },
  tenantContainer: {
    backgroundColor: colors.gray800,
    '& div': {
      backgroundColor: colors.gray700,
    },
  },
}));

const routes = getRoutes();

const UserMenu = () => {
  const classes = useStyles();
  const popOverStyles = popOverContainer();
  const navigate = useNavigate();
  const [userMenuEl, setUserMenuEl] = useState(null);
  const [showTenant, setTenant] = useState(false);
  const [tenantText, setTenantText] = useState('');
  const { logout } = useDescope();
  const sessionToken = getSessionToken();
  const global = useGlobalState();
  const { userInfo } = global.get();
  const { user } = useUser();
  const store = localStore();
  const SearchTenant = (txt) => {
    setTenantText(txt);
  };

  const handleLogout = useCallback(() => {
    logout();
    navigate(routes.home.path);
    store.resetFilters();
    localStorage.setItem('activeTenantId', null);
  }, [logout]);

  const handleUserMenuOpen = (event) => {
    setUserMenuEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuEl(null);
  };

  const switchUserTenant = async (item) => {
    if (item.id) {
      localStorage.setItem('activeTenantId', item.id);
      store.resetFilters();
      window.location.reload();
    }
  };

  const showTenantBox = () => {
    setTenant((showTenant) => !showTenant);
  };

  const toApiDocumentation = () => {
    navigate(routes.apiDocs.path);
  };

  const decodedToken = jwt.decode(sessionToken);
  const tenantList =
    decodedToken?.cqTenants?.length > 1
      ? decodedToken.cqTenants?.filter((val) => val.name.includes(tenantText))
      : decodedToken.cqTenants;

  const userName = decodedToken.name
    ? decodedToken.name
    : user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : `${user.email}`;
  const avatarName = userName.substring(0, 1);
  const activeTenantId = localStorage.getItem('activeTenantId') || '';
  const currentTenantObj = decodedToken?.cqTenants?.filter((val) =>
    val.id.includes(activeTenantId)
  );
  let currentTenantName = currentTenantObj?.[0]?.name;
  return (
    <div className={classes.menuContainer}>
      <CqButton
        height={44}
        onClick={handleUserMenuOpen}
        className={classes.menuButton}
      >
        <div auto-id="account-circle">
          <div>
            <Typography className={classes.primaryText} variant="body1">
              {userName}
            </Typography>
          </div>
          <div>
            <Typography className={classes.captionText} variant="caption">
              {currentTenantName}
            </Typography>
          </div>
        </div>
        <ArrowDropDownIcon />
      </CqButton>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkTheme}>
          <Popover
            open={Boolean(userMenuEl)}
            anchorEl={userMenuEl}
            elevation={0}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleUserMenuClose}
            classes={popOverStyles}
          >
            <div className={classes.menuPopover}>
              {!showTenant ? (
                <>
                  <div className={classes.userList}>
                    {avatarName && (
                      <Avatar
                        className={classes.userAvatar}
                        onClick={toApiDocumentation}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography variant="body2">{avatarName}</Typography>
                      </Avatar>
                    )}
                    <Typography className={classes.primaryText} variant="body1">
                      {userName}
                    </Typography>
                    <Typography
                      className={classes.primaryText}
                      variant="subtitle2"
                      auto-id={'user-email'}
                    >
                      {userInfo?.email}
                    </Typography>
                  </div>
                  <List className={classes.popMenuList}>
                    <ListItem
                      className={clsx(
                        classes.popMenuListItem,
                        classes.popMenuListItemBorder
                      )}
                    >
                      <Link
                        onClick={showTenantBox}
                        className={classes.popMenuListItemText}
                      >
                        <SyncAltIcon
                          sx={{
                            mr: '12px',
                            fontSize: '15px',
                            color: colors.gray50,
                          }}
                        />
                        <span>Switch Tenant</span>
                      </Link>
                    </ListItem>
                    <ListItem
                      className={clsx(
                        classes.popMenuListItem,
                        classes.popMenuListItemBorder
                      )}
                    >
                      <Link
                        className={classes.popMenuListItemText}
                        auto-id={'log-out'}
                        onClick={() => handleLogout()}
                      >
                        <ExitToApp
                          auto-id={'logout-icon'}
                          sx={{
                            mr: '12px',
                            fontSize: '15px',
                            color: colors.gray50,
                          }}
                        />
                        <span>Sign Out</span>
                      </Link>
                    </ListItem>
                  </List>
                </>
              ) : (
                <>
                  <div className={classes.tenantContainer}>
                    <Link
                      className={classes.popMenuBackLink}
                      onClick={showTenantBox}
                    >
                      <ArrowBackIcon />
                      <Typography variant="body2">Back</Typography>
                    </Link>
                    <div>
                      <CqSearch
                        placeholder="Search Tenant"
                        onChange={SearchTenant}
                        value={tenantText}
                        border={false}
                      />
                    </div>
                    <List className={clsx(classes.tenantList)}>
                      {tenantList.map((item, idx) => (
                        <ListItemButton
                          className={classes.popMenuListItem}
                          key={idx.toString()}
                          selected={activeTenantId === item.id}
                        >
                          <Link
                            className={classes.popMenuListItemText}
                            onClick={() => switchUserTenant(item)}
                          >
                            {item.name}
                          </Link>
                        </ListItemButton>
                      ))}
                    </List>
                  </div>
                </>
              )}
            </div>
          </Popover>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default UserMenu;
