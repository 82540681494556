export const findRule = (rules, id) => {
  const excludeRules = rules.exclude || [];
  const includeRules = rules.include || [];
  const excludeRuleIndex = excludeRules.findIndex((rule) => rule.id === id);
  const includeRuleIndex = includeRules.findIndex((rule) => rule.id === id);
  if (excludeRuleIndex > -1) {
    return rules.exclude[excludeRuleIndex];
  }
  if (includeRuleIndex > -1) {
    return rules.include[includeRuleIndex];
  }
};

export const deleteRuleObj = (rules, r) => {
  const { category, index } = getRulecatagory(rules, r);
  const excludeRules = [...rules.exclude];
  const includeRules = [...rules.include];
  const newrules = { ...rules };
  if (category === 'Exclude') {
    excludeRules.splice(index, 1);
    newrules.exclude = excludeRules;
  }
  if (category === 'Include') {
    includeRules.splice(index, 1);
    newrules.include = includeRules;
  }
  return newrules;
};

export const getRulesArray = (rules) => {
  const excludeRules = rules.exclude || [];
  const includeRules = rules.include || [];

  return [...excludeRules, ...includeRules];
};

export const getRulecatagory = (rules, rule) => {
  const ruleInfo = { category: '', index: -1 };
  let i = 0;
  for (const r of rules.include) {
    if (r.id === rule.id) {
      ruleInfo.category = 'Include';
      ruleInfo.index = i;
    }
    i++;
  }
  i = 0;
  for (const r of rules.exclude) {
    if (r.id === rule.id) {
      ruleInfo.category = 'Exclude';
      ruleInfo.index = i;
    }
    i++;
  }
  return ruleInfo;
};
