import { colors } from '../assets/theme';
import { styled } from '@mui/styles';

export const StyledBadge = styled('span')(({ type }) => {
  let color = '';
  let hover = '';
  if (type === 'error') {
    color = colors.red100;
    hover = colors.red800;
  }
  if (type === 'warning') {
    color = colors.orangeMediumLevel;
    hover = colors.orangeLabel;
  }
  if (type === 'info') {
    color = colors.gray100;
    hover = colors.gray800;
  }
  return {
    padding: '3px 0',
    marginRight: '4px',
    color: color,
    border: `1px solid ${color}`,
    borderRadius: '4px',
    cursor: 'pointer',
    width: '40px',
    display: 'inline-block',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: hover,
    },
  };
});
