export const getDomainsQuery = `
  query GetDomainsData {
    timezones {
      name
      offset
    }
    domain {
      list {
        domain
        status
        requestedBy
        requestedAt
        config {
          log4jScan
          standardScan
          proxy
          scheduleEnabled
          timezone {
            name
            offset
          }
          schedule {
            cron
            lastCrawl
            nextSchedule
          }
        }
      }
    }
  }
`;

export const updateDomainConfigMutation = `
  mutation UpdateDomainConfig($domain: String!, $config: DomainConfigInput!) {
    domain {
      update(domain: $domain, config: $config) {
        code
        success
        message
        domain {
          domain
          status
          config {
            log4jScan
            standardScan
            proxy
            scheduleEnabled
            timezone {
              name
              offset
            }
            schedule {
              cron
              lastCrawl
              nextSchedule
            }
          }
        }
      }
    }
  }
`;

export const addDomainQuery = `
  mutation AddDomain($domain: String!, $addDomainMsg: String!, $runCrawlOnApproval: Boolean!, $intrusiveCrawl: Boolean!) {
    domain {
      add(domain: $domain, message: $addDomainMsg, runCrawlOnApproval: $runCrawlOnApproval, intrusiveCrawl: $intrusiveCrawl) {
        success
        message
        domain
      }
    }
  }
`;

export const removeDomainQuery = `
  mutation RemoveDomain($domain: String!) {
    domain {
      remove(domain: $domain) {
        success
        message
        domain
      }
    }
  }
`;

export const getCrawlInfo = `
  query GetCrawlInfo($domain: String, $crawlId: String) {
    crawl {
      getCrawlInfo(domain: $domain, crawlId: $crawlId) {
        domain
        crawlId
        submittedAt
        standardScan
        log4jScan
        totalSubdomains
        activeSubdomains
        config
      }
    }
  }
`;
