import React, { useEffect, useState } from 'react';
import {Grid, Typography, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import VulnerabilitiesDetailsTable from "./VulnerabilitiesDetailsTable";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LaunchIcon from "@mui/icons-material/Launch";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const exposedFilesHeaderColumns = [
    {
        name: 'API Endpoint',
    },
    {
        name: 'Method',
    },
    {
        name: 'Response Code', sort: true
    }
];



const ExposedMonitoringEndpointsDetails = ({ vulnerabilityServers, getRequestResponse, expanded }) => {
  const expandSection = {
    des: false,
    risk: false,
    ref: false,
    rec: false
  }
  const [expand, setExpand] = useState(expandSection)
  useEffect(() => {
      setExpand({
        des: expanded,
        risk: expanded,
        ref: expanded,
        rec: expanded
      })
  }, [expanded])
  const handleChange = (panel) => () => {
    setExpand({...expand, [panel]: !expand[panel]})
  }
    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                            This server was found to expose one or more Application Health Monitoring endpoints, that allow users to
                            query the health of an application using an API call. Such endpoints are usually designed as diagnostics
                            tools and return the operational status of the application to help developers detect problems with their
                            applications before they get escalated or cause outages.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Application health monitoring endpoints often expose information about the application being monitored,
                            thereby potentially exposing sensitive information to unauthorized users of these endpoints.

                            Therefore such endpoints should be gated behind appropriate network and/or user access controls.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            It is recommended to protect such endpoints using an application security solution to prevent unauthorized
                            usage or targeted DoS/DDoS attacks.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List sx={{ listStyleType: 'disc' }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant={'body2'}>
                                    <Link target={'_blank'} href={'https://www.cequence.ai/why-cequence/'} style={{ color: colors.blueButtonBg, cursor: 'pointer' }}>
                                        Cequence Unified API Protection
                                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                                    </Link>
                                    <span style={{ marginLeft: 5 }}>is the only solution that safeguards your organization from losses across
                                        your entire API risk surface, can be deployed quickly, without intrusive instrumentation or agents, and scales
                                        quickly. Using Cequence, you can protect your applications from the full range of automated attacks and
                                        eliminate bot induced business impacts such as infrastructure cost overruns, site outages and broad
                                        damage from bot, frustrated customers.
                                        Reach out to us to explore how Cequence can be deployed to protect such servers exposed publicly.
                                    </span>
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
                <Typography>
                    Application Health Monitoring API Endpoints
                </Typography>
                <VulnerabilitiesDetailsTable
                    columns={exposedFilesHeaderColumns}
                    vulnerabilityServers={vulnerabilityServers}
                    getRequestResponse={getRequestResponse}
                />
            </Grid>
        </>
    );
};

export default ExposedMonitoringEndpointsDetails;
