import React, { useState, useEffect } from 'react';
import App from './App';
import { getUserQuery, graphqlQuery } from './services/graphql';
import { useGlobalState } from './store/globalState';

const AppNoAuth = () => {
  const [user, setUser] = useState({});
  const global = useGlobalState();

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      let response = await graphqlQuery(getUserQuery);
      const data = response.data ? response.data.user : {};
      global.merge({ userInfo: data });
      setUser(data);
    } catch (error) {
      console.log(`graphql query error`, error);
    }
  };

  return (
    <>
      <App
        authObj={{
          isUserLoading: false,
          isSessionLoading: false,
          isLoggedIn: true,
          showSessionMess: false,
          setShowSessionMess: () => {},
          user: user,
        }}
      />
    </>
  );
};

export default AppNoAuth;
