import { addDomainQuery } from "../pages/domains/domainGraphQL";
import { graphqlMutation } from "../services/graphql";

export const addDomain = async (setDialogInProgress, newDomainName, newJustification, isAutomaticCrawl, isIntrusiveCrawl, global, setNewDomainRequestDialogOpened, retrieveData) => {
  setDialogInProgress(true);

  const params = {
    domain: newDomainName,
    addDomainMsg: newJustification,
    runCrawlOnApproval: isAutomaticCrawl,
    intrusiveCrawl: isAutomaticCrawl ? isIntrusiveCrawl : false
  };

  try {
    let response = await graphqlMutation(addDomainQuery, params);
    let { success, message, domain } = response.data.domain.add;
    if (success === false) {
      global.notifications.merge([
        {
          id: `domains_${newDomainName}`,
          message: message,
          category: 'warning',
        },
      ]);
    } else {
      global.notifications.merge([
        {
          id: `domains_${newDomainName}`,
          message: message,
          category: 'success',
        },
      ]);
    }
    setNewDomainRequestDialogOpened(false);
  } catch (error) {
    console.log(`graphql query error`, error);

    global.notifications.merge([
      {
        id: `domains_${newDomainName}`,
        message: `Error while adding new domain ${newDomainName}`,
        category: 'warning',
      },
    ]);
  } finally {
    retrieveData();
    setDialogInProgress(false);
  }
};