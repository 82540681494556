import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';
import {
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((props) => ({
  inputLabel: {
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: '4px',
    color: colors.gray300,
  },
  inputField: {
    '&.MuiOutlinedInput-root': {
      paddingLeft: '6px',
      border: ({ border }) =>
        border === false ? 'none' : `1.5px solid ${colors.gray400}`,
    },
    '& .MuiOutlinedInput-input': {
      padding: '6px 6px 6px 0',
      fontSize: '14px',
    },
    '& fieldset': {
      border: 'none !important',
    },
  },
  inputSearchIcon: {
    fontSize: '18px !important',
    color: colors.gray100,
  },
}));

const CqSearch = ({
  label,
  value = '',
  onChange,
  width,
  placeholder,
  border,
}) => {
  const classes = useStyles({ border });

  return (
    <Grid>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <FormControl style={{ width: width }}>
        <OutlinedInput
          id="search-field"
          className={classes.inputField}
          placeholder={placeholder}
          autoComplete={'off'}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.inputSearchIcon} />
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>
  );
};

CqSearch.prototype = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.number,
};

export default CqSearch;
