import React, { useContext } from 'react';
import NotificationsPage from './Notifications';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import EmptyState from '../../components/EmptyState';

const NotificationsIndex = () => {
  const { activeDomains, showEmptyState } = useContext(WrapperContext)
  return (
    activeDomains.length > 0 ? <NotificationsPage domains={activeDomains} /> : <EmptyState showEmptyState={showEmptyState} />
  )
}

export default NotificationsIndex