import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Switch
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import { colors } from '../../assets/theme';

import { styled } from '@mui/material/styles';
import { StyledSwitch } from '../../components/SwitchRoot';
import CqDialog from '../../components/common/CqDialog';
import CqInput from '../../components/common/CqInput';
import TextWithIcon from '../../components/common/TextWithIcon';
import { WrapperContext } from '../../components/wrapper/wrapperContext';

const StyledIcon = styled('span')(() => ({
  '& svg': {
    fontSize: '24px',
    marginTop: '12px',
  },
}));

const StyledLabel = styled(InputLabel)(() => ({
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: '4px',
  color: colors.gray300,
}));

const StyledContainer = styled(Grid)(() => ({
  paddingBottom: '24px',
  borderBottom: `1px solid ${colors.gray400}`,
  marginBottom: '24px',
}));

const Title = ({ type }) => {
  let text = '';
  let icon = undefined;
  text = type.scheduler
    ? 'Crawl Schedule'
    : type.proxy
    ? 'Proxy URL'
    : type.scan
    ? 'Advanced Crawl'
    : '';
  icon = type.scheduler ? (
    <ScheduleIcon />
  ) : type.proxy ? (
    <MoreVertIcon />
  ) : type.scan ? (
    <WifiTetheringIcon />
  ) : (
    ''
  );
  return (
    <TextWithIcon>
      <StyledIcon>{icon}</StyledIcon>
      <span>{text}</span>
    </TextWithIcon>
  );
};

const CrawlScheduleDialog = ({
  open,
  setConfigDialogStatus,
  timezones,
  crawlConfigDialog,
  updateDomainConfig,
}) => {
  const { domain } = crawlConfigDialog;
  const [isAutomaticSchedule, setIsAutomaticSchedule] = useState(false);
  const [domainTimezone, setDomainTimezone] = useState('');
  const [isProxy, setProxy] = useState(false);
  const [isIntrusive, setIntrusive] = useState(false);
  const [proxyValue, setProxyVal] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const wrapperContext = useContext(WrapperContext)

  useEffect(() => {
    setIntrusive(domain?.config?.log4jScan || false);
    setDomainTimezone(domain?.config?.timezone || '');
    setIsAutomaticSchedule(domain?.config?.scheduleEnabled || false);
    setProxy(domain?.config?.proxy !== '');
    setProxyVal(domain?.config?.proxy || '');
  }, [domain]);

  const { scheduler, proxy, scan } = crawlConfigDialog.edit;

  const handleDomainConfig = () => {
    const config = {};
    config.log4jScan = isIntrusive;
    if (domainTimezone) {
      config.timezone = {
        name: domainTimezone.name,
        offset: domainTimezone.offset,
      };
    }
    config.scheduleEnabled = isAutomaticSchedule;
    config.proxy = proxyValue;
    let message = '';
    if (scan) {
      message = 'Intrusive scan Configuration Updated';
    }
    if (scheduler) {
      message = `The domain schedule for ${domain.domain} has been updated successfully!`;
    }
    if (proxy) {
      message = 'Proxy Configuration Updated';
    }
    setInProgress(true);
    updateDomainConfig(domain.name, config, message).finally(() => {
      setInProgress(false);
      setConfigDialogStatus((state) => {
        return {
          ...state,
          domain: {},
          open: !state.open,
          edit: { ...state.edit, scheduler: false, proxy: false, scan: false },
        };
      });
    });
  };

  const handleCrawlTypeChange = () => {
    setIsAutomaticSchedule((isAutomaticSchedule) => !isAutomaticSchedule);
  };

  const handleTimezoneChange = (event) => {
    const {
      target: { value },
    } = event;
    const timezone = timezones.find((t) => t.name === value);
    setDomainTimezone(timezone);
  };

  const handleDialogClose = () => {
    setConfigDialogStatus((state) => {
      return {
        ...state,
        domain: {},
        open: !state.open,
        edit: { ...state.edit, scheduler: false, proxy: false, scan: false },
      };
    });
  };

  const handleProxySetting = () => {
    const proxyFlag = !isProxy;
    setProxy(proxyFlag);
    if (!proxyFlag) {
      setProxyVal('');
    }
    if (proxyFlag && domain?.config?.proxy) {
      setProxyVal(domain?.config?.proxy);
    }
  };

  const handleIntrusiveSetting = () => {
    setIntrusive((i) => !i);
  };

  const handleProxyVal = (val) => {
    setProxyVal(val);
  };

  const { config } = domain;
  let disableSave = true;
  if (scheduler) {
    if (
      config?.scheduleEnabled !== isAutomaticSchedule ||
      config?.timezone !== domainTimezone
    ) {
      disableSave = false;
    }
  }
  if (proxy) {
    if (config?.proxy !== proxyValue) {
      disableSave = false;
    }
    if (isProxy && proxyValue === '') {
      disableSave = true;
    }
  }
  if (config?.log4jScan !== isIntrusive && scan) {
    disableSave = false;
  }
  const nextScheduleStr = domain.config?.schedule?.nextSchedule
    ? moment(domain.config?.schedule?.nextSchedule).format(
        'MM/DD/YYYY [at] ha Z'
      )
    : '-';

  return (
    <CqDialog
      title={<Title type={crawlConfigDialog.edit} />}
      open={open}
      onSubmit={handleDomainConfig}
      onClose={handleDialogClose}
      submitBtnText="Save"
      maxWidth="sm"
      backdrop={inProgress}
      disableEnforceFocus
      submitBtnDisabled={disableSave}
    >
      {scheduler && (
        <>
          <StyledContainer item container alignItems="center">
            <Grid item xs={9}>
              <Typography>
                Crawls are automatically scheduled for 9pm in your
                <br />
                timezone and can take up to 3 hours to complete
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                checked={isAutomaticSchedule}
                onChange={handleCrawlTypeChange}
              />
            </Grid>
          </StyledContainer>
          <Grid container spacing={3}>
            <Grid item>
              <StyledLabel>Timezone</StyledLabel>
              <FormControl style={{ width: '240px' }} size="small">
                <Select
                  value={domainTimezone?.name || ''}
                  onChange={handleTimezoneChange}
                  displayEmpty
                  disabled={!isAutomaticSchedule}
                  renderValue={(value) => {
                    if (!value) {
                      return 'Select Timezone';
                    }

                    return value;
                  }}
                  sx={{ height: 35 }}
                >
                  {timezones.map((zone, idx) => (
                    <MenuItem key={idx} value={zone.name}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <CqInput
                label="Next Scheduled Crawl"
                value={isAutomaticSchedule ? nextScheduleStr : ''}
                width={240}
                disabled
              />
            </Grid>
          </Grid>
        </>
      )}
      {proxy && (
        <>
          <StyledContainer container>
            <Grid item xs={9}>
              <Typography variant="subtitle1">Proxy URL</Typography>
              <Typography>
                https://proxyuser:pa55w0rd@myproxy.local:443
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                checked={isProxy}
                onChange={handleProxySetting}
              ></Switch>
            </Grid>
          </StyledContainer>
          <Grid>
            <CqInput
              label="Proxy URL"
              value={proxyValue}
              onChange={handleProxyVal}
              width={240}
              disabled={!isProxy}
            />
          </Grid>
        </>
      )}
      {scan && (
        <>
          <StyledContainer container>
            <Grid item xs={9}>
              <Typography variant="subtitle1">Advanced Crawl</Typography>
              <Typography sx={{textAlign: 'justify'}}>
                Advanced crawls generate API requests that are specifically crafted to detect Log4j vulnerabilities in your environment. These crawls generate more API requests than regular or standard Spyder crawls, that may trigger network security alarms. Please use the UserAgent string that is embedded in the crawl requests to identify and allow these requests, should they get blocked by your security controls
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{padding: '0 8px'}}>
              <Switch
                checked={isIntrusive}
                onChange={handleIntrusiveSetting}
                disabled={!wrapperContext.isLog4jEnabled}
              ></Switch>
            </Grid>
          </StyledContainer>
        </>
      )}
    </CqDialog>
  );
};

export { CrawlScheduleDialog };
