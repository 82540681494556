import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import CrawlProgress from './CrawlProgress';
import { Typography, Grid, Table, TableRow, TableCell, TableBody  } from '@mui/material';
import { styled } from '@mui/system';
import {colors} from '../../assets/theme';
import TextWithIcon from '../../components/common/TextWithIcon';
import SendIcon from '@mui/icons-material/Send';
import CrawlPercent from '../../components/CrawlPercent';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import DnsIcon from '@mui/icons-material/Dns';
import { getCrawlProgressData } from './provider';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CqLoading from '../../components/common/CqLoading';
import CqButton from "../../components/common/CqButton";
import moment from 'moment'

const StyledContainer = styled('div')({
  padding: '8px',
  backgroundColor: colors.gray750,
  border: `1px solid ${colors.gray700}`,
  margin: '12px 0',
  borderRadius: '4px',
  '& svg': {
    fill: colors.gray600,
    width: '24px',
    height: '24px'
  },
  '& p': {
    color: colors.gray200
  }
})

const StyledInProgressContainer = styled('div')({
  minWidth: '100%',
  margin: '0'
})

const StyledCount = styled('span')({
  justifyContent: 'center',
  alignContent: 'center',
  fontSize: 34,
  fontWeight: 700
})

const StyledSubCount = styled('span')({
  justifyContent: 'center',
  alignContent: 'center',
  fontSize: 24,
  fontWeight: 400,
  color: colors.gray300,
  lineHeight: '60px'
})

const IconContainer = styled('div')({
  textAlign: 'center',
  marginTop: '100px',
  paddingLeft: '25px',
  cursor: 'pointer',
})

const StyledCell = styled(TableCell)(({background}) => ({
  backgroundColor: background === 'true' ? colors.gray750 : 'none',
  width: background === 'true' ? '300px' : 'auto',
  borderRadius: '4px',
  padding: '10px'
}))

const StyledTableRow = styled(TableRow)({
  border: `0.5px solid ${colors.gray500}`
})

const InProgress = ({domain, crawlId, refreshData, showImage=true, handleAnalyzeCrawl}) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    hosts: {total: 0, discovered: 0},
    responseCodes: [],
    totalAttempted: 0,
    crawlInfo: {},
    loading: false
  })
  useEffect(() => {
    setChartData(data => ({...data, loading: true}))
    getCrawlProgressData(domain, crawlId, setChartData)
  }, [refreshData])
  useLayoutEffect(() => {
    const chart = createChart(chartData);
    chartRef.current = chart
  }, [chartData]);

  let advancedCrawltime = ''
  if(chartData?.crawlInfo?.submittedAt && chartData?.crawlInfo?.log4jScan){
    advancedCrawltime = moment(chartData.crawlInfo.submittedAt + 3600000).format('MM-DD-YYYY hh:mm')
  }

  const createChart = (data) => {
    if(!data.loading){
      let chart = am4core.create('crawl-in-progress-chart', am4charts.XYChart);

      const colorsList = [
        am4core.color('#006169'),
        am4core.color('#177B86'),
        am4core.color('#1A4B80'),
        am4core.color('#2364AA'),
        am4core.color('#433167'),
        am4core.color('#7327B9'),
        am4core.color('#740732'),
        am4core.color('#940941'),
        am4core.color('#872A1B'),
        am4core.color('#C44434'),
        am4core.color('#794403'),
        am4core.color('#A7611E'),
        am4core.color('#26402D'),
        am4core.color('#3E6E4B')
      ]
      const status = {}
      const responseCodesColors = []
      let i = 0
      for(const val of data.responseCodes){
        status[val.code] = val.count
        responseCodesColors.push(colorsList[i] || colorsList[i%14])
        i++
      }
      status.data = ''
      chart.data = [status];
      chart.colors.list = responseCodesColors


      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      // Create axes
      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "data";
      categoryAxis.renderer.grid.template.opacity = 0;
      categoryAxis.renderer.labels.template.disabled = true

      var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.grid.template.opacity = 0;
      valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
      valueAxis.renderer.ticks.template.stroke = am4core.color("#495f43");
      valueAxis.renderer.ticks.template.length = 10;
      valueAxis.renderer.labels.template.disabled = true
      valueAxis.renderer.line.strokeOpacity = 0.5;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.renderer.minGridDistance = 40;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.line.disabled = true;

      // Create series
      function createSeries(field, name, count) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(colors.gray900);
        series.tooltip.label.fill = am4core.color(colors.white);
        series.tooltip.pointerOrientation = "down";
        series.tooltipText = count;
        series.dataFields.categoryY = "data";
        series.stacked = true;
        series.name = name;

        return series;
      }

      // some space needed for the total label
      categoryAxis.renderer.labels.template.marginRight = 40;
      // this is the total label series, it is invisible
      var series = createSeries("empty", "empty");
      // hidden in legend
      series.hiddenInLegend = true;
      // bullets can't be masked
      series.maskBullets = false;

      for( const rc of data.responseCodes){
        createSeries(rc.code, rc.code, rc.count);
      }
    }
  }
  const totalRequests = chartData.totalAttempted || 0;
  const totalHosts = chartData?.hosts?.total || 0;
  const hostsDiscovered = chartData.hosts.discovered || 0
  return chartData.loading ? <CqLoading /> : (
    <StyledInProgressContainer>
      <Table sx={{border: `1px solid ${colors.gray650}`, marginBottom: '16px'}}>
        <TableBody>
          <StyledTableRow>
            <StyledCell background='true'><Typography variant='overline'>DOMAIN</Typography></StyledCell>
            <StyledCell><Typography variant='body2'>{domain}</Typography></StyledCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledCell background='true'><Typography variant='overline'>STANDARD CRAWL</Typography></StyledCell>
            <StyledCell><Typography variant='body2'>{chartData?.crawlInfo?.submittedAt ? moment(chartData?.crawlInfo?.submittedAt).format('MM-DD-YYYY hh:mm') : ''}</Typography></StyledCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledCell background='true'><Typography variant='overline'>ADVANCED CRAWL</Typography></StyledCell>
            <StyledCell>{chartData?.crawlInfo?.log4jScan ? <Typography variant='body2'>{advancedCrawltime}</Typography> : <Typography variant='caption'>Not enabled for this crawl</Typography>}</StyledCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      {showImage &&
      <Grid>
        <IconContainer onClick={() => handleAnalyzeCrawl()}>
          <CrawlProgress/>
        </IconContainer>
        <Typography variant='h3' sx={{textAlign: 'center', color: colors.gray100, margin: '10px 0'}}>
          Crawl In Progress
        </Typography>
        <Typography variant='body2' sx={{textAlign: 'center', color: colors.gray100}}>
          <span>We are currently in the process of scanning this domain. The numbers displayed below</span><br/>
          <span>are preliminary estimates and may not be fully accurate until the scan is complete.</span>
        </Typography>
      </Grid>
      }
      <StyledContainer>
        <CrawlPercent crawlData={chartData.crawlInfo} crawlInProgress/>
      </StyledContainer>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StyledContainer>
            <TextWithIcon>
              <DnsIcon />
              <Typography auto-id={'hosting_providers_txt'} variant={'body2'} sx={{color: colors.gray200}}>Subdomains Crawled</Typography>
            </TextWithIcon>
            <StyledCount>{hostsDiscovered.toLocaleString()}</StyledCount>
            <StyledSubCount> of {totalHosts.toLocaleString()}</StyledSubCount>
          </StyledContainer>
          <StyledContainer>
            <TextWithIcon>
              {!showImage ?
                <SendIcon style={{cursor: 'pointer'}} onClick={() => handleAnalyzeCrawl()}/>
                :
                <SendIcon />
              }
              <Typography auto-id={'hosting_providers_txt'} variant={'body2'} sx={{color: colors.gray200}}>API Requests Sent</Typography>
            </TextWithIcon>
            <StyledCount>{totalRequests.toLocaleString()}</StyledCount>
          </StyledContainer>
        </Grid>
        <Grid item xs={9}>
          <StyledContainer>
            <TextWithIcon>
              <CallReceivedIcon />
              <Typography auto-id={'hosting_providers_txt'} variant={'body2'} sx={{color: colors.gray200}}>Response Code Distribution</Typography>
            </TextWithIcon>
            <div
              id='crawl-in-progress-chart'
              style={{
                width: '100%',
                overflow: 'scroll',
              }}
            ></div>
          </StyledContainer>
        </Grid>
      </Grid>
    </StyledInProgressContainer>
  )
}

export default InProgress;
