export const getDomainsQuery = `
  query getActiveDomains {
    domain {
      getActiveDomains {
	      domain
	      status
	    }
	  }
  }
`;

export const getDashboardSummaryQuery = `
query GetDashboardSummaryQuery($query: CrawlDataFilters!) {
  crawl {
    getCrawlSummary(query: $query) {
      latestCrawlTs
      overview {
        apiServers {
          total
          newLastWeek
          missingLastWeek
        }
        hostingProviders {
          total
          newLastWeek
          missingLastWeek
        }
        apiEndpoints {
          total
        }
      }
      hostingProviders {
        name
        apiServers
      }
      issues {
        high {
          total
          lastWeek
          issues {
            type
            count
            description
          }
        }
        medium {
          total
          lastWeek
          issues {
            type
            count
            description
          }
        }
        low {
          total
          lastWeek
          issues {
            type
            count
            description
          }
        }
      }
      integrations {
        spyderCrawls
        cspm
        sentinel
        spyderCspm
        spyderSentinel
        cspmSentinel
        spyderCspmSentinel
      }
      timestamp
    }
  }
}
`;

export const getNotificationsQuery = `
   query GetNotifications($selectedDomains: [String!], $startTime: Long, $endTime: Long) {
    notification {
      list(query: {domains: $selectedDomains, startTime: $startTime, endTime: $endTime}) {
        type
        message
        timestamp
        severity
      }
    }
   }
  `;

export const getCrawlHistoryQuery = `
    query GetCrawlHistory($crawlHistoryDomains: [String!]) {
    crawl {
      getCrawlHistory(domains: $crawlHistoryDomains) {
        domain
        crawlId
        scheduled
        submittedAt
        status
        timestamp
      }
    }
   }
  `;
export const getDomainReportData = `
  query GetDomainReportData($domains: [String], $crawlId: String) {
    discovery {
      getDomainReportData(domains: $domains, crawlId: $crawlId) {
        org_name
        attack_surface {
          servers
          hosting_providers
          endpoints
          domains
          total
        }
        domains
        hosting_providers {
          cdn {
            name
            count
          }
          cdn_total
          iaas {
            name
            count
          }
          iaas_total
          isp {
            name
            count
          }
          isp_total
        }
        issues {
          value {
            name
            severity
            value
            description
          }
          high {
            log4j
            long4j
            exposed_files
            tls
          }
          medium {
            unhandled
            non_prod_or_origin
            tls
          }
          low {
            health_monitoring
            login
            openapi_swagger
            graphql
            tls
          }
          total_high
          total_medium
          total_low
          total_servers_at_risk
          total_servers
        }
        recommendations {
          high
          medium
          low
        }
      }
    }
  }
`;

export const getAggregateData = `
query GetAggregateData($domains: [String], $crawlId: String) {
  discovery {
    getDomainSummary(domains: $domains, crawlId: $crawlId) {
      total
      hosts {
        total
        attempted
        discovered
      }
      endpoints {
        attempted
        discovered
      }
      providers {
        total
        discovered
        values {
          name
          discovered
          total
          type
        }
      }
      findings {
        high
        medium
        total
        info
        issues {
          severity
          name
          value
          description
          type
        }
      }
       nonApiHostsFindings {
        high
        medium
        total
        info
        issues {
          severity
          name
          value
          description
          type
        }
      }
      domains {
        hosts
        endpoints
        providers
        name
        standardScan
        log4jScan
        findings {
          high
          medium
          total
          info
        }
        nonApiHostsFindings {
          high
          medium
          total
          info
        }
        hostsAttempted
        endPointsAttempted
        crawlDate
      }
    }
  }
 }
`;

export const getCrawlProgressQuery = `
  query GetCrawlProgressData($domain: String, $crawlId: String) {
    discovery {
      getCrawlProgress(domain: $domain, crawlId: $crawlId) {
        total
        responseCodes {
          code
          count
        }
        hosts {
          total
          attempted
          status
        }
      }
    }
  }`;

export const submitCrawlAnalyzeRequestQuery = `
    mutation SubmitCrawlAnalyzeRequest($domain: String!, $crawlId: String) {
    discovery {
      submitCrawlAnalyzeRequest(domain: $domain, crawlId: $crawlId) {
       success
       message
       domain
       crawlId
      }
    }
   }
  `;
