import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ListItem, ListItemText, ListItemIcon, Grid } from '@mui/material';
import { colors } from '../../assets/theme';
import { getRoutes } from '../../lib/constants';
import CqIcon from '../common/CqIcon';
import collapseImg from '../../assets/img/Collapse.png';
import expandImg from '../../assets/img/Expand.png';

const ParentListItem = withStyles({
  root: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    whiteSpace: 'nowrap',
    marginRight: 10,
    marginLeft: -2,
    overflow: 'hidden',
    backgroundColor: colors.gray850,
    textTransform: 'uppercase',
    height: 24,
    cursor: 'default',
    fontFamily: 'NotoSansDisplay',
    '&$selected': {
      backgroundColor: colors.gray850,
    },
    '&:hover': {
      backgroundColor: colors.gray850,
    },
  },
  selected: {},
})(ListItem);

const ChildListItem = withStyles({
  root: {
    marginLeft: 5,
    overflow: 'hidden',
    minHeight: 44,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: colors.gray850,
    fontFamily: 'NotoSans',
    letterSpacing: '0.25px',
    '&$selected': {
      backgroundColor: '#393939',
      borderRadius: 4,
      borderRight: `4px solid ${colors.blueButtonBg}`,
      color: colors.white,
    },
    '&$selected:hover': {
      backgroundColor: colors.gray600,
      borderRadius: 4,
      color: colors.white,
    },
    '&:hover': {
      backgroundColor: '#2B2B2B',
      borderRadius: 4,
      color: colors.gray50,
    },
  },
  selected: {},
})(ListItem);

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: 0,
  },
  navListItem: {
    marginBottom: 38,
    marginTop: 2,
  },
  expandIcon: {
    width: 38,
    height: 20,
  },
}));

const MenuItem = ({
  name,
  parent,
  nested,
  drawerOpen,
  setDrawerOpen,
  showCollapseIcon,
  onHover,
}) => {
  const classes = useStyles();
  const navigation = useNavigate();
  const location = useLocation();
  const [itemHoverState, setItemHoverState] = useState(-1);

  const routes = getRoutes();
  const defaultRoute = routes.dashboard.path;

  const routeChange = (newRoute) => {
    navigation(newRoute);
  };

  const routeMatchesOrIsDefault = (route) => {
    const currentPath = location.pathname;
    return (
      (currentPath === route.path) ||
      (route.path === defaultRoute && currentPath === '/')
    );
  };

  return (
    <div className={classes.navListItem}>
      {(nested?.length > 0 || parent) && drawerOpen && (
        <ParentListItem>
          <ListItemText
            auto-id={`nav-${name}`}
            primaryTypographyProps={{
              style: {
                fontSize: '10px',
                fontWeight: 600,
                color: colors.gray400,
                cursor: 'default',
                fontFamily: 'Noto Sans Display',
                letterSpacing: '1.5px',
              },
            }}
          >
            {name}
          </ListItemText>
          {onHover && showCollapseIcon && drawerOpen && (
            <ListItemIcon
              auto-id={`nav-${name}-icon`}
              style={{ cursor: 'pointer', marginLeft: 165 }}
            >
              <img
                src={collapseImg}
                alt="small-logo"
                onClick={() => setDrawerOpen(!drawerOpen)}
                className={classes.expandIcon}
                cursor={'pointer'}
              />
            </ListItemIcon>
          )}
        </ParentListItem>
      )}
      {showCollapseIcon && !drawerOpen && (
        <ListItemIcon
          auto-id={`nav-${name}-collapse`}
          style={{ alignItem: 'flex-end', marginLeft: 8, cursor: 'pointer' }}
        >
          <img
            src={expandImg}
            alt="small-logo"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={classes.expandIcon}
            cursor={'pointer'}
          />
        </ListItemIcon>
      )}
      {nested?.filter(r => r.hidden !== true).map((route, i) => {
        return (
          <ChildListItem
            selected={routeMatchesOrIsDefault(route)}
            button
            onClick={() => routeChange(route.path)}
            onMouseEnter={() => setItemHoverState(i)}
            onMouseLeave={() => setItemHoverState(-1)}
            key={i}
            style={{ width: drawerOpen ? 210 : 55, paddingLeft: 8 }}
            auto-id={`nav-${route.name}-button`}
          >
            {itemHoverState === i ||
            window.location.pathname.startsWith(route.path) ? (
              <ListItemIcon
                auto-id={`nav-${route.name}-icon-hover`}
                className={classes.icon}
              >
                <CqIcon
                  icon={route.icon}
                  size={drawerOpen ? 14 : 20}
                  color={colors.blueButtonBg}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                auto-id={`nav-${route.name}-icon`}
                className={classes.icon}
              >
                <CqIcon icon={route.icon} size={drawerOpen ? 14 : 20} />
              </ListItemIcon>
            )}
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontSize: '14px',
                  fontWeight: 400,
                  color: colors.gray100,
                  marginLeft: 8,
                },
              }}
              auto-id={`nav-${route.name}-text`}
            >
              {route.name}
            </ListItemText>
          </ChildListItem>
        );
      })}
    </div>
  );
};

export default MenuItem;
