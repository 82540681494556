import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, InputLabel, TextField, Box } from '@mui/material';
import {
  CustomTabs as Tabs,
  CustomTab as Tab,
  CustomTabPanel as TabPanel,
} from '../../components/Tabs';

import { Grid } from '@mui/material';
import { none } from '@hookstate/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import SsoInfo from './SsoInfo';
import CqDialog from '../../components/common/CqDialog';
import CqLoading from '../../components/common/CqLoading';
import CqButton from '../../components/common/CqButton';
import SpSnackBar from '../../components/SpSnackBar';
import UsersTable from './UsersTable';

import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import {
  getUsersQuery,
  addUserQuery,
  removeUserQuery,
  disableUserQuery,
  enableUserQuery,
  requestChangePasswordEmailQuery,
  getSsoConfig,
} from './userGraphQL';
import { colors, cqDialogInputStyles } from '../../assets/theme';
import { useGlobalState } from '../../store/globalState';
import ClientsTable from './ClientsTable';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: `16px !important`,
  },
  dialogInfo: {
    color: colors.white,
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '20px',
  },
  styledTabs: {
    borderBottom: `1px solid ${colors.gray700}`,
  },
  panelBox: {
    paddingTop: '17px',
  },
}));

const TABLE_HEADER_COLUMNS = [
  {
    name: 'Name',
    id: 'name',
    sortable: true,
    sortType: 'string',
  },
  {
    name: 'Email',
    id: 'email',
    sortable: true,
    sortType: 'string',
  },
  {
    name: 'Created On',
    id: 'createdAt',
    sortable: true,
    sortType: 'numeric',
  },
  {
    name: 'SAML',
    id: 'saml',
    sortable: true,
    sortType: 'numeric',
  },
];

export const TABLE_ACTIONS = {
  ENABLE_USER: 'Enable-User',
  DISABLE_USER: 'Disable-User',
  REMOVE_USER: 'Remove-User',
  RESET_PASSWORD: 'Reset-Password',
};

const UsersPage = () => {
  const classes = useStyles();
  const dialogClasses = cqDialogInputStyles();

  const global = useGlobalState();
  const { notifications } = global.get();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();

  const [newUserRequestDialogOpened, setNewUserRequestDialogOpened] =
    useState(false);
  const [dialogInProgress, setDialogInProgress] = useState(false);

  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setNewUserLastName] = useState('');

  const [tableActionType, setTableActionType] = useState();
  const [tableActionParams, setTableActionParams] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    retrieveUserList();
  }, []);

  const showNotification = (id, message, category) => {
    global.notifications.merge([
      {
        id: id,
        message: message,
        category: category,
      },
    ]);
  };

  const retrieveUserList = async () => {
    setLoading(true);
    try {
      const response = await graphqlQuery(getUsersQuery);
      const userList = response.data.users.list.users ?? [];
      setUsers(
        userList.map((user) => ({
          ...user,
          name: `${user.name}`,
        }))
      );
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setLoading(false);
    }
  };

  const enableUser = async (email, enabled) => {
    try {
      const params = {
        email: email,
      };

      const query = enabled ? enableUserQuery : disableUserQuery;
      const result = await graphqlMutation(query, params);
      const response = enabled
        ? result.data.users.enableUser
        : result.data.users.disableUser;
      if (response.status === 'ERROR') {
        throw new Error(response.message);
      }

      showNotification(
        email,
        `Successfully ${enabled ? 'enabled' : 'disabled'} user: ${email}.`,
        'success'
      );
    } catch (error) {
      console.log(`graphql query error`, error);
      showNotification(email, error.message, 'warning');
    } finally {
      retrieveUserList();
    }
  };

  const sendChangePasswordRequest = async (email) => {
    try {
      const params = {
        email: email,
      };

      const result = await graphqlMutation(
        requestChangePasswordEmailQuery,
        params
      );
      const response = result.data.users.requestChangePasswordEmail;
      if (response.status === 'ERROR') {
        throw new Error(response.message);
      }
      showNotification(
        email,
        `Reset password link successfully sent to ${email}.`,
        'success'
      );
    } catch (error) {
      console.log(`graphql query error`, error);
      showNotification(
        email,
        `Error in sending rest password link to ${email}.`,
        'warning'
      );
    }
  };

  const addUser = async () => {
    setDialogInProgress(true);

    const params = {
      addUserInfo: {
        email: newUserEmail,
        firstName: newUserFirstName,
        lastName: newUserLastName,
      },
    };

    try {
      const result = await graphqlMutation(addUserQuery, params);
      const response = result.data.users.addUser;
      if (response.status === 'ERROR') {
        throw new Error(response.message);
      }

      showNotification(newUserEmail, 'New user successfully added.', 'success');
      setNewUserRequestDialogOpened(false);
    } catch (error) {
      console.log(`graphql query error`, error);
      showNotification(newUserEmail, error.message, 'warning');
    } finally {
      setDialogInProgress(false);
      retrieveUserList();
    }
  };

  const removeUser = async (email) => {
    setDialogInProgress(true);
    const params = {
      email: email,
    };

    try {
      const result = await graphqlMutation(removeUserQuery, params);
      const response = result.data.users.removeUser;
      if (response.status === 'ERROR') {
        throw new Error(response.message);
      }

      showNotification(email, `User ${email} successfully removed.`, 'success');
    } catch (error) {
      console.log(`graphql query error`, error);
      showNotification(email, error.message, 'warning');
    } finally {
      setDialogInProgress(false);
      retrieveUserList();
    }
  };

  const handleTableActionEvents = (type, params) => {
    setTableActionType(type);
    setTableActionParams(params);

    const newProps = {
      alertType: false,
    };

    switch (type) {
      case TABLE_ACTIONS.ENABLE_USER:
        newProps.submitBtn = 'Enable';
        newProps.title = 'Are you sure you want to enable this user?';
        newProps.description = `'${params[0]}' will be enabled.`;
        break;
      case TABLE_ACTIONS.DISABLE_USER:
        newProps.submitBtn = 'Disable';
        newProps.title = 'Are you sure you want to disable this user?';
        newProps.description = `'${params[0]}' will be disabled.`;
        break;
      case TABLE_ACTIONS.REMOVE_USER:
        newProps.submitBtn = 'Delete';
        newProps.title = 'Are you sure you want to delete this user?';
        newProps.alertType = true;
        newProps.description = `'${params[0]}' will have no longer access to their accounts.`;
        break;
      case TABLE_ACTIONS.RESET_PASSWORD:
        newProps.submitBtn = 'Reset';
        newProps.title = 'Are you sure you want to reset password?';
        newProps.description = `'${params[0]}' will need to reset password.`;
        break;
      default:
        break;
    }

    setConfirmDialogProps(newProps);
    setOpenConfirmDialog(true);
  };

  const handleTableActions = () => {
    switch (tableActionType) {
      case TABLE_ACTIONS.ENABLE_USER:
      case TABLE_ACTIONS.DISABLE_USER:
        enableUser(...tableActionParams);
        break;
      case TABLE_ACTIONS.REMOVE_USER:
        removeUser(...tableActionParams);
        break;
      case TABLE_ACTIONS.RESET_PASSWORD:
        sendChangePasswordRequest(...tableActionParams);
        break;
      default:
        break;
    }

    setOpenConfirmDialog(false);
  };

  const openNewUserDialog = () => {
    setNewUserRequestDialogOpened(true);
  };

  const deleteNotification = (id) => {
    const deletedItem = notifications.findIndex((item) => item.id === id);
    global.notifications[deletedItem].set(none);
  };

  const isNewUserInfoValid = () => {
    return newUserEmail;
  };

  const handleTab = (e, val) => {
    setTabValue(val);
  };

  return (
    <div className={classes.pageContent}>
      {notifications?.length > 0 && (
        <SpSnackBar
          notifications={notifications}
          isOpen={notifications.length > 0}
          onNotificationDelete={deleteNotification}
        />
      )}
      <Grid
        item
        container
        className={classes.pageHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4">Authentication</Typography>
        </Grid>

        <Grid item></Grid>
      </Grid>

      {loading && <CqLoading />}
      {!loading && users && (
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              className={classes.styledTabs}
              value={tabValue}
              onChange={handleTab}
              aria-label="basic tabs example"
            >
              <Tab label="Users" />
              <Tab label="SSO Configuration" />
              <Tab label="API Clients" />
            </Tabs>
          </Box>
          <TabPanel value={0} tabId={tabValue}>
            <Box className={classes.panelBox}>
              <UsersTable
                users={users}
                openNewUserDialog={openNewUserDialog}
                columns={TABLE_HEADER_COLUMNS}
                onAction={handleTableActionEvents}
                removeUser={removeUser}
              />
            </Box>
          </TabPanel>
          <TabPanel value={1} tabId={tabValue}>
            <Box sx={{ padding: '10px', height: '500px' }}>
              <SsoInfo />
            </Box>
          </TabPanel>
          <TabPanel value={2} tabId={tabValue}>
            <Box className={classes.panelBox}>
              <ClientsTable />
            </Box>
          </TabPanel>
        </Box>
      )}

      {/* New User Dialog */}
      <CqDialog
        title={'Add New User'}
        open={newUserRequestDialogOpened}
        onClose={() => setNewUserRequestDialogOpened(false)}
        onSubmit={addUser}
        submitBtnDisabled={!isNewUserInfoValid()}
        maxWidth="sm"
        backdrop={dialogInProgress}
        closeIcon={true}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <InputLabel className={dialogClasses.dialogLabel}>Email</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={newUserEmail}
              size={'small'}
              className={dialogClasses.dialogInput}
              placeholder="Email"
              onChange={(e) => setNewUserEmail(e.target.value)}
            />
          </Grid>

          <Grid item container columnSpacing={3}>
            <Grid item xs={6}>
              <InputLabel className={dialogClasses.dialogLabel}>
                First Name
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                value={newUserFirstName}
                size={'small'}
                className={dialogClasses.dialogInput}
                placeholder={'First Name'}
                onChange={(e) => setNewUserFirstName(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={dialogClasses.dialogLabel}>
                Last Name
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                value={newUserLastName}
                size={'small'}
                className={dialogClasses.dialogInput}
                placeholder={'Last Name'}
                onChange={(e) => setNewUserLastName(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </CqDialog>

      {/* Remove User Dialog */}
      {confirmDialogProps && (
        <CqDialog
          title={confirmDialogProps.title}
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onSubmit={handleTableActions}
          submitBtnText={confirmDialogProps.submitBtn}
          maxWidth="sm"
          backdrop={dialogInProgress}
          alertType={confirmDialogProps.alertType}
          closeIcon={true}
        >
          <Typography className={classes.dialogInfo}>
            {confirmDialogProps.description}
          </Typography>
        </CqDialog>
      )}
    </div>
  );
};

export default UsersPage;
