export const localStore = () => {
  const userViews = {
    userPreferences: {
      pieChartView: '',
      logscale: '',
      barchartAcendingSort: '',
      serverView: '',
      serverDetailsVulExpand: 'true',
    },
    dashboardFilters: {},
    serverFilters: {
      domains: [],
      appHostingProviders: {},
      severity: [],
      responseCode: [],
      apiServersFound: [],
      selectedDomain: '',
      crawlId: '',
      uapAwareness: [],
      timeRange: '',
      apiHosts: [
        { name: 'API Hosts', selected: true },
        { name: 'Non API Hosts', selected: false},
      ],
      apiGateways: [],
      size: 100,
      from: 0
    }
  };
  if (localStorage) {
    userViews.userPreferences.pieChartView =
      localStorage.getItem('pieChartView');
    userViews.userPreferences.logscale =
      localStorage.getItem('logscale');
    userViews.userPreferences.apiHosts =
      localStorage.getItem('apiHosts');
    userViews.userPreferences.barchartAcendingSort =
      localStorage.getItem('barchartAcendingSort');
    userViews.userPreferences.serverView =
      localStorage.getItem('serverView');
    userViews.userPreferences.serverDetailsVulExpand =
      localStorage.getItem('serverDetailsVulExpand');
    userViews.dashboardFilters =
      localStorage.getItem('dashboardFilters') ? JSON.parse(localStorage.getItem('dashboardFilters')) : {};
    if (localStorage.getItem('serverFilters')){
      userViews.serverFilters =
        JSON.parse(localStorage.getItem('serverFilters'))
    }
  }
  return {
    update: (type, item, val) => {
      if (localStorage) {
        if (typeof val === 'boolean') {
          val = val.toString();
        }
        userViews[type][item] = val;
        localStorage.removeItem(item);
        localStorage.setItem(item, val);
      }
    },
    get: (type, key) => {
      return userViews[type] && userViews[type][key];
    },
    getFilters: (type) => {
      return userViews[type];
    },
    setFilters: (type, val) => {
      userViews[type] = val
      localStorage.removeItem(type);
      localStorage.setItem(type, JSON.stringify(val));
    },
    resetFilters: () => {
      localStorage.removeItem('pieChartView');
      localStorage.removeItem('logscale');
      localStorage.removeItem('apiHosts');
      localStorage.removeItem('barchartAcendingSort');
      localStorage.removeItem('serverView');
      localStorage.removeItem('serverDetailsVulExpand');
      localStorage.removeItem('serverFilters')
      localStorage.removeItem('dashboardFilters')
    },
  };
};
