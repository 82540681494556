import React, { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { enhancedFixedColumnTableStyles, colors } from '../../assets/theme';
import { WrapperContext } from '../../components/wrapper/wrapperContext';
import { DISCOVERIES } from './constants';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useFiltersState } from '../../store/globalState';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  discoveryCell: {
    minWidth: '350px !important',
    maxWidth: '350px !important',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      '& svg': {
        color: colors.gray400,
        fontSize: '16px',
        cursor: 'pointer',
      },
    },
  },
  valueCell: {
    minWidth: '108px !important',
    maxWidth: '108px !important',
  },
  tableBodyCell: {
    borderRadius: '0 !important',
  },
  cellBorderRight: {
    borderRight: `1px solid ${colors.gray400} !important`,
  },
  cellBorderBottom: {
    borderBottom: `1px solid ${colors.gray400} !important`,
  },
  subItem: {
    display: 'flex',
    alignItems: 'center',
  },
  parItem: {
    width: 'fit-content',
    padding: '4px 12px',
    borderRadius: '20px',
  },
  pinkPar: {
    background: DISCOVERY_COLORS['pink'],
    color: 'black'
  },
  yellowPar: {
    background: DISCOVERY_COLORS['yellow'],
    color: 'black',
  },
  grayPar: {
    background: DISCOVERY_COLORS['gray'],
    color: 'black',
  },
  outlinePar: {
    background: 'transparent',
    border: `1px solid ${DISCOVERY_COLORS['gray']}`,
    color: DISCOVERY_COLORS['gray'],
  },
  thText: {
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.3px',
    color: colors.white,
  },
}));

const DISCOVERY_COLORS = {
  pink: 'pink',
  yellow: colors.yellow,
  gray: colors.gray100,
};

const DiscoveryItem = ({ data, findingName }) => {
  const classes = useStyles();
  const wrapperContext = useContext(WrapperContext)

  const issueTypes = wrapperContext.issueTypes;
  return (
    <>
      {data.color ? (
        <Typography variant={'subtitle1'} className={classes.subItem}>
          {data.color === 'pink' ? (
            <WarningIcon
              sx={{
                color: DISCOVERY_COLORS[data.color],
                fontSize: 20,
                marginRight: '4px',
              }}
            />
          ) : data.color === 'yellow' ? (
            <GppMaybeIcon
              sx={{
                color: DISCOVERY_COLORS[data.color],
                fontSize: 20,
                marginRight: '4px',
              }}
            />
          ) : (
            <InfoIcon
              sx={{
                color: DISCOVERY_COLORS[data.color],
                fontSize: 20,
                marginRight: '4px',
              }}
            />
          )}
          {data.name}
        </Typography>
      ) : (
        <Typography
          variant={'subtitle1'}
          className={clsx(classes.parItem, classes[`${data.color}Par`])}
        >
          {issueTypes?.[findingName]?.description || ''}
        </Typography>
      )}
    </>
  );
};

const CrawlsTable = ({ discoveries, downloadReport, discoveryIssues, findingsGroups }) => {
  const tableClasses = enhancedFixedColumnTableStyles();
  const classes = useStyles();
  const globalFilters = useFiltersState();
  const filters = globalFilters.get();
  const { crawlHistoryFilters } = JSON.parse(JSON.stringify(filters));
  const [columns, setColumns] = useState([]);
  const [discoveriesData, setDiscoveriesData] = useState([]);

  const dataFormat = cloneDeep(discoveries.histogram || []);

  useEffect(() => {
    let cols = [{ title: 'Findings' }];
    dataFormat.map((item, idx) => {
      cols.push({ title: item.date, crawlIds: item.crawlIds });
    });
    setColumns(cols);

    const data = Object.assign({}, ...modifyTableData);
    setDiscoveriesData(data);
  }, []);

  let deepCopyDiscoveries = cloneDeep(discoveryIssues);
  crawlHistoryFilters.discovery.map((obj) => {
      if (!obj.selected) {
        delete deepCopyDiscoveries[obj.type];
      }
  });
  const modifyTableData = dataFormat.map((item, idx) => {
    const obj1 = {};
    const obj = {};
    obj['highSeverityIssues'] = {name: item?.data?.highSeverityIssues?.total ?? '--', color: 'pink', type: 'sub'};
    const highIssues = item['data']?.['highSeverityIssues']?.['issues'] || {}
    for(const highIssue in highIssues){
      obj[highIssue] = {name: highIssues?.[highIssue]?.value ?? '--', color: 'pink', type: 'sub'}
    }
    const mediumIssues = item['data']?.['mediumSeverityIssues']?.['issues'] || {}
    obj['mediumSeverityIssues'] = {name: item?.data?.mediumSeverityIssues?.total ?? '--', color: 'yellow', type: 'sub'};
    for(const medIssue in mediumIssues){
      obj[medIssue] = {name: mediumIssues?.[medIssue]?.value ?? '--', color: 'yellow', type: 'sub'}
    }
    const lowIssues = item['data']?.['lowSeverityIssues']?.['issues'] || {}
    obj['lowSeverityIssues'] = {name: item?.data?.lowSeverityIssues?.total ?? '--', type: 'sub'};
    for(const lowIssue in lowIssues){
      obj[lowIssue] = {name: lowIssues?.[lowIssue]?.value ?? '--', type: 'sub'}
    }
    obj1[item.date] = obj;
    return obj1;
  });

  const generateReport = (date) => () =>{
    const fomatData = moment(date).format('MM/DD/YYYY');
    downloadReport(fomatData)
  }

  return (
    <TableContainer
      style={{ overflowX: 'auto', maxWidth: '1400px', marginBottom: '10px' }}
      className={tableClasses.root}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              <TableCell
                key={`column-${i}`}
                className={clsx(
                  {
                    [classes.valueCell]: i !== 0,
                    [classes.cellBorderBottom]: true,
                    [classes.cellBorderRight]: i < columns.length - 1,
                  },
                  [classes.discoveryCell]
                )}
              >
                <div className={classes.thText}>
                  <span>{c.title}</span>
                  {i !== 0 && c.crawlIds && c.crawlIds !== '' && (
                    <Tooltip title="Download XLS Report">
                      <FileDownloadOutlinedIcon
                        onClick={generateReport(c.title)}
                      />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(deepCopyDiscoveries).map((dKey, i) => {
            return(
            <TableRow key={i}>
              <TableCell
                className={clsx(
                  classes.tableBodyCell,
                  classes.discoveryCell,
                  classes.cellBorderRight,
                  {
                    [classes.cellBorderBottom]:
                      i < Object.keys(discoveryIssues).length - 1,
                  }
                )}
              >
                <DiscoveryItem data={discoveryIssues[dKey]} findingName={dKey} />
              </TableCell>
              {Object.keys(discoveriesData).map((dateKey, idx) => {
                return(
                <TableCell
                  key={idx}
                  className={clsx(classes.tableBodyCell, classes.valueCell, {
                    [classes.cellBorderBottom]:
                      i < Object.keys(discoveryIssues).length - 1,
                    [classes.cellBorderRight]:
                      idx < Object.keys(discoveriesData).length - 1,
                  })}
                >
                  {discoveriesData?.[dateKey][dKey] ? 
                    <Typography variant={'subtitle1'} sx={{color: discoveriesData?.[dateKey][dKey]?.color}}>
                      {discoveriesData?.[dateKey][dKey]?.name}
                    </Typography>
                    :
                    <Typography variant={'subtitle1'}>
                      --
                    </Typography>
                  }
                </TableCell>
              )})}
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CrawlsTable;
