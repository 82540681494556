import React, {useEffect, useState} from 'react';
import { colors } from '../../../assets/theme';
import makeStyles from '@mui/styles/makeStyles';
import {  MenuItem,
    Checkbox, Typography, Grid, InputLabel, FormControl, Select, ListSubheader
} from '@mui/material';
import Search from "../../../components/Search";

const useStyles = makeStyles(theme => ({
    root: {

    },
    filterDropdown: {
        border: `1px solid ${colors.gray550}`,
        borderRadius: '4px',
        backgroundColor: colors.gray800,
        marginRight: theme.spacing(0.5),
        height: 32,
        width: 240,
    },
    switch: {
        marginRight: theme.spacing(1),
        height: '30px',
    },
    openMenu: {
        marginTop: 5,
        maxHeight: '80vh',
        marginLeft: -24,
    },
    filterText: {
        color: colors.blueButtonBg,
        cursor: 'pointer',
        marginLeft: 10,
    },
    arrowIcon: {
        color: colors.blueButtonBg,
        marginTop: -2,
        cursor: 'pointer',
    },
    itemLabel: {
        color: 'white',
    },
    checkedItemsContainer: {
        borderBottom: `1px solid ${colors.muteder}`,
    },
    itemText: {
        paddingTop: 9,
    },
    search: {
        width: '90%',
    },
    selectLabel: {
        fontSize: 12,
        lineHeight: '16px',
        marginBottom: '4px',
        color: colors.gray300,
    },
    selectField: {
        '&.MuiOutlinedInput-root': {
            paddingTop: '6px',
            color: colors.white,
            background: colors.gray600,
            fontSize: 14,
            },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiSelect-icon': {
            color: colors.blueButtonBg,
        },
        '&.MuiInputBase-root': {
            lineHeight: '1.1876em'
        },
    },
    menuItem: {
        height: '48px',
        padding: '6px',
    },
    menuItemLabel: {
        fontSize: '14px',
    },
}));

const FilterItem = ({ label,
                        options,
                        multiChoice,
                        pageKey,
                        stateKey,
                        headers = false,
                        placeholder = 'All',
                        showSearch,
                        selectOptions,
                        showAll = false,
                        searchLabel,
                        width = 250,
                    }) => {
    const classes = useStyles();
    const [dirtyOptions, setDirtyOptions] = useState(JSON.parse(JSON.stringify(options)));
    const [value, setValue] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectAll, setSelectAll] = useState(true);

    useEffect(() => {
        if (showAll && headers) {
            let unselectedItems = [];
            Object.entries(dirtyOptions).map((obj) => {
                obj[1].filter((i) => {
                    if (i.selected === false) {
                        unselectedItems.push(i);
                    }
                })
            });
            unselectedItems.length > 0 ? setSelectAll(false) : setSelectAll(true);
        };
        if (showAll && !headers) {
            const unselectedItems = dirtyOptions.filter(o => !o.selected)
            unselectedItems.length > 0 ? setSelectAll(false) : setSelectAll(true);
        };
    }, [dirtyOptions]);


    let filteredDirtyOptions;

    if (showSearch && !headers) {
        filteredDirtyOptions = dirtyOptions.filter((i) => i.name.includes(searchValue));
    } else if (showSearch && headers) {
        const newFilterObj = {};
        Object.entries(dirtyOptions).map((obj) => newFilterObj[obj[0]] = obj[1].filter((i) => i.name.includes(searchValue)));
        filteredDirtyOptions = newFilterObj;
    }
    else {
        filteredDirtyOptions = dirtyOptions;
    };

    const selectOptionHandler = (option) => {
        if (option === undefined) {
            return;
        }
        // console.log(option[0]);
        if (!headers && multiChoice) {
            if (option?.[0]?.name === 'All') {
                const options = dirtyOptions.map(o => ({ name: o.name, type: o.type, selected: option[0].selected}));
                setDirtyOptions([...options]);
                selectOptions([...options], pageKey, stateKey)
            } else {
                const index = dirtyOptions.findIndex(o => o.name === option?.[0]?.name);
                if(index > -1){
                  dirtyOptions[index].selected = dirtyOptions && !dirtyOptions?.[index]?.selected;
                  setDirtyOptions([...dirtyOptions]);
                  selectOptions([...dirtyOptions], pageKey, stateKey)
                }
            }
        }
        if (!headers && !multiChoice) {
            const selectedOpt = dirtyOptions.map(o => o.name === option.name ? {...o, selected: true} : {...o, selected: false});
            setDirtyOptions([...selectedOpt]);
            selectOptions([...selectedOpt], pageKey, stateKey)
        }
        if (headers) {
            if (option?.[0]?.name === 'All') {
                Object.entries(dirtyOptions).map((obj, i) => {
                    obj[1].map((i) => {
                        i.selected = option[0].selected;
                    })
                });
                setDirtyOptions({...dirtyOptions});
                setSelectAll(!selectAll);
                selectOptions({...dirtyOptions}, pageKey, stateKey);
            } else {
                const index = dirtyOptions[option?.[0]?.issue].findIndex(o => o.name === option?.[0]?.name);
                dirtyOptions[option[0].issue][index].selected = !dirtyOptions[option[0].issue][index].selected;
                setDirtyOptions({...dirtyOptions});
                selectOptions({...dirtyOptions}, pageKey, stateKey);
            }
        };

    };

    const renderValue = () => {
        if (!headers) {
            let selectedItems;
            if (dirtyOptions.find((i) => (i.selected)) === undefined) {
                return selectedItems = '';
            } else if(dirtyOptions.find((i) => (!i.selected)) === undefined && dirtyOptions.length > 1) {
                return selectedItems = 'All';
            } else {
                selectedItems = dirtyOptions.filter((i) => (i.selected === true)).map((i) => i.name);
                return selectedItems.length === 0 ? placeholder : selectedItems.join(', ');
            }
        } else {
            let selectedItems = [];
            Object.entries(filteredDirtyOptions).map((obj, i) => {
                const temp = obj[1].filter((i) => (i.selected === true)).map((i) => i.name);
                selectedItems = [...selectedItems, ...temp];
            })
            return selectAll ? placeholder : selectedItems.join(', ');
        }
    };

    const makeItems = (data) => {
        const items = [];

        Object.entries(data).map((obj, i) => {
            items.push(<ListSubheader key={`issue-${i}`} style={{ color: colors.white}}>{obj[0]}</ListSubheader>)
            obj[1].map((item) => {
                items.push(
                    <MenuItem key={`${item.name}`} value={{'issue': obj[0], 'name': item.name}}>
                        { multiChoice &&
                        <Checkbox key={`${item.name}`} checked={item.selected}/>
                        }
                        {item.name}
                    </MenuItem>
                )
            })
        })
        return items;
    };

    return (
        <Grid style={{ marginLeft: 12, marginTop: 10, marginBottom: 10, width: '96%', maxWidth: '210px' }}>
            <InputLabel className={classes.selectLabel}>{label}</InputLabel>
            <FormControl style={{ width: '100%' }} size="small">
                <Select
                    className={classes.selectField}
                    defaultValue={""}
                    displayEmpty
                    multiple={multiChoice}
                    value={value}
                    onChange={(e) => selectOptionHandler(e.target.value)}
                    renderValue={renderValue}
                    style={{height: '35px'}}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        PaperProps: {sx:{
                            maxHeight: '350px',
                            border: `1px solid ${colors.fontGray}`,
                        }}
                    }}
                >
                    { showSearch &&
                    <div className={classes.search}>
                        <Search width={width} searchLabel={searchLabel} localSearchOp={x => setSearchValue(x)}/>
                    </div>
                    }
                    { showAll &&
                    <MenuItem  className={classes.menuItem} key={'All'} value={{name: 'All', selected: !selectAll}}>
                        <Checkbox checked={selectAll} />
                        Select All
                    </MenuItem>
                    }
                    { !headers &&
                    filteredDirtyOptions.map((item, idx) =>
                        <MenuItem
                            key={idx}
                            value={item}
                            className={classes.menuItem}
                        >
                            { multiChoice &&
                              <Checkbox checked={item.selected}/>
                            }
                            <Typography className={classes.menuItemLabel}>{item?.name}</Typography>
                        </MenuItem>
                    )
                    }
                    { headers &&
                    makeItems(filteredDirtyOptions)
                    }
                </Select>
            </FormControl>
        </Grid>
    );
};

export default FilterItem;
