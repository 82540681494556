import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../assets/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    padding: '2px 10px',
    cursor: 'pointer',
    borderRadius: 16,
    color: colors.darkest,
  },
  pending: {
    border: `1px solid ${colors.yellow1}`,
    backgroundColor: colors.yellow1,
  },
  active: {
    border: `1px solid ${colors.greenButtonBg}`,
    backgroundColor: colors.greenButtonBg,
  },
  disabled: {
    border: `1px solid ${colors.red200}`,
    backgroundColor: colors.red200,
  },
  removed: {
    border: `1px solid ${colors.red200}`,
    backgroundColor: colors.red200,
  },
  denied: {
    border: `1px solid ${colors.red200}`,
    backgroundColor: colors.red200,
  },
}));

const StatusItem = ({ status }) => {
  const classes = useStyles();

  return (
    <Typography
      variant={'subtitle1'}
      className={clsx(classes.root, classes[status.toLowerCase()])}
    >
      {status}
    </Typography>
  );
};

StatusItem.prototype = {
  status: PropTypes.string,
};

export default StatusItem;
