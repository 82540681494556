import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Table,
  Grid,
  Box,
  TableBody,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  
 } from '@mui/material';
import {
  StyledContainer,
  StyledCell,
  StyledTableRow
} from '../styles';
import { updateGlobalConfig, testGlobalConfig } from '../provider';
import Info from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getRegions } from '../provider'
import { colors } from '../../../assets/theme';
import CqDialog from '../../../components/common/CqDialog';
import { ResponseData } from '../../../components/requestResponse/ResponseData';
import { ResponseHeader } from '../../../components/requestResponse/ResponseHeader';
import CqButton from '../../../components/common/CqButton';
import { DiscoveryContext } from '../DiscoveryContext';

const getCountryProviders = (prov, providers) => {
  for(const i of prov.country) {
    if(providers[i.name]){
      if(providers[i.name][prov.providerId]){
        const ip = i.region.map(x => x.ip)
        const providerip = providers[i.name][prov.providerId].ip
        providers[i.name][prov.providerId].ip = [...ip, ...providerip]
      } else {
        const obj = {}
        obj.providerName = prov.providerName
        obj.ip = i.region.map(x => x.ip)
        providers[i.name][prov.providerId] = obj
      }

    } else {
      const obj = {}
      obj.providerName = prov.providerName
      obj.ip = i.region.map(x => x.ip)
      providers[i.name]={}
      providers[i.name][prov.providerId] = obj
    }
  }
}

const getRegionsList = async() => {
  try {
    const res = await getRegions()
    const regions = res?.data?.getCrawlRegions?.regions
    const providers = {}
    for(const prov of regions){
      getCountryProviders(prov, providers)
    }
    return providers
  } catch(e) {
    console.error(e)
  }
}

const CrawlRegion = ({globalConfig, setTenantConfigurations}) => {

  const [testConnection, setTestConnectionData] = useState({
    ip: '',
    response: {
      headers: [],
      content: {}
    }
  })
  const [showConnectionDetails, setShowConnectionDetails] = useState(false)
  const [cpList, setcpList] = useState({})
  const [globalConfiguration, setGlobalConfig] = useState({cloudProvider: '', cloudRegion: '', cloudIp: '', inProgress: false})
  const [testConfig, setTestConfig] = useState({host: '', endpoint: '', inProgress: false})
  const [openGlobalConfigDialog, setOpenDialog] = useState(false)
  const discoverContext = useContext(DiscoveryContext);

  useEffect(async() => {
    const cloudProviderList = await getRegionsList()
    setcpList(cloudProviderList)
    setGlobalConfig(globalConfig)
  }, [globalConfig])

  useEffect(() => {
    if(!openGlobalConfigDialog){
      setShowConnectionDetails(false)
      setTestConnectionData({})
    }
  }, [openGlobalConfigDialog])

  const handleGlobalConfigDialog = () => {
    setOpenDialog(val => !val)
  }

  const submitGlobalConfig = () => {
    setGlobalConfig(val => ({...val, inProgress: true}))
    updateGlobalConfig({globalConfig: {cloudProvider: globalConfiguration.cloudProvider, cloudRegion: globalConfiguration.cloudRegion, cloudIp: globalConfiguration.cloudIp}})
    .then((res) => {
      const config = res?.data?.updateTenantGlobalConfig?.config;
      setTenantConfigurations(config)
      discoverContext.setNotify({
        open: true,
        message: 'Saved Global Config!',
        success: true,
      });
    })
    .catch((e) => {
      console.log(e)
      discoverContext.setNotify({
        open: true,
        message: 'Some thing went wrong please try again!',
        success: false,
      });
    })
    .finally(() => {
      setGlobalConfig(val => ({...val, inProgress: false}))
      handleGlobalConfigDialog()
    });
  }

  const copyToClipBoard = () => {
    navigator?.clipboard?.writeText(globalConfig.ip)
    discoverContext.setNotify({
      open: true,
      message: 'Copied to clopboard!',
      success: true,
    });
  }

  const handleTestConnection = () => {
    // setTestConfig(val => ({...val, inProgress: true}))
    // testGlobalConfig(testConfig)
    // .then(res => {
    // })
    // .catch(() => {
    //   discoverContext.setNotify({
    //     open: true,
    //     message: 'Some thing went wrong please try again!',
    //     success: false,
    //   });
    // })
    // .finally(() => {
    //   setTestConfig(val => ({...val, inProgress: false}))
    // });
    /// TBD delete hardcoded data
      setTestConnectionData({
        "method": "get",
        "url": "https://api.thespice.kitchen/metrics",
        "httpVersion": "HTTP/1.1",
        server: {
          "ipAddr": "35.190.122.90",
        },
        response: {
          "status": 404,
          "statusText": "Not Found",
          "time": 14649153,
          "headers": [
              {
                  "name": "expires",
                  "value": "Fri, 03 May 2024 18:08:44 GMT"
              },
              {
                  "name": "date",
                  "value": "Fri, 03 May 2024 17:08:44 GMT"
              },
              {
                  "name": "cache-control",
                  "value": "public, max-age=3600"
              },
              {
                  "name": "last-modified",
                  "value": "Wed, 08 Nov 2023 05:22:56 GMT"
              },
              {
                  "name": "etag",
                  "value": "\"3ca5a2382d2dcb7b1eeb9598d01ecc4c\""
              },
              {
                  "name": "x-goog-generation",
                  "value": "1699420976053851"
              },
              {
                  "name": "x-goog-metageneration",
                  "value": "1"
              },
              {
                  "name": "x-goog-stored-content-encoding",
                  "value": "identity"
              },
              {
                  "name": "x-goog-stored-content-length",
                  "value": "626"
              },
              {
                  "name": "content-type",
                  "value": "text/html"
              },
              {
                  "name": "x-goog-hash",
                  "value": "crc32c=hwfqXw==, md5=PKWiOC0ty3se65WY0B7MTA=="
              },
              {
                  "name": "x-goog-storage-class",
                  "value": "STANDARD"
              },
              {
                  "name": "accept-ranges",
                  "value": "bytes"
              },
              {
                  "name": "content-length",
                  "value": "626"
              },
              {
                  "name": "x-guploader-uploadid",
                  "value": "ABPtcPr_KtGi9bdJBSssFkSLV5kAAA7z6Jaun9Wsfw9Rd-fSfbhaOCnZU0VRHodC7nycT3IGPa2DLEyd"
              },
              {
                  "name": "server",
                  "value": "UploadServer"
              },
              {
                  "name": "via",
                  "value": "1.1 google"
              },
              {
                  "name": "alt-svc",
                  "value": "h3=\":443\"; ma=2592000,h3-29=\":443\"; ma=2592000"
              }
          ],
          "startedDateTime": "2024-05-03T17:08:44.249Z",
          "time": 14649153,
          "httpVersion": "HTTP/1.1",
          "cookies": [],
          "bodySize": 626,
          "redirectURL": "",
          "headersSize": -1,
          "content": {
              "size": 626,
              "mimeType": "text/html",
              "text": "PCFkb2N0eXBlIGh0bWw+PGh0bWwgbGFuZz0iZW4iPjxoZWFkPjxtZXRhIGNoYXJzZXQ9InV0Zi04Ii8+PGxpbmsgcmVsPSJzaG9ydGN1dCBpY29uIiBocmVmPSIvaWNvbi5wbmciLz48bWV0YSBuYW1lPSJ2aWV3cG9ydCIgY29udGVudD0iaW5pdGlhbC1zY2FsZT0xLHdpZHRoPWRldmljZS13aWR0aCIvPjx0aXRsZT5TcGljZSBLaXRjaGVuPC90aXRsZT48bGluayByZWw9InByZWNvbm5lY3QiIGhyZWY9Imh0dHBzOi8vZm9udHMuZ29vZ2xlYXBpcy5jb20iLz48bGluayByZWw9InByZWNvbm5lY3QiIGhyZWY9Imh0dHBzOi8vZm9udHMuZ3N0YXRpYy5jb20iIGNyb3Nzb3JpZ2luLz48bGluayByZWw9InN0eWxlc2hlZXQiIGhyZWY9Imh0dHBzOi8vZm9udHMuZ29vZ2xlYXBpcy5jb20vY3NzMj9mYW1pbHk9Um9ib3RvOndnaHRAMzAwOzQwMDs1MDA7NjAwOzcwMCZkaXNwbGF5PXN3YXAiLz48c2NyaXB0IGRlZmVyPSJkZWZlciIgc3JjPSIvc3RhdGljL2pzL21haW4uYmEyY2U0OGUuanMiPjwvc2NyaXB0PjwvaGVhZD48Ym9keT48bm9zY3JpcHQ+WW91IG5lZWQgdG8gZW5hYmxlIEphdmFTY3JpcHQgdG8gcnVuIHRoaXMgYXBwLjwvbm9zY3JpcHQ+PGRpdiBpZD0icm9vdCI+PC9kaXY+PC9ib2R5PjwvaHRtbD4="
          },
          "cache": {}
        }
    })
      setShowConnectionDetails(true)
  }

  const cpInfo = Object.keys(cpList)
  const providers = cpList?.[globalConfiguration.cloudRegion] || {}
  const providerIds = Object.keys(providers)
  const cloudProviderDisplayname = cpList?.[globalConfig.cloudRegion]?.[globalConfig.cloudProvider]?.providerName || ''

  const handleRegionChange = (e) => {
    const ips = providers?.[e.target.value]?.ip || []
    const ipString = []
    for(const i of ips){
      if(i !== ''){
        ipString.push(i)
      }
    }
    setGlobalConfig(val => ({...val, cloudProvider: e.target.value, cloudIp: ipString.length > 0 ? ipString.join(', ') : ''}))
  }

  return (
    <>
      <StyledContainer container sx={{marginTop: '10px'}}>
        <Grid item xs={8}>
          <Typography variant="subtitle1">Crawl Region</Typography>
          <Typography variant="caption">
            Select a specific region for API Spyder to crawl from. This ensures that API Spyder will respect geofencing rules, and avoids potential IP blocks.
          </Typography>
          <Typography variant="caption">
            Perform a test crawl to confirm that the selected IP address is able to connect to your public facing domain. 
          </Typography>
          <Box sx={{background: '#00BBD31F', display: 'flex', alignItems: 'center', gap: '4px', padding: '8px', mt: '8px'}}>
            <Info sx={{color: '#33C9DC'}} />
            <Typography variant="caption">
              If there is a provider/region that you would like to request, contact support@cequence.ai 
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{padding: '16px'}}>
          <Table sx={{border: `1px solid ${colors.gray650}`, marginBottom: '16px'}}>
            <TableBody>
              <StyledTableRow>
                <StyledCell background='true'><Typography variant='overline'>CLOUD PROVIDER</Typography></StyledCell>
                <StyledCell><Typography variant='body2'>{cloudProviderDisplayname}</Typography></StyledCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledCell background='true'><Typography variant='overline'>REGION</Typography></StyledCell>
                <StyledCell><Typography variant='body2'>{globalConfig.cloudRegion}</Typography></StyledCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledCell background='true'><Typography variant='overline'>IP Address</Typography></StyledCell>
                <StyledCell><Typography variant='body2'>{globalConfig.cloudIp}</Typography></StyledCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <CqButton variant="borderBlue" onClick={handleGlobalConfigDialog}>
              <span>Edit Crawl Region</span>
            </CqButton>
          </Box>
          {/*  */}
        </Grid>
      </StyledContainer>
      <CqDialog
        open={openGlobalConfigDialog}
        onClose={handleGlobalConfigDialog}
        title='Edit Crawl Region'
        onSubmit={submitGlobalConfig}
        submitBtnDisabled={globalConfig.cloudProvider === '' || globalConfig.cloudRegion === ''}
        backdrop={globalConfiguration.inProgress}
      >
        <Typography variant='body2' sx={{marginBottom: '18px'}}>Current Configuration</Typography>
          <Box sx={{display: 'flex', gap: '8px'}}>
            <FormControl sx={{width: '300px'}}>
              <InputLabel id="provider-label">Select Country</InputLabel>
              <Select
                labelId="provider-label"
                value={globalConfiguration.cloudRegion}
                onChange={(e) => setGlobalConfig(val => ({...val, cloudRegion: e.target.value}))}
                label='Select Country'
              >
                {cpInfo.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl sx={{width: '300px'}}>
              <InputLabel id="region-label">Select Provider</InputLabel>
              <Select
                labelId="region-label"
                value={globalConfiguration.cloudProvider}
                onChange={handleRegionChange}
                label='Select Provider'
              >
                {providerIds.map((val, i) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {providers?.[val]?.providerName || ''}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl sx={{width: '300px'}}>
              <TextField
                fullWidth
                variant="outlined"
                value={globalConfiguration.cloudIp}
                disabled
                label='IP Address'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{cursor: 'pointer'}} onClick={copyToClipBoard}>
                      <ContentCopyIcon />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Box>
          <Typography variant='body2' sx={{margin: '18px 0'}}>
            Provide a public-facing fully qualified server and endpoint for testing. This will help determine if Cequence can establish a successful connection from the specified region and IP address.
          </Typography>
          <Box sx={{display: 'flex', gap: '8px'}}>
            <FormControl sx={{width: '300px'}}>
              <TextField
                fullWidth
                variant="outlined"
                label='Fully Qualified Server'
                value={testConfig.host}
                onChange={(e) => setTestConfig(val => ({...val, host: e.target.value}))}
              />
            </FormControl>
            <FormControl sx={{width: '300px'}}>
              <TextField
                fullWidth
                variant="outlined"
                label='Endpoint'
                value={testConfig.endpoint}
                onChange={(e) => setTestConfig(val => ({...val, endpoint: e.target.value}))}
              />
            </FormControl>
            <CqButton
              variant="borderBlue"
              disabled={testConfig.endpoint === '' || testConfig.host === '' || testConfig.inProgress}
              onClick={handleTestConnection}
              height={55}
            >Test Connection</CqButton>
          </Box>
          {showConnectionDetails && <Box sx={{marginTop: '20px'}}>
            <ResponseHeader
              method={testConnection?.method}
              url={testConnection?.url}
              status={testConnection?.response?.status}
              time={testConnection.time && testConnection.time > 0
                ? `${testConnection.time} ms`
                : '--'} 
            />
            <ResponseData ip={testConnection?.server?.ipAddr} selectedResponse={testConnection.response} />
          </Box>}
      </CqDialog>
    </>
  )
}

export default CrawlRegion