import React from 'react';
import { Grid } from "@mui/material";
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { colors } from '../assets/theme';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const SliderComponent = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-rail': {
        backgroundColor: colors.gray400
    },
    '& .MuiSlider-track': {
        backgroundColor: colors.blueButtonBg
    },
    '& .MuiSlider-thumb': {
        backgroundColor: colors.blueButtonBg
    },
}))

const SpSlider = ({ value, handleSliderChange, text, disabled }) => {
    return (
        <Box sx={{ width: 200, alignItems: 'center' }}>
            <Stack spacing={2} direction="row" sx={{ mb: 1, marginBottom: '-10px' }}>
                <Grid item xs style={{ paddingLeft: 10 }}>
                    <SliderComponent
                        size={'medium'}
                        value={value}
                        onChange={handleSliderChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                  <Typography variant="caption">{value}%</Typography>
                </Grid>
            </Stack>
        </Box>
    )
}

export default SpSlider;
