import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';

import makeStyles from '@mui/styles/makeStyles';
import { enhancedTableStyles, colors } from '../../assets/theme';
import { SORT_ORDER } from '../../store/constants';
import {
  numericComparatorByProperty,
  formatDateTime,
  caseInsensitiveComparatorByField,
  idComparatorByField,
} from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  notificationRow: {
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div': {
      visibility: 'visible',
    },
  },
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerRowCellIcon: {
    color: colors.blueButtonBg,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    fontSize: '16px !important',
  },
  severityCell: {
    display: 'flex',
    alignItems: 'center',
  },
  timestampColumn: {
    maxWidth: 240,
    width: 240,
  },
}));

const SEVERITY_COLORS = {
  HIGH: colors.red300,
  MEDIUM: colors.orangeMediumLevel,
  LOW: colors.gray300,
};

const SEVERITY_LEVELS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

const CustomTableRow = ({ notification }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.notificationRow}>
      <TableCell>
        <Typography variant={'subtitle1'}>
          {formatDateTime(notification.timestamp)}
        </Typography>
      </TableCell>

      <TableCell className={classes.severityColumn}>
        <Typography variant={'subtitle1'}>{notification.message}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'} className={classes.severityCell}>
          {notification.severity === SEVERITY_LEVELS.HIGH && (
            <WarningIcon
              sx={{
                color: SEVERITY_COLORS.HIGH,
                fontSize: 20,
                marginRight: '12px',
              }}
            />
          )}
          {notification.severity !== SEVERITY_LEVELS.HIGH && (
            <InfoIcon
              sx={{
                color: SEVERITY_COLORS[notification.severity.toUpperCase()],
                fontSize: 20,
                marginRight: '12px',
              }}
            />
          )}
          {notification.severity}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const CustomTableHeaderCell = ({ column, onUpdate }) => {
  const classes = useStyles();

  return (
    <TableCell
      className={clsx(classes.headerRowCell, classes[`${column.id}Column`])}
    >
      <Typography variant="subtitle2" onClick={onUpdate}>
        {column.name}
        {column.sortable && column.sortOrder === SORT_ORDER.ASCENDING && (
          <ArrowUpwardIcon className={classes.headerRowCellIcon} />
        )}
        {column.sortable && column.sortOrder === SORT_ORDER.DESCENDING && (
          <ArrowDownwardIcon className={classes.headerRowCellIcon} />
        )}
        {column.help && <HelpIcon className={classes.headerRowCellIcon} />}
      </Typography>
    </TableCell>
  );
};

const NotificationsTable = ({ notifications, columns }) => {
  const [columnList, setColumnList] = useState(columns);
  const [notificationList, setNotificationList] = useState(notifications);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableSortIdx, setTableSortIdx] = useState(-1);

  const tableClasses = enhancedTableStyles();

  useEffect(() => {
    if (tableSortIdx >= 0) {
      setNotificationListSorted(tableSortIdx);
    } else {
      setNotificationList(notifications);
    }
  }, [notifications]);

  const setNotificationListSorted = (idx) => {
    const comparator = getComparator(idx);
    setNotificationList(
      notifications.sort(
        comparator(
          columnList[idx].id,
          columnList[idx].sortOrder === SORT_ORDER.ASCENDING
        )
      )
    );
  };

  const handleSortOrderUpdate = (idx) => () => {
    if (tableSortIdx >= 0 && tableSortIdx !== idx) {
      columnList[tableSortIdx].sortOrder = undefined;
    }

    columnList[idx].sortOrder =
      columnList[idx].sortOrder === SORT_ORDER.ASCENDING
        ? SORT_ORDER.DESCENDING
        : SORT_ORDER.ASCENDING;
    setNotificationListSorted(idx);
    setColumnList([...columnList]);
    setTableSortIdx(idx);
  };

  const handlePageChange = (event, page) => {
    setPageNum(page);
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    setPageSize(value);
    setPageNum(0);
  };

  const getPageData = () => {
    return notificationList.slice(
      pageNum * pageSize,
      Math.min(notificationList.length, (pageNum + 1) * pageSize)
    );
  };

  const getComparator = (idx) => {
    switch (columnList[idx]?.sortType) {
      case 'numeric':
        return numericComparatorByProperty;
      case 'id':
        return idComparatorByField;
      case 'string':
        return caseInsensitiveComparatorByField;
      default:
        return caseInsensitiveComparatorByField;
    }
  };

  return (
    <TableContainer className={tableClasses.root}>
      <Table>
        <TableHead>
          <TableRow>
            {columnList.map((c, i) => (
              <CustomTableHeaderCell
                key={`column-${i}`}
                column={c}
                onUpdate={handleSortOrderUpdate(i)}
              />
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {getPageData().map((notification, i) => (
            <CustomTableRow key={i} notification={notification} />
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={notificationList.length}
        page={pageNum}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </TableContainer>
  );
};

CustomTableRow.prototype = {
  notification: PropTypes.object,
};

NotificationsTable.prototype = {
  notifications: PropTypes.array,
  columns: PropTypes.array,
};

export default NotificationsTable;
