import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../assets/theme';
import { Grid, Chip, Box, Typography } from '@mui/material';
import StatusChip from '../pages/servers/gridHelpers/StatusChip';
import SankeyChart from './SankeyChart';
import { StyledLine, StyledOvel } from './FlowChartItems';
import ColoredChip from './ColoredChip';

const StyledVerticalOperator = styled('div')({
  marginLeft: '50px',
  '& span': {
    marginLeft: '23px'
  },
  display: 'block'
})

const StyledModelBox = styled('div')({
  display: 'flex',
  overFlow: 'hidden'
})

const StyledBox = styled('div')({
  backgroundColor: colors.gray750,
  border: `1px solid ${colors.gray700}`,
  borderRadius: 4,
  padding: '8px',
  marginTop: '16px',
  height: '800px'
})

const getValuePass = (val, operator, rule) => {
  let flag = false
  if(operator === 'gt' && val > rule){
    flag = true
  } else if(operator === 'lt' && val <  rule){
    flag = true
  } else if(operator === 'lte' && val <=  rule){
    flag = true
  } else if(operator === 'gte' && val >=  rule){
    flag = true
  }
  return flag
}

function percentage(partialValue, totalValue) {
  return Math.round((100 * partialValue) / totalValue);
}

const StyledGroup = styled(Grid)({backgroundColor: colors.gray800, border: `1px solid ${colors.gray700}`, padding: '8px'})
const StyledCondition = styled(Grid)(({showborder}) => (
  {
    display: 'flex',
    gap: '4px',
    margin: '8px 0',
    border: showborder ? `1px solid ${colors.gray700}` : 'none',
    padding: showborder ? '8px' : 0}
))
const StyledResult = styled(Grid)(({finalPass}) => ({backgroundColor: finalPass ? colors.green800 : 'inherit', border: finalPass ? `1px solid ${colors.green300}`: 'none', padding: '8px'}))

const DiscoveryConfig = (props) => {
  const {discoveryConfig, endpointData, selectedServerHost, discoveryConfigResult} = props
  const { json, xml, html, ignoreWhenAllRedirects, hostname } = discoveryConfig || {};
  const jsonRules = json?.rules?.[0] || {}
  const xmlRules = xml?.rules?.[0] || []
  const htmlRules = html?.rules?.[0] || []
  let jsonEndpoints = 0
  let xmlEndpoints = 0
  let htmlEndpoints = 0
  let redirectedEndpoints = 0
  let rulePass = {json: false, xml: false, html: false, redirect: false, hostname: false}
  if(endpointData.attempted){
    jsonEndpoints = jsonRules.type === 'PERCENT' ? percentage(endpointData.json, endpointData.attempted) : endpointData.json
    xmlEndpoints = xmlRules.type === 'PERCENT' ? percentage(endpointData.xml, endpointData.attempted) : endpointData.xml
    htmlEndpoints = htmlRules.type === 'PERCENT' ? percentage(endpointData.html, endpointData.attempted) : endpointData.html
    redirectedEndpoints = percentage(endpointData.redirects, endpointData.attempted)
  }
  if(discoveryConfig != null){
    rulePass.json = getValuePass(jsonEndpoints, jsonRules.operator, jsonRules.value)
    rulePass.xml = getValuePass(xmlEndpoints, xmlRules.operator, xmlRules.value)
    rulePass.html = getValuePass(htmlEndpoints, htmlRules.operator, htmlRules.value)
    rulePass.redirect = ignoreWhenAllRedirects && endpointData.redirects === endpointData.attempted ? false : true
    rulePass.hostname = {incl: false, excl: false}
    if(hostname?.includes && hostname?.includes.length > 0){
      const testIncl = hostname?.includes.filter(inc => selectedServerHost?.indexOf(inc) > -1 )
      rulePass.hostname.incl = testIncl.length > 0
    }
    if(hostname?.excludes){
      const testex = hostname?.excludes.filter(ex => selectedServerHost?.indexOf(ex) !== -1)
      rulePass.hostname.excl = testex.length === 0
    }
  }
  const hostnameRules = hostname &&  ( hostname?.includes?.length > 0 ||  hostname?.excludes?.length > 0 )
  const hostnameRulepass = rulePass.hostname.incl && rulePass.hostname.excl

  return (
    <Grid container spacing={2}>
      <Grid item sx={{color: '#c9c9c9', pl: '8px', pt: '8px'}} xs={6}>
        <Typography variant='overline' sx={{color: colors.gray300}}>Discovery Algorithm Result</Typography>
        { discoveryConfig != null &&
        <StyledBox>
          {hostnameRules && <>
            <StyledGroup>
              <StyledCondition>
                <Box sx={{ ml: 1 }}>
                  <span>Host name includes </span>
                  {hostname?.includes?.map((inc, i) => <ColoredChip key={`${inc}${i}`} type={selectedServerHost?.indexOf(inc) > -1 ? 'success' : null} label={inc}  />)}
                  <span>  excludes </span>
                  {hostname?.excludes?.map((exl, i) => <ColoredChip key={`${exl}${i}`} type={selectedServerHost?.indexOf(exl) > -1 ? 'success' : null} label={exl} />)}
                </Box>
                <Box sx={{marginLeft: 'auto'}}><ColoredChip testId='inc-exc-pass' label={hostnameRulepass ? 'Passed' : 'Failed'} type={hostnameRulepass ? 'success' : 'fail'} /></Box>
              </StyledCondition>
            </StyledGroup>
            <StyledVerticalOperator>
              <StyledLine height="10px" color="#F4806A"></StyledLine>
              <StyledOvel color="#F4806A">Or</StyledOvel>
              <StyledLine height="10px" color="#F4806A"></StyledLine>
            </StyledVerticalOperator>
          </>}
          <Box sx={{border: `1px solid ${colors.gray700}`, padding: '8px'}}>
            <StyledGroup>
              <StyledCondition showborder="true">
                <Box sx={{ ml: 1 }}>
                  {`JSON responses must be greater than ${jsonRules.type === 'PERCENT' ? `${jsonRules.value}%` : `${jsonRules.value}`}`}
                </Box>
                <Box sx={{marginLeft: 'auto'}}>
                  <ColoredChip label={`Observed: ${jsonRules.type === 'PERCENT' ? (jsonEndpoints + '%') : jsonEndpoints}`} type={rulePass.json ? 'success' : 'fail'} />
                </Box>
              </StyledCondition>
              <StyledVerticalOperator>
                <StyledLine height="10px" color="#F4806A"></StyledLine>
                <StyledOvel color="#F4806A">Or</StyledOvel>
                <StyledLine height="10px" color="#F4806A"></StyledLine>
              </StyledVerticalOperator>
              <StyledCondition showborder="true">
                <Box sx={{ ml: 1 }}>
                  {`XML responses must be greater than ${xmlRules.type === 'PERCENT' ? `${xmlRules.value}%` : `${xmlRules.value}`}`}
                </Box>
                <Box sx={{marginLeft: 'auto'}}>
                  <ColoredChip label={`Observed: ${xmlRules.type === 'PERCENT' ? (xmlEndpoints + '%') : xmlEndpoints}`} type={rulePass.xml ? 'success' : 'fail'} />
                </Box>
              </StyledCondition>
            </StyledGroup>
            <StyledVerticalOperator>
              <StyledLine height="10px" color="#A45C84"></StyledLine>
              <StyledOvel color="#A45C84">And</StyledOvel>
              <StyledLine height="10px" color="#A45C84"></StyledLine>
            </StyledVerticalOperator>
            <StyledGroup>
              <StyledCondition>
                <Box sx={{ ml: 1 }}>
                  {`HTML responses must be less than ${htmlRules.type === 'PERCENT' ? `${htmlRules.value}%` : `${htmlRules.value}`}`}
                </Box>
                <Box sx={{marginLeft: 'auto'}}>
                  <ColoredChip label={`Observed: ${htmlRules.type === 'PERCENT' ? (htmlEndpoints + '%') : htmlEndpoints}`} type={rulePass.html ? 'success' : 'fail'} />
                </Box>
              </StyledCondition>
            </StyledGroup>
          </Box>
          <StyledVerticalOperator>
            <StyledLine height="10px" color="#A45C84"></StyledLine>
            <StyledOvel color="#A45C84">And</StyledOvel>
            <StyledLine height="10px" color="#A45C84"></StyledLine>
          </StyledVerticalOperator>
          <StyledGroup>
            <StyledCondition>
              <Box sx={{ ml: 1 }}>
                {`Suppress API Host if 100% of endpoints are being redirected.`}
              </Box>
              <Box sx={{marginLeft: 'auto'}}>
                <ColoredChip  label={`Observed: ${redirectedEndpoints}% redirects`} type={rulePass.redirect ? 'success' : 'fail'} />
              </Box>
            </StyledCondition>
          </StyledGroup>
          <StyledVerticalOperator>
            <StyledLine height="10px" color="#339A6D"></StyledLine>
            <StyledOvel color="#00341D" space="4px" borderColor="#339A6D">Then</StyledOvel>
            <StyledLine height="10px" color="#339A6D"></StyledLine>
          </StyledVerticalOperator>
          <StyledGroup>
            <StyledCondition>
              <Box sx={{ ml: 1 }}>
                {'Classify as API Host'}
              </Box>
              <Box sx={{marginLeft: 'auto'}}>
                <ColoredChip testId='api-host-pass' label={discoveryConfigResult ? 'True' : 'False'} type={discoveryConfigResult ? 'success' : 'fail'} />
              </Box>
            </StyledCondition>
          </StyledGroup>
        </StyledBox>
        }
      </Grid>
      <Grid item sx={{color: '#c9c9c9', pl: '8px', pt: '8px'}} xs={6}>
        <Typography variant='overline' sx={{color: colors.gray300}}>Crawl Status</Typography>
        <StyledBox>
          <SankeyChart endpointData={endpointData} />
        </StyledBox>
      </Grid>
    </Grid>
  )
}

export default DiscoveryConfig
