import React from 'react';
import { Grid, Box, Divider, Typography } from '@mui/material';
import { colors } from '../../assets/theme';
import { StyledContentCopyIcon, StatusContent, StyledCopyContent, StylesIp, StylesSubtitle, StyledContainer } from './styles'

const ResponseData = ({ip, selectedResponse}) => {
  const handleCopyRequest = async () => {
    const json = JSON.stringify(selectedResponse);
    await navigator.clipboard.writeText(json);
  };
  return (
    <StyledContainer>
      <Grid item container xs={12}>
        <Box sx={{ flexGrow: 1 }}>
          <StylesSubtitle variant={'subtitle1'}>
            Response
          </StylesSubtitle>
        </Box>
        <Box>
          <StylesIp variant={'body2'}>
            Server IP: {ip || '-'}
          </StylesIp>
        </Box>
      </Grid>
      <Divider />
      <Grid>
        <StyledCopyContent>
          <StyledContentCopyIcon
            onClick={() => handleCopyRequest('response')}
          />
        </StyledCopyContent>
        <StatusContent sx={{marginTop: '-20px'}}>
          {selectedResponse.httpVersion} {selectedResponse.status}
        </StatusContent>
        <Box sx={{ pl: '10px'}}>
          {selectedResponse?.headers?.map((i) => {
            return (
              <Typography
                key={i.name}
                sx={{ fontSize: 12 }}
              >
                <span style={{ color: colors.lightBlue100 }}>
                  {i.name}:{' '}
                </span>
                <span style={{ color: colors.orange100 }}>{i.value}</span>
              </Typography>
            );
          })}
        </Box>
        {selectedResponse?.content?.text && (
          <Grid style={{ whiteSpace: 'pre-line' }}>
            <br />
            <StatusContent>
              {window.atob(
                decodeURIComponent(selectedResponse?.content?.text)
              )}
            </StatusContent>
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  )
}

export { ResponseData }