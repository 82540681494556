import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Backdrop,
    CircularProgress,
    Paper, FormControlLabel,
} from '@mui/material';
import Draggable from 'react-draggable';
import CqButton from '../../../components/common/CqButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { colors } from '../../../assets/theme';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import InfoIcon from '@mui/icons-material/Info';
import Grid from "@mui/material/Grid";

const PREFIX = 'CQDialog';
const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    closeBtn: `${PREFIX}-close-btn`,
    content: `${PREFIX}-content`,
    backdrop: `${PREFIX}-backdrop`,
    actions: `${PREFIX}-actions`,
    paperRoot: 'MuiPaper-root',
    blockingRoot: `${PREFIX}-blocking-root`,
    noScroll: `${PREFIX}-noScroll`,
    highLevelRisk: `${PREFIX}-highLevelRisk`,
    mediumLevelRisk: `${PREFIX}-mediumLevelRisk`,
    infoLevelRisk: `${PREFIX}-infoLevelRisk`,
    alertRoot: `${PREFIX}-alertRoot`,
};
const StyledCqDialog = styled(Dialog)(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
        minHeight: '300px',
        padding: theme.spacing(0),
        border: 'none',
        boxShadow: theme.shadows[10],
        borderRadius: '0.25rem',
    },
    [`&.${classes.blockingRoot}`]: {
        '.MuiBackdrop-root': {
            background: colors.gray900,
        },
    },
    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
    },
    [`& .${classes.closeBtn}`]: {
        position: 'absolute',
        right: '9px',
        top: '23px',
        color: colors.gray200,
        fontSize: '24px',
        cursor: 'pointer',
    },
    [`& .${classes.title}`]: {
        textTransform: 'unset',
        padding: '40px 24px 20px',
        fontSize: '20px',
        display: 'flex',
    },
    [`& .${classes.content}`]: {
        border: 'none',
        padding: `0 ${theme.spacing(3)}`,
    },
    [`& .${classes.noScroll}`]: {
        overflowY: 'hidden',
    },
    [`& .${classes.actions}`]: {
        padding: theme.spacing(3),
    },
    [`& .${classes.paperRoot}`]: {
        background: colors.gray800,
        borderRadius: 0,

        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '14px',
            backgroundColor: colors.blue300,
        },
    },
    [`&.${classes.alertRoot} .${classes.paperRoot}`]: {
        '&:before': {
            backgroundColor: colors.red500,
        },
    },
    [`&.${classes.mediumLevelRisk} .${classes.paperRoot}`]: {
        '&:before': {
            backgroundColor: colors.orangeMediumLevel,
        },
    },
    [`&.${classes.highLevelRisk} .${classes.paperRoot}`]: {
        '&:before': {
            backgroundColor: colors.red500,
        },
    },
    [`&.${classes.infoLevelRisk} .${classes.paperRoot}`]: {
        '&:before': {
            backgroundColor: colors.gray100,
        },
    },
}));

const StyledIcon = styled('span')({
    fontSize: 20,
    padding: '4px',
});

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const FindingsDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [backdrop, setBackdrop] = useState(false);

    const onClose = async (e, reason) => {
        if (reason === 'backdropClick' && props.disableBackdropClick === true) {
            e.preventDefault();
            return;
        }
        setOpen(false);
        if (props.onClose) props.onClose();
    };
    const onSubmit = async (e) => {
        if (props.onSubmit) props.onSubmit();
    };
    const onExtraBtnClick = async (e) => {
        if (props.onExtraBtnClick) props.onExtraBtnClick();
    };
    const handleBackdropClose = () => {
        if (props.onBackdropClose) props.onBackdropClose();
    };

    useEffect(() => {
        if (open !== props.open) {
            setOpen(props.open === true);
        }
    }, [props.open]);

    useEffect(() => {
        setBackdrop(props.backdrop === true);
    }, [props.backdrop]);

    useEffect(() => {
        if (open === true && props.onOpen) {
            props.onOpen();
        }
    }, [open]);

    return (
        <StyledCqDialog
            className={clsx(classes.root, {
                [classes.alertRoot]: props.alertType,
                [classes.blockingRoot]: props.blocking,
                [classes.highLevelRisk]: props.highRiskType,
                [classes.mediumLevelRisk]: props.mediumRiskType,
                [classes.infoLevelRisk]: props.infoRiskType,
            })}
            open={open}
            disableEscapeKeyDown={props.disableEscapeKeyDown === true}
            onBackdropClick={props.onBackdropClick}
            onClose={onClose}
            fullWidth={props.fullWidth ? props.fullWidth : true}
            maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            PaperComponent={PaperComponent}
            disableEnforceFocus={props.disableEnforceFocus}
        >
            {/* Header */}
            {props.title && props.title !== '' && (
                <DialogTitle
                    className={classes.title}
                    sx={{ disableTypography: true }}
                    auto-id={'dialog-title'}
                >
                    <StyledIcon>{props.titleIcon}</StyledIcon>
                    {props.highRiskType && !props.titleIcon && (
                        <ReportProblemIcon
                            sx={{
                                fontSize: 20,
                                height: '100%',
                                color: colors.red500,
                                marginRight: '10px',
                                marginTop: '6px',
                            }}
                        />
                    )}
                    {props.mediumRiskType && !props.titleIcon && (
                        <GppMaybeIcon
                            sx={{
                                fontSize: 20,
                                height: '100%',
                                color: colors.orange100,
                                marginRight: '10px',
                                marginTop: '6px',
                            }}
                        />
                    )}
                    {props.infoRiskType && !props.titleIcon && (
                        <InfoIcon
                            sx={{
                                fontSize: 20,
                                height: '100%',
                                color: colors.gray100,
                                marginRight: '10px',
                                marginTop: '6px',
                            }}
                        />
                    )}
                    <span style={{ textAlign: 'center'}}>{'Insecure SSL/TLS Implementation'}</span>
                </DialogTitle>
            )}
            {props.closeIcon && (
                <CloseIcon
                    auto-id={'close'}
                    className={classes.closeBtn}
                    onClick={onClose}
                />
            )}

            {/* Content */}
            <DialogContent
                auto-id={'dialog-content'}
                className={clsx(classes.content, {
                    [classes.noScroll]: props.noScroll,
                })}
            >
                {props.children}
            </DialogContent>

            {/* Actions */}
            <DialogActions className={classes.actions}>
                {/*<Grid item container style={{ justifyContent: 'flex-start' }}>*/}
                {/*    {props.actionChildren}*/}
                {/*    {props.showRestoreToDefaults && (*/}
                {/*      <>*/}
                {/*        <CqButton marginRight={5} onClick={() => props.onRestoreDefaults()}>*/}
                {/*            <RestoreIcon />*/}
                {/*            <span>Restore To Defaults</span>*/}
                {/*        </CqButton>*/}
                {/*    {props.showExtraBtn === true && (*/}
                {/*        <CqButton*/}
                {/*        auto-id={'save'}*/}
                {/*        height={36}*/}
                {/*        variant="borderBlue"*/}
                {/*        onClick={onExtraBtnClick}*/}
                {/*        disabled={props.extraBtnDisabled === true}*/}
                {/*        text={props.extraBtnText ? props.extraBtnText : 'Show Defaults'}*/}
                {/*        />*/}
                {/*        )}*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</Grid>*/}
                <Grid item container style={{ justifyContent: 'flex-end' }}>
                    {props.showCloseBtn !== false && (
                        <CqButton
                            auto-id={'cancel'}
                            height={36}
                            onClick={onClose}
                            marginRight={5}
                            text={props.cancelBtnText ? props.cancelBtnText : 'Cancel'}
                        />
                    )}
                    {props.showExtraBtn === true && (
                      <CqButton
                        auto-id={'save'}
                        height={36}
                        marginRight={5}
                        variant="borderBlue"
                        onClick={onExtraBtnClick}
                        disabled={props.extraBtnDisabled === true}
                        text={props.extraBtnText ? props.extraBtnText : 'Show Defaults'}
                      />
                    )}
                    {(props.showSubmitBtn == null || props.showSubmitBtn === true) && (
                        <CqButton
                            auto-id={'submit'}
                            height={36}
                            variant="primary"
                            onClick={onSubmit}
                            disabled={props.submitBtnDisabled === true}
                            text={props.submitBtnText ? props.submitBtnText : 'Submit'}
                        />
                    )}
                </Grid>
            </DialogActions>

            <Backdrop
                className={classes.backdrop}
                open={backdrop}
                onClick={handleBackdropClose}
            >
                <CircularProgress color="info" />
            </Backdrop>
        </StyledCqDialog>
    );
};

FindingsDialog.prototype = {
    children: PropTypes.any,
    actionChildren: PropTypes.any,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    open: PropTypes.bool,
    backdrop: PropTypes.bool,
    showCloseBtn: PropTypes.bool,
    showExtraBtn: PropTypes.bool,
    showSubmitBtn: PropTypes.bool,
    alertType: PropTypes.bool,
    fullWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
    cancelBtnText: PropTypes.string,
    extraBtnText: PropTypes.string,
    submitBtnText: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onExtraBtnClick: PropTypes.func,
    onBackdropClose: PropTypes.func,
    onBackdropClick: PropTypes.func,
    blocking: PropTypes.bool,
    disableEnforceFocus: PropTypes.bool,
    titleIcon: PropTypes.element,
    showToggleButton: PropTypes.bool,
    toggleValue: PropTypes.bool
};

export default FindingsDialog;
