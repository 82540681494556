import { createState, useState } from '@hookstate/core';
import { localStore } from './localStore';

const store = localStore();

const getUserPreferences = () => {
  const userPreferences = {};
  userPreferences.pieChartView =
    store.get('userPreferences', 'pieChartView') === 'true' ? true : false;
  userPreferences.logscale =
    store.get('userPreferences', 'logscale') || 'linear';
    userPreferences.apiHosts =
      store.get('userPreferences', 'apiHosts') || '';
  userPreferences.barchartAcendingSort =
    store.get('userPreferences', 'barchartAcendingSort') === 'true'
      ? true
      : false;
  userPreferences.serverView =
    store.get('userPreferences', 'serverView') === 'true' ? false : true;
  userPreferences.serverDetailsVulExpand =
    store.get('userPreferences', 'serverDetailsVulExpand') === 'true'
      ? true
      : false;
  return userPreferences;
};

const initialState = createState({
  defaultRoute: 'Servers',
  userInfo: {},
  userPreferences: getUserPreferences(),
  notifications: [],
  sessionTimeout: false,
});
export const useGlobalState = () => useState(initialState);

const filters = createState({
  search: {},
  domainSearchName: '',
  domainStatus: {},
  domainCrawlTypes: {},
  showDeletedDomains: false,
  selectedDomain: '',
  timeRange: 'All',
  apiHosts: [{name: 'APIs only', selected: true}, {name: 'Non APIs only', selected: false}],
  crawlId: '',
  uapAwareness: { 'Spyder Crawls': true, CSPM: true, Sentinel: true },
  crawlHistoryFilters: {
    domains: [],
    discovery: [],
    timeRange: [],
  },
  serversFilters: {
    domains: [],
    appHostingProviders: {},
    severity: [],
    responseCode: [],
    apiServersFound: [],
    selectedDomain: '',
    crawlId: '',
    uapAwareness: [],
    timeRange: '',
    apiHosts: [],
  },
  notificationFilters: {
    domains: [],
    severity: [],
    timeRange: [],
  },
});

export const useFiltersState = () => useState(filters);
