import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '../../assets/theme';

const useStyles = makeStyles((theme) => ({
  checkboxStyles: {
    color: colors.lightGray,
    '&.Mui-checked': {
      color: colors.blue300,
    },
    '&.MuiCheckbox-indeterminate': {
      color: colors.blue300,
    },
  },
}));

const CqCheckbox = ({
  label = '',
  onChange = () => '',
  checked = false,
  name,
  indeterminate,
}) => {
  const classes = useStyles();
  const inputprops = { name: name };
  return (
    <FormControlLabel
      style={{ marginBottom: '0px' }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          color="primary"
          className={classes.checkboxStyles}
          inputProps={inputprops}
          indeterminate={indeterminate}
        />
      }
      label={label}
    />
  );
};

export default CqCheckbox;
