import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { enhancedTableStyles, colors } from '../../assets/theme';
import { DOMAIN_STATUS, SORT_ORDER } from '../../store/constants';
import StatusItem from './StatusItem';
import ExpanderIcon from './ExpanderIcon';
import DomainsMenu from './DomainsMenu';
import ExpandedRow from './ExpandedRow';
import { CrawlScheduleDialog } from './CrawlScheduleDialog';
import { useGlobalState } from '../../store/globalState';
import { validateEmail } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  domainRow: {
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div': {
      visibility: 'visible',
    },
  },
  rowDisabled: {
    opacity: 0.5,
  },
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerRowCellIcon: {
    color: colors.blueButtonBg,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    fontSize: '16px !important',
  },
  expandCell: {
    width: '18px',
    padding: '6px 12px !important',
    cursor: 'pointer',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
  },
  expandedRow: {
    '&:hover, &:hover > td': {
      borderTop: 'none !important',
      borderBottom: 'none !important',
      transition: 'none !important',

      '&:first-of-type': {
        borderLeft: `1px solid transparent !important`,
      },

      '&:last-of-type': {
        borderRight: `1px solid transparent !important`,
      },
    },
  },
  expandedRowLabel: {
    fontSize: '16px',
    marginBottom: '4px',
  },
  expandedRowDesc: {
    fontSize: '12px',
    width: '300px',
  },
  buttonItem: {
    width: 'fit-content',
    padding: '2px 10px',
    cursor: 'pointer',
    borderRadius: 16,
    textTransform: 'capitalize',
  },
  statusItem: {
    border: `1px solid ${colors.greenButtonBg}`,
    backgroundColor: colors.greenButtonBg,
    color: colors.darkest,
  },
  manualScheduleItem: {
    border: `1px solid ${colors.gray100}`,
    color: colors.gray100,
  },
  automaticScheduleItem: {
    padding: '2px 7px 2px 5px',
    border: `1px solid ${colors.greenButtonBg}`,
    color: colors.greenButtonBg,
    display: 'flex',
    alignItems: 'center',
  },
  manualSettingCell: {
    background: 'transparent !important',
  },
  manualSettingContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: '16px 20px',
    borderRadius: 5,
    border: `1px solid ${colors.gray400}`,
    width: '1000px',
  },
  selectMenuItem: {
    backgroundColor: colors.gray600,
    color: colors.gray100,
    border: 'none',

    '&:hover': {
      backgroundColor: colors.gray500,
    },
  },
  timezoneSelect: {
    '& .MuiSelect-root': {
      paddingLeft: '4px',
      borderRadius: 4,
      color: colors.white,
      background: colors.gray500,
      fontSize: 14,
    },
    '& .MuiSelect-icon': {
      color: colors.blueButtonBg,
    },
    '&:before, &:after': {
      border: 'none !important',
    },
  },
  timezoneSelectLabel: {
    color: colors.gray300,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: '4px',
  },
  deleteIcon: {
    fontSize: '20px !important',
    color: colors.blueButtonBg,
  },
  deleteIconContainer: {
    width: '36px',
    height: '36px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    visibility: 'hidden',

    '&:hover': {
      background: colors.gray500,
    },
  },
}));

const ExpandableTableRow = ({ children, domain, setConfigDialogStatus }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  const rowDisabled = domain.status === DOMAIN_STATUS.DISABLED;

  return (
    <>
      <TableRow
        className={clsx(classes.domainRow, {
          [classes.rowDisabled]: rowDisabled,
        })}
      >
        <TableCell className={classes.expandCell}>
          {domain.status !== DOMAIN_STATUS.DISABLED && (
            <ExpanderIcon
              status={domain.status}
              isExpanded={isExpanded}
              onClick={() =>
                setIsExpanded(
                  domain.status === DOMAIN_STATUS.ACTIVE && !isExpanded
                )
              }
              name={domain.name}
            />
          )}
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <ExpandedRow
          config={domain.config}
          setConfigDialogStatus={setConfigDialogStatus}
          domain={domain}
        />
      )}
    </>
  );
};

const CustomTableHeaderCell = ({ column, onUpdate }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.headerRowCell}>
      <Typography variant="subtitle2" onClick={onUpdate}>
        {column.name}
        {column.sortable && column.sortOrder === SORT_ORDER.ASCENDING && (
          <ArrowDownwardIcon className={classes.headerRowCellIcon} />
        )}
        {column.sortable && column.sortOrder === SORT_ORDER.DESCENDING && (
          <ArrowUpwardIcon className={classes.headerRowCellIcon} />
        )}
      </Typography>
    </TableCell>
  );
};

const DomainsTable = ({
  domains,
  timezones,
  columns,
  updateDomainConfig,
  onRemove,
}) => {
  const [columnList, setColumnList] = useState(columns);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [domainList, setDomainList] = useState(domains);
  const [crawlConfigDialog, setConfigDialogStatus] = useState({
    open: false,
    edit: { scheduler: false, proxy: false, scan: false },
    domain: {},
  });
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableSortIdx, setTableSortIdx] = useState(-1);
  const tableClasses = enhancedTableStyles();
  const classes = useStyles();

  const global = useGlobalState();
  const { notifications } = global.get();

  useEffect(() => {
    if (tableSortIdx >= 0) {
      setDomainList(
        domains.sort((a, b) => {
          const order =
            columnList[tableSortIdx].sortOrder === SORT_ORDER.ASCENDING
              ? 1
              : -1;
          return (
            order *
            a[columnList[tableSortIdx].id].localeCompare(
              b[columnList[tableSortIdx].id]
            )
          );
        })
      );
    } else {
      setDomainList(domains);
    }
  }, [domains]);

  const scheduleItemClass = (scheduleEnabled) => {
    return scheduleEnabled
      ? classes.automaticScheduleItem
      : classes.manualScheduleItem;
  };

  const handleSortOrderUpdate = (idx) => () => {
    if (tableSortIdx >= 0 && tableSortIdx !== idx) {
      columnList[tableSortIdx].sortOrder = undefined;
    }

    columnList[idx].sortOrder =
      columnList[idx].sortOrder === SORT_ORDER.ASCENDING
        ? SORT_ORDER.DESCENDING
        : SORT_ORDER.ASCENDING;
    setDomainList(
      domainList.sort((a, b) => {
        const order =
          columnList[idx].sortOrder === SORT_ORDER.ASCENDING ? 1 : -1;
        return (
          order * a[columnList[idx].id].localeCompare(b[columnList[idx].id])
        );
      })
    );
    setColumnList([...columnList]);
    setTableSortIdx(idx);
  };

  const handlePageChange = (event, page) => {
    setPageNum(page);
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    setPageSize(value);
    setPageNum(0);
  };

  const getPageData = () => {
    return domainList.slice(
      pageNum * pageSize,
      Math.min(domainList.length, (pageNum + 1) * pageSize)
    );
  };

  return (
    <TableContainer className={tableClasses.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="column-0"></TableCell>
            {columnList.map((c, i) => (
              <CustomTableHeaderCell
                key={`column-${i + 1}`}
                column={c}
                onUpdate={handleSortOrderUpdate(i)}
              />
            ))}
            <TableCell style={{ width: '60px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {getPageData().map((d, i) => (
            <ExpandableTableRow
              key={d.name}
              domain={d}
              timezones={timezones}
              updateDomainConfig={updateDomainConfig}
              crawlConfigDialog={crawlConfigDialog}
              setConfigDialogStatus={setConfigDialogStatus}
            >
              <TableCell>
                <Typography variant={'subtitle1'}>{d.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'subtitle1'}>
                  {validateEmail(d.requestedBy) ? d.requestedBy : 'Api-Client'}
                </Typography>
              </TableCell>
              <TableCell>
                <StatusItem status={d.status} />
              </TableCell>
              <TableCell>
                <Typography
                  variant={'subtitle1'}
                  className={clsx(
                    classes.buttonItem,
                    scheduleItemClass(d.config?.scheduleEnabled)
                  )}
                >
                  {d.config?.scheduleEnabled && (
                    <CachedIcon
                      sx={{
                        color: colors.greenButtonBg,
                        fontSize: 20,
                        marginRight: '4px',
                      }}
                    />
                  )}
                  {d.status === DOMAIN_STATUS.ACTIVE
                    ? d.config?.scheduleEnabled
                      ? 'Automatic'
                      : 'Manual'
                    : 'N/A'}
                </Typography>
              </TableCell>
              <TableCell>
                {d.status !== DOMAIN_STATUS.DISABLED && (
                  <DomainsMenu
                    setOpenCatalog={setOpenCatalog}
                    domain={d}
                    setConfigDialogStatus={setConfigDialogStatus}
                    global={global}
                    onRemove={onRemove}
                  />
                )}
              </TableCell>
            </ExpandableTableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={domainList.length}
        page={pageNum}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
      />

      <CrawlScheduleDialog
        open={crawlConfigDialog.open}
        timezones={timezones}
        setConfigDialogStatus={setConfigDialogStatus}
        crawlConfigDialog={crawlConfigDialog}
        updateDomainConfig={updateDomainConfig}
      />
    </TableContainer>
  );
};

ExpandableTableRow.prototype = {
  children: PropTypes.any,
  domain: PropTypes.object,
  timezones: PropTypes.array,
  updateDomainConfig: PropTypes.func,
};

DomainsTable.prototype = {
  domains: PropTypes.array,
  timezones: PropTypes.array,
  columns: PropTypes.array,
  updateDomainConfig: PropTypes.func,
  onRemove: PropTypes.func,
};

export default DomainsTable;
