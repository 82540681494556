import React, { useState } from 'react';

import {
  Grid,
  Typography,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material';

import moment from 'moment';
import CqChip from './common/CqChip';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { colors } from '../assets/theme';
import TextWithIcon from './common/TextWithIcon';

const StyledInputLabel = styled(InputLabel)({
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: '4px',
  color: colors.gray300,
});

const DataContentStyles = styled(Grid)({
  borderRadius: 5,
  justifyContent: 'center',
  alignItems: 'center',
});

const ContentStyles = styled(Typography)({
  verticalAlign: 'middle',
  display: 'inline-flex',
  cursor: 'pointer',
});

const StyledSelect = styled(Select)({
  '&.MuiOutlinedInput-root': {
    paddingTop: '6px',
    color: colors.white,
    background: colors.gray600,
    fontSize: 14,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: colors.blueButtonBg,
  },
  '&.MuiInputBase-root': {
    lineHeight: '1.1876em',
  },
});

const MenuItemStyles = styled(MenuItem)({
  display: 'flex',
  width: '300px',
  height: '48px',
  padding: '6px',
  fontSize: '14px',
});

const ChipStyles = styled('div')({
  marginLeft: 'auto',
  fontSize: '10px',
});

const StyledCircle = styled('div')(({ color }) => ({
  width: '14px',
  borderRadius: '30px',
  height: '14px',
  backgroundColor: color,
  marginRight: '6px',
}));

const Label = ({ selectedCrawl, crawlInProgress }) => {
  const crawlTime = selectedCrawl
    ? moment(selectedCrawl.timestamp).format('MMM Do YYYY - hh:mm a')
    : 'Not available';
  return (
    <DataContentStyles item container>
      <ContentStyles variant={'body2'}>
        <span auto-id={'latest-crawl-date'} style={{ marginRight: 5 }}>
          {crawlTime}
        </span>
      </ContentStyles>
      { crawlInProgress && <CqChip backgroundColor="#482902" borderRadius='16px'>
        <TextWithIcon>
          <HourglassTopIcon sx={{fill: '#F9CF9B'}} />
          <span style={{color : '#F9CF9B'}}>In Progress</span>
        </TextWithIcon>
      </CqChip> }
    </DataContentStyles>
  );
};

const SelectCrawl = ({ crawlHistory, setCrawlId, selectedCrawlId, disabled, crawlInProgress }) => {
  let selectedCrawl = crawlHistory.find((o) => o.crawlId === selectedCrawlId);
  let crawlData = [];
  const lastWeekCrawls = [];
  const previousCrawls = [];
  for (let i = 0; i < crawlHistory.length; i++) {
    if (
      crawlHistory[i]['submittedAt'] <=
      new Date().getTime() - 3600 * 24 * 14 * 1000
    ) {
      previousCrawls.push(crawlHistory[i]);
    } else if (
      crawlHistory[i]['submittedAt'] <=
      new Date().getTime() - 3600 * 24 * 7 * 1000
    ) {
      lastWeekCrawls.push(crawlHistory[i]);
    } else {
      crawlData.push(crawlHistory[i]);
    }
  }
  if (lastWeekCrawls.length > 0) {
    crawlData = [...crawlData, { title: 'Last Week' }, ...lastWeekCrawls];
  }
  if (previousCrawls.length > 0) {
    crawlData = [...crawlData, { title: 'Previous' }, ...previousCrawls];
  }

  return (
    <>
      <StyledInputLabel>Crawls</StyledInputLabel>
      <FormControl style={{ width: '100%' }} size="small">
        <StyledSelect
          value={selectedCrawlId}
          onChange={(e) => setCrawlId(e.target.value)}
          displayEmpty
          style={{ height: '35px' }}
          disabled={disabled}
          renderValue={(value) =>
            value !== '' ? (
              <Label selectedCrawl={selectedCrawl} crawlInProgress={crawlInProgress} />
            ) : (
              'Not Available'
            )
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              sx: {
                maxHeight: '350px',
                border: `1px solid ${colors.fontGray}`,
              },
            },
            style: {
              maxHeight: 500,
            },
          }}
        >
          {crawlData.map((v, i) => {
            let crawlDate;
            if (v.timestamp) {
              crawlDate = moment(v.timestamp).format('MMM Do YYYY - hh:mm a');
            }
            return v.title ? (
              <MenuItemStyles key={i.toString()} disabled>
                <Typography variant="caption">{v.title}</Typography>
              </MenuItemStyles>
            ) : (
              <MenuItemStyles
                key={i.toString()}
                selected={v.crawlId === selectedCrawlId}
                value={v.crawlId}
              >
                <>
                  {v && v.inprogress && (
                    <StyledCircle color={colors.orange200} />
                  )}
                  <Typography variant="body2">{crawlDate}</Typography>
                  <ChipStyles>
                    <CqChip color={colors.green200} borderRadius={16}>
                      Full Crawl
                    </CqChip>
                  </ChipStyles>
                </>
              </MenuItemStyles>
            );
          })}
        </StyledSelect>
      </FormControl>
    </>
  );
};

export default SelectCrawl;
