import { useEffect, useState } from 'react';
import { getRoutes } from './constants';
import moment from 'moment';
import wcmatch from 'wildcard-match';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
};

export const getRoutesForParent = (parent) => {
  return Object.values(getRoutes()).filter((r) => {
    return r.parent === parent;
  });
};

export const formatDateTime = (timestamp) => {
  return moment(timestamp).format('MMM DD, YYYY - hh:mm a');
};

export const formatDate = (timestamp) => {
  return moment(timestamp).format('MMM DD, YYYY');
};

export const nowDate = () => {
  return Date.now();
};

export const twoWeekAgoTimestamp = () => {
  let fortnightAway = Date.now() - 12096e5;
  return fortnightAway;
};

export const daysAgoTimestamp = (days) => {
  let fortnightAway = Date.now() - days * 24 * 3600 * 1000;
  return fortnightAway;
};

export const numericComparatorByProperty =
  (field, asc = true) =>
  (a, b) => {
    const sortResult = a[field] < b[field] ? 1 : -1;
    return !asc ? sortResult : -sortResult;
  };

export const numericListComparatorByProperty =
  (field, asc = true, property) =>
  (a, b) => {
    const sortResult = a[field][property] < b[field][property] ? 1 : -1;
    return !asc ? sortResult : -sortResult;
  };

export const numericSubfieldComparatorByProperty =
  (field, firstField, secondField, thirdField, asc = true) =>
  (a, b) => {
    const sortResult =
      a[field][firstField] < b[field][firstField]
        ? -1
        : a[field][firstField] > b[field][firstField]
        ? 1
        : a[field][secondField] < b[field][secondField]
        ? -1
        : a[field][secondField] > b[field][secondField]
        ? 1
        : a[field][thirdField] < b[field][thirdField]
        ? -1
        : a[field][thirdField] > b[field][thirdField]
        ? 1
        : 0;
    return asc ? sortResult : -sortResult;
  };

export const caseInsensitiveComparator = (a, b) =>
  a.toLowerCase().localeCompare(b.toLowerCase());

export const caseInsensitiveComparatorByField =
  (field, asc = true) =>
  (a, b) => {
    const aVal = a[field] || '';
    const bVal = b[field] || '';
    const sortResult = aVal.toLowerCase().localeCompare(bVal.toLowerCase());
    return asc ? sortResult : -sortResult;
  };

export const caseInsensitiveListComparatorByField =
  (field, asc = true) =>
  (a, b) => {
    const aVal = a[field][0] || '';
    const bVal = b[field][0] || '';
    const sortResult = aVal.toLowerCase().localeCompare(bVal.toLowerCase());
    return asc ? sortResult : -sortResult;
  };

export const idComparatorByField =
  (field, asc = true) =>
  (a, b) => {
    const aFirstDigitIdx = a[field].search(/\d/);
    const bFirstDigitIdx = b[field].search(/\d/);

    const aStr = a[field].substr(0, aFirstDigitIdx).toLowerCase();
    const bStr = b[field].substr(0, bFirstDigitIdx).toLowerCase();
    const aNum = parseInt(a[field].substr(aFirstDigitIdx), 10);
    const bNum = parseInt(b[field].substr(bFirstDigitIdx), 10);

    if (aStr === bStr) {
      const sortResult = aNum < bNum ? 1 : -1;
      return !asc ? sortResult : -sortResult;
    } else {
      const sortResult = aStr.localeCompare(bStr);
      return asc ? sortResult : -sortResult;
    }
  };

export const abbrFormat = (num) =>
  num
    ? Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 0,
      }).format(num)
    : 0;

export const wildCardRule = (wildcardstr) => wcmatch(wildcardstr, '.');

export const findingsSort = (v1, v2) => {
  let sortOrder =
    v1?.high < v2?.high
      ? -1
      : v1?.high > v2?.high
      ? 1
      : v1?.medium < v2?.medium
      ? -1
      : v1?.medium > v2?.medium
      ? 1
      : v1?.info < v2?.info
      ? -1
      : v1?.info > v2?.info
      ? 1
      : -1;

  return sortOrder;
};

export const groupFindings = (issues) => {
  const high = [];
  const medium = [];
  const low = [];
  for (const issue of issues) {
    if (issue.severity === 'HIGH') {
      high.push({ name: issue.name, severity: issue.severity });
    }
    if (issue.severity === 'MEDIUM') {
      medium.push({ name: issue.name, severity: issue.severity });
    }
    if (issue.severity === 'INFO') {
      low.push({ name: issue.name, severity: issue.severity });
    }
  }
  return { high, medium, low };
};

export const validateEmail = (email) => {
  // Regular expression pattern for validating email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const ISSUE_TYPE = {
  HOST: 'HOST',
  TLS: 'TLS',
  ENDPOINT: 'ENDPOINT',
};

export const ISSUE_TYPES = {};
