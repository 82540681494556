import React, { useState, useRef } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, TablePagination, Typography } from '@mui/material';

import { colors, enhancedTableStyles } from '../../../assets/theme';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpanderIcon from '../../domains/ExpanderIcon';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  Timeline,
} from '@mui/lab';
import { formatDate, formatDateTime } from '../../../lib/utils';
import CqChip from '../../../components/common/CqChip';
import SendIcon from '@mui/icons-material/Send';
import CqButton from '../../../components/common/CqButton';
import clsx from 'clsx';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles((theme) => ({
  headerRowCell: {
    padding: '16px !important',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  rowSelection: {
    cursor: 'pointer',
  },
  scrollTable: {
    height: '500px !important',
    overflowY: 'auto',
  },
  vulnerabilityRow: {
    '&:hover > td': {
      backgroundColor: colors.gray650,
    },
    '&:hover > td:last-of-type > div': {
      visibility: 'visible',
    },
  },
  rowDisabled: {
    opacity: 0.5,
  },
  headerRowCellIcon: {
    color: colors.blueButtonBg,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    fontSize: '16px !important',
  },
  expandCell: {
    width: '18px',
    padding: '6px 12px !important',
    cursor: 'pointer',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
  },
  expandedRow: {
    '&:hover, &:hover > td': {
      borderTop: 'none !important',
      borderBottom: 'none !important',
      transition: 'none !important',

      '&:first-of-type': {
        borderLeft: `1px solid transparent !important`,
      },

      '&:last-of-type': {
        borderRight: `1px solid transparent !important`,
      },
    },
  },
  expandedRowLabel: {
    fontSize: '16px',
    marginBottom: '4px',
  },
  expandedRowDesc: {
    fontSize: '12px',
    width: '300px',
  },
  timelineIcon: {
    backgroundColor: colors.magenta300,
    borderWidth: '10px',
  },
  timelineIconParent: {
    backgroundColor: colors.blue600,
    borderWidth: '10px',
  },
  timelineContentItem: {
    minHeight: 150,
  },
  severityLabel: {
    width: 'fit-content',
    padding: '2px 10px',
    borderRadius: 16,
    textTransform: 'capitalize',
    color: colors.almostBlack,
  },
  tableGradient: {
    '& tr': {
      '&:nth-of-type(2) td': {
        backgroundImage: `linear-gradient(${colors.gray800}, ${colors.gray700}, ${colors.gray700})`,
        '& h6': {
          color: colors.gray300,
        },
      },
      '&:last-of-type td': {
        backgroundImage: `linear-gradient(${colors.gray700}, ${colors.gray700}, ${colors.gray700} )`,
        '& h6': {
          color: colors.gray400,
        },
      },
    },
  },
  timelineData: {
    backgroundColor: colors.darkLabelText,
  },
  contentTable: {
    '& tr': {
      '& > td': {
        backgroundColor: `${colors.gray800} !important`,
      },
    },
  },
  showTextIcon: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: colors.blueButtonBg,
    display: 'inline-flex',
    fontSize: 16,
    lineHeight: '24px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  copyIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: colors.blueButtonBg,
    },
  },
  showScroll: {
    maxHeight: 350,
  },
  fillColor: {
    backgroundColor: colors.red200,
  },
}));

const columns = [
  {
    name: 'Response Organization',
  },
  {
    name: 'Response IP',
  },
  {
    name: 'Value',
  },
];

const ExpandableTableRow = ({ children, vulnerabilityData }) => {
  const classes = useStyles();
  const tableClasses = enhancedTableStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedText, setSelectedText] = useState('See More');
  const [generateRequest, setGenerateRequest] = useState(false);
  const textAreaRef = useRef(null);

  const handleCopyRequest = async (data) => {
    await navigator.clipboard.writeText(data);
  };

  return (
    <>
      <TableRow className={classes.vulnerabilityRow}>
        <TableCell>
          <ExpanderIcon
            status={'ACTIVE'}
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            name={vulnerabilityData.path}
          />
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow className={classes.expandedRow}>
          <TableCell colSpan={4} style={{ backgroundColor: 'transparent' }}>
            <Timeline>
              {vulnerabilityData?.dnsResponses?.map((res, i) => {
                return (
                  <>
                    <TimelineItem className={classes.timelineContentItem}>
                      <TimelineSeparator>
                        <TimelineDot className={classes.timelineIconParent}>
                          <SendIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Grid
                          style={{
                            border: `1px solid${colors.gray400}`,
                            borderRadius: 5,
                            padding: 10,
                            width: 650,
                          }}
                        >
                          <Grid style={{ display: 'flex' }}>
                            <Grid
                              xs={6}
                              item
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <Grid item container>
                                <Typography
                                  variant={'body1'}
                                  style={{ color: colors.gray200 }}
                                >
                                  Request Sent: &nbsp;
                                </Typography>
                                <Typography variant={'body1'}>
                                  {formatDateTime(res.timestamp?.timestamp)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              xs={4}
                              item
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <Grid item container>
                                <Typography
                                  variant={'body1'}
                                  style={{ color: colors.gray200 }}
                                >
                                  DNS Queries Received: &nbsp;
                                </Typography>
                                <Typography variant={'body1'}>
                                  {res.data.length}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item style={{ marginTop: 10 }}>
                            {!generateRequest && (
                              <CqButton
                                text={'Generate Request'}
                                height={26}
                                variant="borderBlue"
                                onClick={() => setGenerateRequest(true)}
                              />
                            )}
                            {generateRequest && (
                              <div
                                style={{
                                  maxHeight: 200,
                                  overflow: 'scroll',
                                  backgroundColor: colors.darkLabelText,
                                  border: `1px solid ${colors.gray650}`,
                                  borderRadius: 5,
                                  paddingTop: 20,
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <ContentCopyIcon
                                    onClick={() =>
                                      handleCopyRequest(
                                        vulnerabilityData.reqCommand
                                      )
                                    }
                                    className={classes.copyIcon}
                                  />
                                </div>
                                <SyntaxHighlighter
                                  language="curl"
                                  style={github}
                                  customStyle={{
                                    backgroundColor: 'transparent',
                                    color: colors.whitish,
                                  }}
                                >
                                  {vulnerabilityData.reqCommand}
                                </SyntaxHighlighter>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className={classes.timelineContentItem}>
                      <TimelineSeparator>
                        <TimelineDot className={classes.timelineIcon}>
                          <Typography variant={'h4'}>
                            X{res.data.length}
                          </Typography>
                        </TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent>
                        <Grid
                          style={{
                            border: `1px solid${colors.gray400}`,
                            borderRadius: 5,
                            padding: 10,
                            width: 650,
                          }}
                          className={classes.timelineData}
                        >
                          <Grid style={{ display: 'flex' }}>
                            <Grid
                              xs={10}
                              item
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <Typography>
                                {formatDateTime(res.timestamp?.timestamp)}
                              </Typography>
                            </Grid>
                            <Grid
                              xs={2}
                              item
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <Grid
                                item
                                container
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {vulnerabilityData.hasLong4j ? (
                                  <CqChip
                                    color={colors.red200}
                                    className={classes.fillColor}
                                    borderRadius={18}
                                  >
                                    <Typography
                                      style={{
                                        color: colors.almostBlack,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                      }}
                                    >
                                      LoNg4j
                                    </Typography>
                                  </CqChip>
                                ) : (
                                  <CqChip
                                    color={colors.red200}
                                    className={classes.fillColor}
                                    borderRadius={18}
                                  >
                                    <Typography
                                      style={{
                                        color: colors.almostBlack,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                      }}
                                    >
                                      Log4j
                                    </Typography>
                                  </CqChip>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <TableContainer
                            className={clsx(
                              tableClasses.root,
                              selectedText === 'See Less' && classes.showScroll
                            )}
                            style={{ marginTop: 10 }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns.map((c, i) => (
                                    <CustomTableHeaderCell
                                      key={`column-${i + 1}`}
                                      column={c}
                                    />
                                  ))}
                                </TableRow>
                              </TableHead>
                              {res.data.length > 3 ? (
                                <TableBody
                                  className={clsx(
                                    classes.contentTable,
                                    selectedText === 'See More'
                                      ? classes.tableGradient
                                      : ''
                                  )}
                                >
                                  {selectedText === 'See More' &&
                                    res.data.slice(0, 3).map((d, i) => {
                                      console.log(d);
                                      return (
                                        <TableRow key={i}>
                                          <TableCell>
                                            <Typography variant={'subtitle1'}>
                                              {d.pName}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography variant={'subtitle1'}>
                                              {d.ip}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant={'subtitle1'}
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {`${d.paramType}: ${d.paramName}: ${d.pValue}`}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  {selectedText !== 'See More' &&
                                    res.data.map((d, i) => {
                                      return (
                                        <TableRow key={i}>
                                          <TableCell>
                                            <Typography variant={'subtitle1'}>
                                              {d.pName}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography variant={'subtitle1'}>
                                              {d.ip}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant={'subtitle1'}
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {`${d.paramType}: ${d.paramName}: ${d.pValue}`}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              ) : (
                                <TableBody className={classes.contentTable}>
                                  {res.data.map((d, i) => {
                                    return (
                                      <TableRow key={i}>
                                        <TableCell>
                                          <Typography variant={'subtitle1'}>
                                            {d.pName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant={'subtitle1'}>
                                            {d.ip}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            variant={'subtitle1'}
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {`${d.paramType}: ${d.paramName}: ${d.pValue}`}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              )}
                            </Table>
                            {res.data.length > 3 ? (
                              selectedText === 'See More' ? (
                                <Grid
                                  style={{ textAlign: 'center', height: 30 }}
                                >
                                  <Typography
                                    className={classes.showTextIcon}
                                    onClick={() => setSelectedText('See Less')}
                                  >
                                    See More
                                    <ArrowDownwardIcon
                                      fontSize={'small'}
                                      style={{
                                        marginRight: '5px',
                                        marginTop: '3px',
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                              ) : (
                                <Grid
                                  style={{ textAlign: 'center', height: 30 }}
                                >
                                  <Typography
                                    className={classes.showTextIcon}
                                    onClick={() => setSelectedText('See More')}
                                  >
                                    See Less
                                    <ArrowUpwardIcon
                                      fontSize={'small'}
                                      style={{
                                        marginRight: '5px',
                                        marginTop: '3px',
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                              )
                            ) : (
                              ''
                            )}
                          </TableContainer>
                        </Grid>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                );
              })}
            </Timeline>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const CustomTableHeaderCell = ({ column }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.headerRowCell}>
      <Typography variant="subtitle2">{column.name}</Typography>
    </TableCell>
  );
};

const Log4jVulnerabilitiesTable = ({ columns, vulnerabilityServers }) => {
  const tableClasses = enhancedTableStyles();
  const classes = useStyles();
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (event, page) => {
    setPageNum(page);
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    setPageSize(value);
  };

  const servers = vulnerabilityServers?.reduce(
    (acc, current) => acc + parseInt(current.total),
    0
  );

  const getPageData = () => {
    return vulnerabilityServers?.slice(
      pageNum * pageSize,
      Math.min(vulnerabilityServers?.length, (pageNum + 1) * pageSize)
    );
  };

  return (
    <Grid
      style={{ marginTop: 20, width: '100%' }}
      className={servers > 10 ? classes.scrollTable : ''}
    >
      <TableContainer className={tableClasses.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="column-0"></TableCell>
              {columns.map((c, i) => (
                <CustomTableHeaderCell key={`column-${i + 1}`} column={c} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getPageData()?.map((d, i) => {
              return (
                <ExpandableTableRow
                  key={`${d.path}-${i}`}
                  vulnerabilityData={d}
                >
                  <TableCell>
                    <Typography variant={'subtitle1'}>
                      <Link
                        target={'_blank'}
                        href={`https://www.${d.path}/`}
                        style={{
                          color: colors.blueButtonBg,
                          cursor: 'pointer',
                        }}
                      >
                        {d.path}
                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'subtitle1'}>
                      {d.method.toUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid item container>
                      {d.hasLong4j ? (
                        <CqChip color={colors.red200} borderRadius={18}>
                          <Typography
                            style={{
                              color: colors.red200,
                              fontSize: 16,
                              fontWeight: 400,
                              lineHeight: '24px',
                            }}
                          >
                            LoNg4j
                          </Typography>
                        </CqChip>
                      ) : (
                        <CqChip color={colors.red200} borderRadius={18}>
                          <Typography
                            style={{
                              color: colors.red200,
                              fontSize: 16,
                              fontWeight: 400,
                              lineHeight: '24px',
                            }}
                          >
                            Log4j
                          </Typography>
                        </CqChip>
                      )}
                    </Grid>
                  </TableCell>
                </ExpandableTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={vulnerabilityServers?.length}
          page={pageNum}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
        />
      </TableContainer>
    </Grid>
  );
};

export default Log4jVulnerabilitiesTable;
