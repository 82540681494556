import { submitCrawlRequestQuery } from './graphQueries';
import { graphqlMutation } from '../services/graphql';

export const newCrawl = async (domain, log4jScan) => {
  const params = {
    domain: domain,
    options: { log4jScan: log4jScan ? true : false }
  };
  try {
    return await graphqlMutation(submitCrawlRequestQuery, params);
  } catch (error) {
    console.log(`graphql query error`, error);
  }
};
