import React, { useEffect, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonGroup, InputLabel, Grid } from '@mui/material';
import { colors } from '../../../assets/theme';

import { WrapperContext } from '../../../components/wrapper/wrapperContext';
import CqButton from '../../../components/common/CqButton';
import FilterItem from '../../../components/FilterItem';
import { useFiltersState } from '../../../store/globalState';
import { isEmpty } from 'lodash';
import SelectCrawl from '../../../components/SelectCrawl';
import Button from '@mui/material/Button';
import moment from 'moment';
import { timeMapping } from '../../dashboard/constants';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: colors.gray700,
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    gap: '10px',
  },
  filterItemContainer: {
    display: 'flex',
    gap: '5px'
  },
  filterItem: {},
  filterButtonContainer: {
    marginTop: '35px',
    marginLeft: '20px',
  },
  filterButtonContainerRight: {
    marginLeft: 'auto',
    marginTop: '35px',
    marginRight: '5px',
  },
  inputRoot: {
    margin: '10px 0px 10px 10px',
    fontSize: 32,
    fontWeight: 600,
    color: colors.white,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 30,
    '&.MuiSelect-select': {
      paddingRight: 40,
    },
  },
  domainSelection: {
    '& .MuiSelect-icon': {
      fontSize: 36,
      top: 'calc(50% - 17px)',
    },
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: colors.gray600,
      color: 'white',
    },
  },
  dateContent: {
    backgroundColor: colors.gray650,
    borderRadius: 5,
    margin: '20px 0px 20px 0px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  triggerPopoverPaper: {
    minWidth: 278,
    maxWidth: 278,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    backgroundColor: colors.almostBlack,
    border: `1px solid ${colors.white}`,
  },
  triggerPopoverRoot: {
    maxHeight: '50vh',
    marginTop: theme.spacing(1),
    pointerEvents: 'none',
  },
  menuPaper: {
    maxHeight: 200,
    border: `1px solid ${colors.white}`,
  },
  subTitleContainer: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  selectedButtonColor: {
    backgroundColor: colors.blueButtonBg,
  },
  unselectedButtonColor: {
    backgroundColor: colors.gray650,
  },
  inpLabel: {
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: '4px',
    color: colors.gray300,
    marginTop: 10,
  },
}));

const Filters = ({
  setFilters,
  retrieveHostingProviders,
  crawlHistory,
  setCrawlHistory,
  filtersState,
  retrieveCrawlHistory,
  serverView,
  setSearchHostStr,
  loading
}) => {
  const classes = useStyles();
  const contextData = useContext(WrapperContext)
  const activeDomains = contextData.activeDomains;
  const [tempSelectedFilters, setTempSelectedFilters] = useState(filtersState);
  const [searchFocus, setSearchFocus] = useState(false);
  const timeRangeFilter =
    tempSelectedFilters?.serversFilters?.timeRange || 'All';
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRangeFilter === '' ? 'All' : timeRangeFilter
  );

  useEffect(() => {
    setTempSelectedFilters(filtersState);
  }, [filtersState]);

  let filteredCrawlHistory = crawlHistory;
  if (selectedTimeRange !== 'All') {
    const dateFrom = moment(
      Date.now() - timeMapping[selectedTimeRange] * 24 * 3600 * 1000
    ).valueOf();
    filteredCrawlHistory = crawlHistory.filter((i) => {
      if (i.submittedAt >= dateFrom) return i;
    });
  }

  const selectMultiple = async (options, pageKey, stateKey) => {
    let tempFilters = { ...tempSelectedFilters };
    tempFilters[stateKey] = options;
    if (stateKey === 'domains') {
      const hostingProviders = await retrieveHostingProviders(options);
      tempFilters.appHostingProviders = hostingProviders;
    }
    setTempSelectedFilters(tempFilters);
  };

  const selectSingle = async (option, pageKey, stateKey) => {
    if (option) {
      let tempFilters = { ...tempSelectedFilters };
      tempFilters[stateKey] = option;
      tempFilters = { ...tempFilters, selectedDomain: option };
      if (option !=='' && option !== 'All') {
        const hostingProviders = await retrieveHostingProviders(option);
        tempFilters = { ...tempFilters, appHostingProviders: hostingProviders };
        await retrieveCrawlHistory(option, setCrawlHistory);
      } else {
        const domains =  activeDomains.map(val => val.domain)
        const hostingProviders = await retrieveHostingProviders(option, domains);
        tempFilters = { ...tempFilters, appHostingProviders: hostingProviders };
      }
      tempFilters = { ...tempFilters, crawlId: null };
      setTempSelectedFilters(tempFilters);
      setSearchHostStr('')
    }
  };

  const domainSearchFocus = (val) => {
    setSearchFocus(val);
  };

  const selectCrawlId = (crawlId) => {
    let tempFilters = { ...tempSelectedFilters };
    tempFilters['crawlId'] = crawlId;
    setTempSelectedFilters({ ...tempSelectedFilters, crawlId: crawlId });
  };

  const handleTimeRange = (time) => {
    let tempFilters = { ...tempSelectedFilters };
    tempFilters.timeRange = time;
    setSelectedTimeRange(time);
    setTempSelectedFilters({ ...tempFilters, crawlId: '' });
  };

  useEffect(() => {
    let selectedCrawlId = '';
    if (
      tempSelectedFilters &&
      tempSelectedFilters['crawlId'] &&
      tempSelectedFilters['crawlId'] !== ''
    ) {
      selectedCrawlId = tempSelectedFilters['crawlId'];
    } else if (crawlHistory.length > 0) {
      selectedCrawlId = crawlHistory[0].crawlId;
    }
    setTempSelectedFilters((obj) => ({ ...obj, crawlId: selectedCrawlId }));
  }, [tempSelectedFilters.timeRange, tempSelectedFilters.crawlId]);

  return !loading && (
      <div className={classes.filterContainer}>
        <div>
          <div className={classes.filterItemContainer}>
            <div className={classes.filterItem}>
              <FilterItem
                label="Domains"
                options={filtersState.domains || []}
                selectedValue={tempSelectedFilters.selectedDomain}
                multiChoice={searchFocus}
                showSearch={true}
                showAll={false}
                pageKey={'serversFilters'}
                stateKey={'selectedDomain'}
                selectOptions={selectSingle}
                searchLabel="Domain"
                singleSelect={true}
                domainFocus={(val) => domainSearchFocus(val)}
              />
            </div>
            {tempSelectedFilters.selectedDomain !== 'All' && <div className={classes.filterItem}>
              <FilterItem
                label="TimeFrame"
                options={[{name: '24h'}, {name: '3d'}, {name: '1w'}, {name: 'All'}]}
                selectedValue={tempSelectedFilters.timeRange}
                pageKey={'serversFilters'}
                stateKey={'timeRange'}
                selectOptions={handleTimeRange}
                singleSelect={true}
              />
            </div>}
            {tempSelectedFilters.selectedDomain !== 'All' && <div className={classes.filterItem}>
              <Grid
                sx={{
                  marginLeft: '12px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '96%',
                }}
              >
                <SelectCrawl
                  crawlHistory={filteredCrawlHistory}
                  setCrawlId={selectCrawlId}
                  selectedCrawlId={tempSelectedFilters.crawlId || ''}
                />
              </Grid>
            </div>}
            {serverView && (
              <div className={classes.filterItem}>
                <FilterItem
                  label="App Hosting Providers"
                  options={tempSelectedFilters.appHostingProviders || []}
                  multiChoice={true}
                  showSearch={false}
                  pageKey={'serversFilters'}
                  stateKey={'appHostingProviders'}
                  selectOptions={selectMultiple}
                  placeholder="All"
                  showAll={true}
                  headers={true}
                  searchLabel="App Hosting Providers"
                  width={100}
                />
              </div>
            )}
            <div className={classes.filterItem}>
              <FilterItem
                label="API Gateways"
                options={tempSelectedFilters.apiGateways || []}
                multiChoice={true}
                showSearch={false}
                pageKey={'serversFilters'}
                stateKey={'apiGateways'}
                selectOptions={selectMultiple}
                placeholder="All"
                showAll={true}
                width={150}
              />
            </div>
            <div className={classes.filterItem}>
              <FilterItem
                label="Findings"
                options={tempSelectedFilters.severity || []}
                multiChoice={true}
                showSearch={false}
                pageKey={'serversFilters'}
                stateKey={'severity'}
                selectOptions={selectMultiple}
                placeholder="All"
                showAll={true}
              />
            </div>
            <div className={classes.filterItem}>
              <FilterItem
                label="Response Code"
                options={tempSelectedFilters.responseCode || []}
                multiChoice={true}
                showSearch={false}
                pageKey={'serversFilters'}
                stateKey={'responseCode'}
                selectOptions={selectMultiple}
                showAll={true}
                placeholder="All"
                width={50}
              />
            </div>
            <div className={classes.filterItem}>
              <FilterItem
                label="API Hosts"
                options={tempSelectedFilters.apiHosts}
                multiChoice={true}
                showSearch={false}
                pageKey={'serversFilters'}
                stateKey={'apiHosts'}
                selectOptions={selectMultiple}
                placeholder="All"
                showAll={true}
                width={150}
              />
            </div>
          </div>
        </div>
        <div className={classes.filterButtonContainer}>
          <CqButton
            text={'Apply'}
            height={26}
            onClick={() => setFilters({ ...tempSelectedFilters })}
            variant={'primary'}
          ></CqButton>
        </div>
      </div>
  );
};

export default Filters;
