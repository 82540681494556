import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
} from '@mui/material';
import { none } from '@hookstate/core';
import CqDialog from '../../components/common/CqDialog';
import CqLoading from '../../components/common/CqLoading';
import CqButton from '../../components/common/CqButton';
import SpSnackBar from '../../components/SpSnackBar';
import { NewDomainDialog } from '../../components/NewDomainDialog'
import FilterBar from './FilterBar';
import DomainsTable from './DomainsTable';
import { graphqlQuery, graphqlMutation } from '../../services/graphql';
import {
  getDomainsQuery,
  updateDomainConfigMutation,
  removeDomainQuery,
} from './domainGraphQL';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { colors, cqDialogInputStyles } from '../../assets/theme';
import { useFiltersState, useGlobalState } from '../../store/globalState';
import { DOMAIN_STATUS, CRAWL_SCHEDULE } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  removeDialogInfo: {
    color: colors.white,
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '20px',
  },
}));

const DomainsPage = () => {
  const classes = useStyles();
  const dialogClasses = cqDialogInputStyles();

  const global = useGlobalState();
  const { notifications } = global.get();

  const filters = useFiltersState();
  const {
    domainSearchName,
    showDeletedDomains,
    domainStatus,
    domainCrawlTypes,
  } = filters.get();

  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState();
  const [timezones, setTimezones] = useState();

  const [newDomainRequestDialogOpened, setNewDomainRequestDialogOpened] =
  useState(false);
  const [removeDomainConfirmDialogOpened, setRemoveDomainConfirmDialogOpened] =
    useState(false);

  const [removeDomainName, setRemoveDomainName] = useState('');
  const [dialogInProgress, setDialogInProgress] = useState(false);

  const tableHeaderColumns = [
    {
      name: 'Domain Name',
      id: 'name',
      sortable: true,
    },
    {
      name: 'Requested By',
      id: 'requestedBy',
      sortable: true,
    },
    {
      name: 'Status',
      help: false,
    },
    {
      name: 'Crawl Schedule',
    },
  ];

  useEffect(() => {
    retrieveData();
  }, []);

  const retrieveData = async () => {
    setLoading(true);
    try {
      let response = await graphqlQuery(getDomainsQuery, {}, global);
      const domainData = [];
      const resDomains = response.data.domain.list ?? [];
      const resTimezones = response.data.timezones ?? [];

      resDomains.forEach((domain) => {
        domainData.push({
          ...domain,
          name: domain.domain,
        });
      });

      setDomains(domainData);
      setTimezones(resTimezones);
    } catch (error) {
      console.log(`graphql query error`, error);
    } finally {
      setLoading(false);
    }
  };

  const updateDomainConfig = async (domain, updatedConfig, message) => {
    try {
      let response = await graphqlMutation(updateDomainConfigMutation, {
        domain,
        config: updatedConfig,
      });
      const updatedDomain = domains.find((d) => d.name === domain);

      let result = response.data.domain.update;
      if (result.success === false) {
        global.notifications.merge([
          {
            id: `domains_${domain}`,
            message: result.message,
            category: 'warning',
          },
        ]);
      } else {
        if (updatedDomain) {
          const domainDetails = result.domain;
          updatedDomain.config = domainDetails ? domainDetails.config : {};
        }
        setDomains([...domains]);

        global.notifications.merge([
          {
            id: `domains_${domain}`,
            message: message,
            category: 'success',
          },
        ]);
      }
      return 'Success';
    } catch (error) {
      console.log(`graphql query error`, error);

      global.notifications.merge([
        {
          id: '1',
          message: `Error whilte updating domain schedule!`,
          category: 'warning',
        },
      ]);
      return;
    }
  };

  const removeDomain = async () => {
    setDialogInProgress(true);

    const params = {
      domain: removeDomainName,
    };

    try {
      const response = await graphqlMutation(removeDomainQuery, params);
      let { success, message, domains = [] } = response.data.domain.remove;
      if (success === false) {
        global.notifications.merge([
          {
            id: `domains_${removeDomainName}`,
            message: message,
            category: 'warning',
          },
        ]);
      } else {
        global.notifications.merge([
          {
            id: `domains_${removeDomainName}`,
            message: message,
            category: 'success',
          },
        ]);
      }
      setRemoveDomainConfirmDialogOpened(false);
    } catch (error) {
      console.log(`graphql query error`, error);
      global.notifications.merge([
        {
          id: `domains_${newDomainName}`,
          message: `Error while removing domain ${removeDomainName}!`,
          category: 'warning',
        },
      ]);
    } finally {
      retrieveData();
      setDialogInProgress(false);
    }
  };

  const handleRemoveDomain = (domain) => {
    setRemoveDomainName(domain);
    setRemoveDomainConfirmDialogOpened(true);
  };

  const openNewRequestDialog = () => {
    setNewDomainRequestDialogOpened(true);
  };

  const deleteNotification = (id) => {
    const deletedItem = notifications.findIndex((item) => item.id === id);
    global.notifications[deletedItem].set(none);
  };

  const getFilteredDomains = () => {
    const result = domains.filter((d) => {
      const schedule =
        d.status === DOMAIN_STATUS.ACTIVE
          ? d.config?.scheduleEnabled
            ? 'Automatic'
            : 'Manual'
          : 'N / A';
      let flag = true;
      if (!showDeletedDomains) {
        flag = d.status === DOMAIN_STATUS.REMOVED ? false : true;
      }
      if (Object.keys(domainStatus).length && !domainStatus[d.status]) {
        flag = false;
      }
      if (Object.keys(domainCrawlTypes).length && !domainCrawlTypes[schedule]) {
        flag = false;
      }
      if (domainSearchName && !d.name.includes(domainSearchName)) {
        flag = false;
      }
      return flag;
    });

    return result;
  };

  return (
    <div className={classes.pageContent}>
      {notifications?.length > 0 && (
        <SpSnackBar
          notifications={notifications}
          isOpen={notifications.length > 0}
          onNotificationDelete={deleteNotification}
          autoHideDuration={2000}
        />
      )}
      <Grid
        item
        container
        className={classes.pageHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4">Domains</Typography>
        </Grid>

        <Grid item>
          <CqButton
            text={'Request New Domain'}
            height={26}
            variant="primary"
            onClick={openNewRequestDialog}
          >
            <AddCircleOutlineIcon
              sx={{
                color: colors.gray700,
                fontSize: 16,
                marginRight: '6px',
              }}
            />
          </CqButton>
        </Grid>
      </Grid>

      <FilterBar />

      {loading && <CqLoading />}
      {!loading && domains && (
        <>
          <DomainsTable
            domains={getFilteredDomains()}
            timezones={timezones}
            columns={tableHeaderColumns}
            updateDomainConfig={updateDomainConfig}
            onRemove={handleRemoveDomain}
          />
        </>
      )}
      
      <NewDomainDialog
        newDomainRequestDialogOpened={newDomainRequestDialogOpened}
        setNewDomainRequestDialogOpened={setNewDomainRequestDialogOpened}
        global={global}
        retrieveData={retrieveData}
      />

      {/* Remove Domain Dialog */}
      <CqDialog
        title={'Are you sure you want to delete this domain?'}
        open={removeDomainConfirmDialogOpened}
        onClose={() => setRemoveDomainConfirmDialogOpened(false)}
        onSubmit={removeDomain}
        submitBtnText={'Delete'}
        maxWidth="sm"
        backdrop={dialogInProgress}
        alertType={true}
        closeIcon={true}
      >
        <Typography className={classes.removeDialogInfo}>
          Deleting the domain will result in loss of access. You will need to
          submit a new domain request if you wish to regain access.
        </Typography>
      </CqDialog>
    </div>
  );
};

export default DomainsPage;
