import React, {useState} from 'react';
import { Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography, Checkbox, Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../../assets/theme';
import ExpanderIcon from "../../domains/ExpanderIcon";
import CqButton from "../../../components/common/CqButton";
import { getRoutes } from '../../../lib/constants';

const useStyles = makeStyles((theme) => ({
    addLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    noData: {
        marginTop: '250px',
        width: '80%',
        textAlign: 'center',
    },
    root: {
        maxHeight: 485,
        '& table': {
            border: `1px solid ${colors.gray800}`,
            borderCollapse: 'collapse',
            borderSpacing: 0,

            '& thead > tr th': {
                backgroundColor: colors.gray800,
                padding: '0px 0px 0px 16px',
                '&:nth-of-type(2)': {
                    padding: '0px 0px 0px 0px',
                },
            },


            '& tbody tr': {
                borderBottom: `1px solid ${colors.gray650}`,
                '& td': {
                    backgroundColor: colors.darkLabelText,
                    padding: '0px 0px 0px 16px',
                    '&:nth-of-type(2)': {
                        padding: '60x 0px 0px 0px',
                    },
                    '& > *': {
                        fontSize: '13px',
                    },
                },
            },
        },
    },
    caption: {
        justifyContent: 'flex-end',
        position: 'sticky',
        padding: 20,
        backgroundColor: colors.darkGray,
    },
}));

const routes = getRoutes();

const ServersTable = ({ serversData, columns, handleUpdateSelection, handleTableAllSelectClick, hostTypeFilter, selectedOtherServers, handleOtherServersSelection, otherServersCount, routeToServerEndpoints, allDomainsReport  }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);
  const otherServersTotal = hostTypeFilter.allFilter ? otherServersCount.countAttempted : hostTypeFilter.nonApiFilter ? otherServersCount.nonApiCount : otherServersCount.count
    return (
        <>
            <TableContainer className={classes.root}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width:'20px'}}>
                            <Checkbox auto-id={'servers-header-check-box'}
                                style={{height: 20}}
                                checked={serversData.filter((i) => i.selected === false).length <= 0}
                                onChange={event => handleTableAllSelectClick(event)}
                            />
                        </TableCell>
                        { serversData.length > 9 &&
                            <TableCell style={{ width: 10 }} key={'column-1'}>
                            </TableCell>
                        }
                        { columns.map((c, i) =>
                            <TableCell key={`column-${i}`}>
                                <Typography auto-id={`servers-header-${c.replace(' ','-')}`} variant='subtitle2'>{c}</Typography>
                            </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { serversData.map((c, i) =>
                        <TableRow key={i}>
                            <TableCell>
                                <Checkbox
                                    auto-id={`server-${c.name}-checkbox`}
                                    checked={c.selected}
                                    onClick={(event) => handleUpdateSelection(event, c)}
                                />
                            </TableCell>
                            { serversData.length > 9 &&
                                <TableCell>
                                    {c.name === 'Others' &&
                                        <ExpanderIcon style={{padding:'0px 8px 0px 0px'}}
                                            isExpanded={isExpanded}
                                            status={true}
                                            height={20}
                                            width={10}
                                            onClick={() => setIsExpanded(!isExpanded)}
                                            name={c.name}
                                        />
                                    }
                                </TableCell>
                            }
                            <TableCell>
                                <Grid item container>
                                    <Grid xs={1} item style={{ marginTop: 10, width: 30, height: 7,backgroundColor: colors.charts[i], border: `0.5px solid ${colors.white}`, borderRadius: 4 }}>

                                    </Grid>
                                    <Grid item>
                                        <Typography auto-id={`${c.name.replace(' ','-')}-server`} variant={'subtitle2'} style={{ marginLeft: 10 }}>
                                            {c.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                    <Typography auto-id={`${c.name.replace(' ','-')}-server-count`} variant={'subtitle2'}>
                                        {c.name === 'Others' ? otherServersTotal :  hostTypeFilter.allFilter ? c.countAttempted : hostTypeFilter.nonApiFilter ? c.nonApiCount : c.count}
                                    </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    { isExpanded &&
                        <>
                                { selectedOtherServers.map((o, idx) =>
                                    <TableRow key={idx}>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                style ={{
                                                    color: colors.blueButtonBg,
                                                    height: 20
                                                }}
                                                checked={o.selected}
                                                onClick={(event) => handleOtherServersSelection(event, o)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>
                                                {o.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>
                                                {hostTypeFilter.allFilter ? o.countAttempted : hostTypeFilter.nonApiFilter ? o.nonApiCount : o.count}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
            <Grid item container className={classes.caption}>
            {!allDomainsReport && <Grid auto-id={'view-api-servers-details-btn'} item>
                <CqButton
                    text={'View Selected Servers'}
                    height={26}
                    variant={'borderBlue'}
                    onClick={() => routeToServerEndpoints(routes.discovery.path, { description: 'servers' })}
                >
                </CqButton>
            </Grid>}
        </Grid>
        </>
    );
};

export default ServersTable;
