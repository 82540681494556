import React, { useState, useEffect } from 'react';
import {Grid, List, ListItem, Tooltip, Typography} from '@mui/material';

import {colors} from '../../../assets/theme';
import Link from "@mui/material/Link";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import makeStyles from '@mui/styles/makeStyles';
import certImg from '../../../assets/img/cert-default.svg';
import certRedImg from '../../../assets/img/cert-red.svg';
import SvgIcon from "@mui/material/SvgIcon";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Divider from "@mui/material/Divider";
import {formatDate} from "../../../lib/utils";

const useStyles = makeStyles((theme) => ({
    certificatesContainer: {
      backgroundColor: colors.gray700,
        padding: 10,
        marginTop: 10,
    },
    treeTitle: {
        color: colors.gray300,
        fontFamily: 'Noto Sans',
        fontSize: 12,
        fontWeight: 500,
    },
    popMenuListItem: {
        cursor: 'pointer',
        height: 35,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&.Mui-selected': {
            backgroundColor: colors.gray600,
            '&:hover': {
                backgroundColor: colors.gray600,
            },
        },
        '&:hover': {
            backgroundColor: colors.gray600,
        },
    },
    popMenuListItemText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: colors.gray50,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    popMenuList: {
        padding: '4px 0',
        width: '100%',
    },
    severityLowLabel: {
        width: 'fit-content',
        height: 30,
        padding: '5px 5px 5px 5px',
        borderRadius: 18,
        textTransform: 'capitalize',
        color: colors.red200,
        border: `1px solid ${colors.red200}`,
        alignContent: 'center',
        marginLeft: 8,
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    borderBottom: `2px solid ${colors.gray500}`,
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: colors.gray100 }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: colors.gray700,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        fontFamily: 'Noto Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: colors.almostBlack,
}));

const SslEndpointsDetails = ({ vulnerabilityServers, expanded, selectedServerData }) => {
    const classes = useStyles();
    const [ selectedCertIndex, setSelectedCertIndex ] = useState(0);
    const [selectedCert, setSelectedCert] = useState(selectedServerData?.tlsInfo?.certs?.[0] || {});
    const [showTooltip, setShowTooltip] = useState(false);

    const expandSection = {
      des: false,
      risk: false,
      ref: false,
      rec: false
    }
    const [expand, setExpand] = useState(expandSection)
    useEffect(() => {
        setExpand({
          des: expanded,
          risk: expanded,
          ref: expanded,
          rec: expanded
        })
    }, [expanded])
    const handleChange = (panel) => () => {
      setExpand({...expand, [panel]: !expand[panel]})
    }

    const setIsShown = (e) => {
        if (e.target.scrollWidth > e.target.offsetWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    };

    return (
        <>
            <Grid style={{ marginTop: 20 }}>
                <Accordion expanded={expand.des} onChange={handleChange('des')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  variant={'body2'}>
                           Transport Layer Security (TLS), the successor to Secure Socket Layer (SSL), is a network protocol that encrypt
                            communications between TLS servers (e.g., websites) and TLS clients (e.g., web browsers).

                            SSL/TLS is established with a handshake that determines what cipher suite and master secret
                            can be used, and then uses digital certificates to make a connection between a client and server.
                            Using the agreed-upon cipher suite, SSL/TLS uses cryptography to encode data and hashing algorithms to
                            maintain message integrity.

                            An insecure SSL/TLS implementation finding indicates that the SSL certificate of these servers have expired
                            or do not match the site subdomain name.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.risk} onChange={handleChange('risk')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Risk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            Having weaknesses in the SSL/TLS implementation can affect your organization in several ways such as the
                            following:
                        </Typography>
                        <List>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Misuse or exfiltration of data via insecure protocols
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                   MITM (Man-in-the-middle) attacks
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Loss of customer trust or brand reputation
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Display of warnings by browsers connecting to insecure servers
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.rec} onChange={handleChange('rec')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>Recommendation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={'body2'}>
                            To harden your SSL/TLS implementation, Cequence recommends the following steps:
                        </Typography>
                        <List>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Turn off support for old or vulnerable versions of SSL/TLS protocols.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Turn off insecure cipher suites and establish a priority of cipher suites based on their security.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    Ensure that the certificates have not expired and match the subdomains of the servers.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expand.ref} onChange={handleChange('ref')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant={'subtitle1'} style={{ color: colors.gray100 }}>References</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <CircleIcon sx={{ color: colors.white, fontSize: 6, marginRight: 2 }} />
                                <Typography variant={'body2'}>
                                    SSL/TLS Best Practices:
                                    <Link target={'_blank'} href={'https://www.ssl.com/guide/ssl-best-practices/'} style={{ color: colors.blueButtonBg, marginLeft: 5, cursor: 'pointer' }}>
                                        https://www.ssl.com/guide/ssl-best-practises/
                                        <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }}/>
                                    </Link>
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
                <Grid container item>
                    <Typography>
                        Details
                    </Typography>
                </Grid>
               <Grid container item className={classes.certificatesContainer}>
                   <Grid item container xs={6} style={{ height: 100 }}>
                    <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                        <Typography className={classes.treeTitle}>CERTIFICATE CHAIN</Typography>
                    </Grid>
                    <Grid item container>
                       <List className={classes.popMenuList}>
                       {selectedServerData?.tlsInfo?.certs?.map((c, index) => {
                            const marginChildren = (index * 32) + 10;
                               return (
                                   <ListItem
                                       key={`low-${index}`}
                                       onClick={() => {setSelectedCertIndex(index); setSelectedCert(c)}}
                                       className={classes.popMenuListItem}
                                       style={{ paddingLeft: marginChildren}}
                                       selected={selectedCertIndex === index}
                                   >
                                       <Grid container wrap="nowrap" style={{ width: `calc(100% - ${marginChildren}px)`, alignItems: 'center' }}>
                                           <Grid item>
                                               {c?.issues?.length > 0 ?
                                                   <SvgIcon component={certRedImg} style={{ marginTop: 15 }} /> :
                                                   <SvgIcon component={certImg} style={{ marginTop: 15 }} />
                                               }
                                           </Grid>
                                           <Grid item style={{ width: '90%', alignContent: 'center' }}>
                                                   <Tooltip title={c.subject} disableHoverListener={!showTooltip}>
                                                       <Typography
                                                           noWrap
                                                           style={{
                                                               color: colors.gray100,
                                                               marginLeft: 10,
                                                               whiteSpace: 'nowrap',
                                                               textOverflow: 'ellipses',
                                                               overflow: 'hidden'
                                                           }}
                                                           onMouseEnter={(e) => setIsShown(e)}
                                                           onMouseLeave={(e) => setShowTooltip(false)}
                                                       >{c.subject}</Typography>
                                                   </Tooltip>
                                           </Grid>
                                       </Grid>
                                       { index === selectedCertIndex &&
                                        <KeyboardDoubleArrowRightIcon sx={{
                                           color: colors.gray200,
                                           fontSize: 30,
                                           position: 'absolute',
                                           right: 8,
                                        }}/>
                                       }
                                   </ListItem>
                               )
                       }
                       )}
                       </List>
                   </Grid>
                   </Grid>
                   <Divider sx={{ height: '100%', m: 0.5, backgroundColor: colors.gray300 }} orientation="vertical" />
                   <Grid item container xs={5}>
                       <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                           <Typography className={classes.treeTitle}>VULNERABILITIES</Typography>
                       </Grid>
                       {selectedCert?.issues?.length > 0 ?
                           <>
                               {selectedCert?.issues?.map((issue, i) => {
                                   return (
                                    <div key={i}>
                                       <Grid container>
                                          <Grid item container className={classes.severityLowLabel}>
                                              <Typography style={{color: colors.red200, fontSize: 16}}>{issue}</Typography>
                                          </Grid>
                                        </Grid>
                                        {issue.toLowerCase() === 'hostname mismatch' && <>
                                          <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                                            <Typography className={classes.treeTitle}>EXPECTED SUBJECT NAME</Typography>
                                          </Grid>
                                          <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                                            <Typography style={{ fontSize: 16 }}>{new URLSearchParams(window.location.search).get('hostname') || ''}</Typography>
                                          </Grid>
                                          <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                                            <Typography className={classes.treeTitle}>ACTUAL SUBJECT NAME</Typography>
                                          </Grid>
                                          <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                                            <Typography style={{ fontSize: 16 }}>{selectedCert.subject}</Typography>
                                          </Grid>
                                        </>}
                                    </div>
                                   )
                               })}
                           </> :
                           <Grid item container style={{ marginLeft: 8 }}>
                               <Typography style={{ fontSize: 16 }}>--</Typography>
                           </Grid>
                       }
                       <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                           <Typography className={classes.treeTitle}>EXPIRATION DATE</Typography>
                       </Grid>
                       <Grid item container style={{ marginLeft: 8 }}>
                           <Typography style={{ fontSize: 16 }}>{formatDate(selectedCert?.expirationDate)}</Typography>
                       </Grid>
                       <Grid item container style={{ marginTop: 8, marginLeft: 8 }}>
                           <Typography className={classes.treeTitle}>PROTOCOL</Typography>
                       </Grid>
                       <Grid item container style={{ marginLeft: 8 }}>
                           <Typography style={{ fontSize: 16 }}>{selectedServerData?.tlsInfo?.protocol}</Typography>
                       </Grid>
                   </Grid>
               </Grid>
            </Grid>
        </>
    );
};

export default SslEndpointsDetails;
