import React, {useState} from "react";
import {
  FormControlLabel,
  MenuItem, MenuList, Popover, RadioGroup, TableBody, TableCell, TableHead, TableRow, TextField, Typography
} from "@mui/material";
import {SpSwitch, useStyles} from "../../DiscoveryConfig/styles";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {enhancedTableStyles} from "../../../assets/theme";
import Table from "@mui/material/Table";
import CqChip from "../../../components/common/CqChip";
import WarningIcon from "@mui/icons-material/Warning";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "../../../components/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";

const tableHeaderColumns = [
  {
    name: 'Name',
    id: 'name'
  },
  {
    name: 'Status',
    id: 'status'
  },
  {
    name: 'Finding Severity',
    id: 'severity',
  }
];

const CustomTableHeaderCell = ({ column }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.headerRowCell}>
      <Typography variant="subtitle2">
        {column.name}
      </Typography>
    </TableCell>
  );
};


const TlsFindingsDialog = ({ tlsData, handleFindingsAlgoChange }) => {
  const tableClasses = enhancedTableStyles();
  const classes = useStyles();

  return (
    <>
      <TableContainer className={tableClasses.root}>
          <Table>
            <colgroup>
              <col style={{width:'30%'}}/>
              <col style={{width:'30%'}}/>
              <col style={{width:'40%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                {tableHeaderColumns.map((c, i) => (
                  <CustomTableHeaderCell
                    key={`column-${i + 1}`}
                    column={c}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { tlsData.map((item, index) => {
                return (
                  <TableRow key={`${item.name}-${index}`} className={classes.rowSelection}>
                    <TableCell>
                      <Typography
                        variant={'h4'}
                        style={{ fontWeight: 700 }}
                      >
                        {item.value}
                      </Typography>
                      <Typography
                        variant={'body2'}
                      >
                        {item.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={<SpSwitch sx={{ m: 1 }} />}
                        checked={item.enabled}
                        onChange={(event) => handleFindingsAlgoChange(event, event.target.checked, 'tlsEnabled', item)}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <RadioGroup
                          row={true}
                          style={{ marginLeft: 10 }}
                          onChange={(event) => handleFindingsAlgoChange(event, event.target.value, 'tlsSeverity', item)}
                          value={item.severity}>
                          <FormControlLabel
                            value='HIGH'
                            control={<Radio className={classes.severityRadioButton} />}
                            label={<Typography variant="body2" style={{ fontSize: '16px'}}>High</Typography>}
                          />
                          <FormControlLabel
                            value='MEDIUM'
                            control={<Radio className={classes.severityRadioButton} />}
                            label={<Typography variant="body2" style={{ fontSize: '16px'}}>Medium</Typography>}
                          />
                          <FormControlLabel
                            value='INFO'
                            control={<Radio className={classes.severityRadioButton} />}
                            label={<Typography variant="body2" style={{ fontSize: '16px'}}>Info</Typography>}
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
    </>
  );
};

export default TlsFindingsDialog;
