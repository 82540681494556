import { createContext } from 'react';

export const CrawlContext = createContext({
  rules: { exclude: [], include: [] },
  subDomains: [],
  domain: '',
  dispatch: undefined,
  setDialogOpen: undefined,
  setNotify: undefined,
});
