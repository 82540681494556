import React, { useEffect, useState, useContext } from "react";
import { styled } from '@mui/material/styles';
import {
  Grid, Table, TableBody,
  TableCell, TableRow, Typography,
  Switch, IconButton, FormControlLabel,
  TextField, 
  Box,
  Chip,
  TableHead} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import QueryBuilderAlgo from '../../../components/QueryBuilderAlgo';
import { WrapperContext } from '../../../components/wrapper/wrapperContext';

import {
  apiGatewayDiscoveryConfigs,
  apiGatewayDiscoveryConfig,
  submitGatewayConfig,
  submitGatewayConfigStatus,
  addGatewayConfig,
  apiDefaultGatewayDiscoveryConfig
 } from '../provider'
import CqDialog from '../../../components/common/CqDialog';
import {
  StyledContainer,
} from '../styles';
import { colors } from "../../../assets/theme";
import { endpointLevelFields } from '../../../lib/queryBuilderUtils';
import CqLoading from '../../../components/common/CqLoading';
import { convertJoinToComparator, removeIdFieldsAndConvertCombinator } from '../../../lib/queryBuilderUtils'
import { DiscoveryContext } from '../DiscoveryContext';
import CqButton from "../../../components/common/CqButton";
import ShortUniqueId from 'short-unique-id';

const StyledCell = styled(TableCell)(() => ({
  backgroundColor: colors.gray750,
  // width: '300px',
  borderRadius: '4px',
  padding: 0
}))

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  textTransform: 'capitalize',
  '& .MuiFormControlLabel-label': {
    fontSize: '14px'
  }
}))

const StyledChip = styled(Chip)(({type}) => {
  const styles = type === 'CUSTOM' ? {background: colors.darkBlue400, color: colors.darkBlue300}: {}
  return {...styles, textTransform: 'capitalize'}
})

const CrawlGateway = () => {
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openStatusDialog, setStatusDialog] = useState(false)
  const [editConfig, setEditConfig] = useState({})
  const [query, setQuery] = useState({rules:[]});
  const [loading, setLoading] = useState(false)
  const [loadingGateway, setLoadingGateway] = useState(false)
  const discoverContext = useContext(DiscoveryContext);
  const wrapperContext = useContext(WrapperContext)
  const gatewayConfig = wrapperContext.gatewayConfig
  const gatewayConfigSoted = gatewayConfig.sort((v1, v2) => v2.type === 'SYSTEM' ? 1 : -1)
  const addGateway = () => {
    const uid = new ShortUniqueId({ length: 10 });
    setEditConfig({name: uid(), type: 'newGw', status: 'ENABLED'})
    setOpenEditDialog(true)
    setQuery({combinator: 'or', rules: []})
  }

  const fetchGatewayData = async () => {
    try {
      const gatewayData = await apiGatewayDiscoveryConfigs()
      wrapperContext.setGatewayConfig(gatewayData?.data?.getApiGatewayDiscoveryConfigs?.data || [])
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    } 
  }

  const handleEditConfig = (val) => async () => {
    setOpenEditDialog(val => !val)
    setLoadingGateway(true)
    try {
      const configData = await apiGatewayDiscoveryConfig(val); 
      const configDetails = configData?.data?.getApiGatewayDiscoveryConfig?.data
      setEditConfig(configDetails || {})
      if(configDetails?.algo) {
        const algo = JSON.parse(atob(configDetails?.algo, 'base64'))
        algo.rules = algo.rules.map(rl => {
          rl.value = typeof(rl.value) === 'object' ? rl.value.join(',') : rl.value
          return rl
        })
        setQuery(convertJoinToComparator(algo))
      }
      setLoadingGateway(false)
    } catch (e) {
      setEditConfig({})
      setLoadingGateway(false)
      setOpenEditDialog(false)
      setEditConfig({})
      console.error(e)
    }
  }

  const handleStatusChange = async () => {
    setLoadingGateway(true)
    try {
      await submitGatewayConfigStatus({name: editConfig.name, status: editConfig.status})
      setTimeout(() => {
        fetchGatewayData()
      }, 500)
      setEditConfig({})
      discoverContext.setNotify({
        open: true,
        message: 'Saved Gateway Config!',
        success: true,
      });
      setStatusDialog(false)
      setLoadingGateway(false)
    } catch {
      discoverContext.setNotify({
        open: true,
        message: 'Some thing went wrong please try again!',
        success: false,
      });
      setEditConfig({})
      setStatusDialog(false)
      setLoadingGateway(false)
    }
  }

  const submitConfig = async () => {
    setLoadingGateway(true)
    try {
      const data = editConfig
      let algo = removeIdFieldsAndConvertCombinator(query)
      algo.rules = algo.rules.map(val => {
        let valueList = val.value
        if(val.value && typeof(val.value) === 'string'){
          valueList = val.value.split(',')
        }
        const rl = {field: val.field, operator: val.operator, value: valueList}
        if(val.param){
          rl.param = val.param
        }
        return rl
      })
      const algoString = btoa(JSON.stringify(algo))
      const config = {displayName: data.displayName, status: data.status, algo: algoString}
      if(editConfig.type === 'newGw'){
        await addGatewayConfig({name: data.name, displayName: data.displayName, status: data.status, algo: algoString})
      } else {
        await submitGatewayConfig(config, data.name)
      }
      setTimeout(() => {
        fetchGatewayData()
      }, 500)
      
      setEditConfig({})
      discoverContext.setNotify({
        open: true,
        message: 'Saved Gateway Config!',
        success: true,
      });
      setOpenEditDialog(val => !val)
      setLoadingGateway(false)
    } catch(e) {
      console.log(e)
      discoverContext.setNotify({
        open: true,
        message: 'Some thing went wrong please try again!',
        success: false,
      });
      setOpenEditDialog(val => !val)
      setLoadingGateway(false)
    }
  }

  const handleEditDialog = () => {
    setOpenEditDialog(val => !val)
    setEditConfig({})
    setQuery({combinator: 'or',rules: []})
  }

  const getDefultConfig = (name) => async () => {
    try {
      const configData = await apiDefaultGatewayDiscoveryConfig(name); 
      const configDetails = configData?.data?.getApiGatewayDiscoveryDefaultConfig?.data
      setEditConfig(configDetails || {})
      if(configDetails?.algo) {
        const algo = JSON.parse(atob(configDetails?.algo, 'base64'))
        algo.rules = algo.rules.map(rl => {
          rl.value = typeof(rl.value) === 'object' ? rl.value.join(',') : rl.value
          return rl
        })
        setQuery(convertJoinToComparator(algo))
      }
    } catch (e) {
      console.error(e)
    }
  }

  let validate = true;
  if(editConfig.displayName === ''){
    validate = false;
  }
  if(!query.rules || query?.rules?.length === 0){
    validate = false
  }
  for(const rl of query.rules || []){
    if(rl.field === 'resHeader' && rl.param === '' && rl.value === ''){
      validate = false
    } else if(rl.value === ''){
      validate = false
    }
  }
  return (loading ? <CqLoading /> : <>
      <StyledContainer container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">API Gateway Detection</Typography>
          <Typography variant="caption">
            Automatically detect and catalog all API gateways across your domains.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Table sx={{border: `1px solid ${colors.gray700}`, display: 'block', height: '348px', overflow: 'scroll'}}>
            <TableHead>
              <TableRow>
                <TableCell sx={{width: '300px'}}>Gateway Detection</TableCell>
                <TableCell sx={{width: '225px'}}>Type</TableCell>
                <TableCell sx={{width: '225px'}}>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gatewayConfigSoted.map((val, i) => (
                <TableRow  sx={{border: `1px solid ${colors.gray700}`}} key={`${val.displayName}${i}`}>
                  <TableCell>{val.displayName}</TableCell>
                  <TableCell>
                    <StyledChip type={val.type} label={val.type && val.type.toLowerCase()} />
                  </TableCell>
                  <TableCell>
                    <StyledFormControlLabel control={<Switch
                        label
                        checked={val.status === 'ENABLED'}
                        onChange={(e) => {
                          const stat = e.target.checked ? 'ENABLED' : 'DISABLED'
                          setEditConfig({status: stat, name: val.name, displayName: val.displayName})
                          setStatusDialog(true)
                        }}
                      />} label={val.status && val.status.toLowerCase()} />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={handleEditConfig(val.name)}>
                      <EditOutlinedIcon sx={{color: colors.gray400}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ml: 'auto', width: '150px', pt: '8px'}}>
            <CqButton variant='borderBlue' onClick={addGateway}>Add New Detection</CqButton>
          </Box>
        </Grid>
      </StyledContainer>
      <CqDialog
        open={openEditDialog}
        onClose={handleEditDialog}
        title={editConfig.type === 'newGw' ? 'New Gateway Detection' : 'Edit Gateway Detection'}
        onSubmit={submitConfig}
        showExtraBtn={editConfig.type === 'SYSTEM'}
        onExtraBtnClick={getDefultConfig(editConfig.name)}
        submitBtnDisabled={!validate}
        extraBtnText='Show Defaults'
        backdrop={loadingGateway}
      >
        <>
          <Grid container spacing={2} sx={{mt: '20px', borderRadius: '4px', width: '99%'}}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                value={editConfig.displayName || ''}
                onChange={(e) => setEditConfig(val => ({...val, displayName: e.target.value}))}
                label='API Gateway'
              />
            </Grid>
            <Grid item xs={6}>
              <Table sx={{border: `1px solid ${colors.gray700}`, height: '55px'}}><TableBody><TableRow>
                <StyledCell><Box sx={{padding: '10px'}}>Status</Box></StyledCell>
                <TableCell sx={{padding: 0}}>
                  <FormControlLabel sx={{textTransform: 'capitalize', width: '150px', ml: '10px'}} control={<Switch
                    checked={editConfig.status === 'ENABLED'}
                    onChange={(e) => {
                      const val = e.target.checked ? 'ENABLED' : 'DISABLED'
                      setEditConfig(config => ({...config, status: val}))
                    }}
                  />} label={editConfig.status && editConfig.status.toLowerCase()} />
                </TableCell>
              </TableRow></TableBody></Table>
            </Grid>
          </Grid>
          <Grid sx={{pt: '10px'}}>
            <QueryBuilderAlgo  style={{paddingLeft: 0}} fields={endpointLevelFields} query={query} setQuery={setQuery} />
          </Grid>
        </>
      </CqDialog>
      <CqDialog
        open={openStatusDialog}
        onClose={() => {
          setStatusDialog(false)
          setEditConfig({})
        }}
        title='Confirm Status Change'
        onSubmit={handleStatusChange}
        submitBtnText='Confirm'
        backdrop={loadingGateway}
      >
        Updating {editConfig.displayName} to {editConfig.status}
      </CqDialog>
    </>
  )
}

export default CrawlGateway