import React, { useState, useEffect } from "react";
import { Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Descope, useUser } from "@descope/react-sdk";
import { graphqlQuery } from '../../services/graphql';
import { getSsoConfig } from './userGraphQL';
import { colors } from '../../assets/theme'
import CqButton from "../../components/common/CqButton";

const StyledCell = styled(TableCell)(({background}) => ({
  backgroundColor: background === 'true' ? colors.gray750 : 'none',
  borderRadius: '4px',
  padding: '10px'
}))

const StyledTableRow = styled(TableRow)({
  border: `0.5px solid ${colors.gray500}`
})

const SsoInfo = () => {
  const [ssoItems, setSsoItems] = useState({})
  const [isedit, setisEdit] = useState(false)
  useEffect(() => {
    fetchSsoConfig();
  }, []);

  const fetchSsoConfig = async () => {
    try {
      const sspObj = await graphqlQuery(getSsoConfig)
      setSsoItems({
        idpEntityId: sspObj?.data?.users?.getSsoConfig?.idpEntityId || '',
        idpSSOUrl: sspObj?.data?.users?.getSsoConfig?.idpSSOUrl || '',
        idpCertificate: sspObj?.data?.users?.getSsoConfig?.idpCertificate || '',
        idpMetadataUrl: sspObj?.data?.users?.getSsoConfig?.idpMetadataUrl || '',
        domain: sspObj?.data?.users?.getSsoConfig?.domain || '',
      })
    } catch (e) {
    }

  }
  const { user } = useUser();
  const id = user?.userTenants[0].tenantId || ''
  return (
    <>
      {!isedit && <>
        <Table sx={{marginBottom: '20px'}}>
          <TableBody>
            <StyledTableRow>
              <StyledCell background={"true"}><Typography variant='overline'>IDP Entity:</Typography></StyledCell>
              <StyledCell><Typography>{ssoItems.idpEntityId}</Typography></StyledCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledCell background={"true"}><Typography variant='overline'>IDP SSO URL</Typography></StyledCell>
              <StyledCell><Typography>{ssoItems.idpSSOUrl}</Typography></StyledCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledCell background={"true"}><Typography variant='overline'>IDP Certificate</Typography></StyledCell>
              <StyledCell><Typography>{ssoItems.idpCertificate}</Typography></StyledCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledCell background={"true"}><Typography variant='overline'>IDP Meta data Url</Typography></StyledCell>
              <StyledCell><Typography>{ssoItems.idpMetadataUrl}</Typography></StyledCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledCell background={"true"}><Typography variant='overline'>Company SAML Domain</Typography></StyledCell>
              <StyledCell><Typography>{ssoItems.domain}</Typography></StyledCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <CqButton onClick={() => setisEdit(val => !val)}variant='primary'>Configure SSO</CqButton>
      </>}
      {isedit && <Descope
        flowId="saml-config"
        theme="dark"
        tenant={id}
        onSuccess={(e) => {
          fetchSsoConfig()
          setisEdit(val => !val)
        }}
        onError={(e) => { console.log(`saml-config error`, e);}}
      />}
    </>
  )
}

export default SsoInfo
