import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import 'react-querybuilder/dist/query-builder.css';
import {formatQuery} from "react-querybuilder/formatQuery";
import QueryBuilderAlgo from '../../../components/QueryBuilderAlgo';
import { hostLevelFields, endpointLevelFields } from '../../../lib/queryBuilderUtils';

const convertJoinToComparator = (jsonObj) => {
  if (jsonObj?.hasOwnProperty("join")) {
    jsonObj.combinator = jsonObj?.join?.toLowerCase();
    delete jsonObj?.join;
  }

  if (jsonObj?.hasOwnProperty("rules")) {
    jsonObj.rules.forEach((rule) => {
      convertJoinToComparator(rule);
    });
  }
  return jsonObj;
};


const FindingsConfig = ({selectedFindingAlgo}) => {
  // console.log("selectedFindingAlgo", selectedFindingAlgo);
  const [query, setQuery] = useState(convertJoinToComparator(selectedFindingAlgo?.algo) || {});

  return (
    <Grid>
      {query.hasOwnProperty('combinator') &&
        <>
          <Grid>
            {formatQuery(query, { format: 'sql' })}
          </Grid>
          <QueryBuilderAlgo
            style={{paddingLeft: 0}}
            query={query}
            setQuery={setQuery}
            fields={selectedFindingAlgo?.name === 'NON_PROD_OR_ORIGIN' ? hostLevelFields : endpointLevelFields}
            disabled
          />
      </>
      }
    </Grid>
  )
}

export default FindingsConfig
