import React from 'react';

import { getDomainReportData } from './DashboardQueries';
import { graphqlQuery } from '../../services/graphql';
import { colors } from '../../assets/theme';

import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Reports from '../../components/Reports';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import zeroIssues from '../../assets/img/zero-issues.png';
import moment from 'moment';
import { getCrawlSummaryHistogramQuery } from '../crawls/crawlsGraphQL';
import { DISCOVERIES } from '../crawls/constants';
import ShortUniqueId from 'short-unique-id';
import { ISSUE_TYPES } from '../../lib/utils';

const uid = new ShortUniqueId({ length: 10 });

const severity = {
  high: colors.errorIcon,
  medium: colors.yellowChart,
  low: colors.syslogBlue,
  none: colors.neutralFill,
  decrease: colors.successFill,
  increase: colors.successFill,
};

export const generatePdf = async (
  setDialogInProgress,
  selectedPdfDomains,
  setOpenPdfDialog,
  licenseInfo,
  handleExportClose
) => {
  setDialogInProgress(true);
  const domains = selectedPdfDomains
    .filter((i) => {
      return i.selected === true;
    })
    .map((o) => {
      return o.domain;
    });
  const params = {
    crawlReportDomains: domains,
  };

  const crawlTrendParams = {
    crawlHistogramDomains: domains,
    days: 7,
  };
  try {
    let crawlResponse = await graphqlQuery(
      getCrawlSummaryHistogramQuery,
      crawlTrendParams
    );
    let aggResponse = await graphqlQuery(getDomainReportData, { domains });

    if (
      crawlResponse === undefined ||
      aggResponse.data === undefined ||
      aggResponse.data.discovery.getDomainReportData.status === 'E'
    ) {
      //display message, TODO
      return;
    }

    let report = aggResponse.data ? aggResponse.data?.discovery?.getDomainReportData : {};
    const crawlData = crawlResponse.data
      ? crawlResponse.data.discovery.getCrawlHistoryHistogram
      : {};
    const issuesData = [
      {
        category: 'high',
        count: report.issues.total_high,
        color: severity['high'],
      },
      {
        category: 'medium',
        count: report.issues.total_medium,
        color: severity['medium'],
      },
      {
        category: 'low',
        count: report.issues.total_low,
        color: severity['low'],
      },
    ];

    const appHostingIaasProvidersData = [
      {
        category: 'IaaS Providers',
        count: report['hosting_providers']['iaas_total'],
        color: colors.orange500,
      },
      {
        category: 'CDN Providers',
        count: report['hosting_providers']['cdn_total'],
        color: colors.gray650,
      },
      {
        category: 'ISP Providers',
        count: report['hosting_providers']['isp_total'],
        color: colors.gray650,
      },
    ];

    const appHostingCdnProvidersData = [
      {
        category: 'IaaS Providers',
        count: report['hosting_providers']['iaas_total'],
        color: colors.gray650,
      },
      {
        category: 'CDN Providers',
        count: report['hosting_providers']['cdn_total'],
        color: colors.magenta300,
      },
      {
        category: 'ISP Providers',
        count: report['hosting_providers']['isp_total'],
        color: colors.gray650,
      },
    ];

    const appHostingIspProvidersData = [
      {
        category: 'IaaS Providers',
        count: report['hosting_providers']['iaas_total'],
        color: colors.gray650,
      },
      {
        category: 'CDN Providers',
        count: report['hosting_providers']['cdn_total'],
        color: colors.gray650,
      },
      {
        category: 'ISP Providers',
        count: report['hosting_providers']['isp_total'],
        color: colors.green300,
      },
    ];

    let reportImgData = {
      attackSurfaceDomainsImg: undefined,
      appHostingIaasImgData: undefined,
      appHostingCdnImgData: undefined,
      appHostingIspImgData: undefined,
      issuesImg: undefined,
      weeklyCrawlingTrendImgData: undefined,
    };

    let chartPromises = [];
    const createReportChart = (type, chartDataKey, ...params) => {
      let p =
        type === 'column'
          ? createChart(...params)
          : type === 'pie'
          ? createPieChart(...params)
          : createLineChart(crawlData);
      p.then((chartInfo) => {
        reportImgData[chartDataKey] = chartInfo.img;
        try {
          //dispose the chart
          chartInfo.chart.dispose();
        } catch (error) {
          console.log(`error while disposing chart`, error);
        }
      });
      chartPromises.push(p);
    };

    createReportChart(
      'column',
      'attackSurfaceDomainsImg',
      report['attack_surface']
    );
    if (report.issues.total_servers_at_risk > 0) {
      createReportChart('pie', 'issuesImg', issuesData);
    } else {
      reportImgData['issuesImg'] = zeroIssues;
    }

    createReportChart(
      'pie',
      'appHostingIaasImgData',
      appHostingIaasProvidersData
    );
    createReportChart(
      'pie',
      'appHostingCdnImgData',
      appHostingCdnProvidersData
    );
    createReportChart(
      'pie',
      'appHostingIspImgData',
      appHostingIspProvidersData
    );

    const crawlHistoryDataPoints = crawlData?.histogram.filter(
      (i) =>
        (i.hosts &&
          i.providers) !== null
    ).length;
    if (crawlHistoryDataPoints > 1) {
      createReportChart('line', 'weeklyCrawlingTrendImgData');
    }

    await Promise.allSettled(chartPromises);

    const asPdf = await pdf(
      <Reports
        data={report}
        imgData={reportImgData.attackSurfaceDomainsImg}
        issuesImg={reportImgData.issuesImg}
        appHostingImgData={reportImgData.appHostingIaasImgData}
        appHosting2ImgData={reportImgData.appHostingCdnImgData}
        appHosting3ImgData={reportImgData.appHostingIspImgData}
        weeklyCrawlTrendsImgData={reportImgData.weeklyCrawlingTrendImgData}
        licenseInfo={licenseInfo}
      />
    ).toBlob();
    saveAs(
      asPdf,
      `CequenceAPISpyder_ExecutiveReport_${moment().format('YYYYMMMDD')}.pdf`
    );
    handleExportClose();
  } catch (error) {
    console.log(`graphql query error`, error);
  } finally {
    setDialogInProgress(false);
    setOpenPdfDialog(false);
  }
};

const createPromise = () => {
  let resolve, reject;
  let p = new Promise(async (_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  p.resolve = resolve;
  p.reject = reject;

  return p;
};

const createSeries = (chart, field, name, color) => {
  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.strokeWidth = 2;
  series.minBulletDistance = 10;
  series.stroke = am4core.color(color);
  series.name = name;
};

const createChart = (data) => {
  let promise = createPromise();
  const chartId = 'report-xychart-' + uid();
  let chart = am4core.create(chartId, am4charts.XYChart);

  const domainServersData = data.domains.map((e) => ({
    domain: e.name,
    count: e.hosts,
  }));

  chart.background.fill = am4core.color('#f00', 0);
  let top10 = domainServersData.slice(0, 10);
  chart.data = top10;

  let categoryLabel = 'Domains';
  if (domainServersData.length > 10)
    categoryLabel += ` (Total: ${domainServersData.length})`;

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'domain';
  categoryAxis.title.text = categoryLabel;
  categoryAxis.title.fontSize = 12;
  categoryAxis.title.marginTop = 10;
  categoryAxis.title.fill = am4core.color(colors.white);
  categoryAxis.renderer.labels.template.fontSize = 8;
  categoryAxis.renderer.labels.template.fill = am4core.color(colors.white);
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.renderer.grid.template.strokeWidth = 0;
  let label = categoryAxis.renderer.labels.template;
  label.wrap = true;
  label.maxWidth = 60;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = 'API Hosts';
  valueAxis.title.fontSize = 12;
  valueAxis.title.marginRight = 20;
  valueAxis.title.align = 'center';
  valueAxis.title.fill = am4core.color(colors.white);
  valueAxis.renderer.labels.template.fontSize = 8;
  valueAxis.renderer.labels.template.disabled = true;
  valueAxis.renderer.grid.template.strokeWidth = 0;

  let series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = 'count';
  series.dataFields.categoryX = 'domain';
  series.name = 'Domain';
  series.columns.template.strokeWidth = 0;
  series.columns.template.column.cornerRadiusTopLeft = 2;
  series.columns.template.column.cornerRadiusTopRight = 2;

  let labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.text = '{valueY}';
  labelBullet.label.fontSize = 8;
  labelBullet.label.fill = am4core.color(colors.fontWhitish);
  labelBullet.label.verticalCenter = 'bottom';
  labelBullet.label.dy = -10;

  chart.maskBullets = false;

  // Add distinctive colors for each column using adapter
  series.columns.template.adapter.add('fill', (fill, target) => {
    return chart.colors.getIndex(target.dataItem.index);
  });

  let cellSize = 50;
  chart.events.on('datavalidated', function (ev) {
    let chart = ev.target;
    let categoryAxis = chart.xAxes.getIndex(0);

    // Calculate chart width
    let adjustWidth = chart.data.length * cellSize - categoryAxis.pixelWidth;

    // get current chart width
    let targetWidth = chart.pixelWidth + adjustWidth;

    // Set it on chart's container
    chart.svgContainer.htmlElement.style.width = targetWidth + 'px';
  });

  // export as a png image
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.backgroundColor = am4core.color('#f00', 0);
  let options = chart.exporting.getFormatOptions('png');
  options.scale = 20;
  chart.exporting.setFormatOptions('png', options);
  chart.exporting.getImage('png').then((img) => {
    promise.resolve({
      img: img,
      chart: chart,
    });
  });

  return promise;
};

const getChartKeys = () => {
  const obj = {}
  obj.highSeverityIssues = {
    name: 'High',
  }
  obj.mediumSeverityIssues = {
    name: 'Medium',
  }
  obj.lowSeverityIssues = {
    name: 'Info',
  }
  
  obj.apiServers = {
    name: 'API Hosts',
    color: 'outline',
  }

  obj.appHostingProviders = {
    name: 'App Hosting Providers',
    color: 'outline',
  }
  return obj

}

const createLineChart = (crawlData) => {
  let promise = createPromise();
  const chartId = 'report-xychart-' + uid();
  let chart = am4core.create(chartId, am4charts.XYChart);

  chart.height = am4core.percent(100);
  chart.width = am4core.percent(100);

  chart.legend = new am4charts.Legend();
  chart.legend.position = 'right';
  chart.legend.fontSize = 12;
  chart.legend.paddingLeft = 10;
  chart.legend.valign = 'center';

  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 50;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.fontSize = 11;
  const discoveries = getChartKeys(crawlData?.histogram || {})
  Object.entries(discoveries).map((item, index) => {
    createSeries(chart, item[0], item[1].name, colors.issuesChart[index]);
  });

  const modifyChartData = crawlData.histogram.map((item, idx) => {
    return {
      date: item.date,
      highSeverityIssues: item?.findings?.high,
      mediumSeverityIssues: item?.findings?.medium,
      lowSeverityIssues: item?.findings?.info,
      apiServers: item['hosts']?.['discovered'],
      appHostingProviders: item['providers']?.['discovered'],
  }});
  chart.data = modifyChartData;

  chart.events.on('datavalidated', function (ev) {
    let chart = ev.target;
    chart.svgContainer.htmlElement.style.height = '600px';
    chart.svgContainer.htmlElement.style.width = '1200px';
  });

  // export as a png image
  chart.exporting.menu = new am4core.ExportMenu();
  // chart.exporting.backgroundColor = am4core.color("#f00", 0);
  let options = chart.exporting.getFormatOptions('png');
  // options.scale = 20;
  chart.exporting.setFormatOptions('png', options);
  chart.exporting.getImage('png').then((img) => {
    promise.resolve({
      img: img,
      chart: chart,
    });
  });

  return promise;
};

const createPieChart = (data) => {
  let promise = createPromise();
  const chartId = 'report-piechart-' + uid();
  let chart = am4core.create(chartId, am4charts.PieChart);
  chart.data = data;
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = 'count';
  pieSeries.dataFields.category = 'category';
  pieSeries.ticks.template.disabled = true;
  pieSeries.labels.template.disabled = true;

  // cut a hole in our Pie chart the size of 40% the radius
  chart.innerRadius = am4core.percent(60);

  pieSeries.slices.template.propertyFields.fill = 'color';
  pieSeries.slices.template.stroke = am4core.color('#222');
  pieSeries.slices.template.strokeWidth = 5;
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.slices.template.fillOpacity = 1;

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.backgroundColor = am4core.color('#f00', 0);
  let options = chart.exporting.getFormatOptions('png');
  options.scale = 20;
  chart.exporting.setFormatOptions('png', options);
  chart.exporting.getImage('png').then((img) => {
    promise.resolve({
      img: img,
      chart: chart,
    });
  });

  return promise;
};
